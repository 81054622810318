import * as service from "../services/wordpressApi.service";
import moment from 'moment';

// Definition of the actions name

export const ACTIONS = {
    WORDPRESS_ERROR: 'WORDPRESS_ERROR',
    WORDPRESS_FETCHING: 'WORDPRESS_FETCHING',
    WORDPRESS_FETCHING_DONE: 'WORDPRESS_FETCHING_DONE',
    //
    WORDPRESS_WORDPRESSDATA: 'WORDPRESS_WORDPRESSDATA',
    WORDPRESS_CACHE: 'WORDPRESS_CACHE',
    RESET_WORDPRESS_CACHE: 'RESET_WORDPRESS_CACHE'
}

export const set_state_error = (err) => {
    return { type: ACTIONS.WORDPRESS_ERROR, payload: err };
}

export const set_state_fetching = () => {
    return { type: ACTIONS.WORDPRESS_FETCHING };
}

export const set_state_fetching_done = () => {
    return { type: ACTIONS.WORDPRESS_FETCHING_DONE };
}

export const set_wordpress = (data) => {
    return { type: ACTIONS.WORDPRESS_WORDPRESSDATA, payload: data };
}

export const set_wordpress_cache = (data) => {
  return { type: ACTIONS.WORDPRESS_CACHE, payload: data };
}

export const reset_wordpress_cache = () => {
  return { type: ACTIONS.RESET_WORDPRESS_CACHE, payload: {} };
}

export const createCacheData = () => {
  return async (dispatch, getState) => {  
    dispatch(set_wordpress_cache(service.createCacheConfig(parseInt(moment().format("x")))));
  }
}

export const fetchWordpressData = () => {
  return async (dispatch, getState) => {
    const cache = getState().wordpress.wordpressCacheConfig;
    if (Object.keys(cache).length === 0 || parseInt(moment().format("x")) > cache.dateDataWillExpired) {
      try {
        dispatch(set_state_fetching());
        let r = await service.getWordpressData();
        if (!r.data.errors) {
          dispatch(set_wordpress(r.data.data));
          dispatch(createCacheData());
          dispatch(set_state_fetching_done());
        } else {
          dispatch(set_state_error(err));
        }
      } catch (err) {
        dispatch(set_state_error(err));
      }
    }
  }
}