import { View, Text, StyleSheet, Pressable } from "react-native";
import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { getBetStatus, getDrawStatusColor } from '../../../utils/StatusUtils';
import { drawDateFormat } from "../../../utils/DateUtils";

import SVGRightArrow from '../../../assets/icons/SvgRightArrow';

export default function MutualLottery({borderColor, betDetails, currency}) {

  const { t } = useTranslation();
  const navigation = useNavigation();
  const theme = useTheme();

  const [drawStatusColor, setDrawStatusColor] = useState({
    background: theme.colors.greyText,
    text: '#FFFFFF'
  });
  const [drawsEnded, setDrawsEnded] = useState(false);

  const style = makeStyles(drawStatusColor, theme);  

  useEffect(() => {
    setDrawsEnded(betDetails.status !== 'WAIT');
    setDrawStatusColor(getDrawStatusColor(betDetails?.status))
  }, []);

  
  function getBallClass(ball) {
    const findGrid = betDetails?.draw?.listOfGridDrawn?.length > 0 && betDetails?.draw?.listOfGridDrawn[0].winningNumbers;
    return findGrid && findGrid.includes(ball) ? style.ballDrawed : style.ballPulled;
  }

  function getNumberClass(ball) {
    const findGrid = betDetails?.draw?.listOfGridDrawn?.length > 0 && betDetails?.draw?.listOfGridDrawn[0].winningNumbers;
    return findGrid && findGrid.includes(ball) ? style.numberDrawed : style.numberPulled;
  }

  function goToDetailsCombination() {
    navigation.navigate('GainsDetails', { betDetails, currency });
  }

  return (
    <View>
      <View style={style.drawPart}>

        <View style={style.drawInfoPart}>
          <Text style={style.drawInfosText} numberOfLines={1} ellipsizeMode='tail'>
          {/* {t('Draw')} */}{betDetails.draw?.alternativeDrawingName}
          </Text>
          <View style={style.drawInfosText}>
            <Text style={style.drawInfosStatus}>
              {t(getBetStatus(betDetails.status))}
            </Text>
          </View>
          <Text style={style.drawInfosDate} numberOfLines={1} ellipsizeMode='tail'>
            {drawDateFormat(betDetails.draw?.drawingDate ? betDetails.draw?.drawingDate : betDetails.draw?.closingDate)}
          </Text>
        </View>

        <View style={style.drawNamePart}>
          <Text style={style.drawNameText}>
            {betDetails.draw?.drawingName}
          </Text>
          <View style={style.separator}></View>
        </View>

          {betDetails.listOfBetGrids?.map((grid, index) => (
            <View key={index} style={style.drawSelection}>
              <View style={style.leftColumnSelection}>
                <Text style={style.leftTextSelection}>
                {t('Selection')}
                </Text>
              </View>
              <View style={style.rightColumnSelection}>
                {grid.listOfLottoNumbers?.map((ball, index) => (
                  <View key={index} style={drawsEnded ? getBallClass(ball) : style.ballPending}>
                    <Text style={drawsEnded ? getNumberClass(ball) : style.numberPending}>{ball}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}

      </View>
      {
        drawsEnded && (
        <View style={style.drawNumberPart}>
          <View style={style.drawNumberMap}>
          <Text style={style.drawNumberText}>{t('Draw')} : </Text>
            {betDetails.draw?.listOfGridDrawn?.map((grid, index) => (
              <View key={index} style={style.drawNumberContainer}>
                {grid.winningNumbers?.map((numb, index) => (
                <Text key={index} style={style.drawNumberText}>{numb}{numb === grid.winningNumbers[grid.winningNumbers.length - 1] ? ' ' : ', '}</Text>
                ))}
              </View>
            ))}
          </View>
          
          {
            betDetails.listOfGains && (
            <Pressable style={style.detailsCombinationBtn} onPress={() => goToDetailsCombination()}>
              <Text style={style.detailsBtnText}>
                {t('Details')}
              </Text>
              <SVGRightArrow style={{height: 2, width: 2, color: theme.colors.primary}} fill={theme.colors.primary}/>
            </Pressable>
            )
          }

        </View>              
        )
      }
    </View>
  );
}


//====================================== STYLE
const makeStyles = (drawStatusColor, theme) => StyleSheet.create({
  // Draw part 
  drawPart: {
    marginTop: 15,
    backgroundColor: '#F6F6F6',
    marginHorizontal: 15,
    paddingVertical: 8,
    paddingHorizontal: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1.71,

    elevation: 3,
  },
  drawInfoPart: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  drawInfosText: {
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
    color: theme.colors.greyText,
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  drawInfosDate: {
    fontSize: 12,
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    width: '25%',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  drawInfosStatus: {
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
    color: drawStatusColor.text,
    backgroundColor: drawStatusColor.background,
    paddingHorizontal: 6,
    paddingVertical: 1,
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawNamePart: {
    marginTop: 7,
    width: '100%',
    flexDirection: 'column'
  },
  drawNameText: {
    fontSize: 12,
    fontFamily: 'Raleway-MediumItalic',
    color: theme.colors.greyText,
    width: '100%',
  },
  separator: {
    marginTop: 5,
    width: '100%',
    height: 1,
    backgroundColor: '#112F6C',
    marginBottom: 10
  },
  // Draw Selection
  drawSelection: {
    marginTop: 7,
    width: '100%',
    flexDirection: 'row'
  },
  leftColumnSelection: {
    width: '25%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: 7
  },
  rightColumnSelection: {
    width: '75%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 7,
    flexWrap: 'wrap'
  },
  leftTextSelection: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 13,
  },
  drawNumberPart: {
    backgroundColor: theme.colors.primary,
    marginHorizontal: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1.71,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 14,
    paddingVertical: 9,
    justifyContent: 'space-between'
  },
  drawNumberText: {
    fontFamily: 'Raleway-Bold',
    color: '#FFFFFF',
    fontSize: 12,
  },
  drawNumberMap: {
    flexDirection: 'row',
  },
  drawNumberContainer: {
    flexDirection: 'row',
  },
  detailsCombinationBtn: {
    height: 20,
    paddingLeft: 9,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  detailsBtnText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 12
  },
  // Ball Numbers
  ballPending: {
    width: 28,
    height: 28,
    borderColor: theme.colors.greyText,
    borderWidth: 1,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  numberPending: {
    color: theme.colors.greyText,
    fontSize: 15,
    fontFamily: 'Raleway-Bold',
    marginTop: '-5%'
  },
  ballPulled: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
    justifyContent: 'center'
  },
  numberPulled: {
    color: '#8F8E95',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  },
  ballDrawed: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    marginBottom: 3,
    alignItems: 'center',
    backgroundColor: '#30C408',
    justifyContent: 'center'
  },
  numberDrawed: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  }
});


