import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text, StyleSheet, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import * as BasketActions from '../../store/actions/basket.actions';
import * as BetCreatorActions from '../../store/actions/betCreator.actions';
import { commonStyles } from '../../common/style/common.style';
import { GameRulesLink } from './GameRulesLink.comp';
import BetWithoutAdditionnal from './BetWithoutAdditionnal';
import BetWithAdditionnal from './BetWithAdditionnal';
import StakePicker from './StakePicker';
import ConsecutiveDrawPicker from './ConsecutiveDrawPicker';
import { formatNumber, sortNumber } from '../../utils/FormatUtils';
import { SVGFlash, SVGResult, SVGStats, SVGFavorisFull, } from '../../assets/icons';
import SwipeDownModal from '../kpLibrary/SwipeDownModal.comp';
import DrawResultList from '../results/DrawResultList.comp';
import StyledButton from '../kpLibrary/forms/StyledButton.comp';


const GridPicker = ({
  goNextStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = makeStyles(theme);
  const dispatch = useDispatch();

  const currentGame = useSelector(state => state.games.currentGame);
  const selectedSessions = useSelector(state => state.betCreator.selectedSessions);
  const selectedStake = useSelector(state => state.betCreator.selectedStake);
  const selectedBetType = useSelector(state => state.betCreator.selectedBetType);
  const selectedGrids = useSelector(state => state.betCreator.selectedGrids);
  const selectedConsecutiveDrawCount = useSelector(state => state.betCreator.selectedConsecutiveDrawCount);
  const totalPrice = useSelector(state => state.betCreator.totalPrice);
  const currency = useSelector(state => state.login.currency);

  const [totalDrawCountSelected, setTotalDrawCountSelected] = useState(1);
  const [showResultsModal, setShowResultsModal] = useState(false);

  const isAdditional = selectedBetType?.listOfGridParameters?.reduce((acc, grid) => {
      return acc || grid.maxAdditional > 0;
    }, false);

  const optionsList = [
    {
      icon: <SVGFlash fill='#30C408' style={{ width: 28, height: 28, marginBottom: 5 }} />,
      title: "Flash"
    },
    {
      icon: <SVGFavorisFull fill={theme.colors.primary} style={{ width: 28, height: 28, marginBottom: 5 }} />,
      title: "Favourites"
    },
    {
      icon: <SVGStats fill={theme.colors.secondary} style={{ width: 28, height: 28, marginBottom: 5 }} />,
      title: "Stats"
    },
    {
      icon: <SVGResult fill={theme.colors.primary} style={{ width: 28, height: 28, marginBottom: 5 }} />,
      title: "Results"
    }
  ];

  //====================================== Hooks

  useEffect(() => {
    if (selectedBetType) {
      const defaultStake = selectedBetType.stake || selectedBetType.defaultPrices?.[0] || selectedBetType.minBetAmount;
      dispatch(BetCreatorActions.set_stake_selected(defaultStake));
      dispatch(BetCreatorActions.initializeSelectedGrids(selectedBetType));
    }
  }, [selectedBetType])

  useEffect(() => {
    if (selectedGrids != undefined && selectedStake != undefined)
      computeTotalPrice();
  }, [selectedGrids, selectedStake, totalDrawCountSelected])

  useEffect(() => {
    //Note: the session count refers either to the selected sessions OR the consecutive draw count
    const _totalDrawCountSelected = Math.max(selectedConsecutiveDrawCount, Object.values(selectedSessions).length, 1);
    setTotalDrawCountSelected(_totalDrawCountSelected);
  }, [selectedSessions, selectedConsecutiveDrawCount]);


  //====================================== Functions

  function onNumberSelectChange(number, gridName) {
    const currentGrid = selectedGrids[gridName];
    if (currentGrid) {
      const { selectedNumbers, requiredNumber, selectedAdditionalNumbers, maxAdditional } = currentGrid;
      let temporaryNumbs = selectedNumbers;
      let tempAddNumbs = selectedAdditionalNumbers;

      // base numbers
      if (selectedNumbers.length < requiredNumber && !(selectedNumbers.includes(number))) {
        temporaryNumbs.push(number);
      }
      else if (selectedNumbers.length <= requiredNumber && selectedNumbers.includes(number)) {
        temporaryNumbs = selectedNumbers.filter(item => item !== number);
        selectedAdditionalNumbers[0] && temporaryNumbs.push(selectedAdditionalNumbers[0]);
        selectedAdditionalNumbers[0] && (tempAddNumbs = selectedAdditionalNumbers.filter(item => item !== selectedAdditionalNumbers[0]));
      }
      // additionnal numbers
      else if (maxAdditional > 0 && selectedNumbers.length === requiredNumber && selectedAdditionalNumbers.length < maxAdditional && !(selectedAdditionalNumbers.includes(number))) {
        tempAddNumbs.push(number);
      }
      else {
        tempAddNumbs = selectedAdditionalNumbers.filter(item => item !== number);
      }

      // Sort the numbers
      temporaryNumbs.sort(sortNumber);
      tempAddNumbs.sort(sortNumber);

      // Set all the infos of the player numbers
      dispatch(BetCreatorActions.updateSelectedGrid(gridName, {
        selectedNumbers: temporaryNumbs,
        selectedAdditionalNumbers: tempAddNumbs
      }));
    }
  }

  function validateGrid() { 
    let basketItemsToAdd = dispatch(BetCreatorActions.toBasketItemList());
    dispatch(BasketActions.set_game_info(currentGame))
    basketItemsToAdd.forEach(basketItem => dispatch(BasketActions.addItem(basketItem, selectedBetType)));
    // open the basket
    goNextStep();
    // reset the betCreator data
    dispatch(BetCreatorActions.reset_data());
  }

  function isEverythingSelected(nA) {
    return nA && Object.values(nA).reduce((acc, grid) => {
      return acc && grid && grid.selectedNumbers?.length === grid.requiredNumber;
    }, true) && selectedStake > 0;
  }

  function optionsListFunctions(type) {
    switch (type) {
      case 'Flash':
        flashNumbers();
        break;
      case 'Results':
        showGameResults();
        break;
      default:
        break;
    }
  }

  function flashNumbers() {
    selectedBetType.listOfGridParameters.forEach((elem) => {
      // Reset the numbers 
      dispatch(BetCreatorActions.updateSelectedGrid(elem.gridName, {
        selectedNumbers: [],
        selectedAdditionalNumbers: []
      }));

      const currentGrid = selectedGrids[elem.gridName];
      const { listOfLottoNumbers, requiredNumber } = currentGrid;

      // random player numbers
      let randomNumbersSet = new Set();
      while (randomNumbersSet.size < requiredNumber) {
        randomNumbersSet.add(listOfLottoNumbers[Math.floor(Math.random() * listOfLottoNumbers.length)]);
      }
      const selectedNumbers = [...randomNumbersSet];
      selectedNumbers.sort(sortNumber);

      selectedNumbers.forEach((num, index) => {
        setTimeout(function() {
          dispatch(BetCreatorActions.updateSelectedGridFlash(elem.gridName, num));          
        }, 160 * index);
      })
    })
  }

  function clearGridSelection() {
    dispatch(BetCreatorActions.initializeSelectedGrids(selectedBetType));
  }

  function showGameResults() {
    setShowResultsModal(true);
  }

  function computeTotalPrice() {
    let _totalPrice = totalDrawCountSelected * selectedStake;
    if (isAdditional) {
      Object.values(selectedGrids).forEach(grid => {
        let nbAdditionalNumbers = grid.selectedAdditionalNumbers?.length;
        let additionalMultiplier = nbAdditionalNumbers > 0 && grid.additionalMultiplier?.length ? grid.additionalMultiplier[nbAdditionalNumbers - 1] : null;
        _totalPrice *= additionalMultiplier || 1;
      }, _totalPrice);
    }
    dispatch(BetCreatorActions.set_totalPrice(_totalPrice));
  }

  //====================================== Render

  return !selectedBetType?.betTypeId ? <></> : (
    <View style={styles.container}>
      {/* <NextDrawCountdown /> */}
      <ScrollView>
        <View style={styles.body}>


          <View style={styles.cardContainer}>
            {optionsList.map((elem, index) => {
              return (
                <Pressable
                  key={index}
                  style={({ pressed }) => {
                    return [styles.cardMenu, !pressed && commonStyles.shadowedButton];                                                                  
                  }}
                  onPress={() => optionsListFunctions(elem.title)}
                >
                  {elem.icon}
                  <Text style={styles.title}>{elem.title}</Text>
                </Pressable>
              )
            })}
          </View>

          {Object.keys(selectedGrids).length > 0 &&
            <React.Fragment>

              <GameRulesLink game={currentGame} isFromGridPicker={true} />

              {isAdditional ?
                <BetWithAdditionnal
                  t={t}
                  selectedBetType={selectedBetType}
                  bet={formatNumber(totalPrice)}
                  styles={styles}
                  selectedGrids={selectedGrids}
                  onNumberSelectChange={onNumberSelectChange}
                  currency={currency}
                /> :
                <BetWithoutAdditionnal
                  t={t}
                  selectedBetType={selectedBetType}
                  styles={styles}
                  selectedGrids={selectedGrids}
                  onNumberSelectChange={onNumberSelectChange}
                  currency={currency}
                />
              }

              <StakePicker
                t={t}
                selectedBetType={selectedBetType}
                currency={currency}
              />

              <ConsecutiveDrawPicker />

              <View style={styles.recapContainer}>
                <View style={styles.recapGrid}>
                  <Text style={styles.recapTitle}>
                    {t('Number of draws')} : {totalDrawCountSelected}
                  </Text>
                </View>
                <View style={styles.recapGrid}>
                  <Text style={styles.recapTitle}>
                    {t('Total bet')} : {formatNumber(totalPrice)} {currency}
                  </Text>
                </View>
              </View>

              <View style={styles.actionButtons}>
                <StyledButton
                  text={t('validate')}
                  onPress={validateGrid}
                  disabled={!isEverythingSelected(selectedGrids)}
                />

                <StyledButton
                  text={t('clear')}
                  onPress={clearGridSelection}
                  fill='outline'
                  buttonStyle={{ marginTop: 0 }}
                />
              </View>


            </React.Fragment>
          }

          <SwipeDownModal
            gameName={currentGame.name}
            onClose={() => setShowResultsModal(false)}
            title={t('Draw results')}
            visible={showResultsModal}
            contentStyle={styles.swipeDownModalContent}
          >
            <DrawResultList preselectedGameId={currentGame.gameId} />
          </SwipeDownModal>

        </View>
      </ScrollView>
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  actionButtons: {
    marginVertical: 30,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 10,
    flex: 1,
    marginHorizontal: 25,
  },
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20
  },
  cardMenu: {
    height: 85,
    width: 70,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    marginHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
    color: theme.colors.greyText,
  },
  title: {
    color: '#8F8E95',
    fontFamily: 'Raleway-Regular',
    fontSize: 11
  },
  grid: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  gridTitle: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
  restTitle: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 13,
  },
  gridPicker: {
    flexDirection: 'row',
    flexWrap: "wrap",
    justifyContent: 'center',
    width: '90%',
    marginTop: 15
  },
  pickNumber: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  pickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  oddPickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: theme.colors.secondary,
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  AdditionalPickNumberPressed: {
    height: 45,
    width: 45,
    borderRadius: 5,
    backgroundColor: '#BD54EB',
    margin: 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  number: {
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    color: theme.colors.greyText
  },
  numberPressed: {
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    color: '#ffffff'
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  recapTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  swipeDownModalContent: {
    backgroundColor: '#F6F6F6',
    height: '80%',
    padding: 10,
  },
})

//======================================
export default GridPicker;