import { useTheme, useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { SVGRightArrow } from '../../../assets/icons';


const GameRulesHome = (props) => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const game = props.route.params.game;
  const tabName = [
    {
      name: 'How to play',
      direction: 'GameRules'
    },
    {
      name: 'Paytable',
      direction: 'GamePaytables'
    }
  ];

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      navigation.setOptions({ title: t('Game rule') + ' ' + game.name })
      return () => {
        //Page leave
      }
    }, [])
  );

  return (
    <View style={style.container}>
      {tabName.map((tab, index) => {
        const [isHover, setIsHover] = useState(false);
        useFocusEffect(
          React.useCallback(() => {
            return () => {
              //Page leave
              setIsHover(false)
            }
          }, [])
        );
        return (
          <Pressable
            key={index}
            style={style.card}
            onPress={() => {
              navigation.navigate(tab.direction, { game });
            }}
            // For App
            onPressIn={() => setIsHover(true)}
            onPressOut={() => setIsHover(false)}
            // For the web
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
          >
            <View style={style.cardFirstPart}>
              <Text style={isHover ? style.gameNameHover : style.gameName}>{t(tab.name)}</Text>
            </View>
            <SVGRightArrow fill={theme.colors.greyText} />
          </Pressable>           
        )
      })}
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#F6F6F6'
  },
  card: {
    height: 55,
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8.5,
    paddingHorizontal: 13.5,
    marginBottom: 5
  },
  cardFirstPart: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    height: 45,
    width: 45,
    borderRadius: 50,
    marginRight: 30
  },
  gameName: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 15
  },
  gameNameHover: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 15
  }
});

export default GameRulesHome;