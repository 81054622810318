import { StyleSheet, Pressable, View} from "react-native";
import { useTheme } from '@react-navigation/native';
import React from "react";

const RadioButton = ({ selected, onSelected }) => {

  const style = makeStyles(useTheme());

  return (
    <Pressable style={style.radioButton} onPress={() => onSelected(!selected)}>
      {selected && <View style={style.radioButtonFull} />}
    </Pressable>
  );
};

export default RadioButton;

const makeStyles = (theme) => StyleSheet.create({
  radioButton: {
    height: 17,
    width: 17,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: theme.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonFull: {
    height: 10,
    width: 10,
    borderRadius: 8,
    backgroundColor: theme.colors.primary,
  }
});
