import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGAdd = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={24} width={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </Svg>
)

export default SVGAdd