import { useFocusEffect, useNavigation, useTheme } from "@react-navigation/native";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import screens from "../../common/constants/screen.constants";
import BetTypePicker from "../../components/betCreation/BetTypePicker.comp";
import GridPicker from "../../components/betCreation/GridPicker.comp";
import SessionPicker from "../../components/betCreation/SessionPicker.comp";
import * as gamesActions from '../../store/actions/games.actions';
import * as basketActions from '../../store/actions/basket.actions';
import * as betCreatorActions from '../../store/actions/betCreator.actions';
import { HeaderBackButton } from "@react-navigation/elements";
import NextDrawCountdown from "../../components/games/NextDrawCountdown.comp";
import BetCreateExitModal from "../../components/betCreation/BetCreateExitModal";
import BasketBadge from "../../components/betCreation/BasketBadge.comp";
import { trackError } from "../../store/services/firebase.service";
import UserSticker from "../../components/user/UserSticker.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";


const BetCreationPage = () => {

  const navigation = useNavigation();
  const { t } = useTranslation();
  const theme = useTheme()
  const styles = makeStyles(theme)
  const dispatch = useDispatch();

  const basketItemList = useSelector(state => state.basket.itemList);
  const currentGame = useSelector(state => state.games.currentGame);
  const currentSessions = useSelector(state => state.games.currentSessions);
  const currentStep = useSelector(state => state.betCreator.currentStep);
  const selectedGrids = useSelector(state => state.betCreator.selectedGrids);
  const betTypesActiveByGame = useSelector(state => state.games.betTypesActiveByGame);
  const isGameApiFetching = useSelector(state => state.games.isFetching);
  const isBetBreak = useSelector(state => state.betCreator.isBetInBreak);

  const [ignoreBetTypeStep, setIgnoreBetTypeStep] = useState(false);
  const [ignoreSessionStep, setIgnoreSessionStep] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isAdditionalBet, setIsAdditionalBet] = useState(false);
  const [isExitModalVisible, setIsExitModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [hasAvailableSessions, setHasAvailableSessions] = useState(false);

  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      navigation.setOptions({
        headerLeft: renderHeaderLeft,
        headerRight: renderHeaderRight,
      });
    }, [currentStep, ignoreSessionStep, ignoreBetTypeStep])
  );

  useFocusEffect(
    React.useCallback(() => {
      // page enter
      if (!currentGame) {
        trackError("Session Picker Page enter: no current game");
        return navigation.navigate(screens.GamesHome);
      }
      if (!isBetBreak) {
        prepareNewBet();
        dispatch(betCreatorActions.break_bet(false))
      }

      return () => {
        // page leave
      }
    }, [isBetBreak])
  );


  useEffect(() => {
    if (currentGame
      && currentSessions.gameId == currentGame.gameId
      && currentSessions?.list?.length > 0
      && betTypesActiveByGame?.[currentGame.gameId]?.length > 0
      && !isBetBreak
    ) {
      // we have loaded all the betTypes & session data => can initialize steps
      initializeSteps();
    }

  }, [betTypesActiveByGame, currentSessions, isBetBreak])

  useEffect(() => {
    setIsAdditionalBet(basketItemList?.length > 0);
  }, [basketItemList]);

  useEffect(() => {
    if (isLoading && !isGameApiFetching) {
      setLoading(false);
    }
  }, [isGameApiFetching]);


  //====================================== Functions

  const prepareNewBet = () => {
    // reset builder
    dispatch(betCreatorActions.reset_data());

    if (isFirstLoad || currentSessions.gameId != currentGame.gameId) {
      // load the game info (sessions & betTypes)
      loadGameData();
      setIsFirstLoad(false);
    }
    else {
      // game info (sessions & betTypes) already loaded : directly initialize steps
      initializeSteps();
    }
  }

  const loadGameData = () => {
    setLoading(true);
    console.log(`Starting new bet creation > request info for game ${currentGame.gameId}`);
    const isBetTypesLoaded = betTypesActiveByGame?.[currentGame.gameId]?.length > 0;
    if (isBetTypesLoaded) {
      dispatch(gamesActions.getSessionsFuture(currentGame.gameType, currentGame.gameId));
    } else {
      dispatch(gamesActions.getSessionsFutureWithBetTypes(currentGame.gameType, currentGame.gameId));
    }
  }

  const initializeSteps = () => {
    //-- Manage session & betType selection mode
    /*
      case 1 : INSTANT LIVE (ex: FOGO) : don't choose sessions, only a betType, and later, the consecutive draw count
      case 2 : INSTANT DELAYED (ex: 5+2/90) : choose both sessions (multiples) & betType
      case 3 : MUTUAL CLASSIC (ex: 6/45) : choose a unique session, no betType selection (each session has its own betType)
      case 4 : MUTUAL HUB (ex: Africa Millions) : choose a unique session & betType
    */

    const _betTypeList = betTypesActiveByGame?.[currentGame?.gameId] || [];

    console.log(`Game ${currentGame?.gameId}: found ${_betTypeList.length} betTypes / ${currentSessions?.list?.length} sessions`);

    if (!currentGame || currentSessions.gameId != currentGame.gameId || !currentSessions?.list?.length || _betTypeList.length == 0) {
      // still don't have fetched all the init data, or no session available
      return false;
    }

    console.log(`Initialize steps...`);

    // ignore session selection step if: we need to select the consecutive draw count in a future step
    const _ignoreSessionStep = _betTypeList[0].maxDrawByBet > 1;
    setIgnoreSessionStep(_ignoreSessionStep);

    // ignore the betType selection step if: only one active betType OR if the sessions are already attached to a specific betType
    const sessionBetTypeId = currentSessions?.list?.[0]?.betTypeId;
    const _ignoreBetTypeStep = _betTypeList.length == 1 || sessionBetTypeId != undefined;
    setIgnoreBetTypeStep(_ignoreBetTypeStep);

    if (_ignoreBetTypeStep) {
      // pre-select the betType here, as we don't go on the BetType page
      const usedBetType = sessionBetTypeId && _betTypeList.find(E => E.betTypeId == sessionBetTypeId) || _betTypeList[0];
      dispatch(betCreatorActions.set_betType_selected(usedBetType));
    }

    goFirstStep(_ignoreSessionStep, _ignoreBetTypeStep);
    setHasAvailableSessions(true);

    console.log(`Bet settings: ignoreSession=${_ignoreSessionStep}, ignoreBetType=${_ignoreBetTypeStep}`);
    return true;
  }

  const goFirstStep = (_ignoreSessionStep, _ignoreBetTypeStep) => {
    setCurrentStep(_ignoreSessionStep == false ? betCreatorActions.STEPS.SESSION :
      _ignoreBetTypeStep == false ? betCreatorActions.STEPS.BETTYPE
        : betCreatorActions.STEPS.GRID);
  }

  const goNextStep = () => {
    switch (currentStep) {
      case betCreatorActions.STEPS.NONE: {
        goFirstStep(ignoreSessionStep, ignoreBetTypeStep);
        break;
      }
      case betCreatorActions.STEPS.SESSION: {
        setCurrentStep(ignoreBetTypeStep ? betCreatorActions.STEPS.GRID : betCreatorActions.STEPS.BETTYPE);
        break;
      }
      case betCreatorActions.STEPS.BETTYPE: {
        setCurrentStep(betCreatorActions.STEPS.GRID);
        break;
      }
      case betCreatorActions.STEPS.GRID: {
        goBasket();
        break;
      }
    }
  }

  const goPreviousStep = () => {
    switch (currentStep) {
      case betCreatorActions.STEPS.NONE:
      case betCreatorActions.STEPS.SESSION: {
        wantsToExit();
        break;
      }
      case betCreatorActions.STEPS.BETTYPE: {
        if (ignoreSessionStep) {
          wantsToExit();
        } else {
          setCurrentStep(betCreatorActions.STEPS.SESSION);
        }
        break;
      }
      case betCreatorActions.STEPS.GRID: {
        if (ignoreBetTypeStep && ignoreSessionStep) {
          wantsToExit();
        } else {
          setCurrentStep(ignoreBetTypeStep ? betCreatorActions.STEPS.SESSION : betCreatorActions.STEPS.BETTYPE);
        }
        break;
      }
    }
  }

  const goHome = () => {
    navigation.navigate(screens.GamesHome);
  }
  const goBasket = () => {
    navigation.navigate(screens.basket);
  }

  const giveupAdditionalBet = () => {
    setIsExitModalVisible(false);
    dispatch(betCreatorActions.reset_data());
    goBasket();
  }

  const setCurrentStep = step => {
    dispatch(betCreatorActions.set_current_step(step));
  }

  const wantsToExit = () => {
    if (isAdditionalBet || isFormTouched())
      setIsExitModalVisible(true);
    else
      doExit();
  }
  const doExit = () => {
    setIsExitModalVisible(false);
    dispatch(basketActions.clear_basket());
    dispatch(betCreatorActions.reset_data());
    navigation.navigate(screens.GamesHome);
  }

  const isFormTouched = () => {
    return Object.values(selectedGrids).find(G => G.selectedNumbers?.length > 0);
  }

  //====================================== Render

  const renderHeaderLeft = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => goPreviousStep()}
    />
  );

  const renderHeaderRight = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        {isAdditionalBet && <BasketBadge onPress={giveupAdditionalBet} />}
        <UserSticker />
      </View>
    )
  };

  const renderLoading = () => (
    <View style={styles.pageLoading}>
      <ActivityIndicator size="large" color="gray" />
      <Text>{t('loading_data')}</Text>
    </View>
  )

  const renderNoSession = () => (
    <View style={styles.noSessionContainer}>
      <Text style={styles.noSessionText}>
        {t('no_draw_available')}
      </Text>
      <StyledButton
        onPress={goHome}
        text={t('go_back_home')}
      />
    </View>
  )

  const renderStep = () => {
    switch (currentStep) {
      case betCreatorActions.STEPS.SESSION: return <SessionPicker goNextStep={goNextStep} />;
      case betCreatorActions.STEPS.BETTYPE: return <BetTypePicker goNextStep={goNextStep} />;
      case betCreatorActions.STEPS.GRID: return <GridPicker goNextStep={goNextStep} />;
      default: return <></>;
    }
  }


  return (
    <View style={styles.container}>

      {isLoading && renderLoading()}

      {!isLoading && !hasAvailableSessions && renderNoSession()}

      {!isLoading && hasAvailableSessions && (
        <React.Fragment>
          <NextDrawCountdown />
          {renderStep()}
        </React.Fragment>
      )}

      <BetCreateExitModal
        visible={isExitModalVisible}
        onClose={() => setIsExitModalVisible(false)}
        onConfirm={() => doExit()}
        onAlternativeAction={isAdditionalBet ? () => giveupAdditionalBet() : null}
        alternativeActionText={t('basket.back_to_basket')}
      />

    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  noSessionContainer: {
    flex: 1,
    paddingTop: 60,
    paddingHorizontal: 20,
    margin: 10,
  },
  noSessionText: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Raleway-Regular',
    color: '#000',
    marginBottom: 20,
  },
  pageLoading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default BetCreationPage;