import { useTheme } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';

import * as TicketActions from '../../store/actions/ticket.actions';
import { dateLocaleLong } from '../../utils/DateUtils';
import { formatNumber } from '../../utils/FormatUtils';
import { useState } from 'react';
import { useEffect } from 'react';
import screens from '../../common/constants/screen.constants';
import images from '../../assets/images';
import { commonStyles } from '../../common/style/common.style';

const TicketListItem = ({ item }) => {

  const dispatch = useDispatch();
  const styles = makeStyles(useTheme());
  const { t } = useTranslation();

  const currency = useSelector(state => state.login.currency);
  const myTicketList = useSelector(state => state.ticket.myTicketList);
  const gameSettings = useSelector(state => state.wordpress.gameSettings);
  const sourceUrlLogo = gameSettings && gameSettings[item.gameId]?.gameLogoAvatar;

  const [apiError, setApiError] = useState(null);
  const [apiRequested, setApiRequested] = useState(false);
  const stateTicketError = useSelector(state => state.ticket.error);
  const stateTicketFetching = useSelector(state => state.ticket.isFetching);

  useEffect(() => {
    return () => {
      //Destroy: clean states
      apiError && setApiError(null)
      apiRequested && setApiRequested(false);
    }
  }, []);

  useEffect(() => {
    //only if the api call concerns this Ticket
    if (apiRequested) {
      dispatch(TicketActions.searchTicketAndRedirect(item.reference, screens.ticket, { navFromTicketList: true }));
    }
  }, [apiRequested]);

  useEffect(() => {
    if (apiRequested) {
      setApiRequested(stateTicketFetching);
      setApiError(stateTicketError);
      stateTicketError && setTimeout(() => setApiError(null), 2000);
    }
  }, [stateTicketFetching, stateTicketError]);


  const onTicketItemDeletePress = (item) => {
    const list = myTicketList.filter(E => E.reference != item.reference);
    dispatch(TicketActions.set_my_ticket_list(list));
  }

  //====================================== Render
  return (
    <View style={styles.cardContainer}>
      <Pressable
        style={({ pressed }) => {
          return [styles.card, !pressed && commonStyles.shadowedButton];                                                                  
        }}
        onPress={() => setApiRequested(true)}
      >
        <View style={styles.cardSummary}>
          <Image
            style={styles.cardImage}
            // resizeMode='stretch'
            source={sourceUrlLogo ? {uri: sourceUrlLogo} : images.game_logo_default}
          /> 
          <View style={styles.cardText}>
            <Text style={styles.gameName}>{item.gameName}</Text>
            <Text style={styles.drawDate}>{dateLocaleLong(item.drawingDate)}</Text>
          </View>
          <View style={styles.cardArrow}>
            <Icon name={'arrow-forward-ios'} size={14} />
          </View>
        </View>
        <View style={styles.cardSeparator} />
        <View style={styles.totalBet}>
          <Text style={styles.totalBetLabel}>{t('Total bet')} :</Text>
          <Text style={styles.totalBetValue}>{formatNumber(item.amtTotalBet)} {currency}</Text>
        </View>
        <View style={styles.cardActions}>
          <Pressable style={[styles.cardActionButton, styles.cardActionOutlineButton]} onPress={() => onTicketItemDeletePress(item)}>
            <Text style={styles.cardActionOutlineText}>{t('remove')}</Text>
          </Pressable>
          <Pressable style={[styles.cardActionButton, styles.cardActionButtonPlain]} onPress={() => setApiRequested(true)}>
            <Text style={styles.cardActionPlainText}>{t('see')}</Text>
          </Pressable>
        </View>

        {apiError &&
          <View style={styles.apiError}>
            <Text style={styles.apiErrorText}>{t('ticket.ref_not_found')}</Text>
          </View>
        }
      </Pressable>

      {apiRequested &&
        <View style={styles.apiRequested}>
          <ActivityIndicator size={30} style={styles.apiRequestedIndicator} />
        </View>
      }
    </View>
  )

}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  apiError: {
    padding: 10,
    backgroundColor: 'lightpink',
    textAlign: 'center',
  },
  apiErrorText: {
    fontFamily: 'Raleway-SemiBold',
  },
  apiRequested: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff66',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  apiRequestedIndicator: {
    opacity: 1,
  },
  cardContainer: {
    marginBottom: 5,
  },
  card: {
    borderRadius: 6,
    backgroundColor: "#ffffff",
    marginTop: 10,
  },
  cardImage: {
    height: 35,
    width: 55,
    borderRadius: 4,
  },
  cardSummary: {
    flexDirection: 'row',
    padding: 8,
    paddingLeft: 15,
    alignItems: 'center',
  },
  cardActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 5,
    paddingTop: 8,
    paddingLeft: 5,
  },
  cardActionButton: {
    elevation: 6,
    backgroundColor: theme.colors.primary,
    paddingHorizontal: 10,
    borderRadius: 4,
    margin: 5,
    minWidth: 65,
    paddingVertical: 5,
    alignItems: 'center',
  },
  cardActionOutlineButton: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: theme.colors.primary,
  },
  cardActionOutlineText: {
    color: theme.colors.primary,
    fontWeight: 'bold',
    fontSize: 12,
  },
  cardActionPlainText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cardSeparator: {
    marginHorizontal: 10,
    height: 0,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  cardText: {
    paddingHorizontal: 10,
    flex: 1,
  },
  gameName: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    marginBottom: 3,
  },
  drawDate: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 12,
  },
  totalBet: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    marginTop: 8,
    paddingHorizontal: 5,
  },
  totalBetLabel: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Medium',
    fontSize: 12,
  },
  totalBetValue: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 12,
  }
});

export default TicketListItem; 