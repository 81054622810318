import { View, Text, StyleSheet, TextInput, Platform, TouchableOpacity } from "react-native";
import Checkbox from 'expo-checkbox';
import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as basketActions from '../../store/actions/basket.actions';
import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import * as EBulletinService from '../../store/services/ebulletin.service';

import screens from "../../common/constants/screen.constants";
import { useTranslation } from 'react-i18next';
import BasketBadge from "../../components/betCreation/BasketBadge.comp";
import UserSticker from "../../components/user/UserSticker.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import { checkPhoneNumberValid } from "../../utils/FormatUtils";

/** 
 * ###########################################################################
 * 
 * 
 * EN CONSTRUCTION
 * 
 * 
 * ###########################################################################
 */
/**
 * The page when the basket is validated : select the method (retail or online), select the phone number + create/show eBulletin if retail
 */
export default function BasketValidation() {

  const navigation = useNavigation();
  const theme = useTheme();
  const isWeb = Platform.OS === "web";
  const style = makeStyles(theme, isValid);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //====================================== Hooks

  const basket = useSelector(state => state.basket);
  const taxList = useSelector(state => state.taxes.taxes)
  const phoneMinDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMinDigits);
  const phoneMaxDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMaxDigits);
  const phoneFormatSplit = useSelector(state => state.wordpress.appSettings?.localization?.phoneFormatSplit);
  const phonePrefix = useSelector(state => state.wordpress.appSettings?.localization?.phonePrefix);

  const [isValid, setValid] = useState(false);
  const [rememberPlayerNumber, setRememberPlayerNumber] = useState(false);

  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState('');
  const [playerReference, setPhoneNumber] = useState('');

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      checkStorage();
      navigation.setOptions({ headerRight: headerRightBasket });
    }, [])
  );

  useEffect(() => {
    checkPlayerRefValid();
  }, [playerReference])

  //====================================== Functions

  const headerRightBasket = () => (
    <View style={{flexDirection: 'row'}}>
      <BasketBadge />
      <UserSticker />
    </View>
  );

  const checkPlayerRefValid = () => {
    setValid(checkPhoneNumberValid(playerReference, phoneMinDigits, phoneMaxDigits));
  }

  const checkStorage = async () => {
    const checkPlayerRefStorage = await AsyncStorage.getItem('STORAGE_PLAYER_NUMBER');
    setPhoneNumber(checkPlayerRefStorage || '')
    setPhoneNumberFormatted(formatPhoneNumber(checkPlayerRefStorage || ''))
    setRememberPlayerNumber(checkPlayerRefStorage === null ? false : true)
  }

  const createBulletin = async () => {
    if (rememberPlayerNumber) {
      AsyncStorage.setItem('STORAGE_PLAYER_NUMBER', playerReference)
    }
    if (!rememberPlayerNumber) {
      AsyncStorage.removeItem('STORAGE_PLAYER_NUMBER')
    }

    let bulletin = EBulletinService.convertBasketToBulletin(basket, { playerReference }, taxList);
    dispatch(EBulletinActions.finalizeBulletinCreation(bulletin, { phonePrefix }, result => {
      if (result?.api?.bulletinCode) {
        navigation.navigate(screens.EBulletinPage, { bulletinCode: result.api.bulletinCode, showValidationModal: true });
        dispatch(basketActions.clear_basket());
      }
    }));
  }

  const onPhoneNumberInputChange = (value) => {
    const num = value?.replace(/[^0-9+]+/g, '');
    setPhoneNumberFormatted(formatPhoneNumber(num));
    setPhoneNumber(num);
  }

  const formatPhoneNumber = (s, splitFreq = phoneFormatSplit || 2) => {
    if (s.length >= 7 && s.length % splitFreq != 0)
      return s.substr(0, s.length % splitFreq) + ' '
        + s.substr(s.length % splitFreq).replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
    else
      return s.replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
  }

  const onRememberMePress = () => {
    setRememberPlayerNumber(!rememberPlayerNumber);
  }

  //====================================== Render

  return (
    <View style={style.container}>
      <View style={style.body}>
        <Text style={style.numberText}>{t('Enter phone number')}</Text>
        <View style={style.textInputContainer}>
          <View style={style.inputPrefix}>
            <Text style={style.textInputPrefix}>
              {phonePrefix}
            </Text>
          </View>
          <TextInput
            style={isWeb ? style.textInputWeb : style.textInput}
            onChangeText={text => onPhoneNumberInputChange(text)}
            autoFocus={true}
            value={phoneNumberFormatted}
            placeholder="ex. 06 06 06 06 06"
            keyboardType="numeric"
          // maxLength={10}
          />
        </View>
        <TouchableOpacity style={style.checkboxContainer} onPress={onRememberMePress}>
          <Checkbox
            style={style.checkbox}
            value={rememberPlayerNumber}
            color={rememberPlayerNumber ? theme.colors.primary : undefined}
            disabled={!rememberPlayerNumber && !isValid}
          />
          <Text style={style.checkboxText}>{t('Remember me')}</Text>
        </TouchableOpacity>

        <StyledButton
            text={t('validate')}
            onPress={createBulletin}
            disabled={!isValid}
          />
      </View>
    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme, isValid) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    paddingTop: 130,
    alignItems: 'center'
  },
  body: {

  },
  numberText: {
    fontSize: 14,
    fontFamily: 'Raleway-Medium',
    textAlign: 'left',
    marginBottom: 15
  },
  textInputContainer: {
    width: 280,
    height: 36,
    flexDirection: 'row',
    borderRadius: 5,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
    borderColor: isValid ? theme.colors.primary : '#1D1A3C',
    borderWidth: 1,
    paddingHorizontal: 8,
    marginBottom: 15
  },
  textInput: {
    height: 36,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  textInputWeb: {
    height: 36,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
    outlineStyle: 'none',
    fontStyle: 'light'
  },
  inputPrefix: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 10
  },
  textInputPrefix: {
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 10
  },
  checkbox: {
    marginRight: 10,
    color: theme.colors.primary,
    borderRadius: 3
  },
  checkboxText: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Medium',
    fontSize: 12
  }
})
