import React, { useState } from 'react';
import { useNavigation, useTheme, useFocusEffect } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { View, Text, StyleSheet, ScrollView, Pressable } from 'react-native';
import { SVGFavorisFull, SVGGrid } from '../../assets/icons';
import { generateTab } from './MySpace.tab';
import { useTranslation } from 'react-i18next';
import { SVGArrowLine, SVGAccountBank } from '../../assets/icons';
import screens from '../../common/constants/screen.constants';
import StyledButton from '../../components/kpLibrary/forms/StyledButton.comp';
import UserDeactivation from '../../components/my-space/UserDeactivation.comp';
import UserLogout from '../../components/my-space/UserLogout.comp';
import UserSolde from '../../components/kpLibrary/UserSolde.comp';

const MySpace = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const tabList = generateTab(theme);
  const userIsConnected = useSelector(state => state.user.isConnected);

    const onPressNavigatePage = (page, segment) => {
    page === 'UserFavorites' ?
      navigation.navigate(page, segment) :
      navigation.navigate(page)
  }

  return (
    <ScrollView style={style.container}>

      {!userIsConnected ?
        <View style={style.containerCentered}>
          <StyledButton
            onPress={() => navigation.navigate(screens.UserConnect)}
            text={t("Login/Register")}
          />
        </View>
          :
        <View>
          <UserSolde />
          <Text style={style.favouriteText}>
            {t("Transactions")}
          </Text>
          <View style={style.cardContainer}>
            <Pressable
              style={style.cardMenu}
              // onPress={() => onPressNavigatePage(screens.UserDeposit)}
            >
              <View style={style.actionBubble}>
                <SVGArrowLine
                  fill='#ffffff'
                  style={{ transform: [{ rotate: '90deg' }] }}
                />
              </View>
              <Text style={style.title}>
                {t('Deposit')}
              </Text>
            </Pressable>
            <Pressable style={style.cardMenu}>
              <View style={style.actionBubble}>
                <SVGArrowLine
                  fill='#ffffff'
                  style={{ transform: [{ rotate: '270deg' }] }}
                />
              </View>
              <Text style={style.title}>
                {t('Withdraw')}
              </Text>
            </Pressable>
            <Pressable style={style.cardMenu} onPress={() => onPressNavigatePage(screens.UserTransactions)}>
              <View style={style.actionBubble}>
                <SVGAccountBank
                  fill='#ffffff'
                />
              </View>
              <Text style={style.title}>
                {t('Transactions')}
              </Text>
            </Pressable>
          </View>
        </View>
      }

      <Text style={style.favouriteText}>
        {t("Favourites")}
      </Text>
      <View style={style.cardContainer}>
        <Pressable style={style.cardMenu} onPress={() => onPressNavigatePage('UserFavorites', 1)} >
          <SVGFavorisFull
            fill={theme.colors.primary}
            style={style.icon}
          />
          <Text style={style.title}>
            {t('Favorite Games')}
          </Text>
        </Pressable>
        <Pressable style={style.cardMenu} onPress={() => onPressNavigatePage('UserFavorites', 2)} >
          <SVGGrid
            fill={theme.colors.primary}
            style={style.icon}
          />
          <Text style={style.title}>
            {t('Favorite Grid')}
          </Text>
        </Pressable>
      </View>

      {tabList.map((tab, index) => {
        const [isHover, setIsHover] = useState(false);
        useFocusEffect(
          React.useCallback(() => {
            return () => {
              //Page leave
              setIsHover(false)
            }
          }, [])
        );
        if (tab.connectedOnly && !userIsConnected) {
          return;
        }
        return (
          <Pressable
            key={index}
            style={style.tabItem}
            onPress={() => onPressNavigatePage(tab.page)}
            // For App
            onPressIn={() => setIsHover(true)}
            onPressOut={() => setIsHover(false)}
            // For the web
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
          >
            {tab.icon}
            <Text style={isHover ? style.tabTextHover : style.tabText}>
              {t(tab.name)}
            </Text>
          </Pressable>
        )
      })}

      {userIsConnected &&
        <View>
          <UserLogout />
          <UserDeactivation />        
        </View>
      }

    </ScrollView>
  )

}

export default MySpace;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: '#F6F6F6'
  },
  containerCentered: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20
  },
  icon: {
    marginBottom: 10
  },
  favouriteText: {
    textTransform: 'uppercase',
    fontFamily: 'Raleway-Bold',
    fontSize: 17,
    color: theme.colors.greyText,
    marginLeft: 10,
    marginBottom: 10
  },
  cardContainer: {
    flexDirection: 'row',
    marginBottom: 30
  },
  cardMenu: {
    height: 110,
    width: 110,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    marginRight: 14,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5
  },
  title: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
    textAlign: 'center'
  },
  tabItem: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    alignItems: 'center',
    paddingHorizontal: 15,
    width: '100%',
    height: 46,
    borderRadius: 5,
    marginBottom: 5
  },
  tabText: {
    paddingLeft: 18,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 15
  },
  tabTextHover: {
    paddingLeft: 18,
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 15
  },
  disconectButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    width: '100%',
    height: 46,
  },
  disconectButtonText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 15
  },
  transactionsPart: {
    flexDirection: 'row',
    paddingHorizontal: 50,
    marginBottom: 37
  },
  actionBubble: {
    backgroundColor: theme.colors.primary,
    borderRadius: 50,
    width: 44,
    height: 44,
    marginBottom: 13,
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionBubbleText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: theme.colors.greyText
  }
})