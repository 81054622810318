import React, { useState } from 'react';
import { useNavigation, useTheme, useFocusEffect } from '@react-navigation/native';
import { View, Text, Image, StyleSheet, ScrollView, Pressable } from 'react-native';
import { useSelector } from 'react-redux';

import { SVGRightArrow } from '../../../assets/icons';
import screens from '../../../common/constants/screen.constants';


const GamesRulesList = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const navigation = useNavigation();
  const lottery_games = useSelector(state => state.games.lottery_games);
  const gameSettings = useSelector(state => state.wordpress.gameSettings);

  const navigationGameRule = (game) => {
    navigation.navigate(screens.GameRulesHome, { game });
  }

  const RenderGameRulesCard = ({ game, index }) => {

    const sourceUrlLogo = gameSettings && gameSettings[game.gameId]?.gameLogoAvatar;
    const [isHover, setIsHover] = useState(false);

    useFocusEffect(
      React.useCallback(() => {
        return () => {
          //Page leave
          setIsHover(false)
        }
      }, [])
    );

    return (
      <Pressable
        key={index}
        style={style.card}
        onPress={() => {
          navigationGameRule(game);
        }}
        // For App
        onPressIn={() => setIsHover(true)}
        onPressOut={() => setIsHover(false)}
        // For the web
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
      >
        <View style={style.cardFirstPart}>
          <Image
            style={style.image}
            source={sourceUrlLogo ? { uri: sourceUrlLogo } : require('../../../assets/images/Game_Default_Thumnail.png')}
          />
          <Text style={isHover ? style.gameNameHover : style.gameName}>{game.name}</Text>
        </View>
        <SVGRightArrow fill={theme.colors.greyText} />
      </Pressable>
    )
  }

  return (
    <ScrollView style={style.container}>
      {lottery_games.map((game, index) =>
        <RenderGameRulesCard key={index} game={game} index={index} />
      )}
    </ScrollView>
  );
};

export default GamesRulesList;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#F6F6F6'
  },
  title: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 16,
    marginLeft: 30,
    marginBottom: 20
  },
  card: {
    height: 55,
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8.5,
    paddingHorizontal: 13.5,
    marginBottom: 5
  },
  cardFirstPart: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    height: 45,
    width: 45,
    borderRadius: 50,
    marginRight: 30
  },
  gameName: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 15
  },
  gameNameHover: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 15
  }
})
