import axios from 'axios';
import { _APP_VERSION } from '../../../version.json'
import { apiWebPlayerConfig } from '../../../app.json';
import { trackError } from './firebase.service';
import { requestKeycloakToken } from './login.service';
import moment from "moment";

const API_TIMEOUT = 60000;
let keycloak_data;

const createWebPlayerAxios = () => {
  let instance = axios.create({
    timeout: API_TIMEOUT,
  });

  instance.interceptors.request.use(async request => {
    try {
      if (keycloak_data?.dthExpiration && moment(keycloak_data?.dthExpiration).isBefore()) {
        let ret = await requestKeycloakToken();
        setWebPlayerAuthData(ret.data);
        request.headers['Authorization'] = `Bearer ${ret.data.access_token}`;        
      }
    }
    catch (err) {
      trackError("TOKEN ERROR", {err});
    }
    return request;
  });

  instance.interceptors.response.use(undefined, async err => {
    let errData = {};

    if (err.message === "Network Error" || typeof err === "string" || err.code === "ECONNABORTED") {
      // Error Network
      errData = {
        errorInformation: "Network Error",
      }
    } else if (err.response?.status == 401 && err.response?.data?.errorId == undefined && !err.config["is_retry"]) {
      // Error token keycloak
      errData = {
        url: err.response?.config.url,
        errorInformation: err.response?.data,
        httpErrorCode: err.response?.status,
      }
      trackError('api authorization error : invalid token', { ...errData, err, category: 'api' });
      err.config['is_retry'] = true;
      let retKeycloak = await requestKeycloakToken();
      setWebPlayerAuthData(retKeycloak.data);
      //Note: update the authorization header of the current request with the new auth token
      err.config.headers['Authorization'] = `Bearer ${retKeycloak.data.access_token}`;
      let ret = await axios(err.config);
      return ret;
    } else if (err.response?.data?.uniqueErrorCode) {
      // Error Api
      errData = {
        url: err.response?.config.url,
        errorInformation: err.response?.data?.errorInformation,
        httpErrorCode: err.response?.status,
        uniqueErrorCode: err.response?.data?.uniqueErrorCode
      }
    } else {
      errData = {
        httpErrorCode: err.response?.status,
        errorInformation: err.response?.statusText
      }
    }
    trackError('API WebPlayer Axios error', { ...errData, err, category: 'api' });
    // bubble up the error
    return Promise.reject(err);
  });
  
  return instance;
}

export const wp_axios = createWebPlayerAxios();

export const setWebPlayerAuthData = (data) => {
  wp_axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;

  const dthExpiration = moment().add(data.expires_in, 'seconds').toISOString();
  keycloak_data = { ...data, dthExpiration };
}

export const getWebPlayerAppVersion = async () => {
  try {
    let wp_version_data = {
      application: "Player Sale Point Application",
      applicationVersion: _APP_VERSION,
    };

    let res = await wp_axios.get(`${apiWebPlayerConfig.UrlService}/applications/versionStatus?application=${wp_version_data.application}&applicationVersion=${wp_version_data.applicationVersion}`);
    return res.data;
  }
  catch (err) {
    trackError('failed to get version app', { err, data: err.response.data, status: err.response.status });
    //TODO: manage app updates
    switch (err.errorId) {
      case 301100: // API_APP_UNKNOWN - The application is not registered
      case 301101: // API_APP_VERSION_UNKNOWN - The application version is not registered
      case 301102: // API_APP_VERSION_FORBIDDEN - The application version is not authorized
        // await this.appUpdate.check(LoginInformation.ApplicationVersionStatusEnum.FORBIDDEN, err.hint);
        break;
      default:
        throw err;
    }
  }
}
