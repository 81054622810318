import React from 'react';
import { useSelector } from 'react-redux';
import screens from '../../common/constants/screen.constants';
import Basket from '../../screens/basket/Basket.page';

import TicketPage from '../../screens/ticket/Ticket.page';

import GainsDetails from '../../screens/ticket/GainsDetails.page';
import BasketValidation from '../../screens/basket/BasketValidation';
import EBulletinPage from '../../screens/my-bets/EBulletin.page';
import BetCreationPage from '../../screens/betCreation/BetCreation.page';
import { useTranslation } from 'react-i18next';

import MyTicketsPage from '../../screens/my-bets/MyTickets.page';
import GameRulesHome from '../../screens/my-space/GamesRules/GameRulesHome';
import GameRules from '../../screens/my-space/GamesRules/GameRules';
import GamePaytables from '../../screens/my-space/GamesRules/GamePaytables';
import UserConnect from '../../screens/user/UserConnect.page';
import UserRegister from '../../screens/user/UserRegister.page';
import UserWelcome from '../../screens/user/UserWelcome.page';
import UserInformation from '../../screens/user/UserInformation.page';
import UserFormInputEdition from '../../screens/user/UserFormInputEdition.page';
import UserTransactions from '../../screens/my-space/transaction/UserTransactions';
import DepositPaymentMethods from '../../screens/my-space/transaction/DepositPaymentMethods.page';
import UserPasswordChange from '../../screens/user/UserPasswordChange.page';
import UserLimits from '../../screens/my-space/UserLimits.page';
import UserLimitModifier from '../../screens/my-space/UserLimitModifier.page';
import UserPasswordReset from '../../screens/user/UserPasswordReset.page';

const FullScreenNavigationGroups = (Stack) => {

  const { t } = useTranslation();

  const currentGame = useSelector(state => state.games.currentGame);

  return (
    <React.Fragment>
      {/* Lottery Bet Creation */}
      <Stack.Group key="lottery_bet_creation">
        <Stack.Screen
          name={screens.BetCreate}
          component={BetCreationPage}
          options={{ title: currentGame?.name }}
        />
        <Stack.Screen
          name={screens.basket}
          component={Basket}
          options={{ title: t('basket.my_basket') }}
        />
        <Stack.Screen
          name={screens.BasketValidation}
          component={BasketValidation}
          options={{ title: t('Validation') }}
        />
      </Stack.Group>

      {/* Tickets & EBulletins */}
      <Stack.Group key="tickets_bulletins">
        <Stack.Screen
          name={screens.Ticket}
          component={TicketPage}
          options={{ title: 'My ticket' }}
        />
        <Stack.Screen
          name={screens.GainsDetails}
          component={GainsDetails}
          options={{ title: 'Gains details' }}
        />
        <Stack.Screen
          name={screens.EBulletinPage}
          component={EBulletinPage}
          options={{ title: t('ebulletin.my_ebulletin') }}
        />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen
          name={screens.MyTickets}
          component={MyTicketsPage}
          options={{ title: t('ticket.my_scanned_tickets') }}
        />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen
          name={screens.GameRulesHome}
          component={GameRulesHome}
          options={{ title: t('Game Rule') }}
        />
        <Stack.Screen
          name={screens.GameRules}
          component={GameRules}
        />
        <Stack.Screen
          name={screens.GamePaytables}
          component={GamePaytables}
        />
      </Stack.Group>

      {/* User management */}
      <Stack.Group key="user">
        <Stack.Screen
          name={screens.UserConnect}
          component={UserConnect}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={screens.UserRegister}
          component={UserRegister}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={screens.UserWelcome}
          component={UserWelcome}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={screens.UserInformation}
          component={UserInformation}
          options={{ title: t('user.personal_info') }}
        />
        <Stack.Screen
          name={screens.UserFormInputEdition}
          component={UserFormInputEdition}
          options={{ title: t('user.personal_info') }}
        />
        <Stack.Screen
          name={screens.UserPasswordChange}
          component={UserPasswordChange}
          options={{ title: t('Change password') }}
        />
        <Stack.Screen
          name={screens.UserPasswordReset}
          component={UserPasswordReset}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={screens.UserLimits}
          component={UserLimits}
          options={{ title: t('My limits') }}
        />
        <Stack.Screen
          name={screens.UserTransactions}
          component={UserTransactions}
          options={{ title: t('My transactions') }}
        />
        <Stack.Screen
          name={screens.UserDeposit}
          component={DepositPaymentMethods}
          options={{ title: t('Deposit money') }}
        />
        <Stack.Screen
          name={screens.UserLimitModifier}
          component={UserLimitModifier}
        />
      </Stack.Group>
    </React.Fragment>
  )
}

export default FullScreenNavigationGroups;