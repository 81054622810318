import * as service from "../services/odds.service";

// Definition of the actions name

export const ACTIONS = {
  FETCHING_ERROR: 'FETCHING_ERROR',
  ODDS_FETCHING: 'ODDS_FETCHING',

  EVENTS_FETCHING_DONE: 'EVENTS_FETCHING_DONE',
  ODDSTYPE_FETCHING_DONE: 'ODDSTYPE_FETCHING_DONE'
}

export const set_state_error = (err) => {
  return { type: ACTIONS.FETCHING_ERROR, payload: err };
}

export const set_state_fetching = () => {
  return { type: ACTIONS.ODDS_FETCHING };
}

// EVENTS FETCH DONE
export const getEventsList = (events, eventsId) => {
  return { type: ACTIONS.EVENTS_FETCHING_DONE, payload: {events, eventsId} };
}

// ODDSTYPE FETCH DONE
export const getOddsType = (oddsType) => {
  return { type: ACTIONS.ODDSTYPE_FETCHING_DONE, payload: oddsType };
}


export const getEventsById = (eventsId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getEventsById(eventsId);
      if (r.data) {
        dispatch(getEventsList(r.data.list, eventsId))
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getOddsTypeList = () => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getOddsTypeList();
      if (r.data.listOfOddsTypes) {
        dispatch(getOddsType(r.data.listOfOddsTypes))
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

