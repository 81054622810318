import { useTheme, useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View, Text, StyleSheet } from 'react-native';
import { useDispatch } from "react-redux";
import { SVGInformationCircle } from '../../assets/icons';
import screens from '../../common/constants/screen.constants';
import * as BetCreatorActions from '../../store/actions/betCreator.actions';

export const GameRulesLink = ({
  game,
  isFromGridPicker
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = makeStyles(theme);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const onGameRulesHelp = () => {
    navigation.navigate(screens.GameRulesHome, { game });
    dispatch(BetCreatorActions.break_bet(isFromGridPicker === true))
  }

  return (
    <View style={styles.container}>
      <Pressable style={styles.gameRulesButton} onPress={() => onGameRulesHelp()}>
        <SVGInformationCircle fill={theme.colors.primary} style={{ width: 24 }} />
        <Text style={styles.gameRulesText}>
          {t('game_rules')} {game.name}
        </Text>
      </Pressable>

    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    marginTop: 6,
    marginBottom: 12,
    justifyContent: 'flex-start',
  },
  gameRulesButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  gameRulesText: {
    color: theme.colors.primary,
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    paddingLeft: 5,
  },
})

//======================================
