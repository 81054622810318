import { View, Text, StyleSheet, Pressable } from "react-native";
import React, { useEffect, useState } from "react";
import { useNavigation, useTheme } from '@react-navigation/native';

import i18n, { changeLanguage } from 'i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useTranslation } from 'react-i18next'

import { SVGDone } from "../../assets/icons";
import moment from "moment";

const LanguagePick = () => {

  const [localeUsed, setLocaleUsed] = useState(i18n.language)
  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();

  const onPressChangeLanguage = (language) => {
    AsyncStorage.setItem('USER_LANGUAGE', language);
    moment.locale(language);
    setLocaleUsed(language);
    changeLanguage(language);
  }

  return (
    <View style={style.container}>

      {Object.keys(i18n.store.data).map((language, index) => (
        <Pressable key={index} onPress={() => onPressChangeLanguage(language)}>
          {index !== 0 && <View style={style.separator}></View>}
          <View style={style.lineView}>
            <Text style={style.text}>
              {t(language)}
            </Text>
            {language === localeUsed && 
              <View style={style.rightView}>
                <SVGDone fill={theme.colors.greyText} />
              </View>
            }
          </View>
        </Pressable>
      ))}
    </View>
  );
};

export default LanguagePick;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  lineView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 55,
    alignItems: 'center',
    paddingHorizontal: 20
  },
  rightView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginLeft: 10
  },
  text: {
    fontSize: 13,
    fontFamily: 'Raleway-Medium',
    color: theme.colors.greyText
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.greyText
  }
})
