import { ACTIONS } from '../actions/taxes.actions';

// initial state of this reducer

const initialState = {
  useTaxes: false,
  taxes: [],
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function taxesReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.TAXES_LOADED: {
      return { ...state, taxes: action.payload, useTaxes: true }
    }
    case ACTIONS.NO_TAXES: {
      return { ...state, useTaxes: false }
    }
    default:
      return state
  }
}