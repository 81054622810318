import { useTheme } from "@react-navigation/native";
import { useState } from "react";
import { Platform, StyleSheet, View, Text } from "react-native";
import { TextInput } from "react-native-gesture-handler";

const StyledTextInput = (
  {
    customStyle, //optional
    inputProps, //optional - extra attributes for the RN TextInput element
    isError, //optional
    label,//optional
    keyboardType,
    onChangeText,
    placeholder,
    renderPrefix, //optional
    secureTextEntry, //optionnal - for password 
    selectTextOnFocus,
    value,
  }) => {

  //====================================== Hooks

  const isWeb = Platform.OS == "web";
  const theme = useTheme();
  const style = { ...makeStyles(theme, isWeb), ...customStyle };

  const [isFocused, setIsFocused] = useState(false);

  //====================================== Render
  return (
    <View style={style.container}>
      {label &&
        <Text style={style.topTitle}>{label}</Text>
      }
      <View style={[
        style.textInputContainer,
        isFocused && style.textInputContainerFocus,
        !isFocused && value?.length && style.textInputContainerCompleted,
        isError && style.textInputContainerError,
      ]}>
        {renderPrefix && renderPrefix()}
        <TextInput
          style={[
            style.textInput,
            !isFocused && value?.length && style.textInputCompleted,
            isError && style.textInputError,
          ]}
          secureTextEntry={secureTextEntry}
          onChangeText={onChangeText}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          value={value || ''}
          keyboardType={keyboardType}
          selectTextOnFocus={selectTextOnFocus && true}
          placeholder={placeholder}
          placeholderTextColor={'#707070'}
          {...inputProps}
        />
      </View>
    </View>
  )
}


//====================================== Styles

const makeStyles = (theme, isWeb) => StyleSheet.create({
  container: {
    marginVertical: 7,
  },
  textInputContainer: {
    height: 40,
    flexDirection: 'row',
    borderRadius: 5,
    backgroundColor: '#F6F6F6',
    paddingHorizontal: 12,
  },
  textInputContainerFocus: {
    backgroundColor: '#FFFFFF',
    borderColor: theme.colors.primary,
    borderWidth: 1,
  },
  textInputContainerCompleted: {
    backgroundColor: '#D0E9FF',
  },
  textInputContainerError: {
    backgroundColor: '#fadfdf',
  },
  textInput: {
    height: 40,
    width: isWeb ? '100%' : '80%',
    fontFamily: 'Raleway-Regular',
    fontSize: 16,
    outlineStyle: isWeb ? 'none' : '',
  },
  textInputCompleted: {
    color: '#000000',
    fontFamily: 'Raleway-Medium',
  },
  textInputError: {
    color: '#f83d3d',
  },
  topTitle: {
    fontFamily: 'Raleway-Medium',
    fontSize: 13,
    color: theme.colors.greyText,
    marginBottom: 7
  }
});


export default StyledTextInput;