export const translationEN = {
  /*eslint-disable */

  /** -------------------------------------------- GENERAL DICTIONARY  ----------------------------------------*/
  "all": "All",
  "all.fem": "All",
  "back": "Back",
  "cancel": "Cancel",
  "confirm": "Confirm",
  "clear": "Clear",
  "code": "Code",
  "connecting": "Connecting...",
  "dates": "Dates",
  "delete": "Delete",
  "game": "Game",
  "games": "Games",
  "go_back_home": "Back to homepage",
  "loading": "Loading...",
  "loading_data": "Loading data...",
  "lotteries": "Lotteries",
  "no": "No",
  "no_result_found": "No result found.",
  "ref": "Ref.",
  "remove": "Remove",
  "results": "Results",
  "retry": "Retry",
  "search_in_progress": "Search in progress...",
  "see": "See",
  "share": "Share",
  "stake": "Stake",
  "today": "Today",
  "today_at": "Today at",
  "validate": "Validate",
  "yes": "Yes",
  "Next draws": "Next Draws",
  "number_abr": "no.",
  "Numbers": "numbers",
  "Our games": "Our games",
  "Grid": "Grid",
  "Connexion": "Connexion",
  'Others': 'Others',
  "Deposits": "Deposits",
  "Withdrawals": "Withdrawals",
  "deposit": "Deposit",
  "Withdrawal": "Withdrawal",
  /** =========================================================================================================*/

  /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
  "Ticket base amount": "Ticket base amount",
  "end_of_bets": "End of bets",
  "game_rules": "Game rules",
  "minute": "minute",
  "minutes": "minutes",
  "next_draw": "Next draw",
  "next_draw_less": "Next draw in less than",
  "no_draw_available": "No draw is available at the moment. Please try again later.",
  "no_draw_available_short": "No draw available.",
  "select_bet_type": "Choose a game type",
  "select_game_sessions": "Choose one or more game sessions",
  "select_game_session": "Choose a game session",
  "Remaining": "Remaining",
  "Stake per bet": "Stake per bet",
  "Number of draws": "Number of draws",
  "Summary": "Summary",
  'additional numbers': 'additional numbers',
  "Custom amount": "Custom amount",
  "Select amount": "Select your amount",
  "Select between": "Select an amount between",
  "And": "and",
  "Grid Complete": "Your grid is complete",
  "Max amount error": "The amount entered must be less than",
  "Min amount error": "The amount entered must be greater than",
  "Isnt multiple error": "The amount entered must be a multiple of",

  /** ------------------------------------------------ ERRORS ----------------------------------------------*/
  "error.generic": "An error has occured.",
  "error.invalid_customer_details": "User not found",
  "Something went wrong": "Something went wrong",
  "The app takes too long to start": "The app takes too long to start",
  "Old password invalid": "The entered password is incorrect",
  "New password invalid": "New password invalid",

  /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
  "ebulletin.fill_basket": "Start again from this bet",
  "ebulletin.find_point_of_sale": "Find a point of sale",
  "ebulletin.limit_date": "Limit date",
  "ebulletin.my_ebulletin": "My e-bulletin",
  "ebulletin.tag": "E-Bulletin",
  "ebulletin.tag.s": "E-Bulletins",
  "ebulletin.no_ebulletin": "You have no e-bulletin.",
  "ebulletin.my_ebulletins": "My e-bulletins",
  'Deadline': 'Deadline',

  /** ------------------------------------------------ RESULTS ----------------------------------------------*/
  "results.clear_filters": "Clear filters",
  "results.draws_results": "Draw results",
  "results.more_results_message": "To see more draws, refine the filters.",
  "results.most_popular_numbers": "Most popular numbers",
  "results.show_more_results": "Show more draws",

  /** ------------------------------------------------ SCAN ----------------------------------------------*/
  "Manual entry": "Manual entry",
  "scan.button_qrcode": "Scan a QR Code",
  "scan.button_code": "Enter a code",
  "scan.dialog_action_manual_entry": "Enter the code manually",
  "scan.dialog_action_qrcode": "Scan a QR Code",
  "scan.dialog_error_qrcode": "We could not read your QR code.",
  "scan.find_code": "Where can i find my code ?",
  "scan.help_bulletin_1": "Open the tab ‟My Bets”",
  "scan.help_bulletin_2": "Select ‟E-Bulletins”",
  "scan.help_bulletin_3": "Open the bulletin of your choice by clicking on ‟View”",
  "scan.help_bulletin_4": "The code is right below the QR code",
  "scan.help_bulletin_title": "Enter an e-bulletin code to load the same bet to your phone, or share your e-bulletin with a friend.",
  "scan.help_ticket_title": "Enter the code present on your game ticket, right above your QR code.",
  "scan.manual_entry_label": "Enter your code",
  "scan.manual_entry_label_2": "(ticket or e-bulletin)",
  "scan.manual_placeholder": "Enter the code",
  "scan.scan_not_available": "The camera is not available. Please check permissions on your smartphone.",
  "scan.scan_ticket_or_bulletin": "Scan a ticket or a e-bulletin",
  "Scan unavailable": "Only available on Android or IOS app.",
  "scan.isSportBetBulletinError": "You have scanned a sports betting e-bulletin.",
  "scan.openInApp": "Open in the dedicated App",
  "scan.comebackToScan": "Come back to scan",

  /** ------------------------------------------------ TABS ----------------------------------------------*/
  "my bets": "My bets",
  "my space": "My space",
  "tab scan": "Scan",

  /** ------------------------------------------------ GAMES ----------------------------------------------*/
  "Find a game": "Find a game",
  "Discover": "Discover",
  "See all": "See all",
  /** ------------------------------------------------ TICKET ----------------------------------------------*/
  "ticket.my_scanned_tickets": "My scanned tickets",
  "ticket.no_ticket_scanned": "You have not scanned any ticket.",
  "ticket.ref_not_found": "We couldn't find your ticket.",
  "Ticket": "Ticket",
  "Status": "Status",
  "Total bet": "Total bet",
  "Draws made": "Draws made",
  "Draws pending": "Draws pending",
  "Total gains": "total gains",

  "Game type": "Game type",
  "Bet": "Bet",
  "Odd": "Odd",
  "Gains": "Gains",
  "Gains nets": 'Net gains',
  "Gain": "Gain",
  "Draw": "Draw",
  "Selection": "Selection",

  "Expired": "Expired",
  "Lost": "Lost",
  "Won": "Won",
  "Pending": "Pending",
  "Finished": "Finished",
  "Canceled": "Canceled",
  "Price": "Price",

  "Draw results": "Draw results",
  "Your selection": "Your selection",
  "Winning combinations": "Winning combinations",
  "Rank": "Rank",

  "Potential Win": "Potential win",
  "Net Potential Win": "Net potential win",
  "Bets settled": "Bets settled",
  "Details": "Details",
  "Completed matches": "Completed matches",
  "Session": "Session",
  "Result": "Result",
  "Bet Blocked": "One or more bets are blocked",
  "Blocked": "Blocked",

  /** ------------------------------------------------ USER ----------------------------------------------*/
  "user.personal_info": "My personal information",
  /** ------------------------------------------------ MY SPACE ----------------------------------------------*/
  "My Parameters": "My Parameters",
  "My Favourites": "My Favourites",
  "My transactions": "My transactions",
  
  "Find a sale point": "Find a sale point",
  'Games Rules': 'Games Rules',
  'Game rule': 'Rules of',
  'Help': 'Help',
  "Favorite Games": "Favorite Games",
  "Favorite Grid": "Favorite Grid",
  "Parameters": "Parameters",
  'Games rules': 'Games rules',
  'Legal notices': 'Legal notices',
  'No legal notices': 'No legal notices',
  'Application languages': 'Application language',
  "fr": "French",
  "en": "English",
  "pt": "Portuguese",
  "Dark mode": "Dark mode",
  'Paytable': 'Paytable',
  'No Game Rules': 'No Game Rules',
  'Choose a game': 'Choose a game',
  'Empty section': 'This section is empty',
  'Good numbers': "Good numbers",
  'Jackpot': 'Jackpot',
  'x the bet': 'x the bet',
  'Contact': 'Contact',
  'Version': 'Version',
  'No app version': 'No app version specified',
  'How to play': 'How to play',
  "PaytableMessage": "{{totalFoundNumbers}} right number between {{minRange}}° and the {{maxRange}}° ball drawn",
  "PaytableMessage.multiple": "{{totalFoundNumbers}} right numbers between {{minRange}}° and the {{maxRange}}° ball drawn",
  "PaytableMessage.isInOrder": "{{totalFoundNumbers}} right number between {{minRange}}° and the {{maxRange}}° ball drawn, in order",
  "PaytableMessage.multiple.isInOrder": "{{totalFoundNumbers}} right numbers between {{minRange}}° and the {{maxRange}}° ball drawn, in order",
  "Change password": "Change password",
  'Change the password': 'Change the password',
  'Actual password': 'Actual password',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  'Password saved': 'Password saved.',
  "Day": "Daily",
  "Week": "Weekly",
  "Month": "Monthly",
  "LifeTime": "Life time",
  "Period": "Period",
  "Amount": "Amount",
  "Inactive": "Inactive",
  "Delete the limit": "Delete the limit",
  "No limit": "No limit",
  "Bet limit": "Bet limit",
  "Deposit limit": "Deposit limit",
  "Time limit": "Time limit",
  "Temporary exclusion": "Temporary exclusion",
  "Not defined": "Not defined",
  "Time limit passed": "Time limit passed",
  "My limits": "My limits",
  "7 days": "7 days",
  "Limit Amount": "Limit Amount",
  "Deposit money": "Deposit money",
  "Choose payment method": "Choose a payment method",
  /** ------------------------------------------------ BASKET ----------------------------------------------*/
  "basket.all_bets_expired": "All your bets have expired",
  "basket.back_to_basket": "Back to cart",
  "basket.empty": "Your cart is empty",
  "basket.max_bet_exceeded": "The total bet exceeds the authorized amount",
  "basket.my_basket": "My cart",
  "basket.remove_expired_items": "Delete expired bets in order to validate the basket",
  "Cancel bet": "Cancel the bet ?",
  "Cancel bet content": "Be careful, the contents of your basket will be deleted.",
  "Bet expired": "Bet expired",
  "See details": "See the details",
  "Nb of draws": "Number of draws",
  "Add a bet": "Add a bet",
  "Max potential gain": "Max potential gain",
  'Net potential gain': 'Net potential gain',
  "Enter phone number": "Enter your phone number",
  "Remember me": "Remember me",
  "Bet's validated": "Your bet is validated",
  "Find e-bulletin": "Find your e-bulletin in 'My bets'",
  /** ------------------------------------------------ CONNEXION ----------------------------------------------*/
  "Phone number": "phone number",
  "Continue": "Continue",
  "Not registered": "Not registered ?",
  "Sign up": "Sign up",
  "Password": "Password",
  "Invalid login details": "Incorrect phone number or password",
  "Customer is already logged": "Customer is already logged",
  "Player disabled or blocked": "Player disabled or blocked",
  "Logout": "Logout",
  "Disable Account": "Disable Account",
  "Confirm_deactivation.title": "Confirm deactivation ?",
  "Confirm_deactivation.message": "Please note that you will no longer be able to log in. To reactivate your account, you will need to contact the support team.",
  "Account Not Activated Title": "Your account is deactivated.",
  "Account Not Activated": "Your account is deactivated. Please contact support.",
  "deactivation_error_retry": "There is a problem with deactivation, please try again later.",
  "Login/Register": "Login/Register",
  "Balance": "Balance",
  "Deposit": "Deposit",
  "Withdraw": "Withdraw",
  "Transactions": "Transactions",
  "CGU text": "I certify that I am over 18 years old and that I have read the ",
  "Already registered": "Already registered ?",
  "Login": "Login",
  "Name": "Name",
  "Password confirmation": "Password confirmation",
  "Password error 2": "Password must be at least 6 characters",
  "send_verification_code": "Send the verification code",
  "Duplicate Phone.": "The number entered is already in use.",
  "Welcome": "WELCOME !",
  "password.error.mismatch": "Passwords are not the same",
  "password.forgot": "Forgot password",
  "password.reset.title": "Password reset",
  /** ------------------------------------------------ USER INFO --------------------------------------------*/
  "address": "Address",
  "birthDate": "Date of birth",
  "city": "City",
  "coords": "Contact details",
  "country": "Country",
  "currency": "Currency",
  "firstName": "Firstname",
  "lastName": "Lastname",
  "phone": "Phone",
  "postCode": "Zip code",
  /** ---------------------------------------------- FORM EDITION   ------------------------------------------ */
  /*    (build from the field translation key and the suffix '.edition')                                       */
  "address.edition": "Change of address",
  "birthDate.edition": "Change the date of birth",
  "firstName.edition": "Change of first name",
  "lastName.edition": "Change of last name",
  /** ----------------------------------------- POPUPS & GENERIC MESSAGES ---------------------------------------*/
  "changes_saved": "Changes saved.",
  "popup.unsaved_changes.title": "Changes were not saved",
  "popup.unsaved_changes.confirm_exit": "Exit without saving ?",
  "save_changes": "Save changes",

  'setnr=1': "1st",
	'setnr=2': "2nd",
	'setnr=3': "3rd",
	'setnr=4': "4th",
	'setnr=5': "5th",
	'quarternr=1': "1st",
	'quarternr=2': "2nd",
	'quarternr=3': "3rd",
	'quarternr=4': "4th",
}