import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';
import moment from 'moment';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.WordpressService;
const cacheTimeout = 60000 * 60;

const queryWordpress = `
query MyQuery {
  app_settings {
    nodes {
      setting {
        name
        value
        category
      }
    }
  }
  lottery_games {
    nodes {
      lottery_game {
        name
        gameId
        rectangleImage {
          sourceUrl
        }
        squareImage {
          sourceUrl
        }
      }
      GameRulesGroup {
        en
        fr
        pt
      }
    }
  }
  banner_ads {
    nodes {
      banner {
        image {
          sourceUrl
        }
        redirectionLink
      }
    }
  }
  countries {
    nodes {
      country_datas {
        countryAlpha2code
        countryDataName
      }
    }
  }
  fAQs {
    nodes {
      FAQItem {
        question
        answer
        language
        section
        fieldGroupName
      }
    }
  }
  app_themes {
    nodes {
      theme {
        logoOperator {
          sourceUrl
        }
        appTheme {
          colorPrimary
          colorSecondary
          colorTertiary
        }
      }
    }
  }
  helps {
    nodes {
      HelpItem {
        legalNotices
        language
        cgu
      }
    }
  }
}`;

export const createCacheConfig = (timestamp) => {
  return {
    dateDataWillExpired: timestamp + cacheTimeout,
    dateDataWillExpiredIso: new Date(timestamp + cacheTimeout).toISOString()
  };
}

export const getWordpressData = async () => {
  return wp_axios.get(`${API_URL}?query=${queryWordpress}`);
}

//=============================================================================
// HELPERS
//=============================================================================

export const getCountryLabel = (countryList, code2, fallbackToCode = false) => {
  return countryList.find(E => E.code2 == code2)?.label || (fallbackToCode ? code2 : '');
}
