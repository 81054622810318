import { View, Text, StyleSheet, TouchableHighlight, ScrollView } from "react-native";
import { useTheme, useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../store/actions/user.actions";
import React, { useState, useEffect } from "react";
import screens from "../../common/constants/screen.constants";
import { SVGEdit } from "../../assets/icons";
import { HeaderBackButton } from "@react-navigation/elements";
import { capitalize } from "../../utils/FormatUtils";
import moment from "moment";
import { ActivityIndicator } from "react-native";
import { getCountryLabel } from "../../store/services/wordpressApi.service";
import AlertPopup from "../../components/kpLibrary/AlertPopup.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import { useToast } from "react-native-toast-notifications";

const UserInformation = () => {

  //====================================== Hooks

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const toast = useToast();

  const countryList = useSelector(state => state.wordpress.countries);
  const userAccount = useSelector(state => state.user.customer?.account);
  const formData = useSelector(state => state.user.accountUpdateForm);
  const isFetching = useSelector(state => state.user.isFetching);
  const error = useSelector(state => state.user.error);

  const [isExitModalVisible, setIsExitModalVisible] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);


  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      navigation.setOptions({ headerLeft: renderHeaderLeft });

      return () => {
        //Page leave
        dispatch(UserActions.reset_error());
      }
    }, [formData])
  );

  useEffect(() => {
    userAccount && initFormData(userAccount);
  }, [userAccount]);

  useEffect(() => {
    if (error) {
      setErrorPopup(true);
    } else {
      setErrorPopup(false);
    }
  }, [error]);

  //====================================== Functions

  /**
   * Fill the form values from the given source data
   */
  const initFormData = (sourceObj) => {
    const data = { ...formData, isDirty: false };
    data.list.forEach(section => {
      section.fields.forEach(field => {
        if (field.type == 'form')
          field.subFields?.forEach(subField => subField.value = sourceObj[subField.sourceKey]);
        else
          field.value = sourceObj[field.sourceKey];
      })
    });
    dispatch(UserActions.set_account_update_form(data));
  }

  //__
  // Events

  const onFieldEditPress = (section, field) => {
    goEditPage(section, field);
  }

  const onValidate = () => {
    const CustomerDetails = {};
    formData.list.forEach(section => section.fields.forEach(field => {
      if (field.type == 'date' && field.value?.length > 0) {
        //Note: the read apis return a datetime string instead of a date string, but the update api requires a date string...
        field.value = field.value.substr(0, 10);
      }
      if (field.subFields?.length > 0)
        field.subFields.forEach(subField => {
          CustomerDetails[capitalize(subField.sourceKey)] = subField.value;
        });
      else
        CustomerDetails[capitalize(field.sourceKey)] = field.value;
    }))
    const userRequest = {
      internalID: userAccount?.internalID,
      customer: {
        UserDetails: {},
        CustomerDetails,
      }
    };
    const callback = (success) => {
      if (success) {
        toast.show(t('changes_saved'), {
          animation: true,
          duration: 2000,
          iconType: success ? 'success' : 'error',
          position: -50,
          hideOnPress: true,
          onHide: toast.hideAll,
          containerStyle: { width: 300 },
        });
      }
    }
    dispatch(UserActions.updateUser(userRequest, callback));
  }

  const buildCombinedSubFieldsSummary = (field) => {
    let row = 1;
    return field.subFields?.reduce((acc, subField) => {
      if (row != subField.row) {
        row = subField.row;
        acc += "\n";
      }
      const value = subField.selectType == 'country' ? getCountryLabel(countryList, subField.value, true) : subField.value;
      return acc + (value || '') + ' ';
    }, '')
  }

  //__
  // Navigation

  const goEditPage = (section, field) => {
    navigation.navigate(screens.UserFormInputEdition, { section, field });
  }

  const wantsToExit = () => {
    if (formData?.isDirty)
      setIsExitModalVisible(true);
    else
      doExit();
  }
  const doExit = () => {
    setIsExitModalVisible(false);
    if (navigation.canGoBack())
      navigation.goBack()
    else
      navigation.navigate(screens.MySpace);
  }

  //====================================== Render

  const renderHeaderLeft = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => wantsToExit()}
    />
  );

  const renderFormField = (section, field, index) => (
    <View key={index} style={[
      style.fieldContainer,
      index > 0 && section.displayMode == 'list' && style.fieldTopSeparator,
      section.displayMode == 'grid' && style.fieldContainerGrid,
    ]}>
      <View style={style.fieldContent}>
        <Text style={style.fieldName}>
          {t(field.labelKey)}
        </Text>
        <Text style={style.fieldValue}>
          {
            field.type == 'form' && buildCombinedSubFieldsSummary(field) ||
            field.type == 'date' && field.value && moment(field.value).format('L') ||
            field.value
          }
        </Text>
      </View>
      {(!field.readOnly) &&
        <TouchableHighlight 
          style={style.fieldEditButton}
          activeOpacity={0.6}
          underlayColor="rgb(246, 246, 246)"
          onPress={() => onFieldEditPress(section, field)}
        >
          <SVGEdit fill={theme.colors.greyText} />
        </TouchableHighlight>
      }
    </View>
  )

  const renderFormSection = (section, index) => (
    <View style={style.formSection} key={index}>
      <View style={[
        section.displayMode == 'grid' && style.formSectionContentGrid
      ]}>
        {
          section.fields.map((field, index) => renderFormField(section, field, index))
        }
      </View>
    </View>
  )

  return (
    <ScrollView style={style.container}>

      <View>
        {
          formData.list.map((section, index) => renderFormSection(section, index))
        }
      </View>
      <View style={style.formValidate}>
        {
          isFetching &&
          <ActivityIndicator size="large" color='gray' />
        }
        {
          isFetching == false && formData?.isDirty &&
          <StyledButton
            onPress={onValidate}
            text={t('save_changes')}
          />
        }
      </View>

      <AlertPopup
        type={'error'}
        visible={errorPopup}
        onClose={() => setErrorPopup(false)}
        title={t('error.generic')}
        message={t(error)}
        textAction1={t('OK')}
        onAction1={() => setErrorPopup(false)}
        useInlineButtons={true}
      />

      <AlertPopup
        type={'warning'}
        visible={isExitModalVisible}
        onClose={() => setIsExitModalVisible(false)}
        title={t('popup.unsaved_changes.title')}
        message={t('popup.unsaved_changes.confirm_exit')}
        textAction1={t('no')}
        onAction1={() => setIsExitModalVisible(false)}
        textAction2={t('yes')}
        onAction2={doExit}
        useInlineButtons={true}
      />
    </ScrollView>
  );
};

export default UserInformation;

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    backgroundColor: '#fff',
  },
  error: {
    padding: 10,
    alignItems: 'center',
  },
  errorText: {
    color: '#F92626',
    fontFamily: 'Raleway-bold',
    fontSize: 15,
  },
  errorTextDetails: {
    color: '#F92626',
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
  },
  //
  formSection: {
    paddingVertical: 10,
  },
  formSectionTitle: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 18,
    paddingBottom: 5,
  },
  formSectionContentGrid: {
    flexDirection: 'row',
  },
  fieldContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
  },
  fieldContainerGrid: {
    width: '50%'
  },
  fieldContent: {
    fontSize: 11,
    flex: 1,
  },
  fieldName: {
    fontSize: 13,
    fontFamily: 'Raleway-Light',
  },
  fieldValue: {
    fontSize: 14,
    fontFamily: 'Raleway-Medium',
    paddingTop: 8,
  },
  fieldEditButton: {
    borderRadius: 50,
    padding: 10,
  },
  formValidate: {
    padding: 12,
  },
  fieldTopSeparator: {
    borderTopColor: theme.colors.greyText,
    borderTopWidth: 1,
  },
})
