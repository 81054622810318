import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { ticketDateFormat } from '../../utils/DateUtils';
import { getBetStatus, getStatusColor } from '../../utils/StatusUtils';
import { formatNumber } from '../../utils/FormatUtils';
import GridLine from './TicketSummary.GridLine';
import { computeNetAmount } from "../../store/services/taxes.service";

export default function TicketSummary({ticket, t, gameName, currency}) {

  const taxList = useSelector(state => state.taxes.taxes);
  const useTaxes = useSelector(state => state.taxes.useTaxes);
  const [computeColor, setComputeColor] = useState('#404040');
  const [nbDrawsPending, setNbDrawsPending] = useState(0);
  const [nbDrawsMade, setNbDrawsMade] = useState(0);
  const [isSimple, setIsSimple] = useState(false);
  const [totalPotentialWin, setTotalPotentialWin] = useState(0);
  const [totalPotentialNetWin, setTotalPotentialNetWin] = useState(0);
  const [nbBetSettled, setNbBetSettled] = useState(0)
  const [isBetLocked, setIsBetLocked] = useState(
    ticket?.listOfBets?.reduce((acc, bet) => {
      return acc || bet.locked;
    }, false)
  );

  // Only for LotoSports
  const [matchCompleted, setMatchCompleted] = useState(0);

  const style = makeStyles(computeColor);

  useEffect(() => {
    setComputeColor(getStatusColor(ticket))
    computeDrawsMade();
    computePotentialWin();
    computeNbBetSettled();
    computeMatchCompleted();
    setIsSimple(ticket.listOfBets[0]?.typeOfBet == "single")
  }, [ticket])

  useEffect(() => {
    computeDrawsPending();
  }, [nbDrawsMade]);

  function computeDrawsMade() {
    let nbDraws = 0;
    ticket.listOfBets?.forEach((bet) => {
      if (bet.gameType === 'mutualLottery') {
        bet.draw.status === 'end' ? nbDraws += 1 : nbDraws += 0;
      } else if (bet.gameType === 'instantLottery') {
        nbDraws = nbDraws + bet.listOfStatusAndDraws.filter(draw => draw.status !== 'wait').length;
      } else {
        return;
      }
    })
    setNbDrawsMade(nbDraws)
  }

  function computePotentialWin() {
    setTotalPotentialWin(
      ticket.listOfBets?.reduce((acc, curr) => {
        return acc + curr.totalPotentialWin;
      }, 0)
    )
    setTotalPotentialNetWin(
      ticket.listOfBets?.reduce((acc, curr) => {
        return acc + curr?.totalPotentialNetWin;
      }, 0)
    )
  }

  function computeMatchCompleted() {
    if (ticket?.listOfBets[0]?.gameType !== 'sportLottery') return;

    const today = Date.now();
    setMatchCompleted(
      ticket?.listOfBets[0]?.sportLotteryContest.reduce((acc, curr) => {
        const matchDate = new Date(curr.matchDate);
        return acc + (matchDate.getTime() < today ? 1 : 0);
      }, 0)
    )
  }

  function computeDrawsPending() {
    let nbDraws = 0;
    ticket.listOfBets?.forEach((bet) => {
      if (bet.gameType === 'mutualLottery') {
        bet.draw.status === 'end' ? nbDraws += 0 : nbDraws += 1;
      } else if (bet.gameType === 'instantLottery') {
        nbDraws = nbDraws + bet.numberOfDraws;
      } else {
        return
      }
    })
    setNbDrawsPending(nbDraws === 0 ? nbDraws : nbDraws - nbDrawsMade)
  }

  function computeNbBetSettled() {
    if(!ticket.listOfBets && ticket.listOfBets[0].gameType !== "BetSports") return;

    setNbBetSettled(
      ticket.listOfBets.reduce((acc, curr) => {
        return acc + (curr.status !== "WAIT" ? 1 : 0);
      }, 0)
    )
  }

  return (
    <View>
      <View style={style.summaryTicket}>

        {/* CONSTANT PART */}
        <Text style={style.date}>
          {ticketDateFormat(ticket.creationTime)}
        </Text>
        <Text style={style.gameName}>
          {ticket.listOfBets[0]?.typeOfBet} {gameName}
        </Text>

        <GridLine title={`${t('Ticket')} n°`} data={ticket.reference}/>
        <GridLine title={t('Status')} data={isBetLocked ? t('Blocked') : t(getBetStatus(ticket.status))} status={ticket.status} isBetLocked={isBetLocked}/>

        {/* CHANGING PART */}
        {ticket.listOfBets[0].gameType !== "BetSports" ?
          (
            <View style={{width: '100%'}}>
              {ticket.listOfBets[0].gameType === 'sportLottery' ? (
                ticket.listOfBets[0].status === "wait" && <GridLine title={t('Completed matches')} data={`${matchCompleted} / ${ticket.listOfBets[0].sportLotteryContest.length}`}/>
              ) : ticket.listOfBets[0].gameType !== "fastPlay" && (
                <View>
                  <GridLine title={t('Draws made')} data={nbDrawsMade}/>
                  <GridLine title={t('Draws pending')} data={nbDrawsPending}/>
                </View>
              )}
              <GridLine title={t('Total bet')} data={`${formatNumber(ticket.amtTotalBet)} ${currency}`}/>
              {ticket.status !== 'wait' && ticket.amtTotalWon > 0 && <GridLine title={t('Gains')} data={`${formatNumber(ticket.amtTotalWon + ticket.taxAmount)} ${currency}`}/>}
            </View>
          ) : (
            <View style={{width: '100%'}}>

              <GridLine title={t('Bets settled')} data={`${nbBetSettled} / ${ticket.nbBet}`}/>
              <GridLine title={t('Bet')} data={`${formatNumber(ticket.amtTotalBet)} ${currency}`}/>
              {!isSimple && (
                <GridLine title={t('Odd')} data={ticket.listOfBets[0].totalOdds}/>
              )}
              {ticket.status === 'wait' && 
                <GridLine
                  title={t('Potential Win')}
                  data={`${formatNumber(totalPotentialWin)} ${currency}`}
                />
              }
              {useTaxes && ticket.status === 'wait' && 
                <GridLine
                  title={t('Net Potential Win')}
                  data={`${formatNumber(isSimple ? totalPotentialNetWin ? totalPotentialNetWin : totalPotentialWin : computeNetAmount(totalPotentialWin, taxList))} ${currency}`}
                />
              }
              {ticket.status !== 'wait' && ticket.amtTotalWon > 0 && <GridLine title={t('Gains')} data={`${formatNumber(ticket.amtTotalWon + ticket.taxAmount)} ${currency}`}/>}
            </View>
          )}
      
      </View>
      {/* TOTAL GAIN BOTTOM PART */}
      {isBetLocked ? (
        <View style={style.gridGainsBlocked}>
          <View style={style.centerColumn}>
            <Text style={style.leftGainsText}>
              {t('Bet Blocked')}
            </Text>
          </View>
        </View>
      ) : (
        <View style={style.gridGains}>
          <View style={style.leftColumn}>
            <Text style={style.leftGainsText}>
            {t(ticket.status == 'wait' ? 'Gains' : 'Gains nets')} :
            </Text>
          </View>
          <View style={style.rightColumn}>
            <Text style={style.rightGainsText}>
              {formatNumber(ticket.amtTotalWon, taxList)} {currency}
            </Text>
          </View>
        </View>
      )}

    </View>
  );
}


//====================================== STYLE
const makeStyles = (computeColor) => StyleSheet.create({
  summaryTicket: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingTop: 15,
    alignItems: 'center'
  },
  date: {
    paddingBottom: 10,
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13
  },
  gameName: {
    paddingBottom: 14,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 22,
    textTransform: 'uppercase'
  },
  // Grid Part
  gridSummary: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 7,
    alignItems: 'center'
  },
  centerColumn: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  leftColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 7
  },
  leftText: {
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13,
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    justifyContent: 'center'
  },
  rightText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#404040',
    fontSize: 15
  },
  // Status Ball
  statusBallPending: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#FE1593',
  },
  statusBallEnded: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#A9A9A9',
  },
  // Total Gains Summary
  gridGains: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
    backgroundColor: computeColor,
    marginBottom: 10
  },
  gridGainsBlocked: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
    backgroundColor: '#d30000',
    marginBottom: 10
  },
  leftGainsText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#ffffff',
    fontSize: 16,
  },
  rightGainsText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#ffffff',
    fontSize: 20,
  },
});
