import { View, Text, StyleSheet, Platform, ScrollView } from "react-native";
import { useTheme, useNavigation, useFocusEffect, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../store/actions/user.actions";
import { useState } from "react";
import moment from "moment";
import StyledTextInput from "../../components/kpLibrary/forms/StyledTextInput.comp";
import StyledSelectInput from "../../components/kpLibrary/forms/StyledSelectInput.comp";
import StyledDateInput from "../../components/kpLibrary/forms/StyledDateInput.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";


/**
 * @route params allowed : section, field (see user.service::getAccountUpdateFormModel)
 */
const UserFormInputEdition = () => {

  const LEGAL_AGE = 18;

  //====================================== Hooks

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const isWeb = Platform.OS == 'web';

  const formData = useSelector(state => state.user.accountUpdateForm);
  const userAccount = useSelector(state => state.user.customer?.account);
  const countryList = useSelector(state => state.wordpress.countries);

  const [field, setField] = useState(null);
  const [section, setSection] = useState(null);
  const [title, setTitle] = useState('');

  const [fieldUpdates, setFieldUpdates] = useState({}); //{key:sourceKey}: newValue]

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      initialize(route.params?.field, route.params?.section);

      return () => {
        //Page leave
      }
    }, [route])
  );

  //====================================== Functions

  const initialize = (pField, pSection) => {
    //Note: always empty when entering the edit form
    setField(pField);
    setSection(pSection);
    setTitle(pField?.labelKey ? t(pField?.labelKey + '.edition') : '');
    if (pField.sourceKey === "address") {
      setFieldUpdates(
        pField.subFields.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.sourceKey]: curr.value
          }
        }, {})
      )
    } else {
      setFieldUpdates({
        ...fieldUpdates,
        [pField.sourceKey]: pField.value,
      })      
    }

  }

  const updateFormDataField = () => {
    if (!userAccount || !field) return;
    const data = { ...formData, isDirty: true };
    const foundSection = data.list.find(E => E.sectionLabelKey == section.sectionKey);
    const foundField = foundSection?.fields?.find(E => E.sourceKey == field.sourceKey);
    if (!foundField) return;

    if (field.subFields?.length) {
      field.subFields.forEach(subField => {
        const foundSubField = foundField.subFields.find(E => E.sourceKey == subField.sourceKey);
        if (foundSubField)
          foundSubField.value = fieldUpdates[subField.sourceKey] || '';
      });
    } else {
      //the field has been validated as empty
      foundField.value = fieldUpdates[field.sourceKey] || field.defaultValue || '';
    }

    dispatch(UserActions.set_account_update_form(data));
  }

  const onValidate = () => {
    //Warning: btobet api does not update empty fields, it keeps the previous not-empty saved value.
    updateFormDataField();
    navigation.goBack();
  }

  const onInputChange = (_field, value) => {
    setFieldUpdates({
      ...fieldUpdates,
      [_field.sourceKey]: value,
    })
  }


  //====================================== Render

  const renderField = (_field, i) => {
    if (!_field) return;
    switch (_field.type) {
      case 'string':
      case 'number':
      case 'tel':
        return renderTextInput(_field);
      case 'date':
        return renderDateInput(_field);
      case 'select':
        return renderSelectInput(_field);
      default:
        break;
    }
  }

  const renderTextInput = (_field) => (
    <StyledTextInput
      keyboardType={_field.type == 'number' ? 'numeric' : _field.type == 'tel' ? 'phone-pad' : 'default'}
      onChangeText={text => onInputChange(_field, text)}
      placeholder={t(_field.labelKey)}
      selectTextOnFocus={true}
      value={fieldUpdates[_field.sourceKey]}
    />
  )

  const renderDateInput = (_field) => (
    <StyledDateInput
      keyboardType={_field.type == 'number' ? 'numeric' : _field.type == 'tel' ? 'phone-pad' : 'default'}
      onChange={text => onInputChange(_field, text)}
      placeholder={t(_field.labelKey)}
      // Display the updated date else the default one
      value={fieldUpdates[_field.sourceKey] || _field.defaultValue}
      maxDate={moment().add(-LEGAL_AGE, 'years')}
      minDate={'1900'}
    />
  )

  const renderSelectInput = (_field) => {
    let opts = { data: [], key: '', label: '' };
    if (_field.selectType === 'country') {
      opts = { data: countryList, key: 'code2', label: 'label' };
    }
    return (
      <StyledSelectInput
        onSelect={selectedItem => onInputChange(_field, selectedItem?.[opts.key])}
        placeholder={t(_field.labelKey)}
        value={fieldUpdates[_field.sourceKey]}
        data={opts.data}
        fieldKey={opts.key}
        fieldLabel={opts.label}
      />
    )
  }

  const renderFieldList = (fieldList) => {
    return fieldList.map((_field, i) => (
      <View key={i} style={{ borderColor: 'red' }}>
        {renderField(_field)}
      </View>
    ))
  }


  return (
    <ScrollView
      style={style.container}
      contentContainerStyle={{ alignItems: 'center' }}
    >
      <View style={style.content}>
        <View style={style.title}>
          <Text style={style.titleText}>
            {title}
          </Text>
        </View>
        {
          field?.subFields?.length > 0
          && renderFieldList(field.subFields)
          || renderField(field)
        }
        <StyledButton
          onPress={onValidate}
          text={t('validate')}
        />

      </View>
    </ScrollView>
  );
};

export default UserFormInputEdition;

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingVertical: 15,
    backgroundColor: '#fff',
  },
  content: {
    marginHorizontal: 45,
    paddingVertical: 5,
    minWidth: 300,
  },
  title: {
    paddingVertical: 30,
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 22,
    textAlign: 'center',
  },
})
