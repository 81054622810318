import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Modal, Platform, useWindowDimensions } from 'react-native';
import * as UserActions from '../../store/actions/user.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StyledButton from '../kpLibrary/forms/StyledButton.comp';

const LogoutModal = ({
  modalVisible,
  setModalVisible,
}) => {

  const isWeb = Platform.OS === "web";
  const [margin, setMargin] = useState('');
  const windowWidth = useWindowDimensions().width;
  const theme = useTheme();
  const dispatch = useDispatch();
  const styles = makeStyles(theme, margin);
  const { t } = useTranslation();

  useEffect(() => {
    isWeb && getMarginHorizontal();
  }, [windowWidth])

  const getMarginHorizontal = () => {
    let maxWidth = 700;
    if (windowWidth > maxWidth) {
      let windowPercentage = (maxWidth * 100) / windowWidth;
      let marginHorizontal = (100 - windowPercentage) / 2;
      setMargin(`${marginHorizontal}%`)
    } else {
      setMargin(0);
    }
  }

  const logOut = () => {
    dispatch(UserActions.disconnectUser());
    setModalVisible(false);
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {setModalVisible(!modalVisible)}}
    >
      <View style={isWeb ? styles.centeredView : styles.centeredViewPhone}>
        <View style={styles.modalView}>
          <StyledButton
            onPress={logOut}
            text={t("Logout")}
            buttonContainerStyle={{width: '100%'}}
          />
          <StyledButton
            onPress={closeModal}
            text={t("cancel")}
            fill='outline'
            buttonContainerStyle={{width: '100%'}}
          />
        </View>
      </View>
    </Modal>
  );
};

const makeStyles = (theme, margin) => StyleSheet.create({
  //Modal
  centeredView: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: 700,
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginHorizontal: margin
  },
  centeredViewPhone: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: 700,
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center"
  },
  modalView: {
    width: '100%',
    paddingVertical: 55,
    backgroundColor: "white",
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    paddingHorizontal: 31,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
});

export default LogoutModal;
