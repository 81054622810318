import { ACTIONS, STORAGE_USER_TOKEN_INFO } from "../actions/user.actions";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as UserService from '../services/user.service';
import { getBtobetErrorMessage } from "../services/api.online.service";

const initialState = {
  // USER INFOS
  token: null,
  balance: null,
  bonuses: null,
  customer: null,
  balanceAmount: 0,
  currencyISO: null,
  limits: null,
  periodsTypes: null,
  // Transaction
  transactions: null,
  transactionDepositPaymentProviders: null,
  // Password
  isPasswordChanged: false,
  passwordError: '',
  // Page helpers
  accountUpdateForm: UserService.getAccountUpdateFormModel(),
  searchedUser: null,
  // ERROR
  error: null,
  errorCode: null,
  registerError: null,
  // REDUCER STEP
  isFetching: false,
  isConnected: false
}

export const saveUserTokenInfoToStorage = async (token, internalID) => {
  await AsyncStorage.setItem(STORAGE_USER_TOKEN_INFO, JSON.stringify({ token, internalID }))
}

export const removeUserTokenInfoFromStorage = async () => {
  await AsyncStorage.removeItem(STORAGE_USER_TOKEN_INFO)
}

export const computeRegisterError = (errors) => {
  return getBtobetErrorMessage(errors[0].ErrorNo, errors[0].Description);
}

export const computeChangePasswordError = (errors) => {
  return getBtobetErrorMessage(errors[0].ErrorNo, errors[0].Description);
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.USER_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload?.errors?.length > 0 ? action.payload.errors[0].Description : action.payload?.message,
        errorCode: action.payload?.errors?.length > 0 && action.payload?.errors[0]?.ErrorNo
      };
    }
    case ACTIONS.USER_FETCHING: {
      return { ...state, isFetching: true, error: null, errorCode: null }
    }
    case ACTIONS.USER_FETCHING_DONE:
    case ACTIONS.USER_RESET_ERROR: {
      return { ...state, isFetching: false, error: null, errorCode: null }
    }
    //------------------
    case ACTIONS.USER_ACCOUNT_UPDATE_FORM: {
      return {
        ...state,
        accountUpdateForm: action.payload,
      }
    }
    case ACTIONS.USER_INFO: {
      //Note: getting user info with success means we are still connected
      return {
        ...state,
        isConnected: true,
        isFetching: false,
        balance: action.payload?.customer?.balance,
        customer: action.payload?.customer,
        bonuses: action.payload?.customer?.bonuses,
        balanceAmount: action.payload?.customer?.balance?.real,
        currencyISO: action.payload?.customer?.balance?.currencyISO
      }
    }
    case ACTIONS.USER_LOGIN_SUCCESS: {
      //save token & user internalID into storage
      const token = action.payload?.customer?.token;
      const internalID = action.payload?.customer?.account?.internalID;
      saveUserTokenInfoToStorage(token, internalID);
      // update state with user info and status "connected"
      return {
        ...state,
        isConnected: true,
        isFetching: false,
        token: action.payload?.customer?.token,
        balance: action.payload?.balance,
        customer: action.payload?.customer,
        bonuses: action.payload?.bonuses,
        balanceAmount: action.payload?.balance?.real,
        currencyISO: action.payload?.balance?.currencyISO
      }
    }
    case ACTIONS.USER_LOGOUT: {
      removeUserTokenInfoFromStorage();
      return initialState;
    }
    case ACTIONS.USER_REGISTER_FAILED:
      return {
        ...state,
        isConnected: false,
        isFetching: false,
        registerError: computeRegisterError(action.payload)
      };
    case ACTIONS.USER_REGISTER_RESETERROR:
      return {
        ...state,
        isConnected: false,
        isFetching: false,
        registerError: null
      };
    case ACTIONS.USER_TOKEN: {
      return {
        ...state,
        token: action.payload,
      }
    }
    case ACTIONS.USER_SEARCHED: {
      return {
        ...state,
        searchedUser: action.payload?.customer,
      }
    }
    case ACTIONS.USER_GET_LIMITS: {
      return {
        ...state,
        limits: action.payload,
      }
    }
    case ACTIONS.SET_USER_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
      }
    }
    case ACTIONS.SET_TRANSACTION_PAYMENT_PROVIDERS: {
      return {
        ...state,
        transactionDepositPaymentProviders: action.payload,
      }
    }
    case ACTIONS.USER_GET_PERIODS_TYPES: {
      return {
        ...state,
        periodsTypes: action.payload,
      }
    }
    // PASSWORD
    case ACTIONS.CHANGED_PASSWORD: {
      return {
        ...state,
        isPasswordChanged: action.payload,
      }
    }
    case ACTIONS.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        isPasswordChanged: false,
        passwordError: computeChangePasswordError(action.payload?.errors),
      }
    }
    case ACTIONS.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        isPasswordChanged: false,
        passwordError: '',
      }
    }
    default:
      return state;
  }
}