import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Keyboard, Pressable, View, Text, TextInput, StyleSheet, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SVGInformationCircle } from '../../assets/icons';
import screens from '../../common/constants/screen.constants';
import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import * as TicketActions from '../../store/actions/ticket.actions';
import * as GamesActions from '../../store/actions/games.actions';

import { isEbulletinFormat } from '../../store/services/ebulletin.service';
import AlertPopup from '../../components/kpLibrary/AlertPopup.comp';
import StyledButton from '../../components/kpLibrary/forms/StyledButton.comp';

const ScanManualPage = ({ route }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const style = makeStyles(useTheme())
  const isWeb = Platform.OS === "web";


  const [manualEntry, setManualEntry] = useState('');
  const [isShowMobileAppAlert, setShowMobileAppAlert] = useState(false);

  const errorBulletin = useSelector(state => state.ebulletin.error);
  const errorTicket = useSelector(state => state.ticket.error);
  const isFetching = useSelector(state => state.ebulletin.isFetching || state.ticket.isFetching);
  const isSportBet = useSelector(state => state.ebulletin.isSportBet);

  // HOOKS

  useFocusEffect(
    React.useCallback(() => {

      return () => {
        resetSearchState();
      }
    }, [])
  );

  // FUNCTIONS

  const onValidate = () => {
    Keyboard.dismiss();
    console.log("onValidate, entry = " + manualEntry);
    dispatch(GamesActions.getGames());
    if (!manualEntry) return;

    if (isEbulletinFormat(manualEntry)) {
      dispatch(EBulletinActions.searchBulletin(
        manualEntry,
        res => res?.bulletinCode && goBulletinPage(res.bulletinCode),
        true
      ));
    } else {
      dispatch(TicketActions.searchTicket(manualEntry));
    }
  }

  const onHelp = () => {
    navigation.navigate(screens.ScanHelp);
  }

  const resetSearchState = () => {
    dispatch(EBulletinActions.reset_search_state());
    dispatch(TicketActions.reset_search_state());
  }

  const goQRCodeScan = () => {
    isWeb ?
      setShowMobileAppAlert(true) :
      navigation.navigate(screens.ScanPreview);
  }
  const goBulletinPage = (bulletinCode) => {
    navigation.navigate(screens.EBulletinPage, { bulletinCode, fromScan: true });
  }

  const retryScan = () => {
    resetSearchState();
    dispatch(EBulletinActions.set_bulletin_is_sportbet(false))
  }

  return (
    <View style={style.container}>

      {isFetching == false && <>

        <View style={style.text_scan_label_block}>
          <Text style={style.text_scan_label}>
            {t('scan.manual_entry_label')}
          </Text>
          <Text style={style.text_scan_label}>
            {t('scan.manual_entry_label_2')}
          </Text>
        </View>

        <TextInput
          style={style.input}
          value={manualEntry}
          onChangeText={setManualEntry}
          placeholder={t('scan.manual_placeholder')}
          keyboardType="default"
        />

        <StyledButton
          onPress={onValidate}
          text={t('validate')}
          disabled={manualEntry?.length <= 0}
        />

        <Pressable
          style={style.help}
          onPress={onHelp}
        >
          <SVGInformationCircle />
          <Text style={style.help_text}>
            {t('scan.find_code')}
          </Text>
        </Pressable>

      </>}

      {
        isFetching &&
        <View style={style.search_in_progress}>
          <ActivityIndicator size="large" color='gray' />
          <Text>{t('search_in_progress')}</Text>
        </View>
      }

      <AlertPopup
        type={'info'}
        visible={Boolean(errorTicket || errorBulletin)}
        message={t('ticket.ref_not_found')}
        onClose={() => resetSearchState()}
        onAction1={() => resetSearchState()}
        onAction2={goQRCodeScan}
        textAction1={t('retry')}
        textAction2={t('scan.dialog_action_qrcode')}
      />
      <AlertPopup
        type={'info'}
        visible={isSportBet}
        message={t('scan.isSportBetBulletinError')}
        onClose={retryScan}
        onAction1={retryScan}
        onAction2={retryScan}
        textAction1={t('scan.openInApp')}
        textAction2={t('scan.comebackToScan')}
      />

      <AlertPopup
        type={'info'}
        visible={isShowMobileAppAlert}
        message={t('Scan unavailable')}
        onClose={() => setShowMobileAppAlert(false)}
      />

    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 50,
  },
  input: {
    backgroundColor: 'white',
    fontSize: 16,
    textAlign: 'center',
    color: "#444",
    paddingVertical: 8,
  },
  search_in_progress: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: -20,
  },
  text_scan_label_block: {
    paddingTop: 30,
    paddingBottom: 30
  },
  text_scan_label: {
    textAlign: 'center',
    fontWeight: '600',
    color: "#444",
    fontSize: 17,
  },
  help: {
    marginVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  help_text: {
    color: '#222',
    paddingLeft: 10,
  }
})

//======================================
export default ScanManualPage;