import { Text, StyleSheet, ScrollView, View, Pressable } from "react-native";
import { useTheme, useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import {  useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import Paytable from "../../../components/my-space/Paytable.comp";
import * as EBulletinsActions from '../../../store/actions/games.actions';
import AccordionListItem from "../../../components/my-space/AccordionListItem";

const GamePaytables = (props) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const game = props.route.params.game;
  const [currentGameBetType, setCurrentGameBetType] = useState([]);
  const [currentBetTypeSelected, setCurrentBetTypeSelected] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [currentTableOpen, setCurrentTableOpen] = useState(null);
  const [isMultipleCategory, setMultipleCategory] = useState(false);
  const betTypesActiveByGame = useSelector(state => state.games.betTypesActiveByGame);
  const isFetching = useSelector(state => state.games.isFetching);

  useEffect(() => {
    dispatch(EBulletinsActions.getBetTypeList(game.gameType, game.gameId))
  }, [])

  useEffect(() => {
    setCurrentGameBetType(Object.keys(betTypesActiveByGame).length === 0 ? [] : betTypesActiveByGame[game.gameId])
  }, [betTypesActiveByGame])

  useEffect(() => {
    setCategoryList(computeCategoryList());
    setMultipleCategory(currentGameBetType?.every(elem => elem.category === undefined))
  }, [currentGameBetType]);

  useEffect(() => {
    setCurrentBetTypeSelected(categoryList[0]);
  }, [categoryList])

  useEffect(() => {
    setNameList(computeNameList())
  }, [categoryList, currentBetTypeSelected]);

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      navigation.setOptions({ title: game.name })
      return () => {
        //Page leave
      }
    }, [])
  );

  const computeCategoryList = () => {
    let array = currentGameBetType?.map((betType) => {
      return betType.category === undefined ? 'Others' : betType.category;
    })
    return [...new Set(array)].sort();
  }

  const computeNameList = () => {
    return currentGameBetType?.filter((item) => {
      return currentBetTypeSelected === 'Others' ?
        item.category === undefined :
        currentBetTypeSelected === item.category
    })
  }

  return (
    <ScrollView>
      <View style={style.titleContainer}>
        <Text style={style.titleText}>
          {t('Paytable')}
        </Text>
      </View>
      <View style={style.container}>

        {!isFetching && categoryList?.length === 0 && nameList?.length === 0 && (
          <Text style={style.emptySection}>{t('Empty section')}.</Text>
        )}

        {currentGameBetType?.length === 1 && (
          <Paytable game={currentGameBetType[0]} openSimple={true} />
        )}

        {!isMultipleCategory && categoryList?.length > 1 && 
          <ScrollView horizontal={true} style={style.betTypeLine}>
            {categoryList?.map((item, index) => (
              <Pressable
                key={index}
                style={currentBetTypeSelected === item ? style.betTypeCardSelected : style.betTypeCard}
                onPress={() => setCurrentBetTypeSelected(item)}
              >
                <Text 
                  style={currentBetTypeSelected === item ? style.betTypeCardTextSelected : style.betTypeCardText}
                >
                  {item === 'Others' ? t(item) : item}
                </Text>
              </Pressable>
            ))}
          </ScrollView>
        }

        <ScrollView>

          {currentGameBetType?.length > 1 && nameList?.map((item, index) => {
            return (
              <AccordionListItem
                key={index}
                title={item.name}
                item={item}
                index={index}
                currentTableOpen={currentTableOpen}
                setCurrentTableOpen={setCurrentTableOpen}
              />
          )})}

        </ScrollView>
        
      </View>
    </ScrollView>
  );
};

export default GamePaytables;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    marginTop: 20
  },
  titleContainer: {
    width: '100%',
    borderBottomColor: theme.colors.greyText,
    borderBottomWidth: 1,
    justifyContent: 'center', 
    alignItems: 'center',
    height: 60
  },
  titleText: {
    fontFamily: 'Raleway-Medium',
    fontSize: 16,
    color: theme.colors.greyText,
  },
  betTypeLine: {
    flexDirection: 'row',
    height: 60,
    paddingLeft: 8,
    marginBottom: 5
  },
  betTypeCard: {
    backgroundColor: '#ffffff',
    height: 45,
    minWidth: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 2,
    marginHorizontal: 3,
    paddingHorizontal: 10
  },
  betTypeCardSelected: {
    backgroundColor: theme.colors.primary,
    height: 45,
    minWidth: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 2,
    marginHorizontal: 3,
    paddingHorizontal: 10
  },
  betTypeCardText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    color: theme.colors.greyText
  },
  betTypeCardTextSelected: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    color: '#ffffff'
  },
  nameItem: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    height: 55,
    marginHorizontal: 10,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  emptySection: {
    marginTop: 5,
    marginLeft: 25,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-MediumItalic',
    fontSize: 14
  }
});