import { View, Text, StyleSheet, Pressable, TouchableHighlight } from "react-native";
import { useTheme } from '@react-navigation/native';
import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import moment from 'moment';

import { SVGDelete, SVGArrowDown, SVGError } from '../../assets/icons';

import { basketDateFormat } from "../../utils/DateUtils";
import { formatNumber } from "../../utils/FormatUtils";
import { computeMaxPotentialGain } from "../../utils/BasketUtils";
import { computeNetAmount } from "../../store/services/taxes.service";

// Regex format for UUID
const regexUUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

const BasketItem = ({item, removeBasketItem, index}) => {

  const [isBetExpired, setBetExpired] = useState(false);
  const [detailsCollapse, setDetailsCollapse] = useState(false);

  const currency = useSelector(state => state.login.currency);
  const itemList = useSelector(state => state.basket.itemList);
  const taxList = useSelector(state => state.taxes.taxes);
  const useTaxes = useSelector(state => state.taxes.useTaxes);
  const currentGame = useSelector(state => state.games.currentGame);
  const nextSessionByGame = useSelector(state => state.games.nextSessionByGame);

  const theme = useTheme();
  const style = makeStyles(theme, isBetExpired);
  const { t } = useTranslation();

  useEffect(() => {
    setBetExpired(item.draw?.closingDate && moment(item.draw.closingDate).isBefore());
  }, [itemList])

  useEffect(() => {
    // First check if the game is from the hub with the UUID format id
    if (regexUUID.test(nextSessionByGame[currentGame.gameId]?.drawId)) {
      item.potentialAward = nextSessionByGame[currentGame.gameId]?.potentialAward;
    } else {
      // If is not from the HUB, go to the utils
      item.potentialAward = computeMaxPotentialGain(item);
    }
  }, [nextSessionByGame, itemList])

  return (
    <View style={style.basketItem}>
      
      <View style={style.stakePart}>
        {
          isBetExpired ? (
            <Text style={style.stakeText}>
              <SVGError fill={theme.colors.expiredColor} style={{marginRight: 5}} />
                {t('Bet expired')}
            </Text>
          ) : (
            <Text style={style.stakeText}>{formatNumber(item.price)} {currency}</Text>
          )
        }
        <TouchableHighlight
          style={{borderRadius: 50}}
          activeOpacity={0.7}
          underlayColor={theme.colors.primary}
          onPress={() => removeBasketItem(index)}
        >
          <SVGDelete fill='#FFFFFF'/>
        </TouchableHighlight>
      </View>

      <View style={style.gridPart}>
        {item.selectionListByGrid.map((grid, index) => (
          <View key={index}>  
            <Text style={style.gridText}>
              {t('Grid')} {item.selectionListByGrid.length > 1 ? (index === 0 ? 'A' : 'B') : ''}
            </Text>
            <View style={style.numbersLine}>
              {grid?.map((number, idx) => (
                <View key={idx} style={index === 1 ? style.ballB : style.ball}>
                  <Text style={style.number}>{number}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>

      {!detailsCollapse ? (
        <Pressable style={style.detailsPart} onPress={() => setDetailsCollapse(!detailsCollapse)}>
          <Text style={style.detailsText}>
            {t('See details')}
          </Text>
          <View style={style.arrowDownContainer}>
            <SVGArrowDown fill={theme.colors.primary} style={detailsCollapse && style.arrowDownRotate} />
          </View>
        </Pressable>
      ) : (
        <View>
          <Pressable style={style.detailsPartCollapsable} onPress={() => setDetailsCollapse(!detailsCollapse)}>
            <View style={style.arrowDownContainer}>
              <SVGArrowDown fill={theme.colors.primary} style={detailsCollapse && style.arrowDownRotate} />
            </View>
          </Pressable>
          <View style={style.detailsCollapsed}>
            <View style={style.collapsedLine}>
              <Text style={style.collapsedLineLeft}>{t('Nb of draws')}</Text>
              <Text style={style.collapsedLineRight}>{item.nbConsecutiveDraws}</Text>
            </View>
            <View style={style.collapsedLine}>
              <Text style={style.collapsedLineLeft}>{t('stake')}</Text>
              <Text style={style.collapsedLineRight}>{formatNumber(item.price)} {currency}</Text>
            </View>
            {item.draw?.drawingDate && (
              <View style={style.collapsedLine}>
                <Text style={style.collapsedLineLeft}>{t('Draw')}</Text>
                <Text style={style.collapsedLineRight}>{basketDateFormat(item.draw?.drawingDate)}</Text>
              </View>
            )}
            <View style={style.collapsedLine}>
              <Text style={style.collapsedLineLeft}>{t('Max potential gain')}</Text>
              <Text style={style.collapsedLineRight}>{formatNumber(item.potentialAward)} {currency}</Text>
            </View>
            {useTaxes && 
              <View style={style.collapsedLine}>
                <Text style={style.collapsedLineLeft}>{t('Net potential gain')}</Text>
                <Text style={style.collapsedLineRight}>{formatNumber(computeNetAmount(item.potentialAward, taxList))} {currency}</Text>
              </View>
            }
          </View>
        </View>
      )}

    </View>
  );
};

export default BasketItem;

//====================================== STYLE
const makeStyles = (theme, isBetExpired) => StyleSheet.create({
  container: {
    flex: 1,
  },
  basketItem: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    width: '100%',
    borderRadius: 5,
    marginBottom: 10
  },
  stakePart: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: isBetExpired ? theme.colors.expiredBackground : theme.colors.primary
  },
  stakeText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: isBetExpired ? theme.colors.expiredColor : '#FFFFFF',
    fontFamily: 'Raleway-Bold',
    fontSize: 16
  },
  gridPart: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderBottomColor: '#F6F6F6',
    borderBottomWidth: 2
  },
  gridText:{
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
    color: theme.colors.greyText,
    marginBottom: 7
  },
  numbersLine: {
    flexDirection: 'row',
    marginBottom: 7,
    flexWrap: 'wrap'
  },
  ball: {
    width: 33,
    height: 33,
    backgroundColor: isBetExpired ? theme.colors.expiredBackground : theme.colors.primary,
    borderRadius: 30,
    marginRight: 5,
    marginBottom: 5,
    paddingBottom: 4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  ballB: {
    width: 33,
    height: 33,
    backgroundColor: isBetExpired ? theme.colors.expiredBackground : theme.colors.secondary,
    borderRadius: 30,
    marginRight: 5,
    marginBottom: 5,
    paddingBottom: 4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  number: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'Raleway-Bold'
  },
  detailsPart: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 7,
  },
  detailsPartCollapsable: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 7,
  },
  arrowDownContainer: {
    backgroundColor: '#F6F6F6',
    width: 24,
    height: 24,
    borderRadius: 20,
  },
  arrowDownRotate: {
    transform: [{ rotate: '180deg'}]
  },
  detailsText: {
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
    color: theme.colors.greyText,
  },
  detailsCollapsed: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingVertical: 7,
    paddingHorizontal: 20,
  },
  collapsedLine: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 7
  },
  collapsedLineLeft: {
    width: '50%',
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 14
  },
  collapsedLineRight: {
    width: '50%',
    fontFamily: 'Raleway-Medium',
    color: theme.colors.greyText,
    fontSize: 14
  }
})