import { View, Text, StyleSheet } from "react-native";
import { useTheme } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import React from "react";
import { formatNumber } from "../../utils/FormatUtils";
import { useTranslation } from 'react-i18next';

const UserSolde = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const balanceAmount = useSelector(state => state.user.balanceAmount);
  const currency = useSelector(state => state.user.currencyISO);

  return (
  <View style={style.soldePart}>
    <Text style={style.soldeTitle}>
      {t("Balance")}
    </Text>
    <Text style={style.solde}>
      {formatNumber(balanceAmount)} {currency}
    </Text>
  </View>
  );
};

export default UserSolde;

const makeStyles = (theme) => StyleSheet.create({
  soldePart: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    marginBottom: 30
  },
  soldeTitle: {
    fontFamily: 'Raleway-Bold',
    colors: theme.colors.greyText,
    fontSize: 15,
    marginVertical: 'auto'
  },
  solde: {
    fontFamily: 'Raleway-Bold',
    colors: theme.colors.greyText,
    fontSize: 20,
    textAlignVertical: "center"
  }
})
