import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderBackButton } from "@react-navigation/elements";
import screens from '../../common/constants/screen.constants';
import EBulletinListItem from './../../components/ebulletin/EBulletinListItem.comp';
import ItemPicker from '../../components/kpLibrary/ItemPicker.comp';

import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import StyledButton from '../../components/kpLibrary/forms/StyledButton.comp';


const MyEbulletinsPage = ({ route }) => {

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const styles = makeStyles(useTheme());
  const { t } = useTranslation();

  //====================================== Hooks

  const games = useSelector(state => state.games.games);
  const myEbulletinList = useSelector(state => state.ebulletin.myEbulletinList);

  const [filteredEbulletinList, setFilteredEbulletinList] = useState(myEbulletinList);
  const [displayedEbulletinList, setDisplayedEbulletinList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterGameIdList, setFilterGameIdList] = useState([]);
  const [lotteryGamesUsed, setLotteryGamesUsed] = useState([]);

  const ITEMS_PER_PAGE = 10;
  const flatListRef = useRef();

  const headerLeftBackTabHome = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => navigation.navigate(screens.MyBetsTab, { screen: screens.MyBetsHome })}
    />
  );

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      if (!route?.params?.keepData) {
        dispatch(EBulletinActions.loadMyEbulletins());
      }
      navigation.setOptions({ headerLeft: headerLeftBackTabHome });

      return () => {
        //Page leave
      }
    }, [route])
  );

  useEffect(() => {
    if (myEbulletinList.length && games.length) {
      const gameIdUsed = [...new Set(myEbulletinList.map(E => E.gameId))];
      const gamesUsed = games.filter(E => gameIdUsed.includes(E.gameId));
      setLotteryGamesUsed(gamesUsed);
    }
  }, [myEbulletinList, games]);

  useEffect(() => {
    setFilteredEbulletinList(filterTheList(myEbulletinList));
  }, [myEbulletinList, filterGameIdList]);

  useEffect(() => {
    scrollToTop();
    if (pageNumber != 1)
      setPageNumber(1);
    else
      setDisplayedEbulletinList(filteredEbulletinList.slice(0, ITEMS_PER_PAGE));
  }, [filteredEbulletinList]);

  useEffect(() => {
    setDisplayedEbulletinList(filteredEbulletinList.slice(0, pageNumber * ITEMS_PER_PAGE));
  }, [pageNumber]);

  //====================================== Functions

  const onScanPress = () => {
    navigation.navigate(screens.Scan, { screen: screens.ScanHome });
  }

  const onFilterGameApply = (strGameIdList) => {
    const gameIdList = strGameIdList.map(id => +id).filter(id => id >= 0); //remove the 'all' selection
    setFilterGameIdList(gameIdList);
  }

  const filterTheList = (list) => {
    //Note: must return a new array, in order to force re-render
    return filterGameIdList.length > 0 ? list.filter(E => filterGameIdList.includes(E.gameId)) : [...list];
  }

  const displayMoreData = () => {
    if (displayedEbulletinList.length < filteredEbulletinList.length) {
      setPageNumber(pageNumber + 1);
    }
  }

  const scrollToTop = () => {
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0 });
  }

  //====================================== Render

  const renderEmptyList = () => (
    <View style={styles.emptyList}>
      <Text style={styles.emptyListText}>
        {t('ebulletin.no_ebulletin')}
      </Text>
    </View>
  )

  return (
    <View style={styles.container}>

      {myEbulletinList?.length > 0 &&
        <ItemPicker
          customStyles={{ itemList: styles.itemPickerContainer }}
          data={lotteryGamesUsed}
          fieldId='gameId'
          fieldName='name'
          itemAllId={-1}
          itemAllName={t('all')}
          multiSelect={false}
          preselectedIdList={filterGameIdList}
          scrollDirection='horizontal'
          onApply={onFilterGameApply}
        />
      }

      <FlatList
        ref={flatListRef}
        data={displayedEbulletinList}
        renderItem={({ item }) => <EBulletinListItem ebulletin={item} />}
        keyExtractor={(item, index) => index}
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={renderEmptyList}
        onEndReachedThreshold={0.2}
        onEndReached={displayMoreData}
      />

      <View style={styles.scanView}>
        <StyledButton
          onPress={onScanPress}
          text={t('tab scan')}
        />
      </View>

    </View>
  )
}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  itemPickerContainer: {
    marginVertical: 5,
  },
  listContainer: {
    paddingBottom: 80,
    paddingHorizontal: 10,
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
  },
  emptyListText: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Medium',
    fontSize: 16,
  },
  // scan
  scanView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginHorizontal: 40, 
    marginVertical: 20,
  },
});

export default MyEbulletinsPage; 