export function getBetStatus(status) {
  if (!status) return null;
  switch (status.toLowerCase().replace(/_/g, "")) {
    case "gainexpired":
      return "Expired"
    case "lost":
      return "Lost";
    case "paid":
    case "win":
      return "Won";
    case "wait":
    case "WAIT":
    case "bettingallowed":
      return "Pending";
    case "ended":
    case "end":
    case "topay":
    case "defineresult":
      return "Finished";
    case "canceledGame":
    case "canceledticket":
    case "refundedgame":
    case "refundedgameexpired":
    case "refundedticket":
    case "refundedticketexpired":
      return "Canceled";
    default:
      return null;
  }
}

export function getStatusColor(ticket) {

  if (!ticket.status) return "#404040";

  let isCanceled = getCanceledStatus(ticket);

  if (isCanceled) return "#404040";
  
  switch (ticket.status.toLowerCase().replace(/_/g, "")) {
    case "ended":
      return "#8F8E95"
    case "lost":
      return "#EDEDED";
    case "paid":
    case "win":
    case "topay":
      return "#30C408";
    case "wait":
      return "#404040";
    case "canceledGame":
    case "cancelled":
    case "canceledticket":
    case "refundedgame":
    case "refundedgameexpired":
    case "refundedticket":
    case "refundedticketexpired":
    case "gainexpired":
      return "#404040";
    default:
      return '#404040';
  }
}

export function getDrawStatusColor(status) {
  let color = {
    background: '#404040',
    text: '#FFFFFF'
  }
  if (!status) return color;
  switch (status.toLowerCase().replace(/_/g, "")) {
    case "lost":
      color = {
        background: '#EDEDED',
        text: '#D30000'
      };
      break;
    case "paid":
    case "win":
      color = {
        background: '#30C408',
        text: '#FFFFFF'
      };
      break;
    case "wait":
    case "canceledGame":
    case "cancelled":
    case "canceledticket":
    case "refundedgame":
    case "refundedgameexpired":
    case "refundedticket":
    case "refundedticketexpired":
    case "gainexpired":
      color = {
        background: '#404040',
        text: '#FFFFFF'
      };
      break;
    default:
      color = {
        background: '#404040',
        text: '#FFFFFF'
      };
      break;
  }
  return color;
}

export function getBorderTicketColor(betDetails) {
  if (!betDetails) return '#404040';

  if (betDetails.gameType.toLowerCase() === 'instantlottery') {
    const listOfStatus = betDetails.listOfStatusAndDraws?.map(draw => draw.status);
    if (listOfStatus.includes('win')) {
      return '#30C408';
    } else if (!listOfStatus.includes('wait') && !listOfStatus.includes('win') && listOfStatus.includes('lost')) {
      return '#D30000';
    } else {
      return '#404040';
    }
  } 
  else {
    switch (betDetails?.status) {
      case "WIN":
      case "win":
        return '#30C408';
      case "LOST":
      case "lost":
        return '#D30000';
      default:
        return '#404040';
    }
  } 

}

export function getBorderTicketColorMultiple(status) {
  if (!status) {
    return '#404040';
  } else {
    switch (status) {
      case "WIN":
        return '#30C408';
      case "LOST":
        return '#D30000';
      default:
        return '#404040';
    }    
  }
}

function getCanceledStatus(ticket) {
  if (ticket.status.toLowerCase().replace(/_/g, "") === "topay") {
    if(ticket.listOfBets[0].gameType === 'mutualLottery') {
      return ticket.listOfBets.reduce((acc, bet) => {
        return acc && bet.draw.status?.toLowerCase().replace(/_/g, "") === 'canceledticket'
      }, true)
    } else if (ticket.listOfBets[0].gameType === 'fastPlay') {
        return ticket.listOfBets.reduce((acc, bet) => {
          return acc && bet.status?.toLowerCase().replace(/_/g, "") === 'canceledticket'
        }, true)
    } else if (ticket.listOfBets[0].gameType === 'BetSports') {
      return ticket.listOfBets.reduce((acc, bet) => {
        return acc && bet.status?.toLowerCase().replace(/_/g, "") === 'canceledticket'
      }, true)
    } else if (ticket.listOfBets[0].gameType === 'sportLottery') {
      return ticket.listOfBets.reduce((acc, bet) => {
        return acc && bet.status?.toLowerCase().replace(/_/g, "") === 'canceledticket'
      }, true)
    }
    else {
      return ticket.listOfBets.reduce((prev, bet) => {
        return prev && bet.listOfStatusAndDraws.reduce((acc, draw) => {
          return acc && draw.status?.toLowerCase().replace(/_/g, "") === 'canceledticket';
        }, [])
      }, true)
    }
  }
}

