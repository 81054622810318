import React, { useEffect, useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { Modal, StyleSheet, View, Text, Platform } from 'react-native';
import { GestureHandlerRootView, PanGestureHandler, TapGestureHandler } from 'react-native-gesture-handler';
import { commonStyles } from '../../common/style/common.style';


const SwipeDownModal = ({
  children,
  visible,
  onClose,
  //optional:
  gameName,
  title,
  contentStyle,
  titleTextStyle,
}) => {

  const styles = makeStyles(useTheme());
  const isWeb = Platform.OS === "web";

  const [isClosing, setIsClosing] = useState(false);
  const [modalContentLayout, setModalContentLayout] = useState(null);

  //====================================== Hooks

  useEffect(() => {
    isClosing && onClose();
    return () => setIsClosing(false);
  }, [isClosing]);


  //====================================== Functions

  // Modal functions
  const onGestureSwipeDown = e => {
    if (isClosing == false) {
      setIsClosing(true);
    }
  }
  const onGestureTapOutside = async e => {
    // tap outside the modal => close
    if (isClosing == false && e.nativeEvent.absoluteY < modalContentLayout?.y) {
      setIsClosing(true);
    }
  }

  //====================================== Render
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
    >
      <GestureHandlerRootView style={{ flex: 1 }} pointerEvents='box-none'>
        <TapGestureHandler onEnded={onGestureTapOutside}>
          <View style={isWeb ? commonStyles.webModalContainer : commonStyles.phoneModalContainer}>
            <PanGestureHandler activeOffsetY={30} onEnded={onGestureSwipeDown}>
              <View
                style={[styles.modalContent, contentStyle]}
                onLayout={e => setModalContentLayout(e?.nativeEvent?.layout)}
              >

                {/* Headers */}
                {gameName &&
                  <View style={styles.gameName}>
                    <Text style={styles.gameNameText}>
                      {gameName}
                    </Text>
                  </View>
                }
                {title &&
                  <View style={styles.title}>
                    <Text style={[styles.titleText, titleTextStyle]}>
                      {title}
                    </Text>
                  </View>
                }

                {/* Body */}
                {children}

              </View>
            </PanGestureHandler>
          </View>
        </TapGestureHandler>
      </GestureHandlerRootView>
    </Modal>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  modalContent: {
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
    left: 0,
    right: 0,
    paddingTop: 50,
    paddingBottom: 30,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    elevation: 5,
    boxShadow: '0px -3px 13px #00000029',
  },
  //--Headers
  title: {
    marginVertical: 15,
    marginHorizontal: 15,
  },
  titleText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 22,
    color: theme.colors.primary,
    textAlign: 'center'
  },
  gameName: {
    marginBottom: 15,
  },
  gameNameText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 18,
    color: theme.colors.greyText,
    textAlign: 'center'
  },
});

export default SwipeDownModal;