/**
 * Module adapted from https://github.com/werein/react-native-dates, with corrections and customizations
 */

import React, { useEffect, useState } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, Platform } from 'react-native';

import Moment from 'moment';
import i18n from 'i18next';
import { extendMoment } from 'moment-range';
import { useTheme } from '@react-navigation/native';
const moment = extendMoment(Moment);

const DateRangePickerComp = ({
  range,
  date,
  startDate,
  endDate,
  focusedInput,
  onDatesChange,
  isDateBlocked,
  onDisableClicked,
}) => {

  const styles = makeStyles(useTheme());

  const currentDate = moment();
  const [focusedMonth, setFocusedMonth] = useState(
      (startDate ?
        moment(startDate) :
        moment()
      ).startOf('month')
    );
  const localeUsed = i18n.language;

  //====================================== Functions

  useEffect(() => {
    moment.locale(localeUsed)
    setFocusedMonth(
      (startDate ?
        moment(startDate) :
        moment()
      ).startOf('month')
    )
  }, [localeUsed])

  const showPreviousMonth = () => {
    setFocusedMonth(focusedMonth.clone().add(-1, 'M'));
  }
  const showNextMonth = () => {
    setFocusedMonth(focusedMonth.clone().add(1, 'M'));
  }

  const dates = (startDate, endDate, focusedInput) => {
    if (focusedInput === 'startDate') {
      if (startDate && endDate) {
        return ({ startDate, endDate: null, focusedInput: 'endDate' });
      }
      return ({ startDate, endDate, focusedInput: 'endDate' });
    }
    if (focusedInput === 'endDate') {
      if (endDate && startDate && endDate.isBefore(startDate)) {
        return ({ startDate: endDate, endDate: null, focusedInput: 'endDate' });
      }
      return ({ startDate, endDate, focusedInput: 'startDate' });
    }
    return ({ startDate, endDate, focusedInput });
  };

  //====================================== Week
  const Week = ({ startOfWeek }) => {

    const days = [];
    const endOfWeek = startOfWeek.clone().endOf('isoweek');

    for (let day of moment.range(startOfWeek, endOfWeek).by('days')) {
      const onPress = () => {
        if (!isInMonth) {
          return;
        }
        if (isDateBlocked(day)) {
          onDisableClicked(day);
        } else if (range) {
          let isPeriodBlocked = false;
          const start = focusedInput === 'startDate' ? day : startDate;
          const end = focusedInput === 'endDate' ? day : endDate;
          if (start && end) {
            for (let dayPeriod of moment.range(start, end).by('days')) {
              if (isDateBlocked(dayPeriod)) isPeriodBlocked = true;
            };
          }
          onDatesChange(isPeriodBlocked ?
            dates(end, null, 'startDate') :
            dates(start, end, focusedInput));
        } else {
          onDatesChange({ date: day });
        }
      };

      const isDateSelected = () => {
        if (range) {
          if (startDate && endDate) {
            return day.isSameOrAfter(startDate) && day.isSameOrBefore(endDate);
          }
          return (startDate && day.isSame(startDate)) || (endDate && day.isSame(endDate));
        }
        return date && day.isSame(date);
      };

      const isInMonth = focusedMonth.month() == day.month();
      const isBlocked = isDateBlocked(day);
      const isSelected = isInMonth && isDateSelected();
      const isStartDate = day.isSame(startDate);
      const isEndDate = day.isSame(endDate);

      const style = [
        styles.day,
        isBlocked && styles.dayBlocked,
        isSelected && styles.daySelected,
        isStartDate && styles.daySelectedBoundaryLeft,
        isEndDate && styles.daySelectedBoundaryRight,
      ];

      const styleText = [
        styles.dayText,
        isBlocked && styles.dayDisabledText,
        isSelected && styles.daySelectedText,
        (isStartDate || isEndDate) && styles.daySelectedBoundaryText,
      ];

      days.push(
        <TouchableOpacity
          key={day.date()}
          style={style}
          onPress={onPress}
          disabled={isBlocked && !onDisableClicked}
        >
          {(isStartDate || isEndDate) &&
            <React.Fragment>
              <View style={[styles.daySelectedBoundaryHalf, styles.daySelectedBoundaryHalfLeft]} />
              <View style={[styles.daySelectedBoundaryHalf, styles.daySelectedBoundaryHalfRight]} />
            </React.Fragment>
          }
          <Text style={styleText}>
            {isInMonth && day.date()}
          </Text>
        </TouchableOpacity>
      );
    };

    return (
      <View style={styles.week}>{days}</View>
    );
  };

  //====================================== Month
  const Month = () => {

    const dayNames = [];
    const weeks = [];
    const startOfMonth = focusedMonth.clone().startOf('month').startOf('isoweek');
    const endOfMonth = focusedMonth.clone().endOf('month');
    const weekRange = moment.range(currentDate.clone().startOf('isoweek'), currentDate.clone().endOf('isoweek'));

    for (let day of weekRange.by('days')) {
      dayNames.push(
        <Text key={day.date()} style={styles.dayName}>
          {day.format('dd').substring(0, 1).toUpperCase()}
        </Text>
      );
    };

    for (let week of moment.range(startOfMonth, endOfMonth).by('weeks')) {
      weeks.push(
        <Week key={week} startOfWeek={week} />
      );
    };

    return (
      <View style={styles.month}>
        <View style={styles.week}>
          {dayNames}
        </View>
        {weeks}
      </View>
    );
  };

  //====================================== Main Render 


  return (
    <View style={styles.calendar}>
      <View style={styles.heading}>
        <View style={styles.headingTitle}>
          <Text style={[styles.headingText, styles.headingTextBold]}>
            {focusedMonth.format('MMMM')}
          </Text>
          <Text style={styles.headingText}>
            {focusedMonth.format('YYYY')}
          </Text>
        </View>
        <View style={styles.headingNav}>
          <TouchableOpacity style={styles.headingNavButton} onPress={showPreviousMonth}>
            <Text style={styles.headingText}>{' < '}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.headingNavButton} onPress={showNextMonth}>
            <Text style={styles.headingText}>{' > '}</Text>
          </TouchableOpacity>
        </View>
      </View>

      <Month />

    </View>
  );

}


//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  calendar: {
    backgroundColor: 'rgb(255, 255, 255)'
  },
  heading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    paddingRight: 0,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  headingNav: {
    flexDirection: 'row',
  },
  headingNavButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  headingText: {
    fontSize: 16,
    fontFamily: 'Raleway-Medium',
  },
  headingTextBold: {
    fontFamily: 'Raleway-Bold',
    textTransform: 'capitalize',
    paddingRight: 4,
  },
  headingTitle: {
    flexDirection: 'row',
  },
  week: {
    flexDirection: 'row'
  },
  dayName: {
    flexGrow: 1,
    flexBasis: 1,
    textAlign: 'center',
    fontSize: 12,
    marginBottom: 8,
  },
  day: {
    flexGrow: 1,
    flexBasis: 1,
    alignItems: 'center',
    padding: 10
  },
  dayBlocked: {
    backgroundColor: 'rgb(255, 255, 255)'
  },
  daySelected: {
    backgroundColor: '#D3DDFF'
  },
  daySelectedBoundaryLeft: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  daySelectedBoundaryRight: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  daySelectedBoundaryHalf: {
    width: Platform.OS == 'web' ? '50%' : '100%',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    bottom: 0,
    backgroundColor: theme.colors.primary,
  },
  daySelectedBoundaryHalfLeft: {
    left: 0,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,    
  },
  daySelectedBoundaryHalfRight: {
    right: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  dayText: {
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Raleway-SemiBold',
    fontSize: 17,
  },
  dayDisabledText: {
    color: 'gray',
    opacity: 0.5,
    fontFamily: 'Raleway-Light',
  },
  daySelectedText: {
    color: '#000'
  },
  daySelectedBoundaryText: {
    color: 'white',
  },
  month: {
    minHeight: 280, // the number of weeks differs according to the month
  }
});


export default DateRangePickerComp;