import { Pressable, Text, StyleSheet, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../utils/FormatUtils';
import screens from '../../common/constants/screen.constants';

const UserSticker = () => {

  const { t } = useTranslation();
  const isWeb = Platform.OS === "web";
  const styles = makeStyles(isWeb);
  const navigation = useNavigation();
  const userIsConnected = useSelector(state => state.user.isConnected);
  const balanceAmount = useSelector(state => state.user.balanceAmount);
  const currency = useSelector(state => state.user.currencyISO);

  const userConnectNavigation = () => {
    navigation.navigate(screens.UserConnect);
  }

  return (
    !userIsConnected ? 
    <Pressable style={styles.button} onPress={() => userConnectNavigation()}>
      <Text style={styles.textButton}>
        {t('Connexion')}
      </Text>
    </Pressable>
    :
    <View style={styles.balanceAmount}>
      <Text style={styles.textButton}>
        {formatNumber(balanceAmount)} {currency}
      </Text>
    </View>
  );
};

export default UserSticker;

const makeStyles = (isWeb) => StyleSheet.create({
  button: {
    marginRight: isWeb ? 16 : 0,
    backgroundColor: 'transparent',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#FFFFFF'
  },
  textButton: {
    fontFamily: 'Raleway-Medium',
    color: '#FFFFFF',
    fontSize: 15,
    marginVertical: 9,
    marginHorizontal: 15
  },
  balanceAmount: {
    marginRight: isWeb ? 16 : 0,
    backgroundColor: 'rgba(255, 255, 255, 0.17)',
    borderRadius: 5,
  }
})
