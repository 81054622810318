import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const SVGCopyFull = (props) => (

  <Svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98.46 126.59"
    {...props}
  >
    <Rect x={0.75} y={0.75} width={84.03} height={111.51} rx={8.24} ry={8.24} fill="#fff" />
    <Path
      className="cls-2"
      d="M76.54 1.5c4.13 0 7.49 3.36 7.49 7.49v95.03c0 4.13-3.36 7.49-7.49 7.49H8.99c-4.13 0-7.49-3.36-7.49-7.49V8.99c0-4.13 3.36-7.49 7.49-7.49h67.55m0-1.5H8.99C4.03 0 0 4.03 0 8.99v95.03c0 4.97 4.03 8.99 8.99 8.99h67.55c4.97 0 8.99-4.03 8.99-8.99V8.99C85.53 4.02 81.5 0 76.54 0z"
    />
    <Rect className="cls-2" x={12.94} y={13.58} width={85.53} height={113.01} rx={8.99} ry={8.99} />
  </Svg>
)

export default SVGCopyFull