export function generateFormTab() {
  return [
    {
      placeHolder: 'Name',
      keyboardType: 'default',
      secureTextEntry: false,
      value: 'name',
      required: false
    },
    {
      placeHolder: 'firstName',
      keyboardType: 'default',
      secureTextEntry: false,
      value: 'firstName',
      required: false
    },
    {
      placeHolder: 'Phone number',
      keyboardType: 'numeric',
      secureTextEntry: false,
      value: 'phoneNumber',
      required: true
    },
    {
      placeHolder: 'Password',
      keyboardType: 'default',
      secureTextEntry: true,
      value: 'password',
      required: true
    },
    {
      placeHolder: 'Password confirmation',
      keyboardType: 'default',
      secureTextEntry: true,
      value: 'passwordConfirmation',
      required: true
    }
  ]
}