import { useTheme } from "@react-navigation/native";
import { StyleSheet, View, Text } from "react-native";

const PhoneNumberPrefixInput = (
  {
    customStyle,
    value,
  }) => {

  //====================================== Hooks

  const theme = useTheme();
  const styles = { ...makeStyles(theme), ...customStyle };

  //====================================== Render
  return (
    <View style={styles.container}>
      <Text style={styles.textPrefix}>
        {value}
      </Text>
      <View style={styles.separator}></View>
    </View>
  )
}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textPrefix: {
    fontFamily: 'Raleway-Regular',
    fontSize: 15,
    marginLeft: 10,
    marginRight: 16,
    color: '#404040',
  },
  separator: {
    width: 2,
    height: '100%',
    marginRight: 8,
    backgroundColor: 'white'
  },
});


export default PhoneNumberPrefixInput;