import { View, Text, StyleSheet, Pressable, Platform, Dimensions, ScrollView } from "react-native";
import { useTheme, useNavigation, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React from "react";
import { SVGClose } from "../../assets/icons";
import screens from '../../common/constants/screen.constants';
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";

const UserWelcome = () => {

  const height = Dimensions.get('window').height;
  const isWeb = Platform.OS === "web";
  const theme = useTheme();
  const style = makeStyles(theme, height, isWeb);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <ScrollView style={style.container}>
      <View style={style.topContainer}>
        <Pressable onPress={() => navigation.navigate(screens.Games)}>
          <SVGClose fill={theme.colors.greyText} />
        </Pressable>
      </View>

      <View style={style.content}>

        <Text style={style.title}>{route.params?.title}</Text>

        <StyledButton
          onPress={() => navigation.navigate(screens.Games)}
          text={t('go_back_home')}
          buttonStyle={{ width: 280 }}
        />
      </View>
    </ScrollView>
  );
};

export default UserWelcome;

const makeStyles = (theme, height, isWeb) => StyleSheet.create({
  container: {
    padding: 15,
    height: height,
    backgroundColor: '#FFFFFF'
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  content: {
    height: height - 200,
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Raleway-SemiBold',
    color: theme.colors.greyText,
    fontSize: 22,
    marginTop: 200,
    marginBottom: 100
  },
})
