import React, { useState, useRef } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SVGBasket, SVGGameDice, SVGLotteryBall, SVGPerson, SVGQrCode } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { Dimensions, Animated } from 'react-native';
import MyBetsNavigation from '../../screens/my-bets/MyBets.navigation';
import MySpaceNavigation from '../../screens/my-space/MySpace.navigation';
import ScanNavigation from '../../screens/scan/Scan.navigation';
import GamesNavigation from '../../screens/games/Games.navigation';
import ResultsNavigation from '../../screens/results/Results.navigation';
import screens from '../../common/constants/screen.constants';
import { useSelector } from 'react-redux';
import { commonStyleSettings } from '../../common/style/common.style';

const Tab = createBottomTabNavigator();

const BottomNavigation = () => {

  const { t } = useTranslation();
  const theme = useSelector(state => state.wordpress.theme);
  const tabValue = useRef(new Animated.Value(0)).current;
  
  const getWidth = () => {
    let maxWidth =  commonStyleSettings.maxWidth;
    let width = Dimensions.get("window").width;
    if (width >= maxWidth) return maxWidth / 5;
    else return width / 5;
  }

  const getNewValueTab = (value) => {
    return Animated.spring(tabValue, {
        toValue: value == 0 ? 0 : getWidth() * value,
        useNativeDriver: true,
        friction: 8,
      }).start();
  }

  return (
    <React.Fragment>
      <Tab.Navigator
        screenOptions={({ route, navigation }) => ({
          tabBarActiveTintColor: theme.colors.primary,
          tabBarInactiveTintColor: theme.colors.greyText,
          tabBarLabelStyle: {
            fontFamily: 'Raleway-Bold',
            marginBottom: 0
          },
          tabBarShowLabel: true,
          tabBarStyle: {
            borderBottomWidth: 0,
            paddingBottom: 5,
            borderColor: "transparent",
            justifyContent: 'center',
            backgroundColor: '#ffffff'
          },
          headerShown: false, // header managed by each tab sub-navigator
          headerStyle: {
            backgroundColor: theme.colors.primary,
            fontFamily: 'Raleway-Regular',
          },
          headerTintColor: "#fff",
          headerTitleAlign: 'center',
        })}
        initialRouteName="Games"
      >
        <Tab.Screen
          name="Games"
          component={GamesNavigation}
          options={{
            tabBarLabel: t('games'),
            tabBarIcon: ({ color, focused }) => <SVGGameDice fill={color} />
          }}
          listeners={({ navigation, route }) => ({
            // Onpress Update....
            tabPress: e => {
              getNewValueTab(0)
            }
          })}
        />
        <Tab.Screen
          name="MySpace"
          component={MySpaceNavigation}
          options={{
            tabBarLabel: t('my space'),
            tabBarIcon: ({ color }) => <SVGPerson fill={color} />
          }}
          listeners={({ navigation, route }) => ({
            // Onpress Update....
            tabPress: e => {
              getNewValueTab(1)
            }
          })}
        />
        <Tab.Screen
          name={screens.MyBetsTab}
          component={MyBetsNavigation}
          options={{
            tabBarLabel: t('my bets'),
            tabBarIcon: ({ color }) => <SVGBasket fill={color} />
          }}
          listeners={({ navigation, route }) => ({
            // Onpress Update....
            tabPress: e => {
              getNewValueTab(2)
            }
          })}
        />
        <Tab.Screen
          name="Results"
          component={ResultsNavigation}
          options={{
            tabBarLabel: t('results'),
            tabBarIcon: ({ color }) => <SVGLotteryBall style={{ width: 23, height: 23 }} fill={color} />
          }}
          listeners={({ navigation, route }) => ({
            // Onpress Update....
            tabPress: e => {
              getNewValueTab(3)
            }
          })}
        />
        <Tab.Screen
          name="Scan"
          component={ScanNavigation}
          options={() => ({
            tabBarLabel: t('tab scan'),
            tabBarIcon: ({ color }) => <SVGQrCode fill={color} />
          })}
          listeners={({ navigation, route }) => ({
            // Onpress Update....
            tabPress: e => {
              getNewValueTab(4)
            }
          })}  
        />
      </Tab.Navigator>

      {/* View for the borderTopBar */}
      <Animated.View
        style={{
          width: getWidth(),
          height: 3,
          backgroundColor: theme.colors.primary,
          position: 'absolute',
          bottom: 48,
          transform: [
            { translateX: tabValue }
          ]
        }}
      />

    </React.Fragment>
  );
};

export default BottomNavigation;
