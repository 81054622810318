import { useFocusEffect, useTheme } from "@react-navigation/native";
import React, { useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import StyledSelectInput from "./StyledSelectInput.comp";
import { useEffect } from "react";
import { extractDate } from "../../../utils/DateUtils";
import moment from "moment";


const StyledDateInput = (
  {
    customStyle, //optional
    maxDate, //optional
    minDate, //optional
    onChange,
    value,
  }) => {

  //====================================== Hooks

  const isWeb = Platform.OS == "web";
  const theme = useTheme();
  const style = { ...makeStyles(theme, isWeb), ...customStyle };

  const [dayList, setDayList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);

  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    const days = [];
    for (let d = 1; d <= 31; ++d) days.push({ value: d, label: '' + (d < 10 ? '\u00A0' + d : d) });
    setDayList(days);

    const months = moment.months().map((label, i) => ({ value: i, label }));
    setMonthList(months);

    const minYear = minDate ? moment(minDate).year() : 2000;
    const maxYear = maxDate ? moment(maxDate).year() : moment().year() + 100;
    const years = [];
    for (let y = maxYear; y >= minYear; --y) years.push({ value: y, label: '' + y });
    setYearList(years);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      //Note: extract current value
      if (!value) return;
      const m = moment(value);
      setDay(m.date());
      setMonth(m.month());
      setYear(m.year());
    }, [value])
  );

  useEffect(() => {
    if (!day || !month || !year) return;
    const mDate = moment([year, month, day]);
    onChange(extractDate(mDate));
  }, [day, month, year]);

  //====================================== Functions

  const onDaySelect = (selectedItem) => {
    correctSelectedDate(year, month, selectedItem.value);
  };
  const onMonthSelect = (selectedItem) => {
    correctSelectedDate(year, selectedItem.value, day);
  };
  const onYearSelect = (selectedItem) => {
    correctSelectedDate(selectedItem.value, month, day);
  };

  /**
   * check the day exists for the given month/year, correct it otherwise
   * the date MUST ALWAYS be VALID
   */
  const correctSelectedDate = (y, m, d) => {
    // use the JS Date, which auto-corrects an invalid date : 2000-11-31 becomes 2000-12-01
    const correctedDate = new Date(y, m, d);
    setDay(correctedDate.getDate());
    setMonth(correctedDate.getMonth());
    setYear(correctedDate.getFullYear());
  }

  //====================================== Render
  return (
    <View style={style.container}>
      <View style={[style.inputContainer, style.dayInputContainer]}>
        <StyledSelectInput
          data={dayList}
          fieldKey={'value'}
          fieldLabel={'label'}
          onSelect={onDaySelect}
          value={day}
        />
      </View>
      <View style={[style.inputContainer, style.monthInputContainer]}>
        <StyledSelectInput
          data={monthList}
          fieldKey={'value'}
          fieldLabel={'label'}
          onSelect={onMonthSelect}
          value={month}
        />
      </View>
      <View style={[style.inputContainer, style.yearInputContainer]}>
        <StyledSelectInput
          data={yearList}
          fieldKey={'value'}
          fieldLabel={'label'}
          onSelect={onYearSelect}
          value={year}
        />
      </View>
    </View>
  )
}


//====================================== Styles

const makeStyles = (theme, isWeb) => StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  inputContainer: {
    height: 40,
    borderRadius: 5,
    // backgroundColor: '#F6F6F6',
    marginHorizontal: 4,
    marginBottom: 20,
    minWidth: 60,
    width: isWeb ? null : '25%',
  },
  dayInputContainer: {
  },
  monthInputContainer: {
    flex: 1,
  },
  yearInputContainer: {
  },
});


export default StyledDateInput;