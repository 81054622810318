export function computeMaxPotentialGain(item) {

  if (!item) return 0;

  let result = 0;
  const paylines = item.selectedBetType.listOfPayLines;
  const price = item.price;

  // Mutual game (except HUB)
  if (paylines[0].minAwardValue) {
    result = price * Math.max(...paylines.map(elem => elem.minAwardValue));
  }
  // Instant game
  else {
    result = price * Math.max(...paylines.map(elem => elem.gainValue));
  }

  return result;
}