import { Text, StyleSheet, useWindowDimensions, ScrollView, View } from "react-native";
import { useTheme, useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React from "react";
import i18n from 'i18next';

import HTMLView from '../../../components/kpLibrary/HTMLView';

const GameRules = (props) => {

  const style = makeStyles(useTheme());
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const game = props.route.params.game;
  const gameRules = useSelector(state => state.wordpress.gameSettings[game.gameId]?.gameRules[i18n.language]);

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      navigation.setOptions({ title: game.name })
      return () => {
        //Page leave
      }
    }, [])
  );


  return (
    <ScrollView>
      <View style={style.titleContainer}>
        <Text style={style.titleText}>
          {t('How to play')}
        </Text>
      </View>
      <View style={style.container}>
        {!gameRules && <Text style={style.title}>{t('No Game Rules')}.</Text>}
        {gameRules && 
          <ScrollView style={style.item}>
            <HTMLView
              contentWidth={width}
              html={gameRules}
            />
          </ScrollView>
        }
      </View>
    </ScrollView>
  );
};

export default GameRules;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  titleContainer: {
    width: '100%',
    borderBottomColor: theme.colors.greyText,
    borderBottomWidth: 1,
    justifyContent: 'center', 
    alignItems: 'center',
    height: 60
  },
  titleText: {
    fontFamily: 'Raleway-Medium',
    fontSize: 16,
    color: theme.colors.greyText,
  },
  title: {
    fontFamily: 'Raleway-MediumItalic',
    fontSize: 14,
    color: theme.colors.greyText,
    marginLeft: 25,
    marginTop: 25
  },
  item: {
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
    color: theme.colors.greyText,
    padding: 10,
  },
});