import * as React from "react"
import Svg, { Circle } from "react-native-svg"

const SVGKeyboard = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" {...props}>
    <Circle cx={11.94} cy={8.23} r={2.72} />
    <Circle cx={19.71} cy={8.23} r={2.72} />
    <Circle cx={27.47} cy={8.23} r={2.72} />
    <Circle cx={11.94} cy={16.08} r={2.72} />
    <Circle cx={19.71} cy={16.08} r={2.72} />
    <Circle cx={27.47} cy={16.08} r={2.72} />
    <Circle cx={11.94} cy={23.92} r={2.72} />
    <Circle cx={19.71} cy={23.92} r={2.72} />
    <Circle cx={19.71} cy={31.77} r={2.72} />
    <Circle cx={27.47} cy={23.92} r={2.72} />
  </Svg>
)

export default SVGKeyboard;