import React from "react";
import { useTheme } from '@react-navigation/native';
import { dateLocaleLong } from '../../utils/DateUtils';
import { useSelector } from "react-redux";
import { View, Text, StyleSheet, Image, Pressable } from 'react-native';
import images from "../../assets/images";

const DrawResultListItem = ({ item }) => {

  const styles = makeStyles(useTheme());
  const gameSettings = useSelector(state => state.wordpress.gameSettings);
  const sourceUrlLogo = gameSettings && gameSettings[item.gameId]?.gameLogoAvatar;


  const onSessionItemPress = (session) => {
    //TODO: the details page is not implemented for the moment, don't show it.
    // navigation.navigate(screens.DrawResultDetailsPage, { drawId: session.drawId });
    return;
  }

  return (
    <View style={styles.cardContainer}>
      <Pressable style={styles.card} onPress={() => onSessionItemPress(item)}>
        <View style={styles.cardSummary}>
          <Image
            style={styles.cardImage}
            // resizeMode='stretch'
            source={sourceUrlLogo ? {uri: sourceUrlLogo} : images.game_logo_default}
          /> 
          <View style={styles.cardText}>
            <Text style={styles.gameName}>{item.gameName}</Text>
            <Text style={styles.drawIdentifier}>
              {item.drawingName && (item.drawingName + ' - ') || ''}
              {(isNaN(item.drawId) && item.alternativeDrawingName) && item.alternativeDrawingName || ('n° ' + item.drawId)}
            </Text>
            <Text style={styles.drawDate}>{dateLocaleLong(item.drawingDate)}</Text>
          </View>
        </View>
        <View style={styles.cardResults}>
          {item.listOfGridDrawn?.map((grid, i) =>
            grid.winningNumbers?.sort().map((N, j) => (
              <Text key={j} style={[styles.cardNumber, i > 0 && styles.cardNumberAdditional]}>{N}</Text>
            )))
          }
        </View>
      </Pressable>
    </View>
  )
}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  cardContainer: {
    marginBottom: 5,
  },
  card: {
    borderRadius: 3,
    backgroundColor: "#ffffff",
    marginTop: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  cardImage: {
    height: 50,
    width: 55,
    borderRadius: 4,
  },
  cardSummary: {
    flexDirection: 'row',
    padding: 5,
    paddingLeft: 15,
    alignItems: 'center',
  },
  cardResults: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#bbb',
    margin: 8,
    paddingTop: 8,
    paddingLeft: 5,
  },
  cardNumber: {
    borderRadius: 20,
    width: 27,
    height: 27,
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.colors.primary,
    borderWidth: 1,
    borderColor: theme.colors.primary,
    color: '#fff',
    fontFamily: 'Raleway-SemiBold',
  },
  cardNumberAdditional: {
    backgroundColor: '#fff',
    color: theme.colors.primary,
  },

  cardText: {
    paddingHorizontal: 10,
    flex: 1,
  },
  gameName: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
  },
  drawIdentifier: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 12,
    paddingVertical: 2,
  },
  drawDate: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 12,
  },
})

export default DrawResultListItem;
