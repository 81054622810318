import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import ResultsPage from './Results.page';
import DrawResultDetailsPage from './DrawResultDetails.page';
import { useNavigation } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements';
import screens from '../../common/constants/screen.constants';
import { useTheme } from '@react-navigation/native';
import { getDefaultScreenOptions } from '../../common/theme/theme';

const Stack = createNativeStackNavigator();


const ResultsNavigation = () => {

  const { t } = useTranslation();
  const navigation = useNavigation();
  const theme = useTheme();

  const headerLeftKeepSearchResults = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => navigation.navigate('ResultsHome', { keepSearchResults: true })}
    />
  );

  return (
    <Stack.Navigator
      initialRouteName={screens.ResultsHome}
      screenOptions={getDefaultScreenOptions(theme)}
    >

      <Stack.Screen name={screens.ResultsHome} component={ResultsPage} options={{ title: t('results') }} />
      <Stack.Screen name={screens.DrawResultDetailsPage} component={DrawResultDetailsPage} options={{ title: t('results'), headerLeft: headerLeftKeepSearchResults }} />

    </Stack.Navigator>
  )
}

export default ResultsNavigation; 