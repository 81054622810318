import { useEffect } from "react";
import { useState } from "react";
import { Platform, StyleSheet } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import Icon from 'react-native-vector-icons/MaterialIcons';
import { mergeStyles } from "../../../common/style/common.style";

const StyledSelectInput = (
  {
    data,
    fieldKey,
    fieldLabel,
    onSelect,
    placeholder,
    value,
    //optional:
    customStyle,
    inputProps,
  }) => {

  //====================================== Hooks

  const isWeb = Platform.OS == "web";
  const style = mergeStyles(makeStyles(isWeb), customStyle);

  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    const selectedItem = data.find(E => E[fieldKey] == value);
    setSelectedLabel(selectedItem?.[fieldLabel]);
  }, [value]);

  //====================================== Functions

  const handleOnSelect = (selectedItem, index) => {
    onSelect(selectedItem, index);
  }

  //====================================== Render

  return (
    <SelectDropdown
      data={data}
      onSelect={handleOnSelect}
      defaultButtonText={selectedLabel || placeholder || ' '}
      buttonTextAfterSelection={(selectedItem, index) => selectedLabel || placeholder || ' '}
      rowTextForSelection={(item, index) => item?.[fieldLabel]}
      buttonStyle={[
        style.textInputContainer,
        (value || value === 0) && style.textInputContainerCompleted,
      ]}
      buttonTextStyle={[
        style.textInput,
        (value || value === 0) && style.textInputCompleted,
      ]}
      dropdownStyle={style.dropdown}
      rowStyle={style.dropdownRow}
      rowTextStyle={style.dropdownRowText}
      renderDropdownIcon={isOpened => <Icon name={'arrow-drop-down'} size={18} style={style.dropdownIcon} />}
      {...inputProps}
    />
  )
}


//====================================== Styles

const makeStyles = (isWeb) => StyleSheet.create({
  textInputContainer: {
    height: 40,
    borderRadius: 5,
    backgroundColor: '#F6F6F6',
    marginTop: 10,
    marginBottom: 20,
    width: '100%',
    minWidth: 50,
  },
  textInputContainerCompleted: {
    backgroundColor: '#D0E9FF',
  },
  textInput: {
    color: '#707070',
    fontFamily: 'Raleway-Regular',
    fontSize: 16,
    marginLeft: 4,
    textAlign: 'left',
    overflow: 'visible',
  },
  textInputCompleted: {
    color: '#000000',
    fontFamily: 'Raleway-Medium',
  },
  dropdown: {
    borderRadius: 5,
  },
  dropdownRow: {
    minHeight: 40,
  },
  dropdownRowText: {
    fontFamily: 'Raleway-Regular',
    fontSize: 16,
  },
  dropdownIcon: {
    //use absolute position to avoid reducing the text space
    position: isWeb ? null : 'absolute',
    paddingRight: isWeb ? null : 8,
    color: '#404040',
  },
});


export default StyledSelectInput;