import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as OddsActions from '../../../store/actions/odds.actions';
import * as TicketServices from '../../../store/services/ticket.service';
import { getBetStatus, getBorderTicketColor, getDrawStatusColor } from '../../../utils/StatusUtils';
import { drawDateFormat } from "../../../utils/DateUtils";
import { getSportLogo } from "../../../utils/SportsIconsUtils";

import CustomBet from "./CustomBet";
import { useTheme } from "@react-navigation/native";

export default function BetSportsSimple({betDetails, index, t}) {

  const dispatch = useDispatch();
  const eventInfosState = useSelector(state => state.odds.events);

  const [borderColor, setBorderColor] = useState('#404040');
  const [statusColor, setStatusColor] = useState({
    background: '#404040',
    text: '#FFFFFF'
  })
  const [betDetailsStatus, setBetDetailsStatus] = useState(false);
  const [eventId, setEventId] = useState(0)
  const [eventDate, setEventDate] = useState('')
  const [eventName, setEventName] = useState('');
  const [eventLeague, setEventLeague] = useState('');
  const [oddsPlayerInfos, setOddsPlayerInfos] = useState({
    type: '',
    name: ''
  })
  const [sportsId, setSportsId] = useState(0);
  const [isCustomBet, setIsCustomBet] = useState(false)
  const theme = useTheme();
  const style = makeStyles(borderColor, statusColor, theme);

  useEffect(() => {
    setBetDetailsStatus(getBetStatus(betDetails.status))
    setStatusColor(getDrawStatusColor(betDetails.status))
    setBorderColor(getBorderTicketColor(betDetails))
    setIsCustomBet(betDetails?.listOfCustomOdds.length > 0)
    if(betDetails.listOfOddsByType) {
      let oddsTypeInfos = betDetails.listOfOddsByType[0];
      setOddsPlayerInfos({
        type: TicketServices.isMarkerSpecifier(oddsTypeInfos?.typeId) ?
          `${t(oddsTypeInfos?.listOfOdds[0]?.marketSpecifier)} ${oddsTypeInfos?.name}` :
          oddsTypeInfos?.name,
        name: oddsTypeInfos?.listOfOdds[0]?.oddsOutcomeName
      })
    }
  }, [betDetails]);

  useEffect(() => {
    fetchEventsInfos(betDetails)
    getEventId(betDetails)
  }, [isCustomBet])

  useEffect(() => {
    if(eventInfosState) {
      let currentEvent = eventInfosState.filter(item => item.eventsId === eventId);
      if(currentEvent.length > 0) {
        setEventName(currentEvent[0].events[0].list[0]?.list[0]?.list[0]?.name)
        setEventLeague(`${currentEvent[0].events[0].list[0]?.name} - ${currentEvent[0].events[0].list[0]?.list[0]?.name}`)
        setEventDate(currentEvent[0].events[0].list[0]?.list[0]?.list[0]?.begin)
        setSportsId(currentEvent[0].events[0]?.id)
      }
    }
  }, [eventInfosState])

  const fetchEventsInfos = (item) => {
    if (!item.listOfOddsByType) return;
    if (isCustomBet) {
      const { listOfCustomOdds } = item;
      let eventId = listOfCustomOdds[0]?.eventId;
      if (eventId) dispatch(OddsActions.getEventsById(eventId));
    } else {
      const { listOfOddsByType } = item;
      let eventId = listOfOddsByType[0]?.listOfOdds[0].eventId;
      if (eventId) dispatch(OddsActions.getEventsById(eventId));      
    }
  }

  const getEventId = (item) => {
    if (!item.listOfOddsByType) return;
    if (isCustomBet) {
      const { listOfCustomOdds } = item;
      let eventId = listOfCustomOdds[0]?.eventId;
      setEventId(eventId)
    } else {
      const { listOfOddsByType } = item;
      let eventId = listOfOddsByType[0]?.listOfOdds[0].eventId;
      setEventId(eventId)   
    }
  }

  return (
    <View>
      {
        isCustomBet ? (
          <CustomBet
            eventName={eventName}
            eventLeague={eventLeague}
            eventDate={eventDate}
            sportsId={sportsId}
            odds={betDetails?.listOfCustomOdds[0]}
            betDetails={betDetails}
            t={t}
            isSimple={true}
          />
        ) : (
          <ScrollView style={style.container}>

            <View style={style.containerDateStatus}>
              <View style={style.betDate}>
                <Text style={style.betDateText}>{drawDateFormat(eventDate)}</Text>
              </View>   
              <View style={style.betStatus}>
                <Text style={style.betStatusText}>{t(betDetailsStatus)}</Text>
              </View>
            </View>

            <View style={style.containerBetInfos}>

              <Image
                style={style.image}
                source={getSportLogo(sportsId)}
              />

              <View style={style.infos}>
                <Text style={style.matchName}>
                  {eventName}
                </Text>
                <Text style={style.leagueName}>
                  {eventLeague}
                </Text>
                <View style={style.playerOddsView}>
                  <Text style={style.playerOddsType}>
                    {oddsPlayerInfos.type} : {' '}
                    <Text style={style.playerOddsName}>
                      {oddsPlayerInfos.name}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
            
              <View>
                <View style={style.separator}></View>
                <View style={style.betOddsSimplePart}>
                  <View style={style.image}></View>
                  <View style={style.simpleBetInfos}>
                    <Text style={style.simpleBetInfosText}>{t('Odd')}: {betDetails?.totalOdds}</Text>
                    <Text style={style.simpleBetInfosText}>{('Bet')}: {betDetails?.stake}</Text>
                  </View>
                </View>
              </View>

          </ScrollView>
        )
      }
    </View>
    
  );
}

//====================================== STYLE
const makeStyles = (borderColor, statusColor, theme) => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 24,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 4,
    borderLeftColor: borderColor
  },
  containerDateStatus: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  betStatus: {
    borderRadius: 2,
    backgroundColor: statusColor.background,
    paddingVertical: 2,
    paddingHorizontal: 7,
    justifyContent: "center"
  },
  betStatusText: {
    color: statusColor.text,
    fontFamily: 'Raleway-Regular',
    fontSize: 11
  },
  betDateText: {
    fontFamily: "Raleway-Regular",
    fontSize: 12,
  },
  containerBetInfos: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  infos: {
    flexDirection: 'column',
    marginRight: 30,
    width: '85%'
  }, 
  matchName: {
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 14, 
    paddingBottom: 7
  }, 
  leagueName: {
    fontFamily: 'Raleway-LightItalic',
    color: '#404040',
    fontSize: 12, 
    paddingBottom: 7
  },
  playerOddsView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  playerOddsViewCustom: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4
  },
  playerOddsType: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    marginRight: 10
  },
  playerOddsName: {
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#E2E1E1',
    marginVertical: 12
  },
  betOddsSimplePart:{
    flexDirection: 'row',
  }, 
  simpleBetInfos: {
    flexDirection: 'row'
  }, 
  simpleBetInfosText: {
    fontFamily: 'Raleway-Regular',
    fontSize: 12,
    color: '#404040',
    marginRight: 30
  }
});
