import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';

const API_URL = apiWebPlayerConfig.UrlService;

export const LoadTaxes = async () => {
  let params = {};
  return wp_axios.get(`${API_URL}/games/tax/description`, { params });
}

export const computeNetAmount = (grossAmount, taxList) => {
  //Note: if no tax found: netAmount = grossAmount
  //Note: the maxGain is optional
  //Note: in case of invalid amount (decimals), round to the nearest valid integer value. Ex: 100.5 => 101, 100.4 => 100.
  try {
    let taxLevel = taxList.find(E => E.minGain <= grossAmount && (!E.maxGain || grossAmount <= E.maxGain));
    let taxRate = taxLevel ? taxLevel.taxValue : 0;
    let netAmount = Math.round(grossAmount * (1 - taxRate));
    return netAmount;
  }
  catch (err) {
    return grossAmount;
  }
}