import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

import basket from './reducers/basket.reducer';
import betCreator from './reducers/betCreator.reducer';
import ebulletin from './reducers/ebulletin.reducer';
import games from './reducers/games.reducer';
import login from './reducers/login.reducer';
import ticket from './reducers/ticket.reducer';
import odds from './reducers/odds.reducer';
import wordpress from './reducers/wordpressApi.reducer';
import user from './reducers/user.reducer';
import taxes from './reducers/taxes.reducer';

const rootReducer = {
  basket,
  betCreator,
  ebulletin,
  games,
  login,
  ticket,
  odds,
  wordpress,
  user,
  taxes
}

const store = createStore(combineReducers(rootReducer), applyMiddleware(thunk));

export default store;