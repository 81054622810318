import { useNavigation, useTheme } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';

import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import { dateLocaleLong, limitDateEbulletin } from '../../utils/DateUtils';
import { formatNumber } from '../../utils/FormatUtils';
import { useState } from 'react';
import { useEffect } from 'react';
import screens from '../../common/constants/screen.constants';
import { commonStyles } from '../../common/style/common.style';
import images from '../../assets/images';


const EBulletinListItem = ({ ebulletin, index }) => {

  const dispatch = useDispatch();
  const styles = makeStyles(useTheme());
  const navigation = useNavigation();
  const { t } = useTranslation();

  //====================================== Hooks


  const currency = useSelector(state => state.login.currency);
  const myEbulletinList = useSelector(state => state.ebulletin.myEbulletinList);
  const gameSettings = useSelector(state => state.wordpress.gameSettings);
  const sourceUrlLogo = gameSettings && gameSettings[ebulletin.gameId]?.gameLogoAvatar;
  const games = useSelector(state => state.games.games);

  const [totalStake, computeTotalStake] = useState(0);
  const [currentGameName, setCurrentGameName] = useState('');

  useEffect(() => {
    computeTotalStake(
      ebulletin?.itemList?.reduce((acc, curr) => {
        return acc + curr?.price;
      }, 0)
    )
    setCurrentGameName(
      games?.filter(game => game?.gameId === ebulletin?.gameId)[0]?.name
    )
  }, [ebulletin, games])

  //====================================== Functions

  const onItemDeletePress = () => {
    const list = myEbulletinList.filter(item => item.api?.bulletinCode !== ebulletin.api?.bulletinCode);
    dispatch(EBulletinActions.set_my_ebulletin_list(list));
  }

  const onItemPress = () => {
    if (ebulletin?.api?.bulletinCode) {
      dispatch(EBulletinActions.set_current_bulletin(ebulletin));
      navigation.navigate(screens.EBulletinPage);
    }
  }

  //====================================== Render
  return (
    <View style={styles.cardContainer}>
      <Pressable
        style={({ pressed }) => {
          return [styles.card, !pressed && commonStyles.shadowedButton];                                                                  
        }}
        onPress={onItemPress}
      >
        <View style={styles.cardSummary}>
          <Image
            style={styles.cardImage}
            // resizeMode='stretch'
            source={sourceUrlLogo ? { uri: sourceUrlLogo } : images.game_logo_default}
          />
          <View style={styles.cardText}>
            <Text style={styles.gameName}>{currentGameName}</Text>
            <Text style={styles.drawDate}>{dateLocaleLong(ebulletin.firstDrawDate)}</Text>
          </View>
          <View style={styles.cardArrow}>
            <Icon name={'arrow-forward-ios'} size={14} />
          </View>
        </View>
        <View style={styles.cardSeparator} />
        <View style={styles.totalBet}>
          <Text style={styles.totalBetLabel}>{t('Total bet')} :</Text>
          <Text style={styles.totalBetValue}>{formatNumber(totalStake)} {currency}</Text>
        </View>
        <View style={styles.totalBet}>
          <Text style={styles.totalBetLabel}>{t('Deadline')} :</Text>
          <Text style={styles.totalBetValue}>{limitDateEbulletin(ebulletin.dateLimit)}</Text>
        </View>
        <View style={styles.cardActions}>
          <Pressable style={[styles.cardActionButton, styles.cardActionOutlineButton]} onPress={() => onItemDeletePress()}>
            <Text style={styles.cardActionOutlineText}>{t('remove')}</Text>
          </Pressable>
          <Pressable style={[styles.cardActionButton, styles.cardActionButtonPlain]} onPress={onItemPress}>
            <Text style={styles.cardActionPlainText}>{t('see')}</Text>
          </Pressable>
        </View>
      </Pressable>
    </View>
  )

}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  cardContainer: {
    marginBottom: 5,
  },
  card: {
    borderRadius: 6,
    backgroundColor: "#ffffff",
    marginTop: 10,
  },
  cardImage: {
    height: 35,
    width: 55,
    borderRadius: 4,
  },
  cardSummary: {
    flexDirection: 'row',
    padding: 8,
    paddingLeft: 15,
    alignItems: 'center',
  },
  cardActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 5,
    paddingTop: 8,
    paddingLeft: 5,
  },
  cardActionButton: {
    elevation: 6,
    backgroundColor: theme.colors.primary,
    paddingHorizontal: 10,
    borderRadius: 4,
    margin: 5,
    minWidth: 65,
    paddingVertical: 5,
    alignItems: 'center',
  },
  cardActionOutlineButton: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: theme.colors.primary,
  },
  cardActionOutlineText: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
  },
  cardActionPlainText: {
    color: '#fff',
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
  },
  cardSeparator: {
    marginHorizontal: 10,
    height: 0,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  cardText: {
    paddingHorizontal: 10,
    flex: 1,
  },
  gameName: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    marginBottom: 3,
  },
  drawDate: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 12,
  },
  totalBet: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    marginTop: 8,
    paddingHorizontal: 5,
  },
  totalBetLabel: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Medium',
    fontSize: 12,
  },
  totalBetValue: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 12,
  }
});

export default EBulletinListItem; 