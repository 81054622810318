import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';
import { computeNetAmount } from './taxes.service';

const current_ebulletin_builder_version = 3;

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;

export const getBulletin = async (code) => {
  let params = {};
  return wp_axios.patch(`${API_URL}/players/bulletins/${code}/load`, null, { params });
}

export const apiCreateBulletin = async (bulletin, opts = { phonePrefix }) => {
  let params = {};
  let body = {
    phoneNumber: opts.phonePrefix + bulletin.playerReference,
    content: buildApiContentString(bulletin),
  };
  return wp_axios.post(`${API_URL}/players/bulletins`, body, { params });
}


//=============================================================================
// HELPERS
//=============================================================================

const Regex_Ebulletin_Code = /^[0-9]{3}[A-F][0-9]{3}$/;
const STORAGE_MY_EBULLETIN_LIST = 'ebulletin.myEbulletinsList';

export const LoadMyEbulletinsFromStorage = async () => {
  const strData = await AsyncStorage.getItem(STORAGE_MY_EBULLETIN_LIST);
  return strData ? JSON.parse(strData) : null;
}

export const saveMyEbulletinsToStorage = (list) => {
  const strData = JSON.stringify(list);
  return AsyncStorage.setItem(STORAGE_MY_EBULLETIN_LIST, strData);
}

export const isEbulletinFormat = (code) => {
  return code.match(Regex_Ebulletin_Code);
}

export const getEarliestDrawingDate = (ebulletin) => {
  return ebulletin.itemList.reduce((acc, cur) => {
    return !acc || cur.draw?.drawingDate < acc ? cur.draw?.drawingDate : acc;
  });
}

export const getLimitDate = (list) => {
  return list.reduce((res, cur) => {
    return cur.draw?.closingDate > res || !res ? cur.draw.closingDate : res
  }, '');
}

export const getFirstDrawDate = (list) => {
  return list.reduce((res, cur) => {
    return cur.draw?.drawingDate < res || !res ? cur.draw.drawingDate : res
  }, '');
}

export const parsedBulletin = (apiBulletin) => {
  const ebulletinParsed = apiBulletin.content && JSON.parse(apiBulletin.content);

  let dateLimit = getLimitDate(ebulletinParsed?.body);
  let firstDrawDate = getFirstDrawDate(ebulletinParsed?.body);

  let currentBulletin = {
    api: apiBulletin,
    dateLimit,
    firstDrawDate,
    itemList: ebulletinParsed?.body,
    ...ebulletinParsed?.header
  }
  return currentBulletin;
}

export const convertBasketToBulletin = (basket, opts = { playerReference }, taxList) => {
  let bulletinItemList = basket.itemList.map(basketItem => ({
    betTypeId: basketItem.betTypeId,
    drawId: basketItem.drawId,
    draw: basketItem.draw,
    potentialAward: basketItem.potentialAward,
    potentialAwardNet: computeNetAmount(basketItem.potentialAward, taxList),
    nbConsecutiveDraws: basketItem.nbConsecutiveDraws,
    price: basketItem.price,
    selectionListByGrid: basketItem.selectionListByGrid || [],
    stake: basketItem.stake,
  }));

  //dateLimit: the bet closing date of the furthest draw
  let dateLimit = getLimitDate(basket.itemList);
  let firstDrawDate = getFirstDrawDate(basket.itemList);

  let bulletin = {
    api: {},
    gameId: basket.gameId,
    gameType: basket.gameType,
    itemList: bulletinItemList,
    playerReference: opts.playerReference,
    totalStake: basket.totalStake,
    dateLimit,
    firstDrawDate,
  };
  return bulletin;
}

/**
 * Build the stringified json content stored on the server, using the EBulletin protocol format (see doc) 
 * @param {*} bulletin local bulletin object
 */

export const buildApiContentString = (bulletin) => {
  let json = {
    header: {
      version: current_ebulletin_builder_version,
      playerReference: bulletin.playerReference,
      gameType: bulletin.gameType,
      gameId: bulletin.gameId,
      totalStake: bulletin.totalStake,
    },
    body: bulletin.itemList.map(item => ({
      betTypeId: item.betTypeId,
      drawId: item.drawId,
      draw: item.draw,    
      nbConsecutiveDraws: item.nbConsecutiveDraws,
      potentialAward: item.potentialAward,
      price: item.price,
      stake: item.stake,
      selectionListByGrid: item.selectionListByGrid,
    })),
  };
  return JSON.stringify(json);
}