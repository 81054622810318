import { useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, ScrollView, View, Text, StyleSheet } from 'react-native';
import images from '../../assets/images';

const ScanHelpPage = () => {

  const { t } = useTranslation();
  const styles = makeStyles(useTheme());

  const [section, setSection] = useState('ticket'); /*'ticket' or 'bulletin'*/


  const TicketSection = () => (
    <View style={styles.ticketSection}>
      <Text style={styles.messageText}>{t('scan.help_ticket_title')}</Text>
      <View style={styles.ticketImageContainer}>
        <Image style={styles.ticketImage} source={images.scan_help_ticket_illustration} />
      </View>
    </View>
  )

  const BulletinSection = () => (
    <View style={styles.bulletinSection}>
      <Text style={styles.messageText}>{t('scan.help_bulletin_title')}</Text>
      <BulletinStep i='1' />
      <BulletinStep i='2' />
      <BulletinStep i='3' />
      <BulletinStep i='4' />
    </View>
  )

  const BulletinStep = ({ i }) => (
    <View style={styles.stepRow}>
      <View style={styles.stepBoxNumber}>
        <Text style={styles.stepBoxNumberText}>
          {i}
        </Text>
      </View>
      <View style={styles.stepBoxDescr}>
        <Text style={styles.stepBoxDescrText}>
          {t('scan.help_bulletin_' + i)}
        </Text>
      </View>
    </View>
  )

  const getButtonSectionStyles = (_section) => {
    if (_section == section)
      return [styles.buttonSection, styles.buttonSectionSelected];
    else
      return [styles.buttonSection];
  }
  const getButtonSectionTextStyles = (_section) => {
    if (_section == section)
      return [styles.buttonSectionText, styles.buttonSectionTextSelected];
    else
      return [styles.buttonSectionText];
  }


  return (
    <View style={styles.container}>

      <View style={styles.buttonRow}>
        <View style={styles.buttonBox}>
          <Pressable style={getButtonSectionStyles('ticket')} onPress={() => setSection('ticket')}>
            <Text style={getButtonSectionTextStyles('ticket')}>
              Ticket
            </Text>
          </Pressable>
        </View>
        <View style={styles.buttonBox}>
          <Pressable style={getButtonSectionStyles('bulletin')} onPress={() => setSection('bulletin')}>
            <Text style={getButtonSectionTextStyles('bulletin')}>
              {t('ebulletin.tag')}
            </Text>
          </Pressable>
        </View>
      </View>
      <ScrollView>
        {
          (section == 'bulletin') ? <BulletinSection /> : <TicketSection />
        }
      </ScrollView>
    </View>
  )

}

//====================================== STYLE

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },  
  buttonRow: {
    marginTop: 10,
    marginBottom: 30,
    flexDirection: 'row',
    marginHorizontal: 20,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.colors.primary,
    width: '90%',
    maxWidth: 400,
    backgroundColor: 'white',
  },
  buttonBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
  },
  buttonSection: {
    paddingVertical: 10,
    borderRadius: 4,
    width: '100%',
  },
  buttonSectionSelected: {
    backgroundColor: theme.colors.primary,
  },
  buttonSectionText: {
    color: theme.colors.primary,
    fontWeight: '600',
    fontSize: 12,
    textAlign: 'center',
  },
  buttonSectionTextSelected: {
    color: 'white',
  },
  stepRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  stepBoxNumber: {
    backgroundColor: theme.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 20,
    marginVertical: 5,
    marginRight: 15,
  },
  stepBoxNumberText: {
    fontWeight: 'bold',
    color: 'white',
  },
  stepBoxDescr: {
    flex: 1,
  },
  stepBoxDescrText: {
    color: '#227FCE',
    fontSize: 13,
  },
  ticketImageContainer: {
    margin: 4,
    backgroundColor: '#fff',
    elevation: 4,
  },
  ticketImage: {
    resizeMode: 'contain',        
    height: 520,
    width: 220,
    
  },
  ticketSection: {
    alignItems: 'center',
    marginBottom: 20,
  },
  messageText: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Raleway-Medium',
    color: '#404040',
    paddingBottom: 20,
    paddingHorizontal: 20,
  }
})

//======================================
export default ScanHelpPage;