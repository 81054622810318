import sport_icon_football from '../assets/sportsIcons/sport_icon_football.png';
import sport_icon_default from '../assets/sportsIcons/sport_icon_default.png';
import sport_icon_basketball from '../assets/sportsIcons/sport_icon_basketball.png';
import sport_icon_hockey from '../assets/sportsIcons/sport_icon_hockey.png';
import sport_icon_tennis from '../assets/sportsIcons/sport_icon_tennis.png';
import sport_icon_handball from '../assets/sportsIcons/sport_icon_handball.png';
import sport_icon_rugby from '../assets/sportsIcons/sport_icon_rugby.png';
import sport_icon_pingpong from '../assets/sportsIcons/sport_icon_pingpong.png';
import sport_icon_cricket from '../assets/sportsIcons/sport_icon_cricket.png';
import sport_icon_volleyball from '../assets/sportsIcons/sport_icon_volleyball.png';
import sport_icon_waterpolo from '../assets/sportsIcons/sport_icon_waterpolo.png';

export function getSportLogo(sportId) {
  switch (sportId) {
    case 1: return sport_icon_football;
    case 2: return sport_icon_basketball;
    case 4: return sport_icon_hockey;
    case 5: return sport_icon_tennis;
    case 6: return sport_icon_handball;
    case 12: return sport_icon_rugby;
    case 20: return sport_icon_pingpong;
    case 21: return sport_icon_cricket;
    case 23: return sport_icon_volleyball;
    case 26: return sport_icon_waterpolo;
    default:
      return sport_icon_default;
  }
}