import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const style = StyleSheet.create({
  container: {
      padding: 20,
  },
})

const UserFavorites = () => {

  return (
    <View style={style.container}>
      <Text>User Favorites nested page</Text>
    </View>
  )

}

export default UserFavorites; 