import React from 'react';
import { SafeAreaView, StatusBar, useColorScheme } from 'react-native';

import BottomNavigation from './navigation/BottomNavigation';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import FullScreenNavigationGroups from './navigation/FullScreenNavigationGroups';
import { useTheme } from '@react-navigation/native';
import { getDefaultScreenOptions } from '../common/theme/theme';

const Stack = createNativeStackNavigator();


function Main() {

  const theme = useTheme()
  const isDarkMode = useColorScheme() === 'dark';

  const backgroundStyle = {
    backgroundColor: '#F6F6F6', //TODO:EXPO
    flex: 1
  };

  return (
    <SafeAreaView style={backgroundStyle}>
      <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />

      <Stack.Navigator
        screenOptions={getDefaultScreenOptions(theme)}
      >
        {/* TabBar and its nested screens */}
        <Stack.Screen name="BottomNavigation" component={BottomNavigation} options={{ headerShown: false }} />

        {/* Screens without tabBar displayed (fullscreen) */}
        {FullScreenNavigationGroups(Stack)}
      </Stack.Navigator>
      
    </SafeAreaView >
  );
}

export default Main;
