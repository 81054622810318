import { View, Text, StyleSheet, Pressable, Animated, Easing } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTheme, useNavigation } from '@react-navigation/native';
import React, { useRef } from "react";
import Icon from 'react-native-vector-icons/MaterialIcons';
import { SVGCopyFull, SVGDelete, SVGShare } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import screens from "../../common/constants/screen.constants";
import { useEffect } from "react";

const FAButton = ({ebulletin, open, setOpen}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const styles = makeStyles(theme);
  const animatedControllerColor = useRef(new Animated.Value(0)).current;
  const animatedController = useRef(new Animated.Value(0)).current;
  const myEbulletinList = useSelector(state => state.ebulletin.myEbulletinList);
  const { t } = useTranslation();

  const backgrndColor = animatedControllerColor.interpolate({
    inputRange: [0, 1],
    outputRange: [theme.colors.primary, '#FFFFFF'],
  });

  const minWidthBtn = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [56, 200],
  });

  const borderRadiusBtn = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [56, 4],
  });

  const heightBtn = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [56, 150],
  });

  const bottomBtn = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [40, 180],
  });

  useEffect(() => {
    if (!open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
      Animated.timing(animatedControllerColor, {
        duration: 450,
        toValue: 0,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
      Animated.timing(animatedControllerColor, {
        duration: 150,
        toValue: 1,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }
  }, [open])

  const onDeletePress = () => {
    const list = myEbulletinList.filter(E => E.api.bulletinCode != ebulletin.api.bulletinCode);
    dispatch(EBulletinActions.set_my_ebulletin_list(list));
    navigation.navigate(screens.MyBetsTab, { screen: screens.MyEBulletins })
  }

  const onSharePress = () => {
  }

  const onNewBetPress = () => {
  }

  return (
    <View
      style={styles.actionMenuView}
    >
      <Animated.View
        style={[styles.actionMenuButton, { backgroundColor: backgrndColor, minWidth: minWidthBtn, borderRadius: borderRadiusBtn, height: heightBtn, bottom: bottomBtn}]}
      >
        <Pressable onPress={() => setOpen(true)}>
        {
        !open ? 
          <Icon name={'menu'} size={30} color='white' /> :
          <View>
            <Pressable style={styles.itemRow} onPress={() => onSharePress()} >
              <SVGShare fill={theme.colors.primary} />
              <Text style={styles.itemText}>{t('share')}</Text>
            </Pressable>
            <Pressable style={styles.itemRow} onPress={() => onNewBetPress()}>
              <SVGCopyFull fill={theme.colors.primary} width={22} height={22} />
              <Text style={styles.itemText}>{t('ebulletin.fill_basket')}</Text>
            </Pressable>
            <Pressable style={styles.itemRow} onPress={() => onDeletePress()}>
              <SVGDelete fill={theme.colors.primary} />
              <Text style={styles.itemText}>{t('remove')}</Text>
            </Pressable>
          </View>
        }
        </Pressable>
      </Animated.View>
    </View>
  );
};

export default FAButton;
//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  //-- Action menu
  actionMenuButton: {
    backgroundColor: theme.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    width: 56,
    height: 56,
  },
  actionMenuView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 20,
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  itemText: {
    paddingLeft: 8,
    fontSize: 13,
    fontFamily: 'Raleway-SemiBold',
  },
})