import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@react-navigation/native';
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native';
import { SVGArrowDown } from '../../assets/icons';
import Paytable from './Paytable.comp';

const AccordionListItem = ({ title, index, currentTableOpen, setCurrentTableOpen, item }) => {

  const theme = useTheme();
  const styles = makeStyles(theme);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  useEffect(() => {
    if (currentTableOpen !== index) {
      // Close tab
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    } else {
      // Open tab
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: false,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start();
    }
  }, [currentTableOpen]);

  return (
    <View>
      <Pressable onPress={() => setCurrentTableOpen(currentTableOpen == index ? null : index)}>
        <View style={[currentTableOpen === index ? styles.titleContainerOpen : styles.titleContainer]}>
          <Text style={styles.textNameItem}>{title}</Text>
          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
            <SVGArrowDown fill={theme.colors.greyText} />    
          </Animated.View>
        </View>
      </Pressable>
          <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>        
            <View
              style={styles.bodyContainer}
              onLayout={event =>
                setBodySectionHeight(event.nativeEvent.layout.height)
              }
            >
              {currentTableOpen === index && <Paytable game={item}/>}
            </View>
          </Animated.View>
        
    </View>
  );
};
export default AccordionListItem;

const makeStyles = (theme) => StyleSheet.create({
  bodyBackground: {
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    marginHorizontal: 10,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  titleContainer: {
    display: 'flex',
    padding: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    marginTop: 5,
    marginHorizontal: 10,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleContainerOpen: {
    display: 'flex',
    padding: 15,
    backgroundColor: '#FFFFFF',
    marginTop: 5,
    marginHorizontal: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  bodyContainer: {
    padding: 15,
    paddingLeft: 25,
    position: 'absolute',
    bottom: 0,
  },
  textNameItem: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    colors: theme.colors.greyText
  }
});