import { View, Text, StyleSheet, TouchableWithoutFeedback, FlatList, Image, ScrollView, BackHandler } from "react-native";
import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from 'react-native-qrcode-svg';
import { HeaderBackButton } from "@react-navigation/elements";

import screens from "../../common/constants/screen.constants";
import EBulletinBetItem from "../../components/ebulletin/EBulletinBetItem.comp";
import { buildBulletinFromScan } from "../../store/actions/ebulletin.actions";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/FormatUtils";
import images from "../../assets/images";
import AlertPopup from "../../components/kpLibrary/AlertPopup.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import FAButton from "../../components/ebulletin/FAButton.comp";

/** 
 * ###########################################################################
 * 
 * 
 * EN CONSTRUCTION
 * 
 * 
 * ###########################################################################
 */
export default function EBulletinPage({ route }) {

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();
  //====================================== Hooks

  const currency = useSelector(state => state.login.currency);
  const currentBulletin = useSelector(state => state.ebulletin.currentBulletin);
  const lottery_games = useSelector(state => state.games.lottery_games);

  const gameSettings = useSelector(state => state.wordpress.gameSettings);
  const gameLogoURI = gameSettings && gameSettings[currentBulletin?.gameId]?.gameLogoAvatar;

  const [game, setGame] = useState(undefined);
  const [openFab, setOpenFab] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(true);

  const headerLeftGoList = (props) => (
    <HeaderBackButton
      {...props}
      onPress={backAction}
    />
  );

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backAction);

    return () =>
      BackHandler.removeEventListener('hardwareBackPress', backAction);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      navigation.setOptions({ headerLeft: headerLeftGoList });
    }, [])
  );

  useEffect(() => {
    if (route.params?.fromScan && currentBulletin) {
      dispatch(buildBulletinFromScan(currentBulletin))
    }
  }, []);

  useEffect(() => {
    setGame(lottery_games.find(E => E.gameId == currentBulletin?.gameId))
  }, [currentBulletin]);

  //====================================== Functions

  const onFindPOSPress = () => {
    //TODO: find point of saler
  }

  const backAction = () => {
    navigation.navigate(screens.MyBetsTab, { screen: screens.MyEBulletins, keepData: true });
    return true;
  };

  const onClose = () => {
    setOpenFab(false)
  }

  //====================================== Render

  const renderListHeader = () => (
    <View style={styles.listHeader}>
      <Text style={styles.listHeaderText}>{t('my bets')}</Text>
    </View>
  );

  return (
    <ScrollView style={styles.container}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View>
          {/* Main section */}
          <View style={styles.mainSection}>
            <View style={styles.gameRow}>
              <Image
                style={styles.gameLogo}
                source={gameLogoURI ? { uri: gameLogoURI } : images.game_logo_default}
              />
              <Text style={styles.gameName}>{game?.name}</Text>
            </View>
            <View style={styles.gameRowSeparator} />
          </View>

          <View style={styles.totalBetRow}>
            <Text style={styles.totalBetText}>{t('Total bet')} :</Text>
            <Text style={styles.totalBetText}>{formatNumber(currentBulletin?.totalStake)} {currency}</Text>
          </View>

          <View style={styles.qrCodeView}>
            <View style={styles.qrCodeImage}>
              <QRCode
                value={currentBulletin?.api?.bulletinCode}
                backgroundColor={theme.colors.background}
                size={130}
              />
            </View>
            <View style={styles.qrCodeTextView}>
              <Text style={styles.qrCodeText}> {t('code')} :</Text>
              <Text style={styles.qrCodeValue}>{currentBulletin?.api?.bulletinCode || currentBulletin?.bulletinCode}</Text>
            </View>

            <StyledButton
              onPress={onFindPOSPress}
              text={t('ebulletin.find_point_of_sale')}
              disabled={true}
            />

          </View>

          {/* Bet List */}
          <View>
            <FlatList
              data={currentBulletin?.itemList}
              renderItem={({ item, index }) => <EBulletinBetItem ebulletin={currentBulletin} bet={item} index={index} />}
              keyExtractor={(item, index) => index}
              ListHeaderComponent={renderListHeader}
            // Warning: the FlatList is scrollable only if all the parent <View> tags use 'flex:1'
            />
            <FAButton ebulletin={currentBulletin} open={openFab} setOpen={setOpenFab} />            
          </View>


          {/* Case: end of bulletin creation */}
          {route.params?.showValidationModal && (
            <AlertPopup
              type='success'
              visible={showValidationModal}
              onClose={() => setShowValidationModal(false)}
              title={t("Bet's validated")}
              message={t('Find e-bulletin')}
            />
          )}
        </View>   
      </TouchableWithoutFeedback>
    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  //-- Main section
  mainSection: {
  },
  gameRow: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 8,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  gameLogo: {
    height: 35,
    width: 55,
    borderRadius: 4,
  },
  gameName: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    paddingLeft: 8,
  },
  gameRowSeparator: {
    marginHorizontal: 10,
    height: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.primary,
  },
  qrCodeView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrCodeImage: {
    margin: 20,
  },
  qrCodeTextView: {
    flexDirection: 'row',
  },
  qrCodeText: {
    fontFamily: 'Raleway-Regular',
    paddingRight: 4,
  },
  qrCodeValue: {
    fontFamily: 'Raleway-SemiBold',
  },
  totalBetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    marginTop: 8,
    paddingHorizontal: 5,
  },
  totalBetText: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 12,
  },
  //-- List
  listHeader: {
    margin: 20,
    marginBottom: 10,
  },
  listHeaderText: {
    fontFamily: 'Raleway-Bold',
  },
  //-- Action menu
  actionMenuButton: {
    backgroundColor: theme.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 56,
    width: 56,
    height: 56,
    elevation: 6,
    shadowOffset: { width: 0, height: 3, },
    shadowOpacity: 0.29,
    shadowRadius: 6,
  },
  actionMenuView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: 20,
  },
})
