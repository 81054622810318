import React  from "react";
import { useNavigation, useTheme, useFocusEffect } from '@react-navigation/native';
import { View, Text, StyleSheet, Pressable, ActivityIndicator } from 'react-native';
import { SVGChevronRight } from "../../assets/icons";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from '../../store/actions/user.actions';
import screens from '../../common/constants/screen.constants';

const UserLimits = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const limits = useSelector(state => state.user.limits);
  const currencyISO = useSelector(state => state.user.currencyISO);
  const isFetching = useSelector(state => state.user.isFetching);

  const limitsData = [
    {
      limitType: 'Bet',
      title: 'Bet limit',
    },
    {
      limitType: 'Deposit',
      title: 'Deposit limit',
    }
  ]

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(UserActions.getUserLimits());

      return () => {
        //Page leave
      }
    }, [])
  );

  const onPressNavigatePage = (title, data, limitType) => {
    navigation.navigate(screens.UserLimitModifier, { title, data, currencyISO, limitType });
  }

  const renderLoading = () => (
    <View>
      <ActivityIndicator size="small" />
    </View>
  );

  return (
    <View style={style.container}>
      {isFetching ? 
        renderLoading() :
        limitsData?.map((item, index) => {
          const currentData = limits?.filter(elem => elem.LimitType === item.limitType)[0];
          return (
            <View key={index}>
              <View style={style.lineView}>
                <View style={style.leftView}>
                  <Text style={style.text}>
                    {t(item.title)}
                  </Text>
                </View>
                <Pressable style={style.rightView} onPress={() => onPressNavigatePage(item.title, currentData, item.limitType)}>
                  <Text style={style.textRight}>
                    {currentData?.Amount ?
                      currentData?.Amount + ' ' + currencyISO :
                      t('No limit')
                    }
                  </Text>
                  <SVGChevronRight style={style.icon} fill={theme.colors.greyText} />
                </Pressable>
              </View>
              <View style={style.separator}></View>
            </View>
          )
        }) 
      }


    </View>
  );
};

export default UserLimits;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  lineView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 58,
    alignItems: 'center',
    paddingHorizontal: 15
  },
  leftView: {
    flexDirection: 'column',
    marginLeft: 10
  },
  rightView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginLeft: 10
  },
  text: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    textTransform: 'uppercase',
  },
  textRight: {
    fontSize: 13,
    fontFamily: 'Raleway-MediumItalic',
    color: theme.colors.greyText,
    marginRight: 15
  },
  leftTextSubText: {
    fontSize: 13,
    marginTop: 5,
    fontFamily: 'Raleway-Light',
    color: theme.colors.greyText,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.greyText
  }
})
