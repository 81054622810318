import {
    requestKeycloakToken,
    storeTokenObjectToStorage
} from "../services/login.service";
import * as apiWebPlayerService from "../services/api.webplayer.service";

export const APP_INIT = {
    APP_INIT_PENDING: 'APP_INIT_PENDING',
    APP_INIT_TOKEN_EXIST: 'APP_INIT_TOKEN_EXIST',
    APP_INIT_DONE: 'APP_INIT_DONE',
    APP_INIT_FAILED: 'APP_INIT_FAILED',
    APP_VERSION_LOADED: 'APP_VERSION_LOADED',
}

export const updateLogin = (action, pay) => {
    return pay ? { type: action, payload: pay } : { type: action };
}

export const initToken = async(dispatch) => {
    dispatch(onGetToken())
}

export const stockTokenInStorage = async(tokenObj) => {
    if (tokenObj) {
        await storeTokenObjectToStorage(tokenObj);
    }
}

export const tokenAlreadyExist = (tokenObj) => {
    return async dispatch => {
        dispatch({ type: APP_INIT.APP_INIT_TOKEN_EXIST, payload: tokenObj });
    }
}

export const onGetToken = () => {
    return async dispatch => {
      try {
        dispatch(updateLogin(APP_INIT.APP_INIT_PENDING));
        const r = await requestKeycloakToken();
        if (r?.data?.access_token) {
            stockTokenInStorage(r.data);
            apiWebPlayerService.setWebPlayerAuthData(r.data);
            await dispatch(getWebPlayerAppVersion());
            dispatch(updateLogin(APP_INIT.APP_INIT_DONE, r));
        }
      } catch(err) {
        dispatch({ type: APP_INIT.APP_INIT_FAILED, payload: err });
      }
    }
}

export const getWebPlayerAppVersion = () => {
  return async dispatch => {
    let session = await apiWebPlayerService.getWebPlayerAppVersion();
    //TODO: now, we can make some updates related to the session data :
    //  - load some app settings like the defaultCurrency
    //  - check for app updates (applicationVersionStatus/applicationAdvisedUrl)
    session && dispatch({ type: APP_INIT.APP_VERSION_LOADED, payload: session });
  }
}