const screens = {
  basket: 'Basket',
  BasketValidation: 'BasketValidation',
  BetCreate: 'BetCreate',
  DrawResultDetailsPage: 'DrawResultDetailsPage',
  EBulletinPage: 'EBulletinPage',
  Games: 'Games', //Tab root
  GamesHome: 'GamesHome',
  GameRulesHome: 'GameRulesHome',
  GameRules: 'GameRules',
  GamePaytables: 'GamePaytables',
  MyBetsHome: 'MyBetsHome',
  MyBetsTab: 'MyBetsTab',
  MySpace: 'MySpace',
  MyTickets: 'MyTickets',
  MyEBulletins: 'MyEBulletins',
  ResultsHome: 'ResultsHome',
  Scan: 'Scan',
  ScanHelp: 'ScanHelp',
  ScanHome: 'ScanHome',
  ScanManual: 'ScanManual',
  ScanPreview: 'ScanPreview',
  Ticket: 'Ticket',
  GainsDetails: 'GainsDetails',
  UserConnect: 'UserConnect',
  UserFavorites: 'UserFavorites',
  UserFormInputEdition: 'UserFormInputEdition',
  UserInformation: 'UserInformation',
  UserRegister: 'UserRegister',
  UserWelcome: 'UserWelcome',
  UserTransactions: 'UserTransactions',
  UserDeposit: 'UserDeposit',
  UserPasswordChange: 'UserPasswordChange',
  UserPasswordReset: 'UserPasswordReset',
  UserLimits: 'UserLimits',
  UserLimitModifier: 'UserLimitModifier'
}
export default screens;