import React from 'react';
import { useTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import MyBetsHome from './MyBetsHome';
import screens from '../../common/constants/screen.constants';
import MyTicketsPage from './MyTickets.page';
import MyEbulletinsPage from './MyEbulletins.page';
import { getDefaultScreenOptions } from '../../common/theme/theme';

const Stack = createNativeStackNavigator();


const MyBetsNavigation = () => {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName={"MyBetsHome"}
      screenOptions={getDefaultScreenOptions(theme)}
    >

      <Stack.Screen
        name={screens.MyBetsHome}
        component={MyBetsHome}
        options={{ title: t('my bets'), headerBackVisible: false, headerLeft: () => null}}
      />
      <Stack.Screen
        name={screens.MyTickets}
        component={MyTicketsPage}
        options={{ title: t('ticket.my_scanned_tickets') }}
      />
      <Stack.Screen
        name={screens.MyEBulletins}
        component={MyEbulletinsPage}
        options={{ title: t('ebulletin.my_ebulletins')}}
      />

    </Stack.Navigator>
  )
}

export default MyBetsNavigation; 