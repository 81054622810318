import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGLess = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={24} width={24} {...props}>
    <Path d="M0 0h24v24H0V0z" fill="none" />
    <Path d="M6 19h12v2H6v-2z" />
  </Svg>
)

export default SVGLess