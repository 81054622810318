import { ACTIONS } from '../actions/ticket.actions';
import { saveMyTicketsToStorage } from '../services/ticket.service';

// initial state of this reducer

const TicketListItem = {
  reference: '',
  gameId: 0,
  gameName: '',
  drawingDate: '',
  amtTotalBet: 0,
}

const initialState = {
  error: false,
  isFetching: false,
  //
  currentTicket: {},
  myTicketList: [], // TicketListItem[]
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.TICKET_ERROR: {
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.TICKET_FETCHING: {
      return { ...state, isFetching: true, error: false }
    }
    case ACTIONS.TICKET_FETCHING_DONE: {
      return { ...state, isFetching: false }
    }
    case ACTIONS.TICKET_RESET_SEARCH_STATE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.TICKET_CURRENT_TICKET: {
      return { ...state, currentTicket: action.payload }
    }
    case ACTIONS.TICKET_MY_TICKET_LIST:
      saveMyTicketsToStorage(action.payload || []);
      return {
        ...state,
        myTicketList: action.payload || [],
      }
    default:
      return state
  }
}