import axios from 'axios';
import i18n from 'i18next';

//=============================================================================
// AXIOS DEDICATED INSTANCE
//=============================================================================
const API_TIMEOUT = 60000;

const createOnlineAxios = () => {
  let instance = axios.create({
    timeout: API_TIMEOUT,
  });
  instance.interceptors.request.use(async request => {
    request.headers['Authorization'] = `Basic VXNlclJlc3Q6akFaU1dINFJoUzNrZU1qRThNS055WjZFbWdNWDlXaFo=`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    return request;
  });
  return instance;
}
export const ol_axios = createOnlineAxios();

//=============================================================================
// HELPERS
//=============================================================================

/**
 * 
 * @param {*} errorCode 
 * @param {*} defaultMessage true to display a generic error message, or the default message string
 * @returns the translated error message
 */
export const getBtobetErrorMessage = (errorCode, defaultMessage = '') => {
  switch (errorCode) {
    case 512:
      return i18n.t("Account Not Activated");
    case 114:
      return i18n.t("New password invalid");
    case 126:
      return i18n.t("error.invalid_customer_details");
    case 511:
      return "Our system has already registered a user with similar detail";
    case 515:
      return i18n.t("Invalid login details");
    case 516:
      return i18n.t("Customer is already logged");
    case 513:
    case 521:
    case 522:
    case 523:
    case 525:
    case 1043:
      return i18n.t("Player disabled or blocked");
    case 3202:
      return i18n.t("Old password invalid");
    case 3200:
      return i18n.t("New password invalid");
    default:
      return (defaultMessage === true) ? i18n.t("error.generic") : defaultMessage;
  }
}