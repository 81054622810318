import { useFocusEffect, useTheme, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, ScrollView, Pressable, useWindowDimensions } from 'react-native';
import { _APP_VERSION } from '../../../version.json';
import HTMLView from '../../components/kpLibrary/HTMLView';
import i18n from 'i18next';

const LegalNotices = (props) => {

  const style = makeStyles(useTheme());
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { width } = useWindowDimensions();
  const helpText = useSelector(state => state.wordpress.help?.filter(E => E.language === i18n.language)[0]);

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      navigation.setOptions({ title: t('Legal notices') })
      return () => {
        //Page leave
      }
    }, [])
  );

  const renderContentTab = (index) => {
    switch (index) {
      case 0:
        return (
          helpText ? 
          <ScrollView style={style.legalNoticesContent}>
            <HTMLView
              contentWidth={width}
              html={helpText.legalNotices}
            />
          </ScrollView> :
          <Text style={style.title}>{t('No legal notices')}</Text>  
        )
      case 1:
        return (
          <Text style={style.title}>
            {_APP_VERSION || 'No app version'}
          </Text>
        )
      default:
        return null;
    }
  }

  return (
    <ScrollView>
      <View style={style.tabBar}>
        <Pressable
          style={selectedTabIndex === 0 ? style.tabButtonSelected : style.tabButton}
          onPress={() => setSelectedTabIndex(0)}
        >
          <Text style={style.tabText}>
            {t('Legal notices')}
          </Text>
        </Pressable>
        <Pressable
          style={selectedTabIndex === 1 ? style.tabButtonSelected : style.tabButton}
          onPress={() => setSelectedTabIndex(1)}
        >
          <Text style={style.tabText}>
            {t('Version')}
          </Text>
        </Pressable>
      </View>

      {renderContentTab(selectedTabIndex)}
    </ScrollView>
  );
};

export default LegalNotices;

const makeStyles = (theme) => StyleSheet.create({
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    padding: 1,
  },
  tabButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: 20,
    paddingLeft: 2,
    flexGrow: 1
  },
  tabButtonSelected: {
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 8,
    paddingLeft: 2,
    borderBottomColor: theme.colors.greyText,
    borderBottomWidth: 2,
    flexGrow: 1
  },
  tabText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 14
  },
  title: {
    fontFamily: 'Raleway-Regular',
    fontSize: 16,
    color: theme.colors.greyText,
    marginLeft: 15,
    marginTop: 20
  },
  legalNoticesContent: {
    fontFamily: 'Raleway-Regular',
    fontSize: 14,
    color: theme.colors.greyText,
    marginHorizontal: 25,
    marginTop: 15
  }
});
