import axios from 'axios';
import base64 from 'react-native-base64';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { _APP_VERSION } from '../../../version.json'

import { name, displayName, authServiceConfig } from '../../../app.json';

export const getTokenObjectFromStorage = async() => {
    return JSON.parse(await AsyncStorage.getItem('token'));
}

export const storeTokenObjectToStorage = async(tokenObj) => {
    if (tokenObj)
        await AsyncStorage.setItem('token', JSON.stringify(tokenObj))
    else
        await AsyncStorage.removeItem('token');
}


export const requestKeycloakToken = () => {

    const urlPath = 'auth/realms/master/protocol/openid-connect/token';

    const bodyEncoded = new URLSearchParams();
    bodyEncoded.append('grant_type', 'password');
    bodyEncoded.append('username', authServiceConfig.authUsername);
    bodyEncoded.append('password', authServiceConfig.authPassword);
    bodyEncoded.append('scope', 'openid info');

    const authHeader = 'Basic ' + base64.encode(`${authServiceConfig.authId}:${authServiceConfig.authKey}`);

    let params = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'Authorization': authHeader,
        },
    };

    let req = axios.post(`${authServiceConfig.authService}${urlPath}`, bodyEncoded.toString(), params);
    return req;
}