import { useFocusEffect, useNavigation, useRoute, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as TicketActions from '../../store/actions/ticket.actions';

import TicketSummary from '../../components/ticket/TicketSummary';
import InstantOrMutual from '../../components/ticket/Lottery/InstantOrMutual';
import BetSportsSimple from '../../components/ticket/BetSports/BetSportsSimple';
import BetSportsMultiple from '../../components/ticket/BetSports/BetSportsMultiple';
import FastPlay from '../../components/ticket/FastPlay/FastPlay';
import LotoSports from '../../components/ticket/LotoSports/LotoSports';
import screens from '../../common/constants/screen.constants';
import { HeaderBackButton } from '@react-navigation/elements';

const TicketPage = () => {

  const { t } = useTranslation();
  const style = makeStyles(useTheme());
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const [gameName, setGameName] = useState(null);
  const [currency, setCurrency] = useState('');

  const ticket = useSelector(state => state.ticket.currentTicket);
  const myTicketList = useSelector(state => state.ticket.myTicketList);
  const games = useSelector(state => state.games.games);

  const headerLeftGoTicketList = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => navigation.navigate(screens.MyTickets, { keepData: true })}
    />
  );

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      route.params?.navFromTicketList && navigation.setOptions({ headerLeft: headerLeftGoTicketList });
    }, [])
  );


  useEffect(() => {
    const game = games.find(gameItem => gameItem.gameId == ticket.listOfBets[0].gameId);
    setGameName(game?.name);
    setCurrency(ticket.currencySymbol);
    if (ticket && games) {
      dispatch(TicketActions.addOrRefreshMyTicket(myTicketList, ticket, game));
    }
  }, [ticket, games, myTicketList])

  //====================================== RENDER

  const ComputeBetComponent = (bet, index) => {
    switch (bet.gameType.toLowerCase()) {
      case 'instantlottery':
      case 'mutuallottery':
        return (
          <InstantOrMutual
            t={t}
            key={index}
            index={index}
            betDetails={bet}
            currency={currency}
          />
        )
      case 'betsports':
        if (bet.typeOfBet === 'multiple') {
          return (
            <BetSportsMultiple
              t={t}
              key={index}
              index={index}
              betDetails={bet}
              currency={currency}
            />
          )
        } else {
          return (
            <BetSportsSimple
              t={t}
              key={index}
              index={index}
              betDetails={bet}
              currency={currency}
            />
          )
        }
      case 'fastplay':
        return (
          <FastPlay
            t={t}
            key={index}
            index={index}
            betDetails={bet}
            currency={currency}
          />
        )
      case "sportlottery":
        return (
          <LotoSports
            t={t}
            key={index}
            index={index}
            betDetails={bet}
            currency={currency}
          />
        )
      default:
        break;
    }
  }

  return (
    <ScrollView>
      {ticket &&
        <View style={style.container}>

          <TicketSummary ticket={ticket} t={t} gameName={gameName} currency={currency} />

          {ticket.listOfBets?.map((bet, index) => ComputeBetComponent(bet, index))}

        </View>
      }
    </ScrollView>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 15,
  }
});

//======================================
export default TicketPage;
