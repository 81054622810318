import React, { useEffect, useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';

const ItemPicker = ({
  data,
  fieldId,
  fieldName,
  multiSelect,
  customStyles = {
    itemList: null,
    item: null,
    itemSelected: null,
    itemText: null,
    itemTextSelected: null,
  },
  scrollDirection,
  renderItem,
  preselectedIdList,
  itemAllId,
  itemAllName,
  //
  onApply,
}) => {

  const styles = makeStyles(useTheme());
  const { t } = useTranslation();

  const _array2obj = arr => arr ? arr.reduce((a, v) => ({ ...a, [v]: true }), {}) : {};

  const itemAll = { [fieldId]: itemAllId, [fieldName]: itemAllName };

  const [mapSelectedId, setMapSelectedId] = useState(_array2obj(preselectedIdList)); // { id1:boolean, ...}


  //====================================== Hooks

  useEffect(() => {
    let list = preselectedIdList?.length > 0 ? preselectedIdList : itemAllId != undefined ? [itemAllId] : [];
    setMapSelectedId(_array2obj(list));
  }, [preselectedIdList]);

  //====================================== Functions

  const onItemPress = (item) => {
    if (multiSelect) {
      //select or deselect
      const id = item[fieldId];
      let map = { ...mapSelectedId };
      if (item === itemAll) {
        map = { [id]: true };
      } else {
        map[id] = map[id] ? false : true;
        if (itemAllId != undefined && map[itemAllId]) {
          delete map[itemAllId];
        }
      }
      setMapSelectedId(map);
      //validate the item selection
      onApply(convertSelectionMapToArray(map));
    }
    else {
      //validate the item selection
      onApply([item[fieldId]]);
    }
  }

  const convertSelectionMapToArray = (map) => {
    return Object.entries(map).filter(entry => entry[1] == true).map(entry => entry[0]);
  }

  //====================================== Render

  const renderItemDefault = (item) => {
    const isSelected = mapSelectedId[item[fieldId]] == true;
    const itemStyles = [
      styles.item,
      isSelected && styles.itemSelected,
      customStyles?.item,
      isSelected && customStyles?.itemSelected,
    ];
    const itemTextStyles = [
      styles.itemText,
      isSelected && styles.itemTextSelected,
      customStyles?.itemText,
      isSelected && customStyles?.itemTextSelected,
    ];
    return (
      <Pressable style={itemStyles} onPress={() => onItemPress(item)}>
        <Text style={itemTextStyles}>
          {item[fieldName]}
        </Text>
      </Pressable>
    )
  };

  return (
    <View style={styles.container}>
      <ScrollView
        horizontal={scrollDirection == 'horizontal'}
        contentContainerStyle={[styles.itemListView, customStyles?.itemList]}
      >
        {itemAllId != undefined &&
          (renderItem && renderItem(itemAll, onItemPress) || renderItemDefault(itemAll))
        }
        {
          data?.map((item, key) => (
            <View key={key}>
              {renderItem && renderItem(item, onItemPress) || renderItemDefault(item)}
            </View>
          ))
        }
      </ScrollView>
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  container: {
    opacity: 1,
  },
  itemListView: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  item: {
    alignItems: 'center',
    borderColor: '#404040',
    borderWidth: 1,
    borderRadius: 20,
    margin: 5,
    minWidth: 60,
    padding: 10,
  },
  itemSelected: {
    backgroundColor: theme.colors.primary,
    borderWidth: 0,
  },
  itemText: {
    color: '#848484',
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
  },
  itemTextSelected: {
    color: 'white',
  },
});

export default ItemPicker;