import { View, Text, StyleSheet, Pressable, ScrollView } from "react-native";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useTheme } from "@react-navigation/native";

import { formatNumber } from "../../utils/FormatUtils";

export default function GainsDetails(props) {

  const { betDetails, currency } = props.route.params;
  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();

  function getBallClass(ball) {
    const findGrid = betDetails?.draw?.listOfGridDrawn.length > 0 && betDetails?.draw?.listOfGridDrawn[0].winningNumbers;
    return findGrid && findGrid.includes(ball) ? style.ballDrawed : style.ballPulled;
  }

  function getNumberClass(ball) {
    const findGrid = betDetails?.draw?.listOfGridDrawn.length > 0 && betDetails?.draw?.listOfGridDrawn[0].winningNumbers;
    return findGrid && findGrid.includes(ball) ? style.numberDrawed : style.numberPulled;
  }

  return (
    <ScrollView>
      <View style={style.container}>
        
        <Text style={style.title}>
          {t('Draw results')}
        </Text>
        <View style={style.separator}></View>
        <View style={style.ballContainer}>
          {betDetails.draw?.listOfGridDrawn[0].winningNumbers?.map((ball, idx) => (
            <View key={idx} style={style.ballPending}>
              <Text style={style.numberDraw}>
                {ball}
              </Text>
            </View>
          ))}
        </View>

        <Text style={style.title}>
          {t('Your selection')}
        </Text>
        <View style={style.separator}></View>
        <View style={style.ballContainer}>
          {betDetails.listOfBetGrids[0].listOfLottoNumbers?.map((ball, idx) => (
            <View key={idx} style={getBallClass(ball)}>
              <Text style={getNumberClass(ball)}>
                {ball}
              </Text>
            </View>
          ))}
        </View>

        {betDetails.listOfCombinations?.length > 1 && (
          <View>
            <Text style={style.title}>
              {t('Winning combinations')}
            </Text>
            <View style={style.separator}></View>
              {betDetails.listOfGains?.map((gains, index) => (
              <View style={style.combinationsContainer} key={index}>
                <View style={style.ballContainerCombinations}>
                  {gains?.combination?.map((ball, idx) => (
                      <View key={idx} style={getBallClass(parseInt(ball))}>
                        <Text style={getNumberClass(parseInt(ball))}>
                          {ball}
                        </Text>
                      </View>
                  ))}
                </View>
                <View style={style.combinationsDetails}>
                  <Text style={style.combinationsText}>
                    {t('Rank')}    {gains.rank}
                  </Text>
                  <Text style={style.combinationsText}>
                    {t('Gains')}    {formatNumber(gains.unitaryGain)} {currency}
                  </Text>
                </View>
              </View>
            ))} 
          </View>        
        )}


      </View>

    </ScrollView>
  );
}


//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    backgroundColor: '#FFFFFF',
    paddingBottom: 40
  },
  separator: {
    marginTop: 5,
    width: '100%',
    height: 1,
    backgroundColor: '#112F6C'
  },
  ballContainer: {
    flexDirection: 'row',
    marginVertical: 20,
  },
  ballContainerCombinations: {
    flexDirection: 'row',
    marginVertical: 20,
    marginLeft: 22
  },
  combinationsContainer: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText
  },
  combinationsDetails: {
    backgroundColor: '#F6F6F6',
    marginHorizontal: 5,
    height: 30,
    alignItems: 'center',
    flexDirection: 'row'
  },
  combinationsText: {
    fontFamily: 'Raleway-MediumItalic',
    fontSize: 12,
    color: theme.colors.greyText,
    paddingHorizontal: 15
  },
  // Ball Numbers
  ballPending: {
    width: 28,
    height: 28,
    backgroundColor: theme.colors.primary,
    borderRadius: 30,
    marginRight: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  numberDraw: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  },
  ballPulled: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
    justifyContent: 'center'
  },
  numberPulled: {
    color: '#8F8E95',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  },
  ballDrawed: {
    width: 28,
    height: 28,
    borderRadius: 30,
    marginRight: 2,
    alignItems: 'center',
    backgroundColor: '#30C408',
    justifyContent: 'center'
  },
  numberDrawed: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'Raleway-Bold'
  }
});

