import { View, Text, StyleSheet, Pressable, Platform, Dimensions, ScrollView, TouchableOpacity, ActivityIndicator } from "react-native";
import Checkbox from 'expo-checkbox';
import { useTheme, useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SVGClose, SVGError } from "../../assets/icons";
import screens from '../../common/constants/screen.constants';
import * as UserActions from '../../store/actions/user.actions';
import { generateFormTab } from "./Form.tabs";
import StyledTextInput from "../../components/kpLibrary/forms/StyledTextInput.comp";
import CguModal from "../../components/user/CguModal";
import i18n from 'i18next';
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import PhoneNumberPrefixInput from "../../components/kpLibrary/forms/PhoneNumberPrefixInput.comp";
import { checkPhoneNumberValid } from "../../utils/FormatUtils";

const UserRegister = () => {

  const height = Dimensions.get('window').height;
  const isWeb = Platform.OS === "web";
  const formTabList = generateFormTab();
  const theme = useTheme();
  const style = makeStyles(theme, height, isWeb);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const phoneMinDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMinDigits);
  const phoneMaxDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMaxDigits);
  const phoneFormatSplit = useSelector(state => state.wordpress.appSettings?.localization?.phoneFormatSplit);
  const phonePrefix = useSelector(state => state.wordpress.appSettings?.localization?.phonePrefix);
  const userIsConnected = useSelector(state => state.user.isConnected);
  const isFetching = useSelector(state => state.user.isFetching);
  const registerError = useSelector(state => state.user.registerError);
  // CGU
  const [cguAcceptation, setCguAcceptation] = useState(false);
  const [showCguModal, setShowCguModal] = useState(false);
  const content = useSelector(state => state.wordpress.help?.filter(E => E.language === i18n.language)[0])
  // PHONE
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(false);
  // PASSWORD
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  // FORM
  const [isFormularValid, setIsFormularValid] = useState(false);
  const [forms, setForms] = useState(generateFormTab().reduce((obj, item) => {
    return {
      ...obj,
      [item.value]: '',
    };
  }, {}));

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      return () => {
        // Page leave
        dispatch(UserActions.reset_error());
      }
    }, [])
  );

  useEffect(() => {
    if (userIsConnected) {
      navigation.navigate(screens.UserWelcome, { title: t('Welcome')});
    }
  }, [userIsConnected])

  useEffect(() => {
    // Check if the password is valid and the than password confirmation
    setIsPasswordValid(
      forms['password'].length >= 6
      && forms['password'] === forms['passwordConfirmation']
    );
  }, [forms['password'], forms['passwordConfirmation']])

  useEffect(() => {
    // Checking if phone number, password, & cgu are complete to try the inscription
    const isPhoneValid = checkPhoneNumberValid(forms['phoneNumber'], phoneMinDigits, phoneMaxDigits);
    setPhoneNumberValid(isPhoneValid);
    setIsFormularValid(
      isPhoneValid
      && forms['password'].length > 0
      && forms['passwordConfirmation'].length > 0
      && cguAcceptation
    )
  }, [forms['phoneNumber'], forms['password'], forms['passwordConfirmation'], cguAcceptation])

  const onCGUAccepted = () => {
    setCguAcceptation(!cguAcceptation)
  }

  const onFormChangeText = (text, value) => {
    if (registerError) {
      dispatch(UserActions.reset_register_error());
    }
    if (value === 'phoneNumber') {
      const num = text.replace(/ /g, '');
      setForms({
        ...forms,
        [value]: num
      })
    } else {
      setForms({
        ...forms,
        [value]: text
      })
    }
  }

  const formatPhoneNumber = (num, splitFreq = phoneFormatSplit || 2) => {
    const s = num?.replace(/[^0-9+]+/g, '');
    if (s.length >= 7 && s.length % splitFreq != 0)
      return s.substr(0, s.length % splitFreq) + ' '
        + s.substr(s.length % splitFreq).replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
    else
      return s.replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
  }



  const checkPasswordError = () => {
    if (forms['password'] !== forms['passwordConfirmation']) {
      setPasswordError('password.error.mismatch')
    }
    if (forms['password'].length < 6) {
      setPasswordError('Password error 2')
    }
    if (forms['password'] === forms['passwordConfirmation'] && forms['password'].length >= 6) {
      setPasswordError('')
    }
  }

  const checkPhoneNumberError = () => {
    // setPhonenumberError("Phone number error 1")
  }


  const isFieldError = (value) => {
    return ((value === "phoneNumber" && registerError?.length > 1)
      || (value === 'password' && passwordError.length > 1)
      || (value === 'passwordConfirmation' && passwordError.length > 1));
  }

  const validateForm = () => {
    checkPasswordError();
    checkPhoneNumberError();

    if (passwordError.length === 0 && isPasswordValid && isPhoneNumberValid) {
      // DISPATCH API CREATE ACCOUNT
      dispatch(UserActions.registerAndConnect(forms));
    } else {
      return;
    }
  }

  const renderFormsInput = () => {
    return (
      formTabList.map((tab, index) => {
        const focusTab = false;
        return (
          <View key={index} style={{ width: 280 }}>
            <StyledTextInput
              keyboardType={tab.keyboardType}
              onChangeText={text => onFormChangeText(text, tab.value)}
              placeholder={`${t(tab.placeHolder)}${tab.required ? '*' : ''}`}
              value={tab.value === 'phoneNumber' ? formatPhoneNumber(forms[tab.value]) : forms[tab.value]}
              isError={isFieldError(tab.value)}
              inputProps={{
                secureTextEntry: tab.secureTextEntry,
              }}
              renderPrefix={tab.value != 'phoneNumber' ? undefined : () =>
                <PhoneNumberPrefixInput value={phonePrefix} />
              }
            />
            {tab.value === 'phoneNumber' && registerError?.length > 1 && !focusTab &&
              (
                <View style={style.errorMessageContainer}>
                  <SVGError fill='#F83636' style={{ width: 30 }} />
                  <Text style={style.errorMessageText}>
                    {t(registerError)}
                  </Text>
                </View>
              )
            }
          </View>
        )
      })
    )
  }

  return (
    <ScrollView style={style.container}>
      <View style={style.topContainer}>
        <Pressable onPress={() => navigation.goBack()}>
          <SVGClose fill={theme.colors.greyText} />
        </Pressable>
      </View>

      <View style={style.content}>

        <Text style={style.title}>
          {t('Inscription')}
        </Text>

        {renderFormsInput()}

        {passwordError.length > 1 &&
          <View style={style.errorMessageContainer}>
            <SVGError fill='#F83636' style={{ width: 30 }} />
            <Text style={style.errorMessageText}>
              {t(passwordError)}
            </Text>
          </View>
        }

        <View style={style.checkboxContainer}>
          <TouchableOpacity onPress={() => onCGUAccepted()}>
            <Checkbox
              style={style.checkbox}
              value={cguAcceptation}
              color={cguAcceptation ? theme.colors.primary : undefined}
            />
          </TouchableOpacity>
          <View style={{ flexDirection: 'row', width: 250 }}>
            <Text style={style.checkboxText}>{t('CGU text')}
              <Pressable onPress={() => setShowCguModal(content !== undefined)}>
                <Text style={style.checkboxTextCGU}>
                  {t('CGU')}
                </Text>
              </Pressable>
            </Text>
          </View>

        </View>

        {!isFetching &&
          <StyledButton
            onPress={validateForm}
            text={t("validate")}
            disabled={!isFormularValid}
            buttonStyle={{ width: 280, marginBottom: 30 }}
          />
        }

        {isFetching &&
          <ActivityIndicator style={{ marginBottom: 30 }} size="small" />
        }

        <View style={style.separator}></View>

        <View style={{ flexDirection: 'row' }}>
          <Text style={style.registerText}>
            {t("Already registered")}{' '}
          </Text>
          <Pressable onPress={() => navigation.navigate(screens.UserConnect)}>
            <Text style={style.registerTextPressable}>
              {t("Login")}
            </Text>
          </Pressable>
        </View>

      </View>
      <CguModal modalVisible={showCguModal} setModalVisible={setShowCguModal} />
    </ScrollView>
  );
};

export default UserRegister;

const makeStyles = (theme, height, isWeb) => StyleSheet.create({
  container: {
    padding: 15,
    height: height,
    backgroundColor: '#FFFFFF'
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  content: {
    height: height - 200,
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 22,
    marginTop: 40,
    marginBottom: 35
  },
  textInputPrefix: {
    fontFamily: 'Raleway-Regular',
    fontSize: 15,
    marginLeft: 10,
    marginRight: 16,
    color: '#000000',
  },
  textInputPrefixError: {
    fontFamily: 'Raleway-Regular',
    fontSize: 15,
    marginLeft: 10,
    marginRight: 16,
    color: '#F83636',
  },
  checkboxContainer: {
    width: 280,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
    marginTop: 10
  },
  checkbox: {
    width: 25,
    height: 25,
    marginRight: 10,
    color: theme.colors.primary,
    borderRadius: 5
  },
  checkboxText: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 15
  },
  checkboxTextCGU: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 15,
    marginBottom: -4
  },
  separator: {
    width: 280,
    height: 0.5,
    backgroundColor: theme.colors.greyText,
    marginBottom: 30
  },
  registerText: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 16,
    marginBottom: 40
  },
  registerTextPressable: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.primary,
    fontSize: 16
  },
  errorMessageContainer: {
    width: 280,
    flexDirection: 'row',
    marginBottom: 15
  },
  errorMessageText: {
    marginVertical: 'auto',
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: '#F83636',
    marginLeft: 7
  }
})
