import React, { useEffect, useState } from "react";
import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import { View, Text, StyleSheet, Pressable, FlatList, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import * as GamesActions from '../../store/actions/games.actions';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import DateRangePickerModal from "../kpLibrary/DateRangePickerModal";
import ItemPickerModal from "../kpLibrary/ItemPickerModal";
import DrawResultListItem from "./DrawResultListItem.comp";
import { extractDate } from "../../utils/DateUtils";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const DrawResultList = ({
  route,
  preselectedGameId,
}) => {

  const navigation = useNavigation();
  const styles = makeStyles(useTheme());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const maxResultDisplayCount = 30;
  const maxTotalResultDisplayCount = 100;

  const pastSessions = useSelector(state => state.games.pastSessions);
  const isGameError = useSelector(state => state.games.error);
  const isGameApiFetching = useSelector(state => state.games.isFetching);
  const lottery_games = useSelector(state => state.games.lottery_games);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [isMoreResultsShown, setMoreResultsShown] = useState(false);
  const [resultListDisplay, setResultListDisplay] = useState(pastSessions.list);
  const [pageNumber, setPageNumber] = useState(1);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showGamePicker, setShowGamePicker] = useState(false);
  const localeUsed = i18n.language;

  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      const keepSearchResults = route?.params?.keepSearchResults || false;
      if (!keepSearchResults) {
        initData();
      }

      return () => {
        //Page leave
        navigation.setParams({ keepSearchResults: null });
        navigation.setOptions({headerRight: null}); //remove refresh icon
      }
    }, [route])
  );

  // Refresh icon
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (!isLoading && <Icon name="refresh" style={styles.refreshIcon} onPress={refreshResults} />)
    });
  }, [navigation, isLoading]);

  useEffect(() => {
    if (isGameApiFetching == false) {
      setResultListDisplay(pastSessions.list.slice(0, pageNumber * maxResultDisplayCount));
      const maxItemDisplayed = isMoreResultsShown ? maxTotalResultDisplayCount : maxResultDisplayCount;
      setHasMoreResults(pastSessions.list?.length > maxItemDisplayed);
    }
    else {
      setLoading(!isMoreResultsShown);
      setLoadingMore(true);
    }
  }, [pastSessions.list, isGameApiFetching]);

  useEffect(() => {
    setLoading(isGameApiFetching && !isMoreResultsShown);
    setLoadingMore(isGameApiFetching);
  }, [resultListDisplay]);

  useEffect(() => {
    setResultListDisplay(pastSessions.list.slice(0, pageNumber * maxResultDisplayCount));
  }, [pageNumber]);

  useEffect(() => {
    moment.locale(localeUsed)
  }, [])


  //====================================== Functions

  const initData = async () => {
    if (preselectedGameId) {
      dispatch(GamesActions.set_past_sessions_filters([preselectedGameId], null, null));
      requestPastSessions([preselectedGameId], null, null);
    }
    else {
      await dispatch(GamesActions.reset_past_sessions());
      requestPastSessions(pastSessions.gameIdList, null, null);
    }
  }

  const requestPastSessions = (gameIdList, after, before) => {
    setLoading(true);
    setMoreResultsShown(false);
    setPageNumber(1);
    return dispatch(GamesActions.getPastSessions(gameIdList, after, before, maxResultDisplayCount + 1));
  }
  
  const requestMorePastSessions = () => {
    setLoadingMore(true);
    setMoreResultsShown(true);
    return dispatch(GamesActions.getPastSessions(pastSessions.gameIdList, pastSessions.after, pastSessions.before, maxTotalResultDisplayCount + 1));
  }

  const onFilterClearPress = () => {
    dispatch(GamesActions.reset_past_sessions());
    requestPastSessions();
  }

  const onFilterPeriodApply = ({ startDate, endDate }) => {
    setShowDatePicker(false);
    const dthAfter = startDate?.concat('T00:00:00');
    const isEndDateToday = endDate === extractDate(new Date().toISOString());
    const dthBefore = isEndDateToday ? new Date().toISOString().slice(0, -1) : endDate?.concat('T23:59:59') || dthAfter;
    dispatch(GamesActions.set_past_sessions_filters(pastSessions.gameIdList, dthAfter, dthBefore));
    requestPastSessions(pastSessions.gameIdList, dthAfter, dthBefore);
  }

  const onFilterGameApply = (strGameIdList) => {
    setShowGamePicker(false);
    const gameIdList = strGameIdList.map(id => +id).filter(id => id > 0); //remove the 'all' selection
    dispatch(GamesActions.set_past_sessions_filters(gameIdList, pastSessions.after, pastSessions.before));
    requestPastSessions(gameIdList, pastSessions.after, pastSessions.before);
  }

  const refreshResults = () => {
    isMoreResultsShown
      ? requestMorePastSessions()
      : requestPastSessions(pastSessions.gameIdList, pastSessions.after, pastSessions.before);
  }

  const displayMoreData = () => {
    if (isLoadingMore || !isMoreResultsShown) {
      return;
    }
    if (resultListDisplay.length < pastSessions.list.length) {
      setPageNumber(pageNumber + 1);
    }
  }


  //====================================== Render

  const renderLoading = () => (
    <View style={styles.loading}>
      <ActivityIndicator size="small" />
    </View>
  );


  const renderFilters = () => (
    <View style={styles.filterBar}>
      <Pressable style={[styles.filterButton, pastSessions.after && styles.filterButtonSelected]} onPress={() => setShowDatePicker(true)}>
        <Text style={[styles.filterText, pastSessions.after && styles.filterTextSelected]}>
          {pastSessions.after && <Icon name={'check'} style={styles.filterCheckmarkIcon} />}
          {t('dates')}
        </Text>
      </Pressable>
      <Pressable style={[styles.filterButton, pastSessions.gameIdList.length > 0 && styles.filterButtonSelected]} onPress={() => setShowGamePicker(true)}>
        <Text style={[styles.filterText, pastSessions.gameIdList.length > 0 && styles.filterTextSelected]}>
          {pastSessions.gameIdList.length > 0 && <Icon name={'check'} style={styles.filterCheckmarkIcon} />}
          {t('games')}
        </Text>
      </Pressable>
      {(pastSessions.gameIdList.length > 0 || pastSessions.after) &&
        <Pressable style={[styles.filterButton, styles.filterClearButton]} onPress={() => onFilterClearPress()}>
          <Text style={styles.filterClearText}>
            {t('results.clear_filters')}
          </Text>
        </Pressable>
      }
    </View>
  )

  const renderListFooter = () => {
    return !hasMoreResults ? <></> :
      <View style={styles.hasMoreResultsView}>
        {isLoadingMore && renderLoading()}

        {!isLoadingMore && !isMoreResultsShown &&
          <Pressable style={styles.moreButton} onPress={() => requestMorePastSessions()}>
            <Text style={styles.moreButtonText}>
              {t('results.show_more_results')}
            </Text>
          </Pressable>
        }
        {
          !preselectedGameId && !isLoadingMore && isMoreResultsShown && resultListDisplay.length >= maxTotalResultDisplayCount &&
          <Text style={styles.moreResultsMessage}>
            {t('results.more_results_message')}
          </Text>
        }
      </View>
  }

  const renderListEmpty = () => {
    return isLoading ? <></> :
      <View style={styles.error}>
        <Text style={styles.errorText}>{t('no_result_found')}</Text>
      </View>
  }

  return (
    <View style={styles.container}>

      {isLoading && renderLoading()}

      {!isLoading &&
        <React.Fragment>

          {!preselectedGameId && renderFilters()}

          <DateRangePickerModal
            visible={showDatePicker}
            onClose={() => setShowDatePicker(false)}
            onApply={onFilterPeriodApply}
            defaultStartDate={pastSessions.after}
            defaultEndDate={pastSessions.before}
          />
          <ItemPickerModal
            data={lottery_games}
            fieldId='gameId'
            fieldName='name'
            multiSelect
            itemAllId={-1}
            itemAllName={t('all')}
            onClose={() => setShowGamePicker(false)}
            onApply={onFilterGameApply}
            preselectedIdList={pastSessions.gameIdList}
            title={t('games')}
            visible={showGamePicker}
          />

          {isGameError &&
            <View style={styles.error}>
              <Text style={styles.errorText}>{t('error.generic')}</Text>
            </View>
          }

          {!isGameError &&
            <FlatList
              data={resultListDisplay}
              renderItem={({ item }) => <DrawResultListItem item={item} />}
              keyExtractor={(item, index) => index}
              contentContainerStyle={{ justifyContent: 'center' }}
              ListFooterComponent={renderListFooter}
              ListEmptyComponent={renderListEmpty}
              onEndReachedThreshold={0.3}
              onEndReached={displayMoreData}
            // Warning: the FlatList is scrollable only if all the parent <View> tags use 'flex:1'
            />
          }
        </React.Fragment>
      }

    </View>
  );

}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F6F6F6',
  },
  loading: {
    margin: 30,
  },
  refreshIcon: {
    fontSize: 22,
    color: 'white',
    paddingRight: 10,
  },

  //-- FILTERS
  filterBar: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    alignItems: 'center',
    position: 'relative',
  },
  filterClearButton: {
    backgroundColor: theme.colors.primary,
    borderWidth: 0,
    position: 'absolute',
    right: 0,
    marginRight: 0,
  },
  filterClearText: {
    fontSize: 12,
    color: '#fff',
    padding: 5,
    fontFamily: 'Raleway-Bold'
  },
  filterButton: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 5,
    borderColor: theme.colors.greyText,
    borderWidth: 1,
    marginRight: 5,
    padding: 5,
    minWidth: 80,
    minHeight: 36,
  },
  filterButtonSelected: {
    backgroundColor: theme.colors.primary,
    borderRadius: 4,
    borderColor: theme.colors.primary,
  },
  filterText: {
    color: theme.colors.greyText,
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterTextSelected: {
    color: '#ffffff',
  },
  filterCheckmarkIcon: {
    fontSize: 20,
    paddingRight: 2,
  },
  // RESULT LIST
  hasMoreResultsView: {
    display: 'flex',
    alignItems: 'center',
  },
  moreButton: {
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    borderRadius: 5,
    elevation: 5,
    margin: 20,
    minWidth: 250,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    textAlign: 'center',
    padding: 5,
  },
  moreButtonText: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 13,
    color: '#fff',
    padding: 5,
  },
  moreResultsMessage: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 12,
    paddingVertical: 20,
  },
  error: {
    alignItems: 'center',
    margin: 20,
    padding: 10,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Raleway-Regular',
    color: '#000',
    marginBottom: 20,
  }
})

export default DrawResultList;
