import { Platform } from "react-native";

export function formatNumber(number) {
  if (number == null) return null;
  const result = Platform.OS === "android" ? formatNumberSpace(+number) : (+number).toLocaleString();
  return result;
}

function formatNumberSpace(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatNumberComma(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function sortNumber(a, b) {
  return a - b;
}

export function capitalize(s) {
  return s.substr(0, 1).toUpperCase() + s.slice(1);
}

export function checkPhoneNumberValid(phoneNumber, phoneMinDigits, phoneMaxDigits) {
  const minDigits = parseInt(phoneMinDigits) || DEFAULT_PHONE_MIN_LEN;
  const maxDigits = parseInt(phoneMaxDigits) || DEFAULT_PHONE_MAX_LEN;
  return phoneNumber.length >= minDigits && phoneNumber.length <= maxDigits;
}

/**
 * Pretty-print the phone number
 * @param {*} the raw phone number string (ex: "06060606")
 * @param {*} splitFreq add a space every splitFreq occurences
 * @returns The formatted phone number (ex: "06 06 06 06")
 */
export function formatPhoneNumber(s, splitFreq) {
  if (s.length >= 7 && s.length % splitFreq != 0)
    return s.substr(0, s.length % splitFreq) + ' '
      + s.substr(s.length % splitFreq).replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
  else
    return s.replace(new RegExp(`(.{${splitFreq}})`, 'g'), "$1 ").trim();
}