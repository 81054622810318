import React, { useEffect, useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Pressable, Text, View, StyleSheet, Image } from 'react-native';
import { useSelector } from 'react-redux';

import KoralPlayLoader from './kpLibrary/KoralPlayInitLoader';

import images from '../assets/images';


function AppLoader({initializeapp}) {

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = makeStyles(theme);

  const error = useSelector(state => state.login.error);
  const wordpressError = useSelector(state => state.wordpress.error)
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const message = error?.message || (typeof error == 'object' ? JSON.stringify(error) : error);
    setErrorMessage(message);
  }, [error])

  const onRetry = () => {
    setErrorMessage(null);
    initializeapp();
  }

  return (
    <View style={styles.container}>
      {errorMessage?.length > 0 || wordpressError ?
        <View style={styles.errorBox}>
          <View style={styles.messageBox}>
            <Text style={styles.errorMessage1}>
              {t('Something went wrong')}.
            </Text>
            <Text style={styles.errorMessage2}>
            {t('The app takes too long to start')}.
            </Text>
          </View>
          <Pressable style={styles.retryButton} onPress={onRetry}>
            <Text style={styles.retryButtonText}>
              {t('retry')}
            </Text>
          </Pressable>
        </View> :
        <View style={styles.containerLoader}>
          <KoralPlayLoader>
            <Image style={styles.loaderImage} source={images.logo_operator} />
          </KoralPlayLoader>
        </View>
      }
    </View>
  )
}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    width: '100%'
  },
  errorBox: {
    backgroundColor: '#ffdddd',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    height: 235
  },
  retryButton: {
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    marginTop: 20,
    padding: 10,
    alignItems: 'center',
    cursor: 'pointer'
  },
  retryButtonText: {
    color: 'white',
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
  },
  loaderImage: {
    resizeMode: 'contain',
    width: 320,
    height: 100
  },
  errorMessage1: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 17,
  },
  errorMessage2: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 13,
    paddingTop: 17
  },
  messageBox: {
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default AppLoader;
