import { ACTIONS } from '../actions/basket.actions';

// initial state of this reducer

const typeBasketItem = {
  //internalId: 1, //TODO: need to manage an internalId for add/remove actions ? or the object ref is sufficient ?
  betTypeId: 42,
  draw: {}, // draw object ref. (= the session)
  drawId: 123456, // undefined for FOGO-like games
  nbConsecutiveDraws: 5, //default 1 - for FOGO-like games
  potentialAward: 10000,
  potentialAwardNet: 10000,
  price: 500,
  selectionListByGrid: [[2, 4, 6, 8, 10], [4]],
  stake: 100,
  selectedBetType: {}
}

const initialState = {
  gameId: undefined,
  gameType: undefined,
  maxBetsTotalPrice: Infinity,
  isValid: false,
  isTotalStakeValid: false,
  itemList: [], // typeBasketItem
  totalStake: 0,
}

// Reducer to catch the actions

export default function basketReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.BASKET_CLEAR: {
      return { ...initialState }
    }
    case ACTIONS.BASKET_SET_ITEM_LIST: {
      return { ...state, itemList: action.payload }
    }
    case ACTIONS.BASKET_SET_VALID: {
      return { ...state, isValid: Boolean(action.payload) }
    }
    case ACTIONS.BASKET_TOTAL_STAKE: {
      return { ...state, totalStake: +action.payload }
    }
    case ACTIONS.BASKET_TOTAL_STAKE_VALID: {
      return { ...state, isTotalStakeValid: Boolean(action.payload) }
    }
    case ACTIONS.BASKET_SET_GAME_INFO: {
      return {
        ...state,
        gameId: action.payload.gameId,
        gameType: action.payload.gameType,
      }
    }
    case ACTIONS.BASKET_MAXBETSTOTALPRICE: {
      return { ...state, maxBetsTotalPrice: action.payload || Infinity }
    }
    default:
      return state
  }
}