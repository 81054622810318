export const translationFR = {
  /*eslint-disable */

  /** ============================================ GENERAL DICTIONARY  ========================================*/
  "all": "Tout",
  "all.fem": "Toutes",
  "back": "Retour",
  "cancel": "Annuler",
  "confirm": "Confirmer",
  "clear": "Effacer",
  "code": "Code",
  "connecting": "Connexion en cours...",
  "dates": "Dates",
  "delete": "Supprimer",
  "game": "Jeu",
  "games": "Jeux",
  "go_back_home": "Retour à l'accueil",
  "loading": "Chargement...",
  "loading_data": "Chargement des données...",
  "lotteries": "Loteries",
  "no": "Non",
  "no_result_found": "Aucun résultat trouvé.",
  "ref": "Réf.",
  "remove": "Retirer",
  "results": "Résultats",
  "retry": "Réessayer",
  "see": "Voir",
  "share": "Partager",
  "stake": "Mise",
  "today": "Aujourd'hui",
  "today_at": "Aujourd'hui à",
  "search_in_progress": "Recherche en cours...",
  "validate": "Valider",
  "yes": "Oui",
  "Next draws": "Prochains tirages",
  "number_abr": "n°",
  "Numbers": "numéros",
  "Our games": "Nos jeux",
  "Grid": "Grille",
  "Connexion": "Connexion",
  'Others': 'Autres',
  "Deposits": "Dépôts",
  "Withdrawals": "Retraits",
  "deposit": "Dépôt",
  "Withdrawal": "Retrait",
  /** =========================================================================================================*/

  /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
  "Ticket base amount": "Montant de base du ticket",
  "end_of_bets": "Fin des paris",
  "game_rules": "Règles du jeu",
  "minute": "minute",
  "minutes": "minutes",
  "next_draw": "Prochain tirage",
  "next_draw_less": "Prochain tirage dans moins de",
  "no_draw_available": "Aucun tirage n'est disponible pour le moment. Réessayez plus tard.",
  "no_draw_available_short": "Aucun tirage disponible.",
  "select_bet_type": "Choisis un type de jeu",
  "select_game_session": "Choisis une session de jeu",
  "select_game_sessions": "Choisis une ou plusieurs sessions de jeu",
  "Remaining": "Restant",
  "Stake per bet": "Mise par pari",
  "Number of draws": "Nombre de tirages",
  "Summary": "Récapitulatif",
  "Total stake": "Mise totale",
  'additional numbers': 'numéros additionnels',
  "Custom amount": "Montant personnalisé",
  "Select amount": "Saisissez votre montant",
  "Select between": "Selectionnez un montant entre",
  "And": "et",
  "Grid Complete": "Votre grille est complète",
  "Max amount error": "Le montant saisi doit être inférieur à",
  "Min amount error": "Le montant saisi doit être supérieur à",
  "Isnt multiple error": "Le montant saisi doit être un multiple de",

  /** ------------------------------------------------ ERRORS ----------------------------------------------*/
  "error.generic": "Une erreur est survenue.",
  "error.invalid_customer_details": "Le compte joueur n'a pas été trouvé",
  "Something went wrong": "Quelque chose ne va pas",
  "The app takes too long to start": "L’appli met trop longtemps à démarrer",
  "Old password invalid": "Le mot de passe saisi est incorrect",
  "New password invalid": "Le nouveau mot de passe est invalide",

  /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
  "ebulletin.fill_basket": "Repartir de ce pari",
  "ebulletin.find_point_of_sale": "Trouver un point de vente",
  "ebulletin.limit_date": "Date limite",
  "ebulletin.my_ebulletin": "Mon e-bulletin",
  "ebulletin.tag": "E-Bulletin",
  "ebulletin.tag.s": "E-Bulletins",
  "ebulletin.no_ebulletin": "Vous n'avez aucun e-bulletin.",
  "ebulletin.my_ebulletins": "Mes e-bulletins",
  "Deadline": "Date limite",

  /** ------------------------------------------------ RESULTS ----------------------------------------------*/
  "results.clear_filters": "Effacer les filtres",
  "results.draws_results": "Résultats des tirages",
  "results.more_results_message": "Pour voir encore plus de tirages, affinez les filtres.",
  "results.most_popular_numbers": "Numéros les plus sortis",
  "results.show_more_results": "Voir plus de tirages",

  /** ------------------------------------------------ SCAN ----------------------------------------------*/
  "Manual entry": "Saisie manuelle",
  "scan.button_qrcode": "Je scanne un QR code",
  "scan.button_code": "Je saisis mon code",
  "scan.dialog_action_manual_entry": "Saisir le code manuellement",
  "scan.dialog_action_qrcode": "Scanner un QR code",
  "scan.dialog_error_qrcode": "Nous n'avons pas pu lire votre QR code.",
  "scan.find_code": "Où trouver mon code ?",
  "scan.help_bulletin_1": "Rendez-vous dans ‟Mes Paris”",
  "scan.help_bulletin_2": "Cliquez sur ‟E-Bulletins”",
  "scan.help_bulletin_3": "Ouvrez le bulletin de votre choix en cliquant sur ‟Voir”",
  "scan.help_bulletin_4": "Le code est situé sous le QR code",
  "scan.help_bulletin_title": "Saisissez le code d'un e-bulletin pour charger le même pari sur votre téléphone, ou partagez votre e-bulletin avec un ami.",
  "scan.help_ticket_title": "Saisissez le code situé sur votre reçu, juste au dessus du QR code.",
  "scan.manual_entry_label": "Saisissez votre code",
  "scan.manual_entry_label_2": "(ticket ou e-bulletin)",
  "scan.manual_placeholder": "Saisissez le code",
  "scan.scan_not_available": "La caméra n'est pas disponible. Merci de vérifier les autorisations sur votre smartphone.",
  "scan.scan_ticket_or_bulletin": "Scannez un ticket ou un e-bulletin",
  "Scan unavailable": "Disponible uniquement sur l'application Android ou IOS.",
  "scan.isSportBetBulletinError": "Vous avez scanné un e-bulletin de paris sportifs.",
  "scan.openInApp": "Ouvrir dans l'app dédiée",
  "scan.comebackToScan": "Revenir au scan",

  /** ------------------------------------------------ TABS ----------------------------------------------*/
  "my bets": "Mes paris",
  "my favorites": "Mes favoris",
  "my space": "Mon espace",
  "tab scan": "Scan",

  /** ------------------------------------------------ GAMES ----------------------------------------------*/
  "Find a game": "Cherchez un jeu",
  "Discover": "Découvrir",
  "See all": "Voir tout",
  /** ------------------------------------------------ TICKET ----------------------------------------------*/
  "ticket.my_scanned_tickets": "Mes reçus scannés",
  "ticket.no_ticket_scanned": "Vous n’avez scanné aucun reçu.",
  "ticket.ref_not_found": "Nous n'avons pas trouvé votre ticket.",
  "Ticket": "Ticket",
  "Status": "Statut",
  "Total bet": "Mise totale",
  "Draws made": "Tirages effectués",
  "Draws pending": "Tirages restants",
  "Total gains": "Gains cumulés",

  "Game type": "Type de jeu",
  "Bet": "Mise",
  "Odd": "Cote",
  "Gains": "Gains",
  "Gains nets": 'Gains nets',
  "Gain": "Gain",
  "Draw": "Tirage",
  "Selection": "Sélection",

  "Expired": "Expiré",
  "Lost": "Perdu",
  "Won": "Gagné",
  "Pending": "En cours",
  "Finished": "Terminé",
  "Canceled": "Annulé",
  "Price": "Prix",
  "Draw results": "Résultats du tirage",
  "Your selection": "Votre sélection",
  "Winning combinations": "Combinaisons gagnantes",
  "Rank": "Rang",

  "Potential Win": "Gains potentiels",
  "Net Potential Win": "Gains potentiels nets",
  "Bets settled": "Paris réglés",
  "Details": "Détails",
  "Completed matches": "Matchs terminés",
  "Session": "Session",
  "Result": "Résultat",
  "Bet Blocked": "Un ou plusieurs paris sont bloqués",
  "Blocked": "Bloqué",

  /** ------------------------------------------------ USER ----------------------------------------------*/
  "user.personal_info": "Mes informations personnelles",

  /** ------------------------------------------------ MY SPACE ----------------------------------------------*/
  "My Parameters": "Mes paramètres",
  "My Favourites": "Mes favoris",
  "My transactions": "Mes transactions",
  "Find a sale point": "Trouver un point relais",
  'Games Rules': 'Règles des jeux',
  'Game rule': 'Règles du',
  'Help': 'Aide',
  "Favorite Games": "Jeux favoris",
  "Favorite Grid": "Grilles favorites",
  "Parameters": "Paramètres",
  'Games rules': 'Règles des jeux',
  'Legal notices': "Mentions légales",
  'No legal notices': 'Pas de mentions légales',
  'Application languages': "Langue de l'application",
  "fr": "Français",
  "en": "Anglais",
  "pt": "Portuguais",
  "Dark mode": "Mode nuit",
  'Paytable': 'Tableau des gains',
  'No Game Rules': 'Pas de règles du jeu',
  'Choose a game': 'Choisis un jeu',
  'Empty section': 'Cette section est vide',
  'Good numbers': "Bons numéros",
  'Jackpot': 'Cagnotte',
  'x the bet': 'x la mise',
  'Contact': 'Contact',
  'Version': 'Version',
  'No app version': "Pas de version d'app renseigné",
  'How to play': 'Comment jouer',
  "PaytableMessage": "{{totalFoundNumbers}} bon numéro entre la {{minRange}}° et la {{maxRange}}° boule tirée",
  "PaytableMessage.multiple": "{{totalFoundNumbers}} bons numéros entre la {{minRange}}° et la {{maxRange}}° boule tirée",
  "PaytableMessage.isInOrder": "{{totalFoundNumbers}} bon numéro entre la {{minRange}}° et la {{maxRange}}° boule tirée, dans l'ordre",
  "PaytableMessage.multiple.isInOrder": "{{totalFoundNumbers}} bons numéros entre la {{minRange}}° et la {{maxRange}}° boule tirée, dans l'ordre",
  "Change password": "Changer de mot de passe",
  'Change the password': 'Modifier le mot de passe',
  'Actual password': 'Mot de passe actuel',
  'New password': 'Nouveau mot de passe',
  'Confirm new password': 'Confirmez le nouveau mot de passe',
  'Password saved': 'Mot de passe enregistré.',
  "Day": "Quotidienne",
  "Week": "Hebdomadaire",
  "Month": "Mensuelle",
  "LifeTime": "À vie",
  "Period": "Période",
  "Amount": "Montant",
  "Inactive": "Inactive",
  "Delete the limit": "Supprimer la limite",
  "No limit": "Aucune limite",
  "Bet limit": "Limite de pari",
  "Deposit limit": "Limite de dépôt",
  "Time limit": "Limite de temps",
  "Temporary exclusion": "Exclusion temporaire",
  "Not defined": "Non défini",
  "Time limit passed": "Limite de temps passé",
  "My limits": "Mes limites",
  "7 days": "7 jours",
  "Limit Amount": "Montant de la limite",
  "Deposit money": "Déposer de l'argent",
  "Choose payment method": "Choisissez un moyen de paiement",
  /** ------------------------------------------------ BASKET ----------------------------------------------*/
  "basket.all_bets_expired": "Tous vos paris ont expiré",
  "basket.back_to_basket": "Revenir au panier",
  "basket.empty": "Votre panier est vide",
  "basket.max_bet_exceeded": "La mise totale excède le montant autorisé",
  "basket.my_basket": "Mon panier",
  "basket.remove_expired_items": "Supprimez les paris expirés afin de valider le panier",
  "Cancel bet": "Abandonner le pari ?",
  "Cancel bet content": "Attention, le contenu de votre panier va être effacé.",
  "Bet expired": "Pari expiré",
  "See details": "Voir les détails",
  "Nb of draws": "Nombre de tirages",
  "Add a bet": "Ajouter un pari",
  "Max potential gain": "Gain max potentiel",
  'Net potential gain': 'Gain net potentiel',
  "Enter phone number": "Saisissez votre numéro de téléphone",
  "Remember me": "Se souvenir de moi",
  "Bet's validated": "Votre pari est validé",
  "Find e-bulletin": "Retrouvez votre e-bulletin dans 'Mes paris'",
  /** ------------------------------------------------ CONNEXION ----------------------------------------------*/
  "Phone number": "Numéro de téléphone",
  "Continue": "Continuer",
  "Not registered": "Pas encore inscrit ?",
  "Sign up": "Inscrivez-vous",
  "Password": "Mot de passe",
  "Invalid login details": "Numéro de téléphone ou mot de passe incorrect",
  "Customer is already logged": "Le client est déjà connecté",
  "Player disabled or blocked": "Compte désactivé ou bloqué",
  "Logout": "Déconnexion",
  "Disable Account": "Désactiver le compte",
  "Account Not Activated Title": "Votre compte est désactivé.",
  "Account Not Activated": "Votre compte est désactivé. Veuillez contacter le support.",
  "Confirm_deactivation.title": "Confirmer la désactivation ?",
  "Confirm_deactivation.message": "Attention, vous ne pourrez plus vous connecter. Pour réactiver votre compte, vous devrez contacter l'équipe de support.",
  "deactivation_error_retry": "Il y a un problème avec la désactivation, réessayer plus tard s'il vous plait.",
  "Login/Register": "Connexion/Inscription",
  "Balance": "Solde",
  "Deposit": "Déposer",
  "Withdraw": "Retirer",
  "Transactions": "Transactions",
  "CGU text": "Je certifie avoir plus de 18 ans et avoir pris connaissance des ",
  "Already registered": "Déjà inscrit ?",
  "Login": "Connectez-vous",
  "Name": "Nom",
  "Password confirmation": "Confirmation du mot de passe",
  "Password error 2": "Le mot de passe doit comporter au moins 6 caractères",
  "send_verification_code": "Envoyer le code de vérification",
  "Duplicate Phone.": "Le numéro saisi est déjà utilisé.",
  "Welcome": "BIENVENUE !",
  "password.error.mismatch": "Les mots de passe ne sont pas identiques",
  "password.forgot": "Mot de passe oublié",
  "password.reset.title": "Réinitialisation du mot de passe",
  /** ------------------------------------------------ USER INFO --------------------------------------------*/
  "address": "Adresse",
  "birthDate": "Date de naissance",
  "city": "Ville",
  "coords": "Coordonnées",
  "country": "Pays",
  "currency": "Devise",
  "firstName": "Prénom",
  "lastName": "Nom",
  "phone": "Téléphone",
  "postCode": "Code postal",
  /** ---------------------------------------------- FORM EDITION   ------------------------------------------ */
  /*    (build from the field translation key and the suffix '.edition')                                       */
  "address.edition": "Modification de l'adresse",
  "birthDate.edition": "Modification de la date de naissance",
  "firstName.edition": "Modification du prénom",
  "lastName.edition": "Modification du nom",
  /** ----------------------------------------- POPUPS & GENERIC MESSAGES ---------------------------------------*/
  "changes_saved": "Modifications enregistrées.",
  "popup.unsaved_changes.title": "Les modifications n’ont pas été enregistrées",
  "popup.unsaved_changes.confirm_exit": "Quitter sans enregistrer ?",
  "save_changes": "Enregistrer les modifications",

  'setnr=1': "1er",
	'setnr=2': "2ème",
	'setnr=3': "3ème",
	'setnr=4': "4ème",
	'setnr=5': "5ème",
	'quarternr=1': "1er",
	'quarternr=2': "2ème",
	'quarternr=3': "3ème",
	'quarternr=4': "4ème",
}