import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View, Text, StyleSheet, Platform } from 'react-native';
import { commonStyles } from '../../common/style/common.style';
import { SVGKeyboard, SVGQrCode2 } from '../../assets/icons';
import AlertPopup from '../../components/kpLibrary/AlertPopup.comp';


const ScanPage = ({ route }) => {

  const navigation = useNavigation();
  const { t } = useTranslation();
  const style = makeStyles(useTheme())

  const isWeb = Platform.OS === "web";
  const [isShowMobileAppAlert, setShowMobileAppAlert] = useState(false);

  const onQRScanClick = () => {
    isWeb ? setShowMobileAppAlert(true) : navigation.navigate('ScanPreview');
  }

  const onManualScanClick = () => {
    setShowMobileAppAlert(false);
    navigation.navigate('ScanManual');
  }


  return (
    <View style={style.container}>

      <View style={style.box}>
        <Text style={style.text_scan_label}>
          {t('scan.scan_ticket_or_bulletin')}
        </Text>

        <Pressable
          style={({ pressed }) => {
            return [style.button, style.button_qrcode, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={onQRScanClick}
        >
          <SVGQrCode2
            fill="#fff"
            style={{
              marginRight: 16,
              position: 'absolute',
              left: 20,
              width: 24,
              height: 24,
            }}
          />
          <Text style={style.button_qrcode_text}>
            {t('scan.button_qrcode')}
          </Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => {
            return [style.button, style.button_code, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={onManualScanClick}
        >
          <SVGKeyboard
            fill="#000"
            style={{
              marginRight: 16,
              position: 'absolute',
              left: 20,
              width: 24,
              height: 24,
            }}
          />
          <Text style={style.button_code_text}>
            {t('scan.button_code')}
          </Text>
        </Pressable>
      </View>

      <AlertPopup
        type={'info'}
        visible={isShowMobileAppAlert}
        message={t('Scan unavailable')}
        onClose={() => setShowMobileAppAlert(false)}
        onAction1={onManualScanClick}
        textAction1={t('scan.dialog_action_manual_entry')}
      />

    </View>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 50,
    flex: 1
  },
  box: {
    alignItems: 'center'
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20,
    paddingHorizontal: 10,
    margin: 10,
    borderRadius: 4,
    width: '110%'
  },
  button_qrcode: {
    backgroundColor: theme.colors.primary,
  },
  button_code: {
    backgroundColor: "#fff",
  },
  button_qrcode_text: {
    fontSize: 16,
    paddingLeft: 40,
    color: 'white',
    fontFamily: 'Raleway-Bold'
  },
  button_code_text: {
    fontSize: 16,
    paddingLeft: 40,
    color: '#000',
    fontFamily: 'Raleway-Bold'
  },
  text_scan_label: {
    textAlign: 'center',
    fontWeight: '600',
    color: "#444",
    fontSize: 17,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: 'Raleway-Bold'
  },
})

//======================================
export default ScanPage;