import React, { useEffect, useState } from "react";
import { useNavigation, useTheme, useFocusEffect } from '@react-navigation/native';
import { dateFirstFormat } from '../../utils/DateUtils';
import { View, Text, StyleSheet, Image, Pressable, FlatList, ActivityIndicator, useWindowDimensions, Platform } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import screens from "../../common/constants/screen.constants";
import * as GamesActions from '../../store/actions/games.actions';
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/FormatUtils";
import images from "../../assets/images";
import { commonStyles } from "../../common/style/common.style";

const LotteryGameList = () => {

  const navigation = useNavigation();
  const theme = useTheme();
  const style = makeStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const windowWidth = useWindowDimensions().width;
  const [isWeb, setIsWeb] = useState(Platform.OS === "web");
  const [flatListColumns, setFlatListColumns] = useState(2);

  const lottery_games = useSelector(state => state.games.lottery_games);
  const currency = useSelector(state => state.login.currency);
  const nextSessionByGame = useSelector(state => state.games.nextSessionByGame);
  const gameSettings = useSelector(state => state.wordpress.gameSettings);

  const [isLoading, setLoading] = useState(true);

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      GamesActions.getNextSessionByGame();
      setFlatListColumns(isWeb && windowWidth > 700 ? 3 : 2);
      return () => {
        //Page leave
      }
    }, [])
  );

  useEffect(() => {
    setLoading(!lottery_games.length || !Object.keys(nextSessionByGame).length && gameSettings);
  }, [lottery_games, nextSessionByGame]);

  const onGameSelected = (game) => {
    dispatch(GamesActions.set_current_game(game));
    navigation.navigate(screens.BetCreate);
  }

  const renderLoading = () => (
    <View style={style.loading}>
      <ActivityIndicator size="small" />
    </View>
  );

  const renderGameItem = ({ item, index }) => {

    const session = nextSessionByGame[item.gameId];
    const sourceUrlLogo = gameSettings && gameSettings[item.gameId]?.gameLogo;

    return (
      <View style={style.cardContainer}>
        <Pressable
          key={index}
          style={({ pressed }) => {
            return [style.card, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={() => onGameSelected(item)}
        >

          <Image
            style={style.cardImage}
            // resizeMode='stretch'
            source={sourceUrlLogo ? {uri: sourceUrlLogo} : images.game_logo_default}
          /> 

          <View style={style.cardText}>
            <Text style={style.gameName} numberOfLines={1} ellipsizeMode='tail'>{item.name}</Text>
            {session &&
              <React.Fragment>
                <Text style={style.gameGain}>
                  {session.potentialAward && (formatNumber(+session.potentialAward) + ' ' + currency) || '-'}
                </Text>
                <Text style={style.gameDate}>
                  {dateFirstFormat(session.drawingDate)}
                </Text>
              </React.Fragment>
            }
            {!session &&
              <Text style={style.gameNoSession}>
                {t('no_draw_available_short')}
              </Text>
            }
          </View>
        </Pressable>
      </View>
    )
  }

  return (
    <View style={style.container}>

      <View style={style.topContainer}>
        <Text style={style.leftTitle}>
          {t('Our games')}
        </Text>
      </View>

      {isLoading && renderLoading()}
      
      {!isLoading &&
        <View style={style.cardsContainer}>
          <FlatList
            data={lottery_games}
            renderItem={renderGameItem}
            keyExtractor={(item, index) => index}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ justifyContent: 'center' }}
            numColumns={flatListColumns}
          />
        </View>
      }

    </View>
  );

}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    backgroundColor: '#F6F6F6',
    marginTop: 20,
    width: '100%',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 17,
    color: '#404040',
  },
  loading: {
  },
  cardContainer: {
    width: '50%',
    maxWidth: 200,
  },
  card: {
    height: 158,
    borderRadius: 3,
    backgroundColor: "#ffffff",
    margin: 5,
    marginRight: 10,
    marginTop: 10,
  },
  cardImage: {
    height: 84,
    width: '100%',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  cardText: {
    paddingHorizontal: 10,
    paddingTop: 5,
  },
  gameName: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 12,
    paddingBottom: 7
  },
  gameGain: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 13
  },
  gameDate: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 12
  },
  gameNoSession: {
    color: '#404040',
    fontSize: 12,
    fontFamily: 'Raleway-LightItalic',
    paddingTop: 12,
  },
})

export default LotteryGameList;
