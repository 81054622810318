import { useFocusEffect, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGArrowLine } from '../../../assets/icons';
import { useDispatch, useSelector } from "react-redux";
import { View, Text, StyleSheet, Pressable, ScrollView, ActivityIndicator } from 'react-native';
import DateRangePickerModal from '../../../components/kpLibrary/DateRangePickerModal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as UserActions from '../../../store/actions/user.actions';
import { transactionFormat, extractDate } from '../../../utils/DateUtils';
import AlertPopup from '../../../components/kpLibrary/AlertPopup.comp';

const UserTransactions = () => {

  const style = makeStyles(useTheme());
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.user.isFetching);
  const transactions = useSelector(state => state.user.transactions);
  const error = useSelector(state => state.user.error);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  })
  const [filterData, setFilterData] = useState({
    sortExpression: "TransactionDate desc",
    fromDate: "2020-01-16T00:00:00",
    toDate: "2023-01-16T00:00:00",
    param: {
      iDisplayLength: 100
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(UserActions.getUserTransactions(filterData));
      return () => {
        //Page leave
      }
    }, [filterData])
  );

  useEffect(() => {
    setShowErrorPopup(error ? true : false);
  }, [error])

  useEffect(() => {
    setFilterData({
      ...filterData,
      fromDate: date.startDate,
      toDate: "2023-01-16T00:00:00",
    })
  }, [date])

  useEffect(() => {
    setFilterData({
      ...filterData,
      typeId: selectedTabIndex
    })
  }, [selectedTabIndex]);

  const onFilterPeriodApply = ({ startDate, endDate }) => {
    setShowDatePicker(false);
    const dthAfter = startDate?.concat('T00:00:00');
    const isEndDateToday = endDate === extractDate(new Date().toISOString());
    const dthBefore = isEndDateToday ? new Date().toISOString().slice(0, -1) : endDate?.concat('T23:59:59') || dthAfter;
    setDate({
      startDate: dthAfter,
      endDate: dthBefore
    })
  }

  const renderLoading = () => (
    <View>
      <ActivityIndicator size="small" />
    </View>
  );

  return (
    <ScrollView style={style.container}>

      <View style={style.tabBar}>
        <Pressable
          style={[style.tabButton, selectedTabIndex == 0 && style.tabButtonSelected]}
          onPress={() => setSelectedTabIndex(0)}
        >
          <Text style={[style.tabButtonText, selectedTabIndex == 0 && style.tabButtonTextSelected]}>
            {t('all.fem')}
          </Text>
        </Pressable>
        <Pressable
          style={[style.tabButton, selectedTabIndex == 1 && style.tabButtonSelected]}
          onPress={() => setSelectedTabIndex(1)}
        >
          <Text style={[style.tabButtonText, selectedTabIndex == 1 && style.tabButtonTextSelected]}>
            {t('Deposits')}
          </Text>
        </Pressable>
        <Pressable
          style={[style.tabButton, selectedTabIndex == 2 && style.tabButtonSelected]}
          onPress={() => setSelectedTabIndex(2)}
        >
          <Text style={[style.tabButtonText, selectedTabIndex == 2 && style.tabButtonTextSelected]}>
            {t('Withdrawals')}
          </Text>
        </Pressable>
      </View>

      <View style={style.filterBar}>
        <Pressable
          style={[style.filterButton, date.endDate && style.filterButtonSelected]}
          onPress={() => setShowDatePicker(true)}
        >
          <Text style={[style.filterText, date.endDate && style.filterTextSelected]}>
            {date.endDate && <Icon name={'check'} style={style.filterCheckmarkIcon} />}
            {t('dates')}
          </Text>
        </Pressable>
      </View>

      {isFetching ?
        renderLoading()
        :
        !transactions || transactions?.length === 0 ?
          <View>
            <Text style={style.noResultText}>
              {t('no_result_found')}
            </Text>
          </View>
          :
          <View>
            {
              transactions?.map((elem, index) => (
                <View key={index}>
                  <View style={style.transactionLine}>
                  <View style={style.transactionLineLeft}>
                    <View style={elem.TransactionTypeID === 1 ? style.actionBubble : style.actionBubble2 }>
                      <SVGArrowLine
                        fill='#ffffff'
                        style={{ transform: [{ rotate: elem.TransactionTypeID === 1 ? '90deg' : '270deg' }] }}
                      />
                    </View>
                    <View style={style.infosContainer}>
                      {elem.paymentMethodID && <Text style={style.textInfos1}>{elem.paymentMethodID}</Text>}
                      <Text style={style.textInfos2}>{t(elem.TransactionTypeID === 1 ? 'deposit' : 'Withdrawal')}</Text>
                      <Text style={style.textInfos3}>{transactionFormat(elem.TransactionDate)}</Text>
                      <Text style={style.textInfos4}>{`${t('Voucher')} n°${elem.ID}`}</Text>
                    </View>
                  </View>
                  <View style={style.transactionLineRight}>
                    <Text style={style.amountText}>{elem.TransactionTypeID === 1 ? '+' : '-'} {elem.Amount} {elem.CurrencyISO}</Text>
                  </View>
                </View>
                <View style={style.separator}></View>
              </View>
              ))
            }
          </View>
      }

      

      <DateRangePickerModal
        visible={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        onApply={onFilterPeriodApply}
        defaultStartDate={date.startDate}
        defaultEndDate={date.endDate}
      />
      
      <AlertPopup
        visible={showErrorPopup}
        onClose={() => setShowErrorPopup(false)}
        title={t("error.generic")}
        type={'error'}
        onAction1={() => setShowErrorPopup(false)}
        textAction1={'Ok'}
      />
    </ScrollView>
  );
};

export default UserTransactions;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flex: 1,
    backgroundColor: '#F6F6F6'
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    padding: 1,
    marginVertical: 5,
    borderRadius: 5,
    borderColor: theme.colors.primary,
    borderWidth: 2,
  },
  tabButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: 10,
    paddingLeft: 2,
    width: '33.33%',
    marginTop: 1
  },
  tabButtonSelected: {
    backgroundColor: theme.colors.primary,
    borderRadius: 4,
  },
  tabButtonText: {
    color: theme.colors.primary,
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
  },
  tabButtonTextSelected: {
    color: '#fff',
  },
  noResultText: {
    color: theme.colors.greyText,
    fontSize: 13,
    fontFamily: 'Raleway-Regular',
    textAlign: 'center'
  },
  transactionLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 90,
    paddingHorizontal: 15,
    marginTop: 10
  },
  transactionLineLeft: {
    flexDirection: 'row',
  },
  transactionLineRight: {
    paddingTop: 15
  },
  amountText: {
    color: theme.colors.greyText,
    fontSize: 15,
    textTransform: 'uppercase',
    fontFamily: 'Raleway-SemiBold'
  },
  actionBubble: {
    backgroundColor: theme.colors.greyText,
    borderRadius: 50,
    width: 44,
    height: 44,
    marginBottom: 13,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionBubble2: {
    backgroundColor: theme.colors.primary,
    borderRadius: 50,
    width: 44,
    height: 44,
    marginBottom: 13,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  infosContainer: {
    justifyContent: 'space-around',
    marginBottom: 10
  },
  textInfos1: {
    color: theme.colors.greyText,
    fontSize: 13,
    textTransform: 'uppercase',
    fontFamily: 'Raleway-SemiBold'
  },
  textInfos2: {
    color: theme.colors.greyText,
    fontSize: 13,
    fontFamily: 'Raleway-Regular',
    textTransform: 'uppercase',
  },
  textInfos3: {
    color: theme.colors.greyText,
    fontSize: 13,
    fontFamily: 'Raleway-SemiBold'
  },
  textInfos4: {
    color: theme.colors.greyText,
    fontSize: 13,
    fontFamily: 'Raleway-Regular'
  },
  // FILTER
  filterBar: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    alignItems: 'center',
    position: 'relative',
    marginBottom: 20
  },
  filterClearButton: {
    backgroundColor: theme.colors.primary,
    borderWidth: 0,
    position: 'absolute',
    right: 0,
    marginRight: 0,
  },
  filterClearText: {
    fontSize: 12,
    color: '#fff',
    padding: 5,
    fontFamily: 'Raleway-Bold'
  },
  filterButton: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 5,
    borderColor: theme.colors.greyText,
    borderWidth: 1,
    marginRight: 5,
    padding: 5,
    minWidth: 80,
    minHeight: 36,
  },
  filterButtonSelected: {
    backgroundColor: theme.colors.primary,
    borderRadius: 4,
    borderColor: theme.colors.primary,
  },
  filterText: {
    color: theme.colors.greyText,
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterTextSelected: {
    color: '#ffffff',
  },
  filterCheckmarkIcon: {
    fontSize: 20,
    paddingRight: 2,
  },
  separator: {
    width: '95%',
    margin: 'auto',
    height: 1,
    backgroundColor: theme.colors.greyText
  }
});
