import { StyleSheet, Text, View, ScrollView, Image } from "react-native";
import { useFocusEffect, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSolde from "../../../components/kpLibrary/UserSolde.comp";
import { t } from "i18next";
import * as UserActions from '../../../store/actions/user.actions';
import RadioButton from "../../../components/kpLibrary/RadioButton.comp";
import StyledTextInput from "../../../components/kpLibrary/forms/StyledTextInput.comp";

export default function DepositPaymentMethods() {

  const style = makeStyles(useTheme());
  const dispatch = useDispatch();
  const paymentProviders = useSelector(state => state.user.transactionDepositPaymentProviders)?.filter((elem) => {
    return elem.PaymentProviderID === 1002;
  });
  // Note: when we'll get multiple payment methods, add this state in every item
  const [radioBtnPressed, onRadioBtnPressed] = useState(false);
    const [voucherInfos, setVoucherInfos] = useState(null);

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(UserActions.getTransactionDepositPaymentProviders());
      return () => {
        //Page leave
      }
    }, [])
  );

  useEffect(() => {
    paymentProviders && setVoucherInfos(paymentProviders[0]?.PaymentProviderMethods?.filter(elem => elem.Description === 'Voucher')[0])
  }, [paymentProviders])

  return (
    <ScrollView style={style.container}>
      <UserSolde />
      <Text style={style.title}>{t('Choose payment method')}</Text>
      <View style={style.paymentMethodContainer}>
        {voucherInfos && 
          <View style={style.paymentMethodPressable}>
            <View style={style.leftPart}>
              <RadioButton selected={radioBtnPressed} onSelected={onRadioBtnPressed} />
              <Image style={style.image} source={voucherInfos?.PaymentProviderMethodLogoURL} />          
            </View>
            <View style={style.rightPart}>
              <Text style={style.paymentMethodTitle}>{voucherInfos?.Description}</Text>
            </View>
            {radioBtnPressed && 
              <View style={style.paymentMethodPressableOpen}>
                <StyledTextInput />
              </View>
            }
          </View>
        }
      </View>
    </ScrollView>
  );
}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: '#F6F6F6'
  },
  title: {
    fontSize: 15,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    marginLeft: 10,
    marginBottom: 25
  },
  paymentMethodContainer: {
    flexDirection: 'column'
  },
  paymentMethodPressable: {
    backgroundColor: '#ffffff',
    height: 46,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginBottom: 5
  },
  paymentMethodPressableOpen: {
    backgroundColor: '#ffffff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginBottom: 5
  },
  image: {
    resizeMode: 'contain',
    height: 20,
    width: 60,
    marginLeft: 10
  },
  paymentMethodTitle: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    fontSize: 13
  },
  leftPart: {
    width: '40%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightPart: {
    width: '60%',
    flexDirection: 'row',
    alignItems: 'center',
  }
});
