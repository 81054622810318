import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SVGLotteryBall = (props) => (
  <Svg
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={0}
    {...props}
  >
    <Path
      className="cls-1"
      d="M14.82 18.76a10 10 0 0 1-9.64 0 5.61 5.61 0 0 1 9.64 0ZM5.15 1.25a10 10 0 0 1 9.7 0A5.6 5.6 0 0 1 10 4a5.6 5.6 0 0 1-4.85-2.75ZM1.26 14.87A10 10 0 0 1 0 10a9.84 9.84 0 0 1 1.23-4.8A5.61 5.61 0 0 1 4 10a5.63 5.63 0 0 1-2.74 4.87Z"
    />
    <Path
      className="cls-1"
      d="M15.47 10.06a6.14 6.14 0 0 1 3-5.31 9.91 9.91 0 0 0-3.17-3.23 6.14 6.14 0 0 1-5.3 3 6.13 6.13 0 0 1-5.3-3 9.91 9.91 0 0 0-3.21 3.23 6.14 6.14 0 0 1 3 5.31 6.13 6.13 0 0 1-3 5.27 10 10 0 0 0 3.18 3.16 6.15 6.15 0 0 1 10.56 0 10.1 10.1 0 0 0 3.18-3.16 6.13 6.13 0 0 1-2.94-5.27Zm-3.16 2.59a2.19 2.19 0 0 1-.91.9 2.8 2.8 0 0 1-1.39.32 3.23 3.23 0 0 1-1.34-.29 2.53 2.53 0 0 1-1-.81 2 2 0 0 1-.34-1.16h1.78a1 1 0 0 0 .26.66.82.82 0 0 0 .63.25c.59 0 .88-.44.88-1.31S10.52 10 9.8 10a1.07 1.07 0 0 0-.91.39l-1.4-.32L8 6.19h4.42v1.36h-3l-.21 1.51a1.93 1.93 0 0 1 .49-.19 2.08 2.08 0 0 1 .6-.09 2.23 2.23 0 0 1 1.7.67 2.68 2.68 0 0 1 .61 1.88 2.69 2.69 0 0 1-.3 1.32Z"
    />
    <Path
      className="cls-1"
      d="M20 10a9.9 9.9 0 0 1-1.27 4.87 5.61 5.61 0 0 1 0-9.68A10 10 0 0 1 20 10ZM14.82 18.76a10 10 0 0 1-9.64 0 5.61 5.61 0 0 1 9.64 0ZM14.85 1.25A5.6 5.6 0 0 1 10 4a5.6 5.6 0 0 1-4.85-2.75 10 10 0 0 1 9.7 0ZM4 10a5.63 5.63 0 0 1-2.75 4.83A10 10 0 0 1 0 10a9.84 9.84 0 0 1 1.23-4.8A5.61 5.61 0 0 1 4 10Z"
    />
    <Path
      className="cls-1"
      d="M20 10a9.9 9.9 0 0 1-1.27 4.87 5.61 5.61 0 0 1 0-9.68A10 10 0 0 1 20 10ZM14.85 1.25A5.6 5.6 0 0 1 10 4a5.6 5.6 0 0 1-4.85-2.75 10 10 0 0 1 9.7 0ZM14.82 18.76a10 10 0 0 1-9.64 0 5.61 5.61 0 0 1 9.64 0Z"
    />
    <Path
      className="cls-1"
      d="M20 10a9.9 9.9 0 0 1-1.27 4.87 5.61 5.61 0 0 1 0-9.68A10 10 0 0 1 20 10ZM4 10a5.63 5.63 0 0 1-2.75 4.83A10 10 0 0 1 0 10a9.84 9.84 0 0 1 1.23-4.8A5.61 5.61 0 0 1 4 10Z"
    />
  </Svg>
)

export default SVGLotteryBall;