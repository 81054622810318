import { useTheme } from '@react-navigation/native';
import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, Pressable } from 'react-native';
import AlertPopup from '../kpLibrary/AlertPopup.comp';

const BetCreateExitModal = ({
  visible, onClose, onConfirm,
  onAlternativeAction, alternativeActionText,
}) => {

  const theme = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  return (
    <AlertPopup
      type={'warning'}
      visible={visible}
      onClose={onClose}
      title={t('Cancel bet')}
      message={t('Cancel bet content')}
      textAction1={t('no')}
      onAction1={onClose}
      textAction2={t('yes')}
      onAction2={onConfirm}
      useInlineButtons={true}
      actionFooter={
        onAlternativeAction &&
        <Pressable style={styles.alternativeButton} onPress={() => onAlternativeAction()}>
          <Text style={styles.alternativeButtonText}>
            {alternativeActionText}
          </Text>
        </Pressable>
      }
    />
  )
};

const makeStyles = (theme) => StyleSheet.create({
  alternativeButton: {
    padding: 15,
    textAlign: 'center',
  },
  alternativeButtonText: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    textTransform: 'uppercase',
  },
});

export default BetCreateExitModal;