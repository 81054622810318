import * as service from "../services/user.service";
import AsyncStorage from '@react-native-async-storage/async-storage';

export const ACTIONS = {
  USER_ERROR: 'USER_ERROR',
  USER_RESET_ERROR: 'USER_RESET_ERROR',
  USER_FETCHING: 'USER_FETCHING',
  USER_FETCHING_DONE: 'USER_FETCHING_DONE',
  //
  USER_ACCOUNT_UPDATE_FORM: 'USER_ACCOUNT_UPDATE_FORM',
  USER_INFO: 'USER_INFO',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_REGISTER_FAILED: 'USER_REGISTER_FAILED',
  USER_REGISTER_RESETERROR: 'USER_REGISTER_RESETERROR',
  USER_SEARCHED: 'USER_SEARCHED',
  USER_TOKEN: 'USER_TOKEN',
  CHANGED_PASSWORD: 'CHANGED_PASSWORD',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  USER_GET_LIMITS: 'USER_GET_LIMITS',
  SET_USER_TRANSACTIONS: 'SET_USER_TRANSACTIONS',
  SET_TRANSACTION_PAYMENT_PROVIDERS: 'SET_TRANSACTION_PAYMENT_PROVIDERS'
}

export const STORAGE_USER_TOKEN_INFO = 'ONLINE_USER_TOKEN_INFO';

// Dispatchers

// Lifecycle
export const set_state_error = (err) => {
  return { type: ACTIONS.USER_ERROR, payload: err };
}
export const reset_error = () => {
  return { type: ACTIONS.USER_RESET_ERROR };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.USER_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.USER_FETCHING_DONE };
}
//
export const set_account_update_form = (data) => {
  return { type: ACTIONS.USER_ACCOUNT_UPDATE_FORM, payload: data };
}
export const set_user_connected = (data) => {
  return { type: ACTIONS.USER_LOGIN_SUCCESS, payload: data };
}

export const set_user_disconnected = () => {
  return { type: ACTIONS.USER_LOGOUT };
}

export const set_user_info = (data) => {
  return { type: ACTIONS.USER_INFO, payload: data };
}

export const set_user_token = (data) => {
  return { type: ACTIONS.USER_TOKEN, payload: data };
}
export const set_searched_user = (data) => {
  return { type: ACTIONS.USER_SEARCHED, payload: data };
}

export const setRegisterFailed = (err) => {
  return { type: ACTIONS.USER_REGISTER_FAILED, payload: err };
}

export const reset_register_error = () => {
  return { type: ACTIONS.USER_REGISTER_RESETERROR };
}

export const set_password_changed = (isChanged) => {
  return { type: ACTIONS.CHANGED_PASSWORD, payload: isChanged };
}

export const change_password_error = (errors) => {
  return { type: ACTIONS.CHANGE_PASSWORD_ERROR, payload: errors };
}

export const reset_password_error = () => {
  return { type: ACTIONS.RESET_PASSWORD_ERROR };
}

export const user_disable_account = () => {
  return { type: ACTIONS.USER_DISABLE_ACCOUNT };
}

export const get_user_limits = (data) => {
  return { type: ACTIONS.USER_GET_LIMITS, payload: data };
}

export const set_user_transactions = (data) => {
  return { type: ACTIONS.SET_USER_TRANSACTIONS, payload: data };
}

export const set_transaction_payment_providers = (data) => {
  return { type: ACTIONS.SET_TRANSACTION_PAYMENT_PROVIDERS, payload: data };
}


// ACTIONS

/**
 * Goal : restore the connected mode with the user data (if the token is found in the storage)
 */
export const initialize = () => {
  return async (dispatch) => {
    try {
      const tokenInfoStr = await AsyncStorage.getItem(STORAGE_USER_TOKEN_INFO);
      const tokenInfo = tokenInfoStr ? JSON.parse(tokenInfoStr) : null;
      if (tokenInfo && tokenInfo.token && tokenInfo.internalID) {
        dispatch(set_user_token(tokenInfo.token));
        dispatch(getUserInfo(tokenInfo.token, tokenInfo.internalID, /*disconnectOnError=*/true));
      }
    } catch (err) {
      //Note: no trackError here, firebase may not be initialized
      console.warn("failed to initialize User Actions", err);
    }
  }
}

export const connectUser = (login, password) => {
  return async (dispatch) => {
    try {
      dispatch(set_state_fetching());
      let r = await service.connectUser(login, password);
      if (r.data?.successful) {
        dispatch(set_user_connected(r.data));
      } else {
        dispatch(set_state_error(r.data));
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const disconnectUser = () => {
  return async (dispatch, getState) => {
    const internalID = getState().user?.customer?.account?.internalID;
    try {
      await service.disconnectUser(internalID);
      dispatch(set_user_disconnected());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const registerAndConnect = (forms) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const currencyISO = getState().login.currency;
      let r = await service.registerUser(forms, currencyISO);
      if (r.data.isSuccessful) {
        dispatch(connectUser(forms.phoneNumber, forms.password));
      } else {
        dispatch(setRegisterFailed(r.data.errors))
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

/**
 * Request the info of the user attached to the given token/internalID.
 * In case of success, we set the user state to "connected" and load/refresh the user data.
 */
export const getUserInfo = (token, internalID, disconnectOnError = false) => {
  return async (dispatch) => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getUserInfo(token, internalID);
      if (r.data.successful) {
        dispatch(set_user_info(r.data));
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(r.data));
        disconnectOnError && dispatch(set_user_disconnected());
      }
    } catch (err) {
      dispatch(set_state_error(err));
      disconnectOnError && dispatch(set_user_disconnected());
    }
  }
}

export const updateUser = (userRequest, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const token = getState().user?.token;
      let r = await service.updateUser(token, userRequest);
      if (r.data.isSuccessful) {
        //Note: directly request the updated user info
        const internalID = getState().user.customer?.account?.internalID;
        await dispatch(getUserInfo(token, internalID));
        callback && callback(true);
      } else {
        dispatch(set_state_error(r.data));
        callback && callback(false);
      }
    } catch (err) {
      dispatch(set_state_error(err));
      callback && callback(false);
    }
  }
}

export const searchUser = ({ phoneNumber }) => {
  return async (dispatch) => {
    try {
      dispatch(set_searched_user(null));
      dispatch(set_state_fetching());
      let r = await service.searchUser({ phoneNumber });
      if (r.data.isSuccessful) {
        dispatch(set_searched_user(r.data));
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(r.data));
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const changePassword = (fields) => {
  return async (dispatch, getState) => {
    const internalID = getState().user?.customer?.account?.internalID;
    const token = getState().user?.token;
    try {
      dispatch(set_state_fetching());
      let r = await service.changePassword(fields, internalID, token);
      if (r.data.isSuccessful) {
        dispatch(set_password_changed(true))
        dispatch(change_password_error(''));
      } else {
        dispatch(set_password_changed(false))
        dispatch(change_password_error(r.data));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const resetPasswordByCode = (dateOfBirth, email, password, autoLogin = true) => {
  return async (dispatch) => {
    try {
      dispatch(set_state_fetching());
      //1. Get the reset code
      let rResetCode = await service.getPasswordResetCode({ dateOfBirth, email });
      if (rResetCode.data.isSuccessful == false) {
        dispatch(set_state_error(r.data));
        return;
      }
      const passwordResetCode = rResetCode.data.passwordResetCode;
      //2. Reset the password
      let r = await service.resetPasswordByCode({ password, passwordResetCode });
      if (r.data.isSuccessful == false) {
        dispatch(set_state_error(r.data));
        return;
      }
      //3. AutoLogin
      if (autoLogin && r.data.customer) {
        const username = r.data.customer?.Account.Username;
        dispatch(connectUser(username, password));
      } else {
        dispatch(set_state_fetching_done());
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getUserLimits = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const internalID = getState().user.customer?.account?.internalID;
      const token = getState().user?.token;
      let r = await service.getUserLimits(token, internalID);
      if (r.data.successful) {
        dispatch(get_user_limits(r.data?.limits));
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(r.data));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const onCreateUserLimit = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const token = getState().user?.token;
      let r = await service.onCreateUserLimit(token, data);
      if (r.data.successful) {
        dispatch(set_state_fetching_done());
        callback && callback(true);
      } else {
        dispatch(set_state_error(r.data));
        callback && callback(false);
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
      callback && callback(false);
    }
  }
}

export const onModifyUserLimit = (data, LimitId, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const token = getState().user?.token;
      const internalID = getState().user.customer?.account?.internalID;
      let rDelete = await service.onDeleteUserLimit(token, internalID, LimitId);
      if (rDelete.data.successful) {
        let r = await service.onCreateUserLimit(token, data);
        if (r.data.successful) {
          dispatch(set_state_fetching_done());
          callback && callback(true);
        } else {
          dispatch(set_state_error(r.data));
          callback && callback(false);
        }
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(rDelete.data));
        callback && callback(false);
      }
    } catch (err) {
      dispatch(set_state_error(err));
      callback && callback(false);
    }
  }
}

export const onDeleteUserLimit = (limitId, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const internalID = getState().user.customer?.account?.internalID;
      const token = getState().user?.token;
      let r = await service.onDeleteUserLimit(token, internalID, limitId);
      if (r.data.successful) {
        dispatch(set_state_fetching_done());
        callback && callback(true);
      } else {
        dispatch(set_state_error(r.data));
        callback && callback(false);
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
      callback && callback(false);
    }
  }
}

export const onDisableUserAccount = (callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const internalID = getState().user.customer?.account?.internalID;
      const token = getState().user?.token;
      let r = await service.onDisableUserAccount(token, internalID);
      if (r.data.isSuccessful) {
        dispatch(reset_error());
        dispatch(set_user_disconnected());
        dispatch(set_state_fetching_done());
        callback && callback(true);
      } else {
        dispatch(set_state_error(r.data));
        callback && callback(false);
      }
    } catch (err) {
      dispatch(set_state_error(err));
      callback && callback(false);
    }
  }
}

export const getUserTransactions = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const token = getState().user?.token;
      let r = await service.getUserTransactions(token, data);
      if (r.data.FindDepositsWithdrawalsForUserResult.IsSuccessful) {
        dispatch(set_user_transactions(r.data?.FindDepositsWithdrawalsForUserResult.displayedResults));
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(r.data.FindDepositsWithdrawalsForUserResult));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_user_transactions(null));
      dispatch(set_state_error(err));
    }
  }
}

export const getTransactionDepositPaymentProviders = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(set_state_fetching());
      const token = getState().user?.token;
      let r = await service.getTransactionDepositPaymentProviders(token);
      if (r.data.successful) {
        dispatch(set_transaction_payment_providers(r.data?.PaymentProviders));
        dispatch(set_state_fetching_done());
      } else {
        dispatch(set_state_error(r.data));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}