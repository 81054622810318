import { useFocusEffect, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View, Text, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as betCreatorActions from '../../store/actions/betCreator.actions';
import moment from "moment";
import { GameRulesLink } from './GameRulesLink.comp';
import StyledButton from '../kpLibrary/forms/StyledButton.comp';
import { commonStyles } from '../../common/style/common.style';

const SessionPicker = ({
  goNextStep
}) => {

  const { t } = useTranslation();
  const theme = useTheme()
  const styles = makeStyles(theme)
  const dispatch = useDispatch();

  const currentGame = useSelector(state => state.games.currentGame);
  const currentSessions = useSelector(state => state.games.currentSessions);
  const selectedSessions = useSelector(state => state.betCreator.selectedSessions);

  const [allowMultiSession, setAllowMultiSession] = useState(false);


  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      //Note: reset the selection when entering the session picker
      dispatch(betCreatorActions.set_sessions_selected([]));
    }, [])
  );

  useEffect(() => {
    // multiSession only for instant lottery games
    setAllowMultiSession(currentGame.gameType == 'instantLottery');
  }, [currentGame])


  //====================================== Functions

  const onSessionSelectChange = (session) => {
    if (allowMultiSession) {
      const up_selectedSessions = { ...selectedSessions };
      if (selectedSessions[session.drawId]) {
        delete up_selectedSessions[session.drawId];
      } else {
        up_selectedSessions[session.drawId] = session;
      }
      dispatch(betCreatorActions.set_sessions_selected(up_selectedSessions));
    }
    else {
      let single_session = {};
      single_session[session.drawId] = session;
      dispatch(betCreatorActions.set_sessions_selected(single_session));
      goNextStep();
    }
  }

  const onValidate = () => {
    if (Object.keys(selectedSessions).length > 0) {
      goNextStep();
    }
  }

  //====================================== Render

  const renderSessionButton = (session, isSelected) => {

    const textStyle = isSelected ? [styles.sessionButtonText, styles.sessionButtonTextSelected] : [styles.sessionButtonText];

    return (
      <Pressable
        key={session.drawId}
        style={({ pressed }) => {
          return [styles.sessionButton, isSelected && styles.sessionButtonSelected, !pressed && commonStyles.shadowedButton];                                                                  
        }}
        onPress={() => onSessionSelectChange(session)}
      >
        <Text style={[...textStyle, styles.sessionButtonText_Date]}>
          {moment(session.drawingDate).format('LL - HH:mm')}
        </Text>
        {session.drawingName &&
          <Text style={[...textStyle, styles.sessionButtonText_Name]}>
            {session.drawingName}
          </Text>
        }
        <Text style={[...textStyle, styles.sessionButtonText_ClosingDate]}>
          {t('end_of_bets')} : {moment(session.closingDate).format('HH:mm')}
        </Text>
      </Pressable>
    )
  }

  return (
    <View style={styles.container}>

      <ScrollView>

        {/* <NextDrawCountdown /> */}

        <View style={styles.body}>

          <GameRulesLink game={currentGame} />

          <Text style={styles.stepTitle}>
            {t(allowMultiSession ? 'select_game_sessions' : 'select_game_session')}
          </Text>

          {currentSessions.list.map(session => (
            renderSessionButton(session, selectedSessions[session.drawId])
          ))}

        </View>

      </ScrollView>

      {allowMultiSession && Object.keys(selectedSessions).length > 0 &&
        <View style={styles.validateContainer}>
          <View style={styles.validateRow}>
            <StyledButton
              onPress={onValidate}
              text={t('validate')}
              buttonStyle={{ marginVertical: 20 }}
            />
          </View>
        </View>
      }
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingHorizontal: 15,
    paddingBottom: 100, // for the validate footer
    flex: 1,
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginBottom: 10,
    color: '#404040',
  },
  //
  sessionButton: {
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingVertical: 15,
    marginHorizontal: 5,
    marginVertical: 3,
    borderRadius: 4,
    elevation: 5,
  },
  sessionButtonSelected: {
    backgroundColor: theme.colors.primary,
  },
  sessionButtonText: {
    color: '#000',
    paddingBottom: 2,
  },
  sessionButtonTextSelected: {
    color: '#fff',
  },
  sessionButtonText_Date: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
  },
  sessionButtonText_Name: {
    fontFamily: 'Raleway-BoldItalic',
    fontSize: 14,
  },
  sessionButtonText_ClosingDate: {
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
  },
  //
  validateContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  validateRow: {
    elevation: 9,
    marginHorizontal: 30,
    backgroundColor: theme.colors.background,
  },
})

//======================================
export default SessionPicker;