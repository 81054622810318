import { useTheme } from '@react-navigation/native';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { SVGArrowDown } from '../../assets/icons';
import moment from "moment";
import { formatNumber } from '../../utils/FormatUtils';
import { useEffect } from 'react';


const EBulletinBetItem = ({ ebulletin, bet }) => {

  const theme = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();


  //====================================== Hooks

  const currency = useSelector(state => state.login.currency);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [drawReference, setdrawReference] = useState('');
  const [drawDateFormatted, setdrawDateFormatted] = useState('');
  const [limitDateFormatted, setlimitDateFormatted] = useState('');
  const [useSpecificDraw, setuseSpecificDraw] = useState(false);

  useEffect(() => {

    if (bet.drawId && !bet.draw) {
      //TODO: request api to get the draw info, then save to storage
    }

    let drawRef = bet.draw?.dailyReference || bet.draw?.alternativeDrawingName || bet.drawId;
    if (!isNaN(drawRef)) drawRef = t('number_abr') + drawRef;
    setdrawReference(drawRef);

    setdrawDateFormatted(formatDate(bet.draw?.drawingDate || ebulletin.firstDrawDate));
    setlimitDateFormatted(formatDate(bet.draw?.closingDate));

    setuseSpecificDraw(Boolean(bet.drawId));

  }, [ebulletin, bet]);



  //====================================== Functions

  const formatDate = (strDate) => {
    return strDate ? moment(strDate).format("ll - LT") : '';
  }


  //====================================== Render
  return (
    <View style={styles.container}>

      {/* Total stake */}
      <View style={styles.summaryItem}>
        <Text style={styles.summaryItemLabel}>{t('Total stake')} :</Text>
        <Text style={styles.summaryItemValue}>{formatNumber(bet.price)} {currency}</Text>
      </View>
      <View style={styles.contentSeparator} />

      {/* Grids */}
      <View style={styles.gridListView}>
        {bet.selectionListByGrid.map((grid, gIndex) => (
          <View style={styles.gridView} key={gIndex}>
            <Text style={styles.gridName}>
              {t('Grid')} {bet.selectionListByGrid.length > 1 ? gIndex == 0 ? 'A' : 'B' : ''}
            </Text>
            <View style={styles.gridSelection}>
              {grid.map((number, nIndex) => (
                <View style={[styles.selectedNumber, gIndex % 2 == 1 && styles.selectedNumberGridB]} key={nIndex}>
                  <Text style={styles.selectedNumberText}>
                    {number}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
      <View style={styles.contentSeparator} />

      {/* Details */}
      <View style={styles.detailsView}>
        {isDetailsExpanded == false &&
          <TouchableOpacity
            style={styles.detailsViewReduced}
            onPress={() => setIsDetailsExpanded(!isDetailsExpanded)}
          >
            <Text style={styles.showDetailsText}>
              {t('See details')}
            </Text>
            <View style={styles.showDetailsIconView}>
              <SVGArrowDown fill={theme.colors.primary} style={isDetailsExpanded && styles.arrowDownRotate} />
            </View>
          </TouchableOpacity>
        }
        {isDetailsExpanded &&
        <View>
            <TouchableOpacity
              style={styles.detailsViewExpanded}
              onPress={() => setIsDetailsExpanded(!isDetailsExpanded)}
            >
            <View style={styles.showDetailsIconView}>
              <SVGArrowDown fill={theme.colors.primary} style={isDetailsExpanded && styles.arrowDownRotate} />
            </View>
          </TouchableOpacity>

            <View style={styles.detailsItem}>
              <Text style={styles.detailsItemName}>{t('Stake per bet')} :</Text>
              <Text style={styles.detailsItemValue}>{formatNumber(bet.stake)} {currency}</Text>
            </View>
            {useSpecificDraw == false &&
              <View style={styles.detailsItem}>
                <Text style={styles.detailsItemName}>{t('Number of draws')} :</Text>
                <Text style={styles.detailsItemValue}>{bet.nbConsecutiveDraws}</Text>
              </View>
            }
            <View style={styles.detailsItem}>
              <Text style={styles.detailsItemName}>{t('Total stake')} :</Text>
              <Text style={styles.detailsItemValue}>{formatNumber(bet.price)} {currency}</Text>
            </View>
            {limitDateFormatted?.length > 0 &&
              <View style={styles.detailsItem}>
                <Text style={styles.detailsItemName}>{t('ebulletin.limit_date')} :</Text>
                <Text style={styles.detailsItemValue}>{limitDateFormatted}</Text>
              </View>
            }
            <View style={styles.detailsItem}>
              <Text style={[styles.detailsItemName, styles.bold]}>{t('Max potential gain')} :</Text>
              <Text style={[styles.detailsItemValue, styles.bold]}>{formatNumber(bet.potentialAward)} {currency}</Text>
            </View>
            <View style={styles.detailsItem}>
              <Text style={[styles.detailsItemName, styles.bold]}>{t('Net potential gain')} :</Text>
              <Text style={[styles.detailsItemValue, styles.bold]}>{formatNumber(bet.potentialAwardNet)} {currency}</Text>
            </View>
            {useSpecificDraw && bet.draw &&
              <React.Fragment>
                <View style={styles.detailsItem}>
                  <Text style={styles.detailsItemName}>{t('Draw')}</Text>
                  <Text style={styles.detailsItemValue}>{drawDateFormatted}</Text>
                </View>
                {bet.draw.drawingName?.length > 0 &&
                  <View style={styles.detailsItem}>
                    <Text style={styles.detailsItemName}></Text>
                    <Text style={styles.detailsItemValue}>{bet.draw.drawingName}</Text>
                  </View>
                }
                <View style={styles.detailsItem}>
                  <Text style={styles.detailsItemName}></Text>
                  <Text style={styles.detailsItemValue}>{drawReference}</Text>
                </View>
              </React.Fragment>
            }

        </View>
        }
      </View>

    </View>
  )

}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    margin: 10,
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  contentSeparator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.background,
  },
  bold: {
    fontFamily: 'Raleway-Bold',
  },
  //Summary
  summaryItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  summaryItemLabel: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Medium',
    fontSize: 13,
  },
  summaryItemValue: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-SemiBold',
    fontSize: 13,
  },
  //Grids
  gridListView: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 4,
  },
  gridView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  gridName: {
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    marginRight: 12,
    flexShrink: 0,
  },
  gridSelection: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  selectedNumber: {
    width: 28,
    height: 28,
    backgroundColor: theme.colors.primary,
    borderRadius: 30,
    marginRight: 3,
    marginBottom: 5,
    paddingBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedNumberGridB: {
    backgroundColor: theme.colors.secondary,
  },
  selectedNumberText: {
    color: '#fff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  //Details
  detailsItem: {
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 8,
  },
  detailsItemName: {
    width: '45%',
    fontFamily: 'Raleway-Medium',
    color: theme.colors.greyText,
    fontSize: 12,
  },
  detailsItemValue: {
    width: '55%',
    fontFamily: 'Raleway-SemiBold',
    color: theme.colors.greyText,
    fontSize: 12,
  },
  detailsItemMultiValue: {
    width: '55%',
  },
  detailsView: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingVertical: 7,
    paddingHorizontal: 15,
  },
  detailsViewExpanded: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  detailsViewReduced: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  showDetailsIconView: {
    backgroundColor: '#F6F6F6',
    width: 24,
    height: 24,
    borderRadius: 20,
  },
  showDetailsText: {
    fontFamily: 'Raleway-Medium',
    fontSize: 13,
  },
  arrowDownRotate: {
    transform: [{ rotate: '180deg'}]
  },

});

export default EBulletinBetItem; 