import { View, Text, StyleSheet, Pressable, TextInput, Platform, Dimensions, TouchableOpacity, ActivityIndicator, Keyboard } from "react-native";
import Checkbox from 'expo-checkbox';
import { useTheme, useFocusEffect, useNavigation, StackActions } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SVGClose, SVGEdit } from "../../assets/icons";
import screens from '../../common/constants/screen.constants';
import * as UserActions from '../../store/actions/user.actions';
import { getBtobetErrorMessage } from "../../store/services/api.online.service";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import StyledTextInput from "../../components/kpLibrary/forms/StyledTextInput.comp";
import { checkPhoneNumberValid, formatPhoneNumber } from "../../utils/FormatUtils";

const UserConnect = () => {

  const height = Dimensions.get('window').height
  const isWeb = Platform.OS === "web";
  const theme = useTheme();
  const style = makeStyles(theme, isValid, height, passwordStep);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [isValid, setValid] = useState(false);
  const [passwordStep, setPasswordStep] = useState(false);
  const [rememberPlayerNumber, setRememberPlayerNumber] = useState(false);
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState('');
  const [password, setPassword] = useState('');
  const [playerReference, setPhoneNumber] = useState('');
  const phoneMinDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMinDigits);
  const phoneMaxDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMaxDigits);
  const phoneFormatSplit = useSelector(state => state.wordpress.appSettings?.localization?.phoneFormatSplit);
  const phonePrefix = useSelector(state => state.wordpress.appSettings?.localization?.phonePrefix);
  const isConnected = useSelector(state => state.user.isConnected);
  const isFetching = useSelector(state => state.user.isFetching);
  const user = useSelector(state => state.user);

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      checkStorage();
      return () => {
        // Page leave
        dispatch(UserActions.reset_error());
      }
    }, [])
  );

  useEffect(() => {
    checkPlayerRefValid();
  }, [playerReference])

  useEffect(() => {
    if (isConnected) {
      navigation.goBack();
    }
  }, [isConnected])

  const checkPlayerRefValid = () => {
    setValid(checkPhoneNumberValid(playerReference, phoneMinDigits, phoneMaxDigits));
  }

  const checkStorage = async () => {
    const checkPlayerRefStorage = await AsyncStorage.getItem('STORAGE_PLAYER_NUMBER');
    setPasswordStep(checkPlayerRefStorage === null ? false : true)
    setPhoneNumber(checkPlayerRefStorage || '')
    setPhoneNumberFormatted(formatPhoneNumber(checkPlayerRefStorage || ''), phoneFormatSplit || 2)
    setRememberPlayerNumber(checkPlayerRefStorage === null ? false : true)
  }

  const onPhoneNumberInputChange = (value) => {
    const num = value?.replace(/[^0-9+]+/g, '');
    setPhoneNumberFormatted(formatPhoneNumber(num, phoneFormatSplit || 2));
    setPhoneNumber(num);
  }

  const onPasswordInputChange = (value) => {
    // const num = value?.replace(/[^0-9+]+/g, '');
    setPassword(value);
  }

  const onRememberMePress = () => {
    setRememberPlayerNumber(!rememberPlayerNumber);
  }

  const continueConnexionStep = (step) => {
    Keyboard.dismiss();
    if (rememberPlayerNumber) {
      AsyncStorage.setItem('STORAGE_PLAYER_NUMBER', playerReference)
    }
    if (!rememberPlayerNumber) {
      AsyncStorage.removeItem('STORAGE_PLAYER_NUMBER')
    }

    if (step == 'first') {
      if (!isValid) {
        setPasswordStep(false);
        return;
      } else {
        setPasswordStep(true);
      }
    }
    if (step == 'second') {
      dispatch(UserActions.connectUser(playerReference, password));
    }
  }

  const stepBack = () => {
    setPhoneNumberFormatted('');
    setValid(false);
    setPasswordStep(false);
  }

  const goRegistration = () => {
    navigation.navigate(screens.UserRegister)
  }

  const goResetPassword = () => {
    //Note: the reset page must replace the connection page,
    //      in order to close both pages when the reset(incl. autologin) is done
    navigation.dispatch(StackActions.replace(screens.UserPasswordReset, {phoneNumber: playerReference}));
  }

  const closeModal = () => {
    if (rememberPlayerNumber) {
      AsyncStorage.setItem('STORAGE_PLAYER_NUMBER', playerReference)
    }
    if (!rememberPlayerNumber) {
      AsyncStorage.removeItem('STORAGE_PLAYER_NUMBER')
    }
    navigation.goBack();
  }

  return (
    <View style={style.container}>
      <View style={style.topContainer}>
        <Pressable onPress={() => closeModal()}>
          <SVGClose fill={theme.colors.greyText} />
        </Pressable>
      </View>
      <View style={style.content}>

        {/* Error message */}
        {user.error?.length > 0 &&
          <View style={style.errorContainer}>
            <Text style={style.errorText}>
              {getBtobetErrorMessage(user.errorCode, true)}
            </Text>
          </View>
        }

        <Text style={style.title}>
          {t('Connexion')}
        </Text>

        {/* Phone number input */}
        <View style={[style.textInputContainer, passwordStep && style.textInputContainerNumberValid]}>
          <View style={{ height: 41, justifyContent: 'center' }}>
            <Text style={style.textInputPrefix}>
              {phonePrefix}
            </Text>
          </View>
          <TextInput
            style={[style.textInput, isWeb && style.textInputWeb, !passwordStep && style.textInputWide]}
            onChangeText={text => onPhoneNumberInputChange(text)}
            autoFocus={true}
            disabled={passwordStep}
            value={phoneNumberFormatted}
            placeholder={t("Phone number")}
            keyboardType="numeric"
          />
          {passwordStep &&
            <Pressable style={{ height: 41, justifyContent: 'center' }} onPress={() => stepBack()}>
              <SVGEdit fill={theme.colors.greyText} />
            </Pressable>
          }
        </View>

        {/* Password input */}
        {passwordStep &&
          <View style={style.passwordInputContainer}>
            <StyledTextInput
              keyboardType="default"
              onChangeText={text => onPasswordInputChange(text)}
              placeholder={t("Password")}
              secureTextEntry={true}
              value={password}
              inputProps={{ autoFocus: true }}
            />
          </View>
        }

        {/* Button for the first step */}
        {!isFetching && !passwordStep &&
          <StyledButton
            onPress={() => continueConnexionStep('first')}
            text={t("Continue")}
            disabled={!isValid}
            buttonStyle={{ width: 280 }}
          />
        }

        {/* Button for the second step, has to be different because of the disabled options */}
        {!isFetching && passwordStep &&
          <>
            <StyledButton
              onPress={() => continueConnexionStep('second')}
              text={t("Continue")}
              disabled={password.length === 0}
              buttonStyle={{ width: 280 }}
            />
            <StyledButton
              onPress={() => goResetPassword()}
              text={t("password.forgot")}
              fill='outline'
              buttonStyle={{ width: 280 }}
            />
          </>
        }

        {isFetching &&
          <ActivityIndicator size="small" />
        }

        <TouchableOpacity style={style.checkboxContainer} onPress={onRememberMePress}>
          <Checkbox
            style={style.checkbox}
            value={rememberPlayerNumber}
            color={rememberPlayerNumber ? theme.colors.primary : undefined}
            disabled={!rememberPlayerNumber && !isValid}
          />
          <Text style={style.checkboxText}>{t('Remember me')}</Text>
        </TouchableOpacity>

        <View style={style.separator}></View>

        <View style={{ flexDirection: 'row' }}>
          <Text style={style.registerText}>
            {t("Not registered")}{' '}
          </Text>
          <Pressable onPress={() => goRegistration()}>
            <Text style={style.registerTextPressable}>
              {t("Sign up")}
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default UserConnect;

const makeStyles = (theme, isValid, height, passwordStep) => StyleSheet.create({
  container: {
    padding: 15,
    height: height,
    backgroundColor: '#FFFFFF'
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  content: {
    height: height - 200,
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 22,
    marginTop: 40,
    marginBottom: 35
  },
  passwordInputContainer: {
    width: 280,
  },
  textInputContainer: {
    width: 280,
    height: 40,
    flexDirection: 'row',
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Raleway-Bold',
    marginBottom: 8,
  },
  textInputContainerNumberValid: {
    paddingHorizontal: 8,
    justifyContent: 'space-between'
  },
  textInput: {
    height: 41,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 16,
  },
  textInputWeb: {
    outlineStyle: 'none',
    fontStyle: 'light'
  },
  textInputWide: {
    width: 280,
  },
  textInputPrefix: {
    fontFamily: 'Raleway-Regular',
    fontSize: 15,
    marginLeft: 10,
    marginRight: 16,
    color: '#000000',
  },
  separator: {
    width: 280,
    height: 0.5,
    backgroundColor: theme.colors.greyText,
    marginBottom: 20
  },
  registerText: {
    fontFamily: 'Raleway-Regular',
    color: '#7B7B7B',
    fontSize: 16
  },
  registerTextPressable: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.primary,
    fontSize: 16
  },
  checkboxContainer: {
    width: 280,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 40,
    marginTop: 10
  },
  checkbox: {
    width: 20,
    height: 20,
    marginRight: 10,
    color: theme.colors.primary,
    borderRadius: 5
  },
  checkboxText: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Regular',
    fontSize: 15
  },
  errorContainer: {
    width: 280,
    backgroundColor: '#FADFDF',
    padding: 12,
    justifyContent: 'center',
    borderRadius: 5,
    marginBottom: 30,
    marginTop: 60
  },
  errorText: {
    textAlign: 'center',
    fontFamily: 'Raleway-SemiBold',
    fontSize: 15,
    color: '#F92626'
  }
})