import React from 'react';
import { useTheme } from '@react-navigation/native';
import MySpace from "./MySpace"
import UserFavorites from "./UserFavorites"
import AppParameters from './AppParameters.page';
import SalePoint from './SalePoint';
import GamesRulesList from './GamesRules/GamesRulesList';
import Faq from './Faq';
import Help from './Help';
import LegalNotices from './LegalNotices.page';
import LanguagePick from './LanguagePick';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { getDefaultScreenOptions } from '../../common/theme/theme';

const Stack = createNativeStackNavigator();


const MySpaceNavigation = () => {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="MySpaceHome"
      screenOptions={getDefaultScreenOptions(theme)}
    >
      <Stack.Screen
        name="MySpaceHome"
        component={MySpace}
        options={{ title: t('my space') }}
      />
      <Stack.Screen
        name="UserFavorites"
        component={UserFavorites}
        options={{ title: t('My Favorites') }}
      />
      <Stack.Screen
        name="AppParameters"
        component={AppParameters}
        options={{ title: t('My Parameters') }}
      />
      <Stack.Screen
        name="SalePoint"
        component={SalePoint}
        options={{ title: t('Find a sale point') }}
      />
      <Stack.Screen
        name="GamesRulesList"
        component={GamesRulesList}
        options={{ title: t('Games Rules') }}
      />
      <Stack.Screen
        name="Faq"
        component={Faq}
        options={{ title: t('FAQ') }}
      />
      <Stack.Screen
        name="Help"
        component={Help}
        options={{ title: t('Help') }}
      />
      <Stack.Screen
        name="LegalNotices"
        component={LegalNotices}
        options={{ title: t('Legal notices') }}
      />
      <Stack.Screen
        name="LanguagePick"
        component={LanguagePick}
        options={{ title: t('Application languages') }}
      />
    </Stack.Navigator>
  )
}

export default MySpaceNavigation; 