import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as OddsActions from '../../../store/actions/odds.actions';

import { getBetStatus, getDrawStatusColor, getBorderTicketColorMultiple } from '../../../utils/StatusUtils';
import { drawDateFormat } from "../../../utils/DateUtils";
import { getSportLogo } from "../../../utils/SportsIconsUtils";
import { useTheme } from "@react-navigation/native";

export default function CustomBet({odds, betDetails, t, isSimple, eventName, eventDate, eventLeague, sportsId}) {

  const dispatch = useDispatch();
  const oddsTypeList = useSelector(state => state.odds.oddsType)

  const [borderColor, setBorderColor] = useState('#404040');
  const [statusCustomBetColor, setStatusCustomBetColor] = useState({
    background: '#404040',
    text: '#FFFFFF'
  })
  const [oddsPlayer, setOddsPlayer] = useState([])
  const thtme = useTheme();
  const style = makeStyles(borderColor, statusCustomBetColor, theme);

  useEffect(() => {
    setStatusCustomBetColor(getDrawStatusColor(odds?.status))
    setBorderColor(getBorderTicketColorMultiple(odds?.status))
    dispatch(OddsActions.getOddsTypeList())
  }, [odds]);

  useEffect(() => {
    if(oddsTypeList.length > 0) {
      const oddsTypeIdList = odds.listOfOddsOutcome.map(elem => elem.oddsTypeId)
      const oddsTypeCurrent = [];
      oddsTypeIdList?.forEach((id) => {
        oddsTypeCurrent.push(oddsTypeList?.find(odds => odds.typeId === id))
      })
      setOddsPlayer(oddsTypeCurrent)
    }
  }, [oddsTypeList])

  return (
    <ScrollView style={style.container}>

      <View style={style.containerDateStatus}>
        <View style={style.betDate}>
          <Text style={style.betDateText}>{drawDateFormat(eventDate)}</Text>
        </View>   
        <View style={style.betStatus}>
          <Text style={style.betStatusText}>{t(getBetStatus(odds?.status))}</Text>
        </View>
      </View>

      <View style={style.containerBetInfos}>

        <Image
          style={style.image}
          source={getSportLogo(sportsId)}
        />

        <View style={style.infos}>
          <Text style={style.matchName}>
            {eventName}
          </Text>
          <Text style={style.leagueName}>
            {eventLeague}
          </Text>
        </View>
        
      </View>

      <Image
        style={style.customBetImage}
        source={require('../../../assets/images/CustomBetLogo.png')}
      />

      <View style={style.customBetOddsPlayer}>
        {odds?.listOfOddsOutcome?.map((oddsOutcome, index) => (
          <View key={index} style={style.playerOddsViewCustom}>
            <View style={style.customBullet}></View>

            {index === odds?.listOfOddsOutcome?.length - 1 || (
              <View style={style.verticalLine}></View>
            )}
            
            <Text style={style.playerOddsTypeCustom}>
              {oddsPlayer[index]?.name} : {' '}
              <Text style={style.playerOddsName}>
                {oddsOutcome.oddsOutcomeName}
              </Text>
            </Text>
          </View>
        ))}
      </View>
      
      {isSimple && (
      <View>
        <View style={style.separator}></View>
        <View style={style.betOddsSimplePart}>
          <View style={style.image}></View>
          <View style={style.simpleBetInfos}>
            <Text style={style.simpleBetInfosText}>{t('Odd')}: {betDetails?.totalOdds}</Text>
            <Text style={style.simpleBetInfosText}>{('Bet')}: {betDetails?.stake}</Text>
          </View>
        </View>
      </View>
      )}

    </ScrollView>
  )
}


//====================================== STYLE
const makeStyles = (borderColor, statusCustomBetColor, theme) => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 24,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 4,
    borderLeftColor: borderColor
  },
  containerDateStatus: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  betStatus: {
    borderRadius: 2,
    backgroundColor: statusCustomBetColor.background,
    paddingVertical: 2,
    paddingHorizontal: 7,
    justifyContent: "center"
  },
  betStatusText: {
    color: statusCustomBetColor.text,
    fontFamily: 'Raleway-Regular',
    fontSize: 11
  },
  betDateText: {
    fontFamily: "Raleway-Regular",
    fontSize: 12,
  },
  containerBetInfos: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  infos: {
    flexDirection: 'column',
    marginRight: 30,
    width: '85%'
  }, 
  matchName: {
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 14, 
    paddingBottom: 7
  }, 
  leagueName: {
    fontFamily: 'Raleway-LightItalic',
    color: '#404040',
    fontSize: 12, 
    paddingBottom: 7
  },
  playerOddsView: {
    width: '125%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  playerOddsViewCustom: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4
  },
  playerOddsType: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    marginRight: 10,
  },
  playerOddsTypeCustom: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    marginRight: 10,
    marginLeft: 25
  },
  playerOddsName: {
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary
  },
  customBetImage: {
    width: 85,
    height: 10,
    marginVertical: 7
  },
  customBetOddsPlayer: {
    backgroundColor: '#F2F2F2',
    marginLeft: 18
  },
  customBullet: {
    width: 8,
    height: 8,
    borderRadius: 50,
    backgroundColor: theme.colors.primary,
    marginLeft: -25,
    marginTop: 4
  },
  verticalLine: {
    width: 1,
    height: '100%',
    paddingVertical: 8,
    backgroundColor: theme.colors.primary,
    marginLeft: -4,
    marginBottom: -25,
    marginTop: 12
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#E2E1E1',
    marginVertical: 12
  },
  betOddsSimplePart:{
    flexDirection: 'row',
  }, 
  simpleBetInfos: {
    flexDirection: 'row'
  }, 
  simpleBetInfosText: {
    fontFamily: 'Raleway-Regular',
    fontSize: 12,
    color: '#404040',
    marginRight: 30
  }
});

