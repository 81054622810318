import moment from 'moment';

// Definition of the actions name

export const ACTIONS = {
  BASKET_CLEAR: 'BASKET_CLEAR',
  BASKET_MAXBETSTOTALPRICE: 'BASKET_MAXBETSTOTALPRICE',
  BASKET_SET_ITEM_LIST: 'BASKET_SET_ITEM_LIST',
  BASKET_SET_VALID: 'BASKET_SET_VALID',
  BASKET_SET_GAME_INFO: 'BASKET_SET_GAME_INFO',
  BASKET_TOTAL_STAKE: 'BASKET_TOTALSTAKE',
  BASKET_TOTAL_STAKE_VALID: 'BASKET_TOTAL_STAKE_VALID',
}
// LIFECYCLE


// SETTERS
export const set_item_list = (list) => {
  return { type: ACTIONS.BASKET_SET_ITEM_LIST, payload: list };
}
export const set_maxBetsTotalPrice = (maxBetsTotalPrice) => {
  return { type: ACTIONS.BASKET_MAXBETSTOTALPRICE, payload: maxBetsTotalPrice };
}
export const set_valid = (isValid) => {
  return { type: ACTIONS.BASKET_SET_VALID, payload: isValid };
}
export const set_total_stake = (totalStake) => {
  return { type: ACTIONS.BASKET_TOTAL_STAKE, payload: totalStake };
}
export const set_total_stake_valid = (isTotalStakeValid) => {
  return { type: ACTIONS.BASKET_TOTAL_STAKE_VALID, payload: isTotalStakeValid };
}
export const clear_basket = () => {
  return { type: ACTIONS.BASKET_CLEAR };
}
export const set_game_info = (game) => {
  return { type: ACTIONS.BASKET_SET_GAME_INFO, payload: game };
}

// ACTIONS

/**
 * Refresh the basket: check validity, compute total stake, ...
 * @param {*} callback(isValid, validItemCount) [optional]
 */
export const refresh = (callback = null) => {
  return (dispatch, getState) => {
    const list = [...getState().basket.itemList];

    //refresh total stake
    dispatch(set_total_stake(computeTotalStake(list)));

    //refresh total stake validity
    const maxBetsTotal = getState().basket.maxBetsTotalPrice;
    const isTotalStakeValid = checkTotalStake(list, maxBetsTotal);
    dispatch(set_total_stake_valid(isTotalStakeValid))
    
    //refresh basket validity
    let validItemCount = list.reduce((acc, cur) => acc + isBasketItemValid(cur), 0);
    const isAllBetsValid = (validItemCount == list.length);
    const isValid = isAllBetsValid && isTotalStakeValid && list.length > 0;
    dispatch(set_valid(isValid));

    //refresh the list
    dispatch(set_item_list(list));

    callback && callback(isValid, validItemCount);
  }
}

const isBasketItemValid = (item) => {
  return !item.draw?.closingDate /*fast instant lotteries*/ || moment(item.draw.closingDate).isAfter();
}

const checkTotalStake = (list, maxBetsTotal) => {
  const isTotalStakeValid = list.reduce((acc, curr) => {
    return acc + curr.price;
  }, 0);
  return isTotalStakeValid < maxBetsTotal;
}

export const resetList = () => {
  return (dispatch, getState) => {
    let list = [];
    dispatch(set_item_list(list));
  }
}

export const addItem = (item, selectedBetType) => {
  return (dispatch, getState) => {
    const maxBetsTotalPrice = selectedBetType?.maxBetsTotalPrice;
    let list = [...getState().basket.itemList];
    list.push({ ...item, maxBetsTotalPrice, selectedBetType });
    dispatch(set_item_list(list));
    dispatch(set_maxBetsTotalPrice(maxBetsTotalPrice));
    dispatch(refresh());
  }
}

export const removeItem = (item) => {
  return (dispatch, getState) => {
    let list = [...getState().basket.itemList];
    let index = list.findIndex(E => E === item);
    if (index >= 0) {
      list.splice(index, 1);
    }
    dispatch(set_item_list(list));
    dispatch(refresh());
  }
}

// HELPERS

const computeTotalStake = (list) => {
  return list.reduce((acc, cur) => acc + cur.price, 0);
}