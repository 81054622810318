import { useTheme } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { extractDate } from "../../utils/DateUtils";
import Icon from 'react-native-vector-icons/MaterialIcons';

import DateRangePickerComp from "./DateRangePicker.comp";
import { useTranslation } from "react-i18next";
import SwipeDownModal from "./SwipeDownModal.comp";

const DateRangePickerModal = ({
  onApply,
  onClose,
  visible,
  defaultStartDate,
  defaultEndDate,
}) => {

  const styles = makeStyles(useTheme());
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(extractDate(defaultStartDate, null));
  const [endDate, setEndDate] = useState(extractDate(defaultEndDate, null));
  const [focusedInput, setFocusedInput] = useState('startDate');

  useEffect(() => {
    if (visible) {
      setStartDate(extractDate(defaultStartDate, null));
      setEndDate(extractDate(defaultEndDate, null));
      setFocusedInput('startDate');
    }
  }, [visible]);

  const onSelectDateRange = ({ startDate, endDate, focusedInput }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setFocusedInput(focusedInput);
  }

  const isDateBlocked = (date) =>
    date.isAfter(moment(), 'day');

  const applyAndClose = () => {
    const strStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : null;
    const strEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : strStartDate;
    onApply({ startDate: strStartDate, endDate: strEndDate });
    onClose();
  }

  const clearData = () => {
    setStartDate(null);
    setEndDate(null);
    setFocusedInput('startDate');
  }

  return (
    <SwipeDownModal
      onClose={onClose}
      visible={visible}
    >
      <DateRangePickerComp
        onDatesChange={onSelectDateRange}
        isDateBlocked={isDateBlocked}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        range
      />
      <View style={styles.buttonBar}>
        {startDate &&
          <Pressable style={[styles.buttonClear]} onPress={clearData}>
            <Icon name={'cleaning-services'} style={styles.buttonClearIcon} />
          </Pressable>
        }
        <Pressable style={[styles.buttonCancel]} onPress={() => onClose()}>
          <Text style={[styles.buttonCancelText]}>
            {t('cancel')}
          </Text>
        </Pressable>
        <Pressable style={styles.buttonValidate} onPress={() => applyAndClose()}>
          <Text style={styles.buttonValidateText}>
            OK
          </Text>
        </Pressable>
      </View>
    </SwipeDownModal>
  );
}

const makeStyles = (theme) => StyleSheet.create({
  buttonBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 1,
    marginBottom: 5,
    marginTop: 15,
  },
  buttonCancel: {
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: 10,
    paddingLeft: 2,
  },
  buttonCancelText: {
    color: theme.colors.primary,
    fontSize: 16,
    fontFamily: 'Raleway-Medium',
    textTransform: 'uppercase',
  },
  buttonClear: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: 10,
    paddingLeft: 2,
  },
  buttonClearIcon: {
    fontSize: 20,
    color: theme.colors.primary,
  },
  buttonValidate: {
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    borderRadius: 4,
    paddingVertical: 10,
    marginLeft: 24,
    minWidth: 60,
  },
  buttonValidateText: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'Raleway-SemiBold',
  }
});

export default DateRangePickerModal;