import { View, Text, StyleSheet, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBetStatus, getBorderTicketColor } from '../../../utils/StatusUtils';
import { formatNumber } from "../../../utils/FormatUtils";
import { computeNetAmount } from '../../../store/services/taxes.service';
import InstantLottery from "./InstantLottery";
import MutualLottery from "./MutualLottery";

export default function InstantOrMutual({betDetails, index, currency}) {

  const [gameName, setGameName] = useState('');
  const games = useSelector(state => state.games.games);
  const useTaxes = useSelector(state => state.taxes.useTaxes);
  const taxList = useSelector(state => state.taxes.taxes);
  const [borderColor, setBorderColor] = useState('#404040');
  const [betDetailsStatus, setBetDetailsStatus] = useState(false);
  const [drawsRemaining, setDrawsRemaining] = useState(0)
  const [asAdditionnal, setAsAdditionnal] = useState(false);
  const { t } = useTranslation();
  const style = makeStyles(borderColor);

  useEffect(() => {
    setGameName(games.find(game => game.gameId === betDetails.gameId).name)
    setBorderColor(getBorderTicketColor(betDetails))
    setBetDetailsStatus(
      betDetails.gameType === 'mutualLottery' ?
      betDetails.draw.status === 'wait' :
      betDetails.listOfStatusAndDraws?.some(draw => draw.status == 'wait')
    )
    setDrawsRemaining(
      betDetails.gameType === 'mutualLottery' ?
      betDetails.draw.listOfGridDrawn ?
        betDetails.draw.listOfGridDrawn?.length :
        0 :
        betDetails.listOfStatusAndDraws.filter(draw => draw.status != 'wait').length
    )
    setAsAdditionnal(
      betDetails.listOfBetGrids.reduce((acc, bet) => {
        return acc && bet.usedAdditionalNumbers > 0;
      }, true)
    )
  }, [betDetails]);

  function getGameTypeComponent(gameType) {
    switch (gameType.toLowerCase()) {
      case 'instantlottery':
        return (<InstantLottery borderColor={borderColor} betDetails={betDetails} />)
      case 'mutuallottery':
        return (<MutualLottery borderColor={borderColor} betDetails={betDetails} />)
      default:
        break;
    }
  }

  return (
    <ScrollView style={style.container}>
      <View style={style.containerBetIndex}>
        <View style={style.betIndex}>
          <Text style={style.betIndexText}>Bet n°{index + 1}</Text>
        </View>        
      </View>

      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Game type')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            {gameName}
          </Text>
        </View>
      </View>
      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Bet')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            {formatNumber(betDetails.stake)} {currency}
          </Text>
        </View>
      </View>
      {asAdditionnal && (
        <View style={style.gridSummary}>
          <View style={style.leftColumn}>
            <Text style={style.leftText}>
              {t('Price')} :
            </Text>
          </View>
          <View style={style.rightColumn}>
            <Text style={style.rightText}>
              {formatNumber(betDetails.price)} {currency}
            </Text>
          </View>
        </View>        
      )}
      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Status')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            <View style={getBetStatus(betDetails.gameType === 'mutualLottery' ?
              betDetails.draw.status :
              betDetailsStatus ?
                'WAIT' :
                'end') === 'Pending' ?
                  style.statusBallPending :
                  style.statusBallEnded}
            >
            </View>{' '}
            {t(getBetStatus(
              betDetails.gameType === 'mutualLottery' ?
                betDetails.draw.status ?
                  betDetails.draw.status :
                  betDetails.status :
                    betDetailsStatus ?
                      'WAIT' :
                      'end')
            )}
          </Text>
        </View>
      </View>
      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Draws made')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            {drawsRemaining}
          </Text>
        </View>
      </View>

        <View style={style.gridSummary}>
          <View style={style.leftColumn}>
            <Text style={style.leftText}>
            {t('Gains')} :
            </Text>
          </View>
          <View style={style.rightColumn}>
            <Text style={style.rightText}>
              {betDetails.earn ? formatNumber(betDetails.earn + (betDetails.taxAmount || 0)) : 0} {currency}
            </Text>
          </View>
        </View>
        {useTaxes && <View style={style.gridSummary}>
          <View style={style.leftColumn}>
            <Text style={style.leftText}>
            {t('Gains nets')} :
            </Text>
          </View>
          <View style={style.rightColumn}>
            <Text style={style.rightText}>
              {betDetails.earn ? formatNumber(betDetails.earn) : 0} {currency}
            </Text>
          </View>
        </View>}

      {/* Draw part */}

      {getGameTypeComponent(betDetails.gameType)}

    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (borderColor) => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 4,
    borderLeftColor: borderColor
  },
  containerBetIndex: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 5
  },
  betIndex: {
    backgroundColor: borderColor,
    paddingHorizontal: 19,
    paddingVertical: 7
  },
  betIndexText: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
   // Grid Part
  gridSummary: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 8
  },
  leftColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 25,
    paddingRight: 7
  },
  leftText: {
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13,
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    justifyContent: 'center'
  },
  rightText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#404040',
    fontSize: 15
  },
  // Status Ball
  statusBallPending: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#FE1593',
  },
  statusBallEnded: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#A9A9A9',
  }
});
