import { apiConfig } from '../../../app.json';
import { ol_axios } from './api.online.service';

//=============================================================================
// API CALLS
//=============================================================================

export const connectUser = (login, password) => {
  let params = {
    login: `${login}`,
    password: `${password}`,
    ipAddress: "127.0.0.1",
  };
  return ol_axios.post(`${apiConfig.userMCS}/user/customerLoginAccount`, params);
}

export const disconnectUser = (internalId) => {
  let params = {
    internalID: `${internalId}`
  };
  return ol_axios.post(`${apiConfig.userMCS}/user/customerLogoutAccount`, params);
}

export const registerUser = (forms, currencyISO) => {
  //TODO: manage fields CountryISO/LanguageISO/CurrencyISO
  let params = {
    customer: {
      CustomerV3: {
        CustomerDetails: {
          MobileNumber: forms.phoneNumber,
          Password: forms.password,
          FirstName: forms.firstName,
          LastName: forms.name,
          Email: `${forms.phoneNumber}@email.com`,
          Username: forms.phoneNumber,
          CurrencyISO: "XAF",
          CountryISO: "MDG",
          LanguageISO: "FR",
          Postcode: "101",
          Gender: "",
          DateOfBirth: "2000-01-01",
          City: "City",
          Address: "Address"
        }
      }
    }
  };
  return ol_axios.post(`${apiConfig.providerMCS}/account/customerCreateAccount`, params);
}

export const updateUser = (token, userRequest) => {
  let headers = { token };
  return ol_axios.post(`${apiConfig.providerMCS}/account/customerUpdateAccount`, userRequest, { headers });
}

export const getUserInfo = (token, internalID) => {
  let headers = { token };
  let body = { internalID };
  return ol_axios.post(`${apiConfig.providerMCS}/account/getBankInformation`, body, { headers });
}

export const getUserTransactions = (token, data) => {
  let headers = { token };
  return ol_axios.post(`${apiConfig.providerMCS}/transaction/findDepositsWithdrawalsForUser`, data, { headers });
}

export const getTransactionDepositPaymentProviders = (token) => {
  let headers = { token };
  return ol_axios.post(`${apiConfig.providerMCS}/transaction/transactionGetDepositPaymentProviders`, {}, { headers });
}

export const searchUser = ({ phoneNumber }) => {
  let body = { phoneNumber };
  return ol_axios.post(`${apiConfig.providerMCS}/account/customerGetDetailsAlternative`, body);
}

export const changePassword = (fields, userID, token) => {
  let headers = { token };
  let params = {
    oldPassword: fields.oldPassword,
    newPassword: fields.newPassword,
    userID: userID,
  }
  return ol_axios.post(`${apiConfig.providerMCS}/resetPassword`, params, { headers });
}

export const getPasswordResetCode = ({ dateOfBirth, email }) => {
  let params = { dateOfBirth, email };
  return ol_axios.post(`${apiConfig.providerMCS}/customerGetPasswordResetCode`, params);
}

export const resetPasswordByCode = ({ password, passwordResetCode }) => {
  //Note: we don't use the "autoLogin" option of this api,
  //      as the field names given in the response don't have the same format as  other apis (capitalized names...)
  //      btobet power
  let params = {
    returnCustomerDetails: true,
    autoLogin: false,
    password,
    passwordResetCode,
  }
  return ol_axios.post(`${apiConfig.providerMCS}/customerResetPasswordByCode`, params);
}

export const onDisableUserAccount = (token, internalID) => {
  let headers = { token };
  let body = { internalID };
  return ol_axios.post(`${apiConfig.providerMCS}/account/customerDeactivateAccount`, body, { headers });
}

// LIMIT
export const getUserLimits = (token, internalID) => {
  let headers = { token };
  let body = { internalID, apiKey: apiConfig.apiKey };
  return ol_axios.post(`${apiConfig.providerMCS}/limit/responsibleGamingGetLimits`, body, { headers });
}

export const onDeleteUserLimit = (token, internalID, limitId) => {
  let headers = { token };
  let body = { internalID, limitId };
  return ol_axios.post(`${apiConfig.providerMCS}/limit/responsibleGamingRemoveLimit`, body, { headers });
}

export const onModifyUserLimit = (token, data) => {
  let headers = { token };
  let body = { 
    modifyLimitRequest: {
      amount: data.amount,
      id: data.limitId,
      coolDownPeriod: 0
    }
  };
  return ol_axios.post(`${apiConfig.providerMCS}/limit/ResponsibleGamingModifyLimit`, body, { headers });
}

export const onCreateUserLimit = (token, data) => {
  let headers = { token };
  return ol_axios.post(`${apiConfig.providerMCS}/limit/responsibleGamingSetLimit`, data, { headers });
}

//=============================================================================
// HELPERS
//=============================================================================

/**
 * The form to update the user account info
 */
export const getAccountUpdateFormModel = () => ({
  isDirty: false,
  list: [
    {
      sectionKey: 'coords',
      sectionLabelKey: 'coords',
      displayMode: 'list',
      fields: [
        { labelKey: 'phone', sourceKey: 'mobileNumber', type: 'tel', value: '', readOnly: true },
        { labelKey: 'lastName', sourceKey: 'lastName', type: 'string', value: '' },
        { labelKey: 'firstName', sourceKey: 'firstName', type: 'string', value: '' },
        { labelKey: 'birthDate', sourceKey: 'dateOfBirth', type: 'date', value: null, defaultValue: '2000-01-01' },
        {
          labelKey: 'address', sourceKey: 'address', type: 'form', subFields: [
            { row: 1, labelKey: 'address', sourceKey: 'address', type: 'string', value: '' },
            { row: 2, labelKey: 'postCode', sourceKey: 'postCode', type: 'string', value: '' },
            { row: 2, labelKey: 'city', sourceKey: 'city', type: 'string', value: '' },
            { row: 3, labelKey: 'country', sourceKey: 'countryISO', type: 'select', selectType: 'country', value: null },
          ]
        },
        { labelKey: 'currency', sourceKey: 'currencyISO', type: 'select', value: null, readOnly: true },
      ]
    },
  ]
});
