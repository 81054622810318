// Definition of the actions name

export const ACTIONS = {
  BETCREATOR_BETTYPE_SELECTED: 'BETCREATOR_BETTYPE_SELECTED',
  BETCREATOR_CLEAR_SELECTED_GRID_NUMBERS: 'BETCREATOR_CLEAR_SELECTED_GRID_NUMBERS',
  BETCREATOR_CONSECUTIVE_DRAWS: 'BETCREATOR_CONSECUTIVE_DRAWS',
  BETCREATOR_GRIDS_SELECTED: 'BETCREATOR_GRIDS_SELECTED',
  BETCREATOR_RESET: 'BETCREATOR_RESET',
  BETCREATOR_SESSIONS_SELECTED: 'BETCREATOR_SESSIONS_SELECTED',
  BETCREATOR_STAKE_SELECTED: 'BETCREATOR_STAKE_SELECTED',
  BETCREATOR_TOTAL_PRICE: 'BETCREATOR_TOTAL_PRICE',
  BETCREATOR_CURRENT_STEP: 'BETCREATOR_CURRENT_STEP',
  BETCREATOR_BREAK: 'BETCREATOR_BREAK'
}
export const STEPS = {
  NONE: 0,
  SESSION: 1,
  BETTYPE: 2,
  GRID: 3,
}
// LIFECYCLE

// SETTERS
export const set_sessions_selected = (sessions) => {
  return { type: ACTIONS.BETCREATOR_SESSIONS_SELECTED, payload: sessions };
}
export const set_current_step = (step) => {
  return { type: ACTIONS.BETCREATOR_CURRENT_STEP, payload: step };
}
export const set_betType_selected = (betType) => {
  return { type: ACTIONS.BETCREATOR_BETTYPE_SELECTED, payload: betType };
}
export const set_grids_selected = (selectedGrids) => {
  return { type: ACTIONS.BETCREATOR_GRIDS_SELECTED, payload: selectedGrids };
}
export const set_stake_selected = (stake) => {
  return { type: ACTIONS.BETCREATOR_STAKE_SELECTED, payload: stake };
}
export const set_consecutive_draws = (count) => {
  return { type: ACTIONS.BETCREATOR_CONSECUTIVE_DRAWS, payload: count };
}
export const set_totalPrice = (value) => {
  return { type: ACTIONS.BETCREATOR_TOTAL_PRICE, payload: value };
}

export const clear_selected_grid_numbers = () => {
  return { type: ACTIONS.BETCREATOR_CLEAR_SELECTED_GRID_NUMBERS };
}
export const reset_data = () => {
  return { type: ACTIONS.BETCREATOR_RESET };
}
export const break_bet = (isBetInBreak) => {
  return { type: ACTIONS.BETCREATOR_BREAK, payload: isBetInBreak };
}
// Actions

/**
 * Update the `selectedGrids` state, for the given `gridName`
 * @param {*} gridName the name of the grid to update
 * @param {*} updatedGridData the changes to merge to the grid
 */
export const updateSelectedGrid = (gridName, updatedGridData) => {
  return (dispatch, getState) => {
    const current_selectedGrids = getState().betCreator.selectedGrids;
    dispatch(set_grids_selected({
      ...current_selectedGrids,
      [gridName]: { ...current_selectedGrids[gridName], ...updatedGridData }
    }));
  }
}

export const updateSelectedGridFlash = (gridName, updatedNum) => {
  return (dispatch, getState) => {
    const current_selectedGrids = getState().betCreator.selectedGrids;
    dispatch(set_grids_selected({
      ...current_selectedGrids,
      [gridName]: { ...current_selectedGrids[gridName], selectedNumbers: [updatedNum, ...current_selectedGrids[gridName].selectedNumbers] }
    }));
  }
}

/**
 * Initialize the state `selectedGrids` by using the `selectedBetType` config
 */
export const initializeSelectedGrids = (selectedBetType) => {
  return (dispatch) => {
    const selectedGrids = selectedBetType?.listOfGridParameters?.reduce((acc, grid) => {
      return {
        ...acc,
        [grid.gridName]:
        {
          ...grid,
          ...selectedBetType.listOfGrids.find(elem => elem.gridName === grid.gridName),
          selectedNumbers: [],
          selectedAdditionalNumbers: []
        }
      }
    }, {});
    dispatch(set_grids_selected(selectedGrids));
  }
}

// Functions (service)

/**
 * Convert the pending bet to a [list of] basket item
 * @returns a list of basket items (1 for each selected session - except consecutiveDraws)
 * @see basket.actions.js > typeBasketItem
 */
export const toBasketItemList = () => {
  return (dispatch, getState) => {
    const state = getState().betCreator;

    const pricePerItem = state.totalPrice / (Object.keys(state.selectedSessions).length || 1);

    const basketItemBase = {
      betTypeId: state.selectedBetType.betTypeId,
      nbConsecutiveDraws: state.selectedConsecutiveDrawCount || 1,
      price: pricePerItem,
      selectionListByGrid: Object
        .values(state.selectedGrids)
        .map(grid => grid.selectedNumbers.concat(grid.selectedAdditionalNumbers)),
      stake: state.selectedStake,
    };
    
    let itemList = Object.values(state.selectedSessions).map(draw => ({
      ...basketItemBase,
      draw,
      drawId: draw.drawId,
    }));

    if (Object.keys(state.selectedSessions).length == 0 && state.selectedBetType.maxDrawByBet > 1) {
      //Note: for the FOGO-like loteries, we select only the consecutive draw count. The draws are only selected when taking the bet (POS Agent)
      itemList.push({
        ...basketItemBase,
        draw: {},
        drawId: undefined,
      })
    }
    return itemList;
  }
}