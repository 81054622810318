import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getBetStatus, getBorderTicketColor } from '../../../utils/StatusUtils';

import { formatNumber } from "../../../utils/FormatUtils";

export default function FastPlay({betDetails, index, currency}) {
  
  const [gameName, setGameName] = useState('');
  const games = useSelector(state => state.games.games);

  const [borderColor, setBorderColor] = useState('#404040');
  const [betDetailsStatus, setBetDetailsStatus] = useState();
  const [drawsRemaining, setDrawsRemaining] = useState(0)
  const { t } = useTranslation();
  const style = makeStyles(borderColor);

  useEffect(() => {
    setGameName(games.find(game => game.gameId === betDetails.gameId).name)
    setBorderColor(getBorderTicketColor(betDetails))
    setBetDetailsStatus(betDetails.status)
    setDrawsRemaining(
      betDetails.gameType === 'mutualLottery' ?
        betDetails.draw.listOfGridDrawn?.length :
        betDetails.listOfStatusAndDraws?.filter((draw) => draw.status !== 'wait').length
    )
  }, [betDetails]);

  return (
    <ScrollView style={style.container}>
      <View style={style.containerBetIndex}>
        <View style={style.betIndex}>
          <Text style={style.betIndexText}>Bet n°{index + 1}</Text>
        </View>
      </View>

      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Bet')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            {formatNumber(betDetails.stake)} {currency}
          </Text>
        </View>
      </View>

      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Status')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            <View style={
              betDetailsStatus === 'WAIT' ?
                  style.statusBallPending :
                    betDetailsStatus === 'WIN' ?
                      style.statusBallWin :
                      style.statusBallEnded
              }
            >
            </View>{' '}
            {t(getBetStatus(betDetailsStatus))}
          </Text>
        </View>
      </View>

      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
          {t('Gains')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            {betDetails.earn ? formatNumber(betDetails.earn) : 0} {currency}
          </Text>
        </View>
      </View>

      <View style={style.gridSummary}>
        <View style={style.leftColumn}>
          <Text style={style.leftText}>
            {t('Draws number')} :
          </Text>
        </View>
        <View style={style.rightColumn}>
          <Text style={style.rightText}>
            1002
          </Text>
        </View>
      </View>       

      {/* Draw part */}
      <View style={style.imageContainer}>
        <Image style={style.image} source={{uri: `data:image/jpg;base64,${betDetails.propositionForm}`}}/>
        <View style={style.resultView}>
          <Text style={style.resultText}>{t('Result')}</Text>
        </View>
        <Image style={style.imageResult} source={{uri: `data:image/jpg;base64,${betDetails.resultForm}`}}/>
      </View>

    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (borderColor) => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    paddingBottom: 20,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 4,
    borderLeftColor: borderColor
  },
  containerBetIndex: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 5,
    marginBottom: 10
  },
  betIndex: {
    backgroundColor: borderColor,
    paddingHorizontal: 19,
    paddingVertical: 7
  },
  betIndexText: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15
  },
   // Grid Part
  gridSummary: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 8
  },
  leftColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 25,
    paddingRight: 7
  },
  leftText: {
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13,
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    justifyContent: 'center'
  },
  rightText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#404040',
    fontSize: 15
  },
  // Status Ball
  statusBallPending: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#FE1593',
  },
  statusBallEnded: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#A9A9A9',
  },
  statusBallWin: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#29D300',
  },
  image: {
    width: 250,
    height: 460
  },
  imageContainer: {
    marginTop: 15,
    width: '100%',
    alignItems: 'center'
  },
  resultView: {
    marginTop: 25,
    width: 250,
    alignItems: 'flex-start'
  },
  resultText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: '#404040'
  },
  imageResult: {
    margin: 10,
    width: 252,
    height: 62
  }
});
