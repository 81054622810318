import * as service from "../services/ebulletin.service";
import moment from 'moment';
import { trackError } from "../services/firebase.service";

// Definition of the actions name

const current_ebulletin_builder_version = 3;

export const ACTIONS = {
  BULLETIN_ERROR: 'BULLETIN_ERROR',
  BULLETIN_FETCHING: 'BULLETIN_FETCHING',
  BULLETIN_FETCHING_DONE: 'BULLETIN_FETCHING_DONE',
  BULLETIN_RESET_SEARCH_STATE: 'BULLETIN_RESET_SEARCH_STATE',
  //
  BULLETIN_CURRENT_BULLETIN: 'BULLETIN_CURRENT_BULLETIN',
  BULLETIN_MY_BULLETIN_LIST: 'BULLETIN_MY_BULLETIN_LIST',
  //
  BULLETIN_IS_SPORTBET: "BULLETIN_IS_SPORTBET"
}

// Export action

export const set_state_error = (err) => {
  return { type: ACTIONS.BULLETIN_ERROR, payload: err };
}

export const set_state_fetching = () => {
  return { type: ACTIONS.BULLETIN_FETCHING };
}

export const set_state_fetching_done = () => {
  return { type: ACTIONS.BULLETIN_FETCHING_DONE };
}

export const reset_search_state = () => {
  return { type: ACTIONS.BULLETIN_RESET_SEARCH_STATE };
}

export const set_current_bulletin = (bulletin) => {
  return { type: ACTIONS.BULLETIN_CURRENT_BULLETIN, payload: bulletin };
}

export const set_my_ebulletin_list = (list, save = true) => {
  return { type: ACTIONS.BULLETIN_MY_BULLETIN_LIST, payload: { list, save } };
}

export const set_bulletin_is_sportbet = (bool) => {
  return { type: ACTIONS.BULLETIN_IS_SPORTBET, payload: bool };
}

//

export const searchBulletin = (code, onSuccess, isFromScan) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getBulletin(code);
      if (r.data?.bulletin) {
        if (isBulletinSportsBet(r.data?.bulletin)) {
          dispatch(set_bulletin_is_sportbet(true))
          return;
        }
        const ebulletin = isFromScan ? service.parsedBulletin(r.data?.bulletin) : r.data?.bulletin;
        dispatch(set_current_bulletin(r.data?.bulletin))
        dispatch(set_state_fetching_done());
        onSuccess && onSuccess(r.data?.bulletin);
        dispatch(set_bulletin_is_sportbet(false))
        dispatch(addBulletinToList(ebulletin, true));
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const isBulletinSportsBet = (ebulletin) => {
  return JSON.parse(ebulletin.content)?.header.gameType === "sportBetting";
}

export const loadMyEbulletins = () => {
  return async dispatch => {
    const list = await service.LoadMyEbulletinsFromStorage();
    dispatch(set_my_ebulletin_list(list, /*save=*/false));
    dispatch(cleanExpiredBulletins());
  }
}

/**
 * Request the api to create the bulletin, fill api metadata, save to local list
 */
export const finalizeBulletinCreation = (bulletin, opts = { phonePrefix }, onSuccess = null) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.apiCreateBulletin(bulletin, opts);
      if (!r?.data?.bulletin?.bulletinCode) {
        throw 'api createBulletin : empty response';
      }
      bulletin.api = r.data.bulletin;
      dispatch(set_current_bulletin(bulletin));
      dispatch(addBulletinToList(bulletin, true));
      dispatch(set_state_fetching_done());
      onSuccess && onSuccess(bulletin);
    } catch (err) {
      console.warn("finalize bulletin creation", err)
      dispatch(set_state_error(err));
    }
  }
}

export const addBulletinToList = (ebulletin, bOverwrite = false) => {
  return async (dispatch, getState) => {
    if (!ebulletin?.api?.bulletinCode && !ebulletin.bulletinCode) {
      trackError("cannot add bulletin to list : missing bulletinCode");
      return;
    }
    let list = [...getState().ebulletin.myEbulletinList]
    const item = list.find((E) => {
       return E.api ? E.api.bulletinCode == ebulletin.api.bulletinCode : E.bulletinCode == ebulletin.bulletinCode;
    });
    if (!item) {
      list.unshift(ebulletin); //push_front
      dispatch(set_my_ebulletin_list(list));
    }
    else if (bOverwrite) {
      list.splice(list.findIndex(E => E == item), 1, ebulletin);
      dispatch(set_my_ebulletin_list(list));
    }
  }
}

export const cleanExpiredBulletins = () => {
  //Note: the bulletin is still valid if the bulletinCode expiryDate AND the betting dateLimit (if set) are not reached
  return async (dispatch, getState) => {
    const filteredList = getState().ebulletin.myEbulletinList.filter((item) => {
      return moment(item.api?.expiryDate).isAfter() && (!item.dateLimit || moment(item.dateLimit).isAfter());
    });
    dispatch(set_my_ebulletin_list(filteredList))
  }
}

export const buildBulletinFromScan = (apiBulletin) => {
  return async (dispatch, getState) => {
    dispatch(set_current_bulletin(service.parsedBulletin(apiBulletin)))
  }
}
