import { ScrollView, View, Text, StyleSheet } from "react-native";
import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { HeaderBackButton } from "@react-navigation/elements";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import * as BasketActions from '../../store/actions/basket.actions';
import BasketItem from "./BasketItem";
import { formatNumber } from "../../utils/FormatUtils";
import { SVGError } from "../../assets/icons";
import screens from "../../common/constants/screen.constants";
import BetCreateExitModal from '../../components/betCreation/BetCreateExitModal';
import BasketBadge from "../../components/betCreation/BasketBadge.comp";
import UserSticker from "../../components/user/UserSticker.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";


export default function Basket() {

  //====================================== Hooks

  const itemList = useSelector(state => state.basket.itemList);
  const totalStake = useSelector(state => state.basket.totalStake);
  const currency = useSelector(state => state.login.currency);
  const isValid = useSelector(state => state.basket.isValid);
  const isTotalStakeValid = useSelector(state => state.basket.isTotalStakeValid);

  const [isExitModalVisible, setIsExitModalVisible] = useState(false);
  const [isAllBetsExpired, setIsAllBetsExpired] = useState(false);

  const theme = useTheme();
  const style = makeStyles(theme, isTotalStakeValid);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { t } = useTranslation();

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      navigation.setOptions({ headerLeft: headerLeftGoList, headerRight: headerRightBasket });
      dispatch(BasketActions.refresh(basketRefreshCallback));
    }, [])
  );

  //====================================== Functions

  const removeBasketItem = (index) => {
    dispatch(BasketActions.removeItem(itemList[index]));
  }

  const validateBasket = () => {
    dispatch(BasketActions.refresh(validateBasketRefreshCallback));
  }

  const validateBasketRefreshCallback = (isValid, validItemCount) => {
    if (isValid)
      navigation.navigate(screens.BasketValidation);
    else
      basketRefreshCallback(validItemCount);
  }

  const basketRefreshCallback = (validItemCount) => {
    if (validItemCount == 0) {
      dispatch(BasketActions.resetList());
      setIsAllBetsExpired(true);
    }
  }

  const addNewBet = () => {
    navigation.navigate(screens.BetCreate);
  }

  const headerLeftGoList = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => {
        setIsExitModalVisible(true);
      }}
    />
  );

  const headerRightBasket = (props) => (
    <View style={{ flexDirection: 'row' }}>
      <BasketBadge />
      <UserSticker />
    </View>
  );

  const giveUpBasket = () => {
    dispatch(BasketActions.clear_basket());
    navigation.navigate(screens.Games);
  }

  //====================================== Render

  return (
    <ScrollView style={style.container}>
      <View style={style.body}>

        {/* Basket Items */}
        {itemList?.map((item, index) => (
          item && <BasketItem key={index} index={index} item={item} removeBasketItem={removeBasketItem} />
        ))}

        {/* Error messages */}
        {!isValid && (
          <View style={[style.errorPart, itemList.length == 0 && style.errorPartFilled]}>
            <SVGError fill={theme.colors.expiredColor} style={{ marginRight: 5 }} />
            <Text style={style.errorText}>
              {
                itemList.length == 0 ? t(isAllBetsExpired ? 'basket.all_bets_expired' : 'basket.empty') :
                  !isTotalStakeValid ? t('basket.max_bet_exceeded') :
                    t('basket.remove_expired_items')
              }
            </Text>
          </View>
        )}

        {/* Footer */}
        {itemList.length > 0 && (
          <View style={style.totalStake}>
            <Text style={style.totalBetTextLeft}>{t('Total bet')}</Text>
            <Text style={style.totalBetTextRight}>{formatNumber(totalStake)} {currency}</Text>
          </View>
        )}

        <View style={style.actionButtons}>
          <StyledButton
            text={t('Add a bet')}
            onPress={addNewBet}
            fill='outline'
            buttonStyle={{ marginBottom: 0 }}
          />

          {itemList.length > 0 && (
            <StyledButton
              text={t('validate')}
              onPress={validateBasket}
              disabled={itemList.length === 0 || !isValid || !isTotalStakeValid}
            />
          )}
        </View>

        <BetCreateExitModal
          visible={isExitModalVisible}
          onClose={() => setIsExitModalVisible(false)}
          onConfirm={giveUpBasket}
        />
      </View>
    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (theme, isTotalStakeValid) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    flex: 1,
  },
  title: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    color: '#404040',
  },
  totalStake: {
    width: '100%',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 5,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: isTotalStakeValid ? '#EDEDED' : theme.colors.expiredBackground
  },
  totalBetTextLeft: {
    fontSize: 16,
    fontFamily: isTotalStakeValid ? 'Raleway-Medium' : 'Raleway-Bold',
    color: isTotalStakeValid ? theme.colors.greyText : theme.colors.expiredColor
  },
  totalBetTextRight: {
    fontSize: 21,
    fontFamily: 'Raleway-Bold',
    color: isTotalStakeValid ? theme.colors.greyText : theme.colors.expiredColor
  },
  actionButtons: {
    marginHorizontal: 30,
    marginTop: 20,
  },
  errorPart: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginTop: 10,
  },
  errorPartFilled: {
    backgroundColor: theme.colors.expiredBackground,
  },
  errorText: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.expiredColor
  }
})
