import React, { useEffect, useState } from "react";
import { useNavigation, useTheme } from '@react-navigation/native';
import { useSelector } from "react-redux";
import { View, Text, StyleSheet, Button, ScrollView, Pressable, Switch } from 'react-native';

import { SVGChevronRight } from "../../assets/icons";

import { useTranslation } from 'react-i18next';

import i18n from 'i18next';

const AppParameters = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [isDarkMode, setIsDarkMode] = useState(false);
  const userIsConnected = useSelector(state => state.user.isConnected);

  const onPressNavigatePage = (screen) => {
    navigation.navigate(screen);
  }

  return (
    <View style={style.container}>

      <View style={style.lineView}>
        <Text style={style.text}>
          {t('Application languages')}
        </Text>
        <Pressable style={style.rightView} onPress={() => onPressNavigatePage('LanguagePick')}>
          <Text style={style.text}>
            {t(i18n.language)}
          </Text>
          <SVGChevronRight style={style.icon} fill={theme.colors.greyText} />
        </Pressable>
      </View>

      <View style={style.separator}></View>

      <View style={style.lineView}>
        <Text style={style.text}>
          {t("Dark mode")}
        </Text>
        <View style={style.rightView}>
          <Switch
            disabled
            style={{marginRight: 7}}
            value={isDarkMode}
            onValueChange={() => setIsDarkMode(!isDarkMode)}
          />
        </View>
      </View>
      
      {userIsConnected && <View style={style.separator}></View>}
      
      {userIsConnected && 
        <View style={style.lineView}>
          <Pressable
            onPress={() => onPressNavigatePage('UserPasswordChange')}
          >
            <Text style={style.text}>
              {t("Change password")}
            </Text>          
          </Pressable>
        </View>
      }

    </View>
  );
};


const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  lineView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 55,
    alignItems: 'center',
    paddingHorizontal: 15
  },
  rightView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginLeft: 10
  },
  text: {
    fontSize: 13,
    fontFamily: 'Raleway-Medium',
    color: theme.colors.greyText
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.greyText
  }
})

export default AppParameters;