import moment from "moment";

export function dateLocaleLong(value) {
  return value ? moment(value).format('LLLL') : '';
}

export function dateFirstFormat(value) {
    if (!value) {
        return ""
    }
    return moment(value).format("dddd DD/MM");
}

export function dateSecondFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format("dddd DD/MM, HH:mm");
}

export function ticketDateFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('LLLL');
}

export function drawDateFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('DD/MM HH:mm');
}

export function extractDate(value, defaultValue = '') {
  return value ? moment(value).format('YYYY-MM-DD') : defaultValue;
}

export function basketDateFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('ll - HH:mm');
}

export function transactionFormat(value) {
  if (!value) {
      return ""
  }
  return moment(value).format('ll');
}

export function limitDateEbulletin(value) {
  if (!value) {
      return ""
  }
  return moment(value).format("DD/MM/YYYY HH:mm");
}