import { Provider, useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import store from './src/store/index';
import { Platform, StyleSheet, View } from 'react-native';
import * as navigationService from './src/store/services/navigation.service';
import './src/common/i18n/i18n';
import { initToken } from './src/store/actions/login.actions';
import { fetchWordpressData } from './src/store/actions/wordpressApi.actions';
import Main from './src/components/Main';
import AppLoader from './src/components/AppLoader';
import * as Localization from 'expo-localization';
import * as GamesActions from './src/store/actions/games.actions';
import * as UserActions from './src/store/actions/user.actions';

import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import moment from 'moment';
import 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { commonStyleSettings } from './src/common/style/common.style';
import { InitFirebase } from './src/store/services/firebase.service';
import { ToastProvider } from 'react-native-toast-notifications';
import { StyledToast } from './src/components/kpLibrary/StyledToast.comp';

export default function AppContainer() {
  return (
    <Provider store={store}>
      <ToastProvider
        renderToast={(toastOptions) => {
          return (
            <StyledToast
              {...toastOptions}
              visible={true}
            >
              {toastOptions?.message}
            </StyledToast>
          )
        }}
      >
        <App />
      </ToastProvider>
    </Provider>
  );
}

export function App() {

  const isWeb = Platform.OS === "web";
  const dispatch = useDispatch();
  const styles = makeStyles();
  const isKeycloackLogged = useSelector(state => state.login.isKeycloackLogged);
  const wpIsFetched = useSelector(state => state.wordpress.isFetched)
  const token = useSelector(state => state.login.token)
  const gamesList = useSelector(state => state.games.games)
  const theme = useSelector(state => state.wordpress.theme);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    initializeapp();
  }, []);

  useEffect(() => {
    // Games fetch
    if (token) {
      dispatch(GamesActions.getGames());
      dispatch(GamesActions.getNextSessionByGame());
    }
  }, [token]);

  const initializeapp = async () => {
    //token
    initToken(dispatch);
    //locale
    moment.locale(await AsyncStorage.getItem('USER_LANGUAGE') || Localization.locale.substring(0, 2));
    // Firebase
    InitFirebase();
    // Wordpress Data
    dispatch(fetchWordpressData());
    dispatch(UserActions.initialize());
  }

  // Fetching fonts for all the app
  const fetchFonts = async () =>
    await Font.loadAsync({
      'Raleway-Regular': require('./assets/fonts/Raleway/Raleway-Regular.ttf'),
      'Raleway-Medium': require('./assets/fonts/Raleway/Raleway-Medium.ttf'),
      'Raleway-SemiBold': require('./assets/fonts/Raleway/Raleway-SemiBold.ttf'),
      'Raleway-Bold': require('./assets/fonts/Raleway/Raleway-Bold.ttf'),
      'Raleway-Light': require('./assets/fonts/Raleway/Raleway-Light.ttf'),
      'Raleway-LightItalic': require('./assets/fonts/Raleway/Raleway-LightItalic.ttf'),
      'Raleway-BoldItalic': require('./assets/fonts/Raleway/Raleway-BoldItalic.ttf'),
      'Raleway-MediumItalic': require('./assets/fonts/Raleway/Raleway-MediumItalic.ttf'),
    });

  return (
    <NavigationContainer
      theme={theme}
      ref={navigatorRef => navigationService.setTopLevelNavigator(navigatorRef)}
      onReady={() => navigationService.onNavigationStateChange()}
      onStateChange={() => navigationService.onNavigationStateChange()}
    >
      <View style={isWeb ? styles.container1Web : styles.container1Phone}>
        <View style={isWeb ? styles.container2Web : styles.container2Phone}>
          {
            !fontsLoaded ?
              <AppLoading
                startAsync={fetchFonts}
                onError={console.warn}
                onFinish={() => setFontsLoaded(true)}
              /> :
              isKeycloackLogged && wpIsFetched && gamesList.length > 0 ?
                <Main /> :
                <AppLoader initializeapp={initializeapp} />
          }
        </View>
      </View>
    </NavigationContainer>
  );
}

const makeStyles = () => StyleSheet.create({
  container1Web: { flex: 1, backgroundColor: 'rgb(40, 39, 54)' },
  container1Phone: { flex: 1 },
  container2Web: {
    flex: 1,
    maxWidth: commonStyleSettings.maxWidth,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  container2Phone: { flex: 1, }
});