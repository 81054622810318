import React from 'react';

const HTMLView = ({
  contentWidth,
  html,
}) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: html }}></div>
  );
};

export default HTMLView;