import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, View, Text, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { commonStyles } from '../../common/style/common.style';
import * as betCreatorActions from '../../store/actions/betCreator.actions';
import { GameRulesLink } from './GameRulesLink.comp';

const BetTypePicker = ({
  goNextStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme()
  const styles = makeStyles(theme)
  const dispatch = useDispatch();

  const currentGame = useSelector(state => state.games.currentGame);
  const betTypesActiveByGame = useSelector(state => state.games.betTypesActiveByGame);

  const [betTypeList, setBetTypeList] = useState([]);
  const [betTypeButtonWidth, setBetTypeButtonWidth] = useState('50%');

  //====================================== Hooks

  useEffect(() => {
    const _betTypeList = betTypesActiveByGame?.[currentGame.gameId] || [];

    setBetTypeList(betTypesActiveByGame?.[currentGame.gameId] || []);

    const nameMaxLength = _betTypeList.reduce((max, betType) => Math.max(betType.name.length, max), 0);
    setBetTypeButtonWidth(nameMaxLength > 8 ? '50%' : '33%');

  }, [betTypesActiveByGame]);


  //====================================== Functions

  const onBetTypeSelect = (betType) => {
    dispatch(betCreatorActions.set_betType_selected(betType));
    goNextStep();
  }

  //====================================== Render

  const renderBetTypeButton = (betType) => {

    return (
      <View
        style={[ styles.betTypeButtonBox, {width: betTypeButtonWidth} ]}
        key={betType.betTypeId}
      >
        <Pressable
          style={({ pressed }) => {
            return [styles.betTypeButton, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={() => onBetTypeSelect(betType)}
        >
          <Text style={styles.betTypeButtonText}>
            {betType.name}
          </Text>
        </Pressable>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView>

      {/* <NextDrawCountdown /> */}

      <View style={styles.body}>

        <GameRulesLink game={currentGame} isFromGridPicker={true} />

        <Text style={styles.stepTitle}>
          {t('select_bet_type')}
        </Text>

        <View style={styles.betTypeListContainer}>
          {betTypeList.map(betType => (
            renderBetTypeButton(betType)
          ))}
        </View>

      </View>

      </ScrollView>
    </View>
  )
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingHorizontal: 15,
    flex: 1,
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginBottom: 10,
    color: '#404040',
  },
  //
  betTypeListContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  betTypeButtonBox: {
  },
  betTypeButton: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    margin: 5,
    borderRadius: 4,
    elevation: 5,
  },
  betTypeButtonText: {
    overflow: 'hidden',
    color: '#000',
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Raleway-Bold',
    paddingBottom: 2,
  },
})

//======================================
export default BetTypePicker;