import { CommonActions } from '@react-navigation/native';
import { trackPage } from './firebase.service';

let navigator;
let currentRouteName = '';

export const setTopLevelNavigator = navigatorRef => {
  navigator = navigatorRef;
};

export const navigate = (screen, params) => {
  navigator?.dispatch(
    CommonActions.navigate(screen, params)
  );
};

export const onNavigationStateChange = async () => {
  const newRouteName = navigator?.getCurrentRoute()?.name;

  if (newRouteName && newRouteName !== currentRouteName) {
    await trackPage(newRouteName);
  }
  currentRouteName = newRouteName;
}