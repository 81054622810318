import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Pressable, Modal, Platform } from 'react-native';
import { SVGClose, SVGDone, SVGError, SVGInformationCircle, SVGWarningOutline } from '../../assets/icons';
import { commonStyles } from '../../common/style/common.style';
import StyledButton from './forms/StyledButton.comp';

const DEFAULT_POSITION = 100;

const AlertPopup = ({
  type, //Required: success, error, info, warning
  message,
  onClose,
  title,
  position,
  visible,
  //action buttons
  disableAction1,
  onAction1,
  onAction2,
  textAction1,
  textAction2,
  actionFooter,
  useInlineButtons,
  //nested content
  children,
}) => {

  const isWeb = Platform.OS === "web";
  const theme = useTheme();

  const [iconBackground, setIconBackground] = useState(null);
  const styles = makeStyles(theme, position, iconBackground);

  const [messageIcon, setMessageIcon] = useState(null); //JSX


  useEffect(() => {
    refreshIcon();
  }, [type]);

  const refreshIcon = () => {
    let tmpIcon = null;
    let tmpIconBackground = null;
    switch (type) {
      case 'success':
        tmpIcon = <SVGDone fill='#30C408' />;
        tmpIconBackground = '#CEFAC2';
        break;
      case 'info':
        tmpIcon = <SVGInformationCircle fill={theme.colors.primary} />;
        tmpIconBackground = '#DBE3FF';
        break;
      case 'warning':
        tmpIcon = <SVGWarningOutline stroke='#F83D3D' fill='#F83D3D' />;
        tmpIconBackground = '#FADFDF';
        break;
      case 'error':
        tmpIcon = <SVGError fill='#F83636' />;
        tmpIconBackground = '#FADFDF';
        break;
    }
    setMessageIcon(tmpIcon);
    setIconBackground(tmpIconBackground);
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={() => onClose(!visible)}
    >
      <View style={[isWeb ? commonStyles.webModalContainer : commonStyles.phoneModalContainer, styles.backdrop]}>
        <View style={styles.modalView}>

          <View style={styles.topContainer}>
            <View style={styles.messageIconContainer}>
              {messageIcon}
            </View>
            <Pressable style={styles.closeButton} onPress={() => onClose()}>
              <SVGClose fill='#707070' />
            </Pressable>
          </View>

          <View style={styles.bodyContainer}>
            {title &&
              <View style={styles.title}>
                <Text style={styles.titleText}>
                  {title}
                </Text>
              </View>
            }
            {message &&
              <View style={styles.message}>
                <Text style={styles.messageText}>
                  {message}
                </Text>
              </View>
            }
            {children}
          </View>

          {textAction1 &&
            <View style={[styles.buttonsContainer, useInlineButtons && styles.buttonsInlineContainer]}>
              <StyledButton
                disabled={disableAction1}
                onPress={() => onAction1 && onAction1()}
                text={textAction1}
                buttonStyle={[{ height: 45, margin: 5 }, styles.button1, disableAction1 && styles.disabled]}
                buttonContainerStyle={[useInlineButtons && styles.buttonInline]}
              />
              {textAction2 &&
                <StyledButton
                  onPress={() => onAction2 && onAction2()}
                  text={textAction2}
                  buttonStyle={[{ height: 45, margin: 5 }, styles.button2]}
                  textStyle={styles.button2Text}
                  buttonContainerStyle={[useInlineButtons && { flex: 1 }]}
                />
              }
            </View>
          }
          {actionFooter &&
            <View style={styles.buttonsContainer}>
              {actionFooter}
            </View>
          }
        </View>
      </View>
    </Modal>
  );
};

const makeStyles = (theme, position, iconBackground) => StyleSheet.create({
  //Modal
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: position && isNaN(parseInt(position)) ? position : 'flex-start',
    paddingTop: !position ? DEFAULT_POSITION : !isNaN(parseInt(position)) ? position : 0,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    // padding: 15,
    minWidth: 300,
    maxWidth: '90%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  topContainer: {
    padding: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  //body
  bodyContainer: {
    paddingTop: 5,
    paddingBottom: 24,
    paddingHorizontal: 18,
  },
  messageIconContainer: {
    width: 40,
    height: 40,
    backgroundColor: iconBackground,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  closeButton: {
    borderColor: '#CFCFCF',
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: 26,
    height: 26,
  },
  titleText: {
    marginVertical: 5,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 18,
  },
  messageText: {
    marginVertical: 5,
    fontFamily: 'Raleway-Medium',
    color: theme.colors.greyText,
    fontSize: 14,
  },
  // Action Buttons
  buttonsContainer: {
    backgroundColor: '#EDEDED',
    paddingHorizontal: 8,
    paddingVertical: 10,
    borderRadius: 5,
  },
  buttonsInlineContainer: {
    flexDirection: 'row',
  },
  buttonInline: {
    flex: 1,
  },
  button1: {
    backgroundColor: theme.colors.primary,
  },
  button1Text: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  button2: {
    backgroundColor: '#fff',
  },
  button2Text: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
  },
  disabled: {
    opacity: .5,
  }

});

export default AlertPopup;