import { View, Text, StyleSheet } from "react-native";
import { useFocusEffect, useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from "react";

import * as GamesActions from '../../store/actions/games.actions';

const Paytable = ({
  game,
  openSimple
}) => {

  const dispatch = useDispatch();
  const style = makeStyles(useTheme(), openSimple);
  const { t } = useTranslation();
  const currentPaylinesList = useSelector(state => state.games.currentPaylinesList); 

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(GamesActions.computePaytableInfos(game))
      return () => {
        //Page leave
        dispatch(GamesActions.computePaytableInfos([]))
      }
    }, [game])
  );

  const computeNumbersMessage = (elem) => {
    const injectionData = {totalFoundNumbers: elem.totalFoundNumbers, minRange: elem.minRange, maxRange: elem.maxRange};
    if (elem.totalFoundNumbers > 1) {
      if (elem.isInOrder) {
        return t('PaytableMessage.multiple.isInOrder', injectionData)
      } else {
        return t('PaytableMessage.multiple', injectionData)
      }
    } else {
      if (elem.isInOrder) {
        return t('PaytableMessage.isInOrder', injectionData)
      } else {
        return t('PaytableMessage', injectionData)
      }
    }
  }

  return (
    <View>
      {currentPaylinesList?.length > 0 && (
        <View style={style.container}>

            <View style={style.gainsContainer}>
              <View style={style.gainsCategoryPart}>
                <Text style={style.gainsTitle}>{t('Good numbers')}</Text>
              </View>
              <View style={style.gainsPartTop}>
                <Text style={style.gainsTitle}>{t("Gain")}</Text>
              </View>
            </View>
          
          {currentPaylinesList?.map((elem, index) => (
            <View key={index} style={style.gainsContainer}>
              <View style={style.gainsCategoryPart}>
                <Text style={style.gainsCategoryText}>
                  {elem.minRange > 0 && elem.maxRange > 0 ? 
                    computeNumbersMessage(elem) : 
                    elem.categoryGain
                  }
                </Text>
              </View>
              <View style={style.gainsPart}>
                <Text style={style.gainsText}>
                  {elem.gain === 'Jackpot' ?
                    t('Jackpot')
                    : elem.gain} {elem.gain !== 'Jackpot' && t('x the bet')}
                </Text>
              </View>
            </View>
          ))}
        </View>    
      )}
    </View>

  );
};

export default Paytable;

const makeStyles = (theme, openSimple) => StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginBottom: 15,
  },
  betTypeLine: {
      flexDirection: 'row',
      height: 60,
      paddingLeft: 5,
      marginBottom: 5
  },
  betTypeCard: {
      backgroundColor: '#ffffff',
      height: 45,
      minWidth: 45,
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 2,
      marginHorizontal: 3,
      paddingHorizontal: 10
  },
  betTypeCardSelected: {
      backgroundColor: theme.colors.primary,
      height: 45,
      minWidth: 45,
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 2,
      marginHorizontal: 3,
      paddingHorizontal: 10
  },
  betTypeCardText: {
      fontFamily: 'Raleway-Bold',
      fontSize: 16,
      color: theme.colors.greyText
  },
  betTypeCardTextSelected: {
      fontFamily: 'Raleway-Bold',
      fontSize: 16,
      color: '#ffffff'
  },
  gainsContainer: {
      flexDirection: 'row'
  },
  gainsCategoryPart: {
      width: '59%',
      marginRight: 5
  },
  gainsPartTop: {
    width: '40%',
},
  gainsPart: {
      width: '40%',
      backgroundColor: theme.colors.primary,
      color: '#ffffff',
      borderRadius: 5,
      marginBottom: 3,
      justifyContent: 'center',
      alignItems: 'center'
  },
  gainsTitle: {
      fontFamily: 'Raleway-Bold',
      fontSize: 12,
      backgroundColor: theme.colors.greyText,
      color: '#ffffff',
      borderRadius: 5,
      textAlign: 'center',
      paddingVertical: 5,
      marginBottom: 8
  },
  gainsCategoryText: {
      fontFamily: 'Raleway-Bold',
      fontSize: 12,
      backgroundColor: openSimple ? '#ffffff' : '#EDEDED',
      color: theme.colors.greyText,
      borderRadius: 5,
      textAlign: 'center',
      paddingVertical: 5,
      paddingHorizontal: 10,
      marginBottom: 3
  },
  gainsText: {
      fontFamily: 'Raleway-Bold',
      fontSize: 12,
      backgroundColor: theme.colors.primary,
      color: '#ffffff',
      borderRadius: 5,
      textAlign: 'right',
      paddingVertical: 5,
      marginBottom: 3
  }
});