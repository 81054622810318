import * as TaxesServices from '../services/taxes.service';

export const ACTIONS = {
  TAXES_LOADED: 'TAXES_LOADED',
  NO_TAXES: 'NO_TAXES'
}

export const set_load_taxes = (taxList) => {
  return { type: ACTIONS.TAXES_LOADED, payload: taxList };
}

export const set_no_taxes = () => {
  return { type: ACTIONS.NO_TAXES };
}

export const LoadTaxes = () => {
  return async dispatch => {
    let r = await TaxesServices.LoadTaxes();
    if (r.data?.success) {
      dispatch(set_load_taxes(r.data?.listOfTaxLevel || []))
    } else {
      dispatch(set_no_taxes())
    }
  }
}