import React from 'react';
import { useSelector } from 'react-redux';
import Games from "./Games.page"
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Image } from 'react-native';
import images from '../../assets/images';
import { useTheme } from '@react-navigation/native';
import UserSticker from '../../components/user/UserSticker.comp';
import { getDefaultScreenOptions } from '../../common/theme/theme';

const Stack = createNativeStackNavigator();


const GamesNavigation = () => {

  const theme = useTheme();
  const sourceUrlLogoOperator = useSelector(state => state.wordpress.theme.logoOperator)

  return (
    <Stack.Navigator
      initialRouteName="GamesHome"
      screenOptions={getDefaultScreenOptions(theme)}
    >
      <Stack.Screen
        name="GamesHome"
        component={Games}
        options={
          {
            headerTitle: (props) => (
              <Image
                source={sourceUrlLogoOperator ? {uri: sourceUrlLogoOperator} : images.logo_operator}
                style={{ width: 145, height: 45 }}
              />
            ),
            headerRight: () => (
              <UserSticker />
            ),
          }
        } 
      />
    </Stack.Navigator>
  )
}

export default GamesNavigation; 