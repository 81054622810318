import { useTheme } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { Animated, StyleSheet, View, Text, TouchableWithoutFeedback } from "react-native";
import { SVGDone, SVGError, SVGInformationCircle, SVGWarningOutline } from "../../assets/icons";
import { commonStyleSettings } from "../../common/style/common.style";

//====================================== Component
export const StyledToast = ({
  hideOnPress,
  onHide,
  onPress,
  visible,

  animation,
  children,
  duration,
  iconType, //success, info, warning, error
  position,

  contentStyle,
  textStyle,
}) => {

  const TOAST_ANIMATION_DURATION = 300;

  //====================================== Hooks

  const theme = useTheme();
  const styles = makeStyles();

  const [iconBackground, setIconBackground] = useState(null);
  const [messageIcon, setMessageIcon] = useState(null); //JSX

  const [hideTimer, setHideTimer] = useState(null);
  const hideTimerRef = useRef(hideTimer);
  hideTimerRef.current = hideTimer;

  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (visible) {
      handleShow();
      duration && setHideTimer(setTimeout(() => handleHide(), duration));
    }
    return () => {
      clearTimeout(hideTimer);
    }
  }, [visible])

  useEffect(() => {
    refreshIcon();
  }, [iconType]);

  //====================================== Functions

  const handleShow = () => {
    if (animation) {
      Animated.timing(opacity, {
        toValue: 1,
        duration: TOAST_ANIMATION_DURATION,
        useNativeDriver: false,
      }).start();
    } else {
      opacity.setValue(1);
    }
  }

  const handleHide = () => {
    if (animation) {
      Animated.timing(opacity, {
        toValue: 0,
        duration: TOAST_ANIMATION_DURATION,
        useNativeDriver: false,
      }).start(({ finished }) => {
        finished && onHide && onHide();
      });
    } else {
      opacity.setValue(0);
      onHide && onHide();
    }
  }

  const refreshIcon = () => {
    let tmpIcon = null;
    let tmpIconBackground = null;
    switch (iconType) {
      case 'success':
        tmpIcon = <SVGDone fill='#30C408' />;
        tmpIconBackground = '#CEFAC2';
        break;
      case 'info':
        tmpIcon = <SVGInformationCircle fill={theme.colors.primary} />;
        tmpIconBackground = '#DBE3FF';
        break;
      case 'warning':
        tmpIcon = <SVGWarningOutline stroke='#F83D3D' fill='#F83D3D' />;
        tmpIconBackground = '#FADFDF';
        break;
      case 'error':
        tmpIcon = <SVGError fill='#F83636' />;
        tmpIconBackground = '#FADFDF';
        break;
    }
    setMessageIcon(tmpIcon);
    setIconBackground(tmpIconBackground);
  }

  //====================================== Render
  return !visible ? null : (
    <View
      style={[
        styles.containerStyle,
        position < 0 ?
          { bottom: -position } :
          position > 0 ?
            { top: position } :
            { top: 0, bottom: 0 },
      ]}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          onPress && onPress();
          hideOnPress && onHide && handleHide();
        }}
      >
        <Animated.View
          style={[
            styles.contentStyle,
            contentStyle,
            { opacity },
          ]}
        >
          <View style={[
            styles.messageIconContainer,
            { backgroundColor: iconBackground }
          ]}>
            {messageIcon}
          </View>
          <Text style={[
            styles.textStyle,
            textStyle,
          ]}>
            {children}
          </Text>
        </Animated.View>
      </TouchableWithoutFeedback>
    </View>
  )
}

//====================================== Styles

const makeStyles = () => StyleSheet.create({
  containerStyle: {
    // position: 'absolute',
    left: 0,
    right: 0,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentStyle: {
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: commonStyleSettings.maxWidth - 20,
    marginHorizontal: 20,
    //shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: 10
  },
  textStyle: {
    flex: 1,
    fontSize: 16,
    color: '#404040',
    textAlign: 'center'
  },
  messageIconContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginRight: 10,
  },
});


// export default ToastController;