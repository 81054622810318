import { ACTIONS } from '../actions/ebulletin.actions';
import { saveMyEbulletinsToStorage } from '../services/ebulletin.service';

//=========================================================
// type definitions (for information)

const typeBulletinItem = {
  betTypeId: 42,
  drawId: 123456, // undefined for FOGO-like games
  nbConsecutiveDraws: 5, //default 1 - for FOGO-like games
  potentialAward: 10000,
  price: 500,
  selectionListByGrid: [[2, 4, 6, 8, 10], [4]],
  stake: 100,
  draw: {}, // draw object ref. (= the session)
}
const typeBulletinApiMetadata = {
  bulletinCode: '',
  content: '',
  phoneNumber: '',
  allocationDate: '',
  expiryDate: '',
}
const typeBulletin = {
  gameId: 1,
  gameType: 'instantLottery',
  playerReference: '0606060606',
  totalStake: 1200,
  dateLimit: '',
  firstDrawDate: '', //optional
  api: {}, // typeBulletinApiMetadata
  itemList: [], // typeBulletinItem
}

//=========================================================
// initial state of this reducer

const initialState = {
  error: false,
  isSportBet: false,
  isFetching: false,
  //
  currentBulletin: {},
  myEbulletinList: [], //typeBulletin
}

//=========================================================
// Reducer to catch the actions
// Format of action should be { type, payload }

export default function ebulletinReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.BULLETIN_ERROR: {
      console.warn("Bulletin action error : " + JSON.stringify(action.payload), action.payload);
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.BULLETIN_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.BULLETIN_FETCHING_DONE: {
      return { ...state, isFetching: false }
    }
    case ACTIONS.BULLETIN_RESET_SEARCH_STATE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.BULLETIN_IS_SPORTBET: {
      return { ...state, isSportBet: action.payload }
    }
    case ACTIONS.BULLETIN_CURRENT_BULLETIN: {
      return { ...state, currentBulletin: action.payload }
    }
    case ACTIONS.BULLETIN_MY_BULLETIN_LIST:
      action.payload.save && saveMyEbulletinsToStorage(action.payload.list || []);
      return {
        ...state,
        myEbulletinList: action.payload.list || [],
      }
    default:
      return state
  }
}