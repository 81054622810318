import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;
const API_URL_v2d = API_URL.replace("/v2/", "/v2-e/");

export const getTicket = async (reference) => {
  let params = {};
  return wp_axios.get(`${API_URL_v2d}/tickets/${reference}`, { params });
}

//=============================================================================
// HELPERS
//=============================================================================

const STORAGE_MY_TICKET_LIST = 'ticket.myTicketList';

export const LoadMyTicketsFromStorage = async () => {
  const strData = await AsyncStorage.getItem(STORAGE_MY_TICKET_LIST);
  return strData ? JSON.parse(strData) : null;
}
export const saveMyTicketsToStorage = (list) => {
  const strData = JSON.stringify(list);
  return AsyncStorage.setItem(STORAGE_MY_TICKET_LIST, strData);
}

/**
 * Keep only the base info of a ticket (ref, ...), 
 * in order to store it in the storage and retrieve full data from api
 */
export const shortenTicket = (ticket, game) => {
  let firstBet = ticket.listOfBets[0];
  if (!firstBet) {
    return null;
  }
  return {
    amtTotalBet: ticket.amtTotalBet,
    drawingDate: getEarliestDrawingDate(ticket),
    reference: ticket.reference,
    gameId: firstBet.gameId,
    gameName: game.name,
  }
}

export const getEarliestDrawingDate = (ticket) => {
  return ticket.listOfBets.reduce((acc, cur) => {
    //mutualLottery
    if (cur.draw?.drawingDate) {
      return !acc || cur.draw?.drawingDate < acc ? cur.draw?.drawingDate : acc;
    }
    //instantLottery
    else if (cur.listOfStatusAndDraws?.length) {
      return !acc || cur.listOfStatusAndDraws[0].draw?.drawingDate < acc ? cur.listOfStatusAndDraws[0].draw?.drawingDate : acc;
    }
    // sportLottery
    else if (cur.contestDate) {
      return !acc || cur.contestDate < acc ? cur.contestDate : acc;
    }
    // default: use the creationTime
    return !acc || cur.creationTime < acc ? cur.creationTime : acc;

  }, null);
}

export const isMarkerSpecifier = (typeId) => {
  const specificierMarketId = [235, 202];
  return specificierMarketId.some(numb => numb == typeId);
}