import React, { useEffect, useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import StyledButton from './forms/StyledButton.comp';
import SwipeDownModal from './SwipeDownModal.comp';

const ItemPickerModal = ({
  data,
  fieldId,
  fieldName,
  multiSelect,
  customStyles = {
    itemList: null,
    item: null,
    itemSelected: null,
    itemText: null,
    itemTextSelected: null,
  },
  renderItem,
  preselectedIdList,
  itemAllId,
  itemAllName,
  title,
  visible,
  //
  onApply,
  onClose,
}) => {

  const styles = makeStyles(useTheme());
  const { t } = useTranslation();

  const _array2obj = arr => arr ? arr.reduce((a, v) => ({ ...a, [v]: true }), {}) : {};

  const itemAll = { [fieldId]: itemAllId, [fieldName]: itemAllName };

  const [mapSelectedId, setMapSelectedId] = useState(_array2obj(preselectedIdList)); // { id1:boolean, ...}

  //====================================== Hooks

  useEffect(() => {
    if (visible) {
      // Init values
      let list = preselectedIdList?.length > 0 ? preselectedIdList : itemAllId != undefined ? [itemAllId] : [];
      setMapSelectedId(_array2obj(list));
    }
  }, [visible]);

  //====================================== Functions

  const onItemPress = (item) => {
    if (multiSelect) {
      //select or deselect
      const id = item[fieldId];
      let map = { ...mapSelectedId };
      if (item === itemAll) {
        map = { [id]: true };
      } else {
        map[id] = map[id] ? false : true;
        if (itemAllId != undefined && map[itemAllId]) {
          delete map[itemAllId];
        }
      }
      setMapSelectedId(map);
    }
    else {
      //validate the item selection
      onApply([item[fieldId]]);
      onClose();
    }
  }

  const applyAndClose = () => {
    //convert object to array
    const selectedIdList = Object.entries(mapSelectedId).filter(entry => entry[1] == true).map(entry => entry[0]);
    onApply(selectedIdList);
    onClose();
  }

  //====================================== Render

  const renderItemDefault = (item) => {
    const isSelected = mapSelectedId[item[fieldId]] == true;
    const itemStyles = [
      styles.item,
      isSelected && styles.itemSelected,
      customStyles?.item,
      isSelected && customStyles?.itemSelected,
    ];
    const itemTextStyles = [
      styles.itemText,
      isSelected && styles.itemTextSelected,
      customStyles?.itemText,
      isSelected && customStyles?.itemTextSelected,
    ];
    return (
      <Pressable style={itemStyles} onPress={() => onItemPress(item)}>
        <Text style={itemTextStyles}>
          {item[fieldName]}
        </Text>
      </Pressable>
    )
  };

  return (
    <SwipeDownModal
      onClose={onClose}
      title={title}
      visible={visible}
      titleTextStyle={{ textAlign: 'left', color: '#404040' }}
    >
      <ScrollView style={{ flexGrow: 0 }}
        contentContainerStyle={[styles.itemListView, customStyles?.itemList]}
      >
        {itemAllId != undefined &&
          (renderItem && renderItem(itemAll, onItemPress) || renderItemDefault(itemAll))
        }
        {
          data?.map((item, key) => (
            <View key={key}>
              {renderItem && renderItem(item, onItemPress) || renderItemDefault(item)}
            </View>
          ))
        }
      </ScrollView>
      <View style={styles.buttonBar}>
        <StyledButton
          onPress={applyAndClose}
          text={t('validate')}
        />
      </View>
    </SwipeDownModal>
  )
};

const makeStyles = (theme) => StyleSheet.create({
  //--Items
  itemListView: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  item: {
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    borderRadius: 20,
    margin: 5,
    minWidth: 60,
    padding: 10,
  },
  itemSelected: {
    backgroundColor: '#D0E9FF',
  },
  itemText: {
    color: '#404040',
    fontFamily: 'Raleway-SemiBold',
    fontSize: 14,
  },
  itemTextSelected: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
  },
  //--Buttons
  buttonBar: {
    justifyContent: 'center',
    margin: 15,
  },
});

export default ItemPickerModal;