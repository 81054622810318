import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGFlash = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={24} width={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path d="M11 21h-1l1-7H7.5c-.88 0-.33-.75-.31-.78C8.48 10.94 10.42 7.54 13.01 3h1l-1 7h3.51c.4 0 .62.19.4.66C12.97 17.55 11 21 11 21z" />
  </Svg>
)

export default SVGFlash