import React, { useState } from 'react';
import { useNavigation, useTheme } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import screens from '../../common/constants/screen.constants';
import AlertPopup from '../../components/kpLibrary/AlertPopup.comp';
import * as UserActions from '../../store/actions/user.actions';

const UserDeactivation = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [disableModalVisible, setDisableModalVisible] = useState(false);
  const userError = useSelector(state => state.user.error);

  const setUserDisable = () => {
    setDisableModalVisible(true);
  }

  const onDisableAccount = () => {
    const callback = (success) => {
      if (success) {
        setDisableModalVisible(false);
        navigation.navigate(screens.UserWelcome, { title: t('Account Not Activated Title')});
      }
    }
    dispatch(UserActions.onDisableUserAccount(callback));
  }

  return (
    <View>

      <Pressable style={style.disableButton} onPress={() => setUserDisable()} >
        <Text style={style.disableButtonText}>
          {t("Disable Account")}
        </Text>
      </Pressable>

      <AlertPopup
        type={userError ? 'error' : 'warning'}
        title={userError ? null : t('Confirm_deactivation.title')}
        message={userError ? t('deactivation_error_retry') : t('Confirm_deactivation.message')}
        visible={disableModalVisible}
        onClose={() => setDisableModalVisible(false)}
        textAction1={userError ? t('retry') : t('confirm')}
        textAction2={userError ? null : t('cancel')}
        useInlineButtons={userError ? false : true}
        onAction1={() => onDisableAccount()}
        onAction2={() => setDisableModalVisible(false)}
      />
    </View>
  );
};

export default UserDeactivation;

const makeStyles = (theme) => StyleSheet.create({
  disableButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    width: '100%',
    height: 40,
    marginBottom: 20
  },
  disableButtonText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.expiredColor,
    fontSize: 15
  }
})
