import {  useTheme } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import DrawResultList from '../../components/results/DrawResultList.comp';

const ResultsPage = ({ route }) => {

  const style = makeStyles(useTheme());
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <View style={style.container}>

      <View style={style.tabBar}>
        <Pressable style={[style.tabButton, selectedTabIndex == 0 && style.tabButtonSelected]} onPress={() => setSelectedTabIndex(0)}>
          <Text style={[style.tabButtonText, selectedTabIndex == 0 && style.tabButtonTextSelected]}>
            {t('results.draws_results')}
          </Text>
        </Pressable>
        <Pressable style={[style.tabButton, selectedTabIndex == 1 && style.tabButtonSelected]} onPress={() => setSelectedTabIndex(1)}>
          <Text style={[style.tabButtonText, selectedTabIndex == 1 && style.tabButtonTextSelected]}>
            {t('results.most_popular_numbers')}
          </Text>
        </Pressable>
      </View>
      
      {selectedTabIndex == 0
        && <DrawResultList route={route} />
        || <Text>EN CONSTRUCTION</Text>
      }

    </View>
  )

}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginVertical: 5,
    flex: 1,
    backgroundColor: '#F6F6F6'
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    padding: 1,
    marginVertical: 5,
    borderRadius: 5,
    borderColor: theme.colors.primary,
    borderWidth: 2,
  },
  tabButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: 10,
    paddingLeft: 2,
    width: '50%',
  },
  tabButtonSelected: {
    backgroundColor: theme.colors.primary,
    borderRadius: 4,
  },
  tabButtonText: {
    color: theme.colors.primary,
    fontSize: 12,
    fontFamily: 'Raleway-SemiBold',
  },
  tabButtonTextSelected: {
    color: '#fff',
  },
});

export default ResultsPage; 