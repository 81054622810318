import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import { SVGEdit } from '../../assets/icons';
import StyledButton from '../../components/kpLibrary/forms/StyledButton.comp';
import CustomAmountModal from '../../components/betCreation/CustomAmountModal';
import { useDispatch } from "react-redux";
import { HeaderBackButton } from "@react-navigation/elements";
import * as UserActions from '../../store/actions/user.actions';
import screens from '../../common/constants/screen.constants';
import { useToast } from "react-native-toast-notifications";
import AlertPopup from '../../components/kpLibrary/AlertPopup.comp';

const UserLimitModifier = ({route}) => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const toast = useToast();
  const [customAmountModalVisible, setCustomAmountModalVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [unsaveChangesPopupVisible, setUnsaveChangesPopupVisible] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const currentData = route?.params?.data;
  const currencyISO = route?.params?.currencyISO;
  const limitType = route?.params?.limitType;


  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      navigation.setOptions({
        title: t(route?.params?.title),
      });
      setCustomAmount(currentData?.Amount)
    }, [])
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerLeftBackTabHome
    });
  }, [customAmount])

  const headerLeftBackTabHome = (props) => (
    <HeaderBackButton
      {...props}
      onPress={() => goBack()}
    />
  );

  const goBack = () => {
    if (currentData?.Amount == customAmount) {
      navigation.goBack();
    } else {
      setUnsaveChangesPopupVisible(true);
    }
  }

  const onOpenModal = () => {
    setCustomAmountModalVisible(true);
  }

  const onValidateCustomAmount = () => {
    setCustomAmountModalVisible(false);
  }

  const callback = (success) => {
    if (success) {
      navigation.navigate(screens.UserLimits);
      toast.show(t('changes_saved'), {
        animation: true,
        duration: 2000,
        iconType: success ? 'success' : 'error',
        position: 30,
        hideOnPress: true,
        onHide: toast.hideAll,
        containerStyle: { width: 300 },
      });
    }
  }

  const onSaveChanges = () => {
    const data = {
      limitType,
      periodType: "Week",
      amount: +customAmount
    }
    if (currentData === undefined) {
      dispatch(UserActions.onCreateUserLimit(data, callback));      
    } else {
      dispatch(UserActions.onModifyUserLimit(data, currentData.LimitId, callback));
    }

  }

  const onDeleteLimit = () => {
    dispatch(UserActions.onDeleteUserLimit(currentData?.LimitId, callback));
  }

  return (
    <View style={style.container}>

      <View>
        <View style={style.verticalView}>
          <Text style={style.textTitle}>
            {t('Period')}
          </Text>
          <Text style={style.textPeriod}>
            {t('7 days')}
          </Text>
        </View>
        <View style={style.separator}></View>

        <View style={style.lineView}>
          <View style={style.verticalView}>
            <Text style={style.textTitle}>
              {t('Limit Amount')}
            </Text>
            <Text style={customAmount ? style.textRightExistant : style.textRight}>
              {customAmount ? customAmount + ' ' + currencyISO : t('Not defined')}
            </Text>
          </View>
          <Pressable style={style.rightView} onPress={() => onOpenModal()}>
            <SVGEdit style={style.icon} fill={theme.colors.greyText} />   
          </Pressable>
        </View>
        <View style={style.separator}></View>        
      </View>

      <View style={style.buttonView}>
        {(currentData === undefined && +customAmount > 0)
          || (currentData?.Amount != customAmount)
          ?
            <StyledButton
              text={t('save_changes')}
              onPress={() => onSaveChanges()}
              buttonStyle={{marginBottom: 10}}
            />
          : <View></View>
        }

        {currentData !== undefined && currentData?.Amount == +customAmount &&
          <StyledButton
            text={t('Delete the limit')}
            onPress={() => setDeletePopupVisible(true)}
            buttonStyle={{marginBottom: 10}}
          />
        }
      </View>

      <CustomAmountModal
        modalVisible={customAmountModalVisible}
        onClose={() => {
          setCustomAmount(currentData?.Amount || 0);
          setCustomAmountModalVisible(false);
        }}
        currency={currencyISO}
        onValidate={onValidateCustomAmount}
        personalizedAmount={customAmount}
        computeCustomAmount={setCustomAmount}
        minAmount={100}
        maxAmount={10000000}
      />

      <AlertPopup
        visible={deletePopupVisible}
        type={'warning'}
        useInlineButtons={true}
        title={t('Delete the limit') + ' ?'}
        onAction2={onDeleteLimit}
        onAction1={() => setDeletePopupVisible(false)}
        onClose={() => setDeletePopupVisible(false)}
        textAction2={t('yes')}
        textAction1={t('no')}
      />

      <AlertPopup
        visible={unsaveChangesPopupVisible}
        type={'warning'}
        useInlineButtons={true}
        title={t('popup.unsaved_changes.title')}
        message={t('popup.unsaved_changes.confirm_exit')}
        onAction2={() => navigation.goBack()}
        onAction1={() => setUnsaveChangesPopupVisible(false)}
        onClose={() => setUnsaveChangesPopupVisible(false)}
        textAction2={t('yes')}
        textAction1={t('no')}
      />
    </View>
  );
};

export default UserLimitModifier;

const makeStyles = (theme) => StyleSheet.create({
  container: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  lineView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 63,
    alignItems: 'center',
  },
  verticalView: {
    flexDirection: 'column',
    height: 63,
  },
  leftView: {
    flexDirection: 'column',
    marginLeft: 10
  },
  rightView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginHorizontal: 16
  },
  textTitle: {
    fontSize: 13,
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    marginVertical: 10
  },
  textPeriod: {
    fontSize: 13,
    fontFamily: 'Raleway-Semibold',
    color: theme.colors.greyText,
  },
  text: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    textTransform: 'uppercase',
  },
  textRight: {
    fontSize: 13,
    fontFamily: 'Raleway-LightItalic',
    color: theme.colors.greyText,
    marginRight: 15
  },
  textRightExistant: {
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    marginRight: 15
  },
  leftTextSubText: {
    fontSize: 13,
    marginTop: 5,
    fontFamily: 'Raleway-Light',
    color: theme.colors.greyText,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.greyText
  },
  modalLine: {
    height: 41,
    paddingHorizontal: 47,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalLineText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.greyText,
    fontSize: 13
  },
  modalLineSelected: {
    height: 41,
    paddingHorizontal: 47,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#D0E9FF'
  },
  modalLineTextSelected: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 13
  },
  buttonView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginHorizontal: 40,
    marginVertical: 20,
  },
})
