import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { getLocales } from 'react-native-localize';
import * as Localization from 'expo-localization';

import AsyncStoragePlugin from 'i18next-react-native-async-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { translationFR } from './translation.fr';
import { translationEN } from './translation.en';
import { translationPT } from './translation.pt';

import 'moment/locale/fr';
import 'moment/locale/pt';

const getI18n = () => {

  const detectUserLanguage = async (callback) => {
    const locale_used = await AsyncStorage.getItem('USER_LANGUAGE') || Localization.locale.substring(0, 2);
    callback(locale_used);
  }

  i18n
    .use(initReactI18next)
    .use(AsyncStoragePlugin(detectUserLanguage))
    .init({
      resources: {
        fr: { translation: translationFR },
        en: { translation: translationEN },
        pt: { translation: translationPT },
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ","
      },
      react: {
        useSuspense: true,
      },
      compatibilityJSON: 'v3',
      keySeparator: false,
    });

  return i18n;
}

export default getI18n();
