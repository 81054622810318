import { View, Text, StyleSheet, ScrollView, ActivityIndicator, Keyboard } from "react-native";
import { useTheme, useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../store/actions/user.actions";
import { SVGError } from "../../assets/icons";
import { useState, useEffect } from "react";
import StyledTextInput from "../../components/kpLibrary/forms/StyledTextInput.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import { useToast } from "react-native-toast-notifications";
import PasswordDoubleCheckInput from "../../components/kpLibrary/forms/PasswordDoubleCheckInput";
import { useRef } from "react";


const UserPasswordChange = () => {

  //====================================== Hooks

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const toast = useToast();


  const isFetching = useSelector(state => state.user.isFetching);
  const isPasswordChanged = useSelector(state => state.user.isPasswordChanged);
  const passwordError = useSelector(state => state.user.passwordError);
  const [buttonIsDisabled, setIsDisabled] = useState(true);

  const passwordDoubleCheckRef = useRef();
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(UserActions.reset_password_error());
      return () => {
        //Page leave
      }
    }, [])
  );

  useEffect(() => {
    if (isPasswordChanged) {
      const hide = () => {
        toast.hideAll();
        setTimeout(() => navigation.navigate('AppParameters'), 2000)
        dispatch(UserActions.set_password_changed(false));
        return;
      }
      toast.show(t('changes_saved'), {
        animation: true,
        duration: 2000,
        iconType: 'success',
        position: -50,
        hideOnPress: true,
        onHide: hide(),
        containerStyle: { width: 300 },
      });
    }
  }, [isPasswordChanged])

  useEffect(() => {
    setIsDisabled(!oldPassword.length || !newPassword.length || !newPassword2.length);
  }, [oldPassword, newPassword, newPassword2])

  //====================================== Functions

  const onNewPasswordInputChange = (value, confirmedValue, isValid) => {
    setNewPassword(value);
    setNewPassword2(confirmedValue);
    setIsNewPasswordValid(isValid);
  }

  const onChangePasswordPress = () => {
    Keyboard.dismiss();
    passwordDoubleCheckRef.current?.displayErrors();
    if (isNewPasswordValid) {
      dispatch(UserActions.reset_password_error());
      dispatch(UserActions.changePassword({ oldPassword, newPassword }));
    }
  }

  //====================================== Render
  return (
    <ScrollView
      style={style.container}
      contentContainerStyle={{ alignItems: 'center' }}
    >
      <View style={style.content}>

        {/* Actual Password Input */}
        <StyledTextInput
          label={t('Actual password')}
          value={oldPassword}
          isError={passwordError.length > 1}
          onChangeText={text => setOldPassword(text)}
          secureTextEntry={true}
        />
        {/* Actual Password Error */}
        {passwordError?.length > 1 &&
          <View style={style.errorMessageContainer}>
            <SVGError fill='#F83636' style={{ width: 30 }} />
            <Text style={style.errorMessageText}>
              {passwordError}
            </Text>
          </View>
        }

        {/* New Password Inputs */}
        <PasswordDoubleCheckInput
          ref={passwordDoubleCheckRef}
          onChangeText={onNewPasswordInputChange}
        />

        <View style={style.validateContainer}>
          {!isFetching &&
            <StyledButton
              disabled={buttonIsDisabled}
              onPress={onChangePasswordPress}
              text={t('Change the password')}
            />
          }

          {isFetching &&
            <ActivityIndicator style={{ marginBottom: 30 }} size="small" />
          }
        </View>

      </View>
    </ScrollView>
  );
};

export default UserPasswordChange;

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingBottom: 15,
    backgroundColor: '#fff'
  },
  content: {
    marginHorizontal: 45,
    paddingVertical: 5,
    minWidth: 300,
  },
  title: {
    paddingVertical: 30,
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 22,
    textAlign: 'center',
  },
  validateContainer: {
    marginTop: 10
  },
  errorMessageContainer: {
    width: 280,
    flexDirection: 'row',
    marginBottom: 15
  },
  errorMessageText: {
    marginVertical: 'auto',
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: '#F83636',
    marginLeft: 7
  }
})