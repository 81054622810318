import { useFocusEffect, useNavigation, useTheme } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import screens from '../../common/constants/screen.constants';

import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import * as TicketActions from '../../store/actions/ticket.actions';
import StyledButton from '../../components/kpLibrary/forms/StyledButton.comp';
import { commonStyles } from '../../common/style/common.style';

const MyBetsHome = () => {

  const navigation = useNavigation();
  const styles = makeStyles(useTheme());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ticketsList = useSelector(state => state.ticket.myTicketList);
  const ebulletinList = useSelector(state => state.ebulletin.myEbulletinList);

  useFocusEffect(
    React.useCallback(() => {
      dispatch(TicketActions.loadMyTickets());
      dispatch(EBulletinActions.loadMyEbulletins());
    }, [])
  );

  const onScanPress = () => {
    navigation.navigate(screens.Scan, { screen: screens.ScanHome });
  }
  const onMyTicketsPress = () => {
    navigation.navigate(screens.MyTickets);
  }
  const onEBulletinsPress = () => {
    navigation.navigate(screens.MyEBulletins);
  }

  return (
    <View style={styles.container}>

      {/* {!userIsConnected &&
      <View style={styles.containerCentered}>
        <Pressable style={styles.connexionButton} onPress={() => navigation.navigate(screens.UserConnect)}>
          <Text style={styles.connexionButtonText}>
            {t("Login/Register")}
          </Text>
        </Pressable>
      </View>
    } */}

      <View>
        {/* <Text style={styles.title}>Mes paris en point de vente</Text> */}
        <Pressable
          style={({ pressed }) => {
            return [styles.itemButton, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={onEBulletinsPress}
        >
          <Text style={styles.itemButtonTextLeft}>
            {t('ebulletin.tag.s')}
          </Text>
          <Text style={styles.itemButtonTextRight}>
            {ebulletinList.length}
          </Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => {
            return [styles.itemButton, !pressed && commonStyles.shadowedButton];                                                                  
          }}
          onPress={onMyTicketsPress}
        >
          <Text style={styles.itemButtonTextLeft}>
            {t('ticket.my_scanned_tickets')}
          </Text>
          <Text style={styles.itemButtonTextRight}>
            {ticketsList.length}
          </Text>
        </Pressable>
      </View>

      <View style={styles.scanView}>
        <StyledButton
          onPress={onScanPress}
          text={t('tab scan')}
        />
      </View>

    </View>
  )

}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 20,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontFamily: 'Raleway-Bold',
    fontSize: 17,
    color: theme.colors.greyText,
    marginBottom: 20
  },
  itemButton: {
    backgroundColor: '#ffffff',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 4,
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  itemButtonTextLeft: {
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  itemButtonTextRight: {
    color: theme.colors.primary,
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
  },
  containerCentered: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20
  },
  connexionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    borderRadius: 4,
    width: 192,
    height: 46,
    backgroundColor: theme.colors.primary,
  },
  connexionButtonText: {
    color: '#FFFFFF',
    fontFamily: 'Raleway-Regular',
    fontSize: 15
  },
  scanView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginHorizontal: 40,
    marginVertical: 20,
  },
});

export default MyBetsHome; 