import React, { useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useTranslation } from 'react-i18next';
import LogoutModal from '../../components/my-space/LogoutModal.comp';

const UserLogout = () => {

  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const setUserDisconnected = () => {
    setLogoutModalVisible(true);
  }

  return (
    <View>
      <Pressable style={style.disconectButton} onPress={() => setUserDisconnected()} >
        <Text style={style.disconectButtonText}>
          {t("Logout")}
        </Text>
      </Pressable>

      <LogoutModal
        modalVisible={logoutModalVisible}
        setModalVisible={setLogoutModalVisible}
      />      
    </View>
  );
};

export default UserLogout;

const makeStyles = (theme) => StyleSheet.create({
  disconectButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    width: '100%',
    height: 46,
  },
  disconectButtonText: {
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary,
    fontSize: 15
  }
})