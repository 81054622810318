import {  
    SVGFavorisFull,
    SVGGrid,
    SVGSettings,
    SVGInformationCircle,
    SVGAnnoucement,
    SVGHelp,
    SVGSecurity,
    SVGLocation,
    SVGMentions,
    SVGAccountSettings
  } from '../../assets/icons';
import screens from '../../common/constants/screen.constants';

export function generateTab(theme) {
    return [
        {
          name: 'user.personal_info',
          page: screens.UserInformation,
          icon: <SVGAccountSettings fill={theme.colors.primary}/>,
          connectedOnly: true,
        },
        {
          name: 'My limits',
          page: 'UserLimits',
          icon: <SVGSecurity fill={theme.colors.primary} style={{width: 24, height: 24}}/>,
          connectedOnly: true,
        },
        {
          name: 'Parameters',
          page: 'AppParameters',
          icon: <SVGSettings fill={theme.colors.primary}/>
        },
        {
          name: 'Find a sale point',
          page: 'SalePoint',
          icon: <SVGLocation fill={theme.colors.primary}/>
        },
        {
          name: 'Games rules',
          page: 'GamesRulesList',
          icon: <SVGInformationCircle fill={theme.colors.primary}/>
        },
        // {
        //   name: 'FAQ',
        //   page: 'Faq',
        //   icon: <SVGHelp fill={theme.colors.primary}/>
        // },
        // {
        //   name: 'Help',
        //   page: 'Help',
        //   icon: <SVGAnnoucement fill={theme.colors.primary}/>
        // },
        {
          name: 'Legal notices',
          page: 'LegalNotices',
          icon: <SVGMentions fill={theme.colors.primary}/>
        },
      ]
  }