import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import * as GamesActions from '../../store/actions/games.actions';
import * as TaxesActions from '../../store/actions/taxes.actions';
import { fetchWordpressData } from '../../store/actions/wordpressApi.actions';
import { useTranslation } from 'react-i18next';
// component import
import BannerAds from '../../components/kpLibrary/BannerAds';
import LotteryGameList from '../../components/games/LotteryGameList';

const Games = ({ navigation }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const wordpressData = useSelector(state => state.wordpress);
  const wordpressCache = useSelector(state => state.wordpress.wordpressCacheConfig)

  const [isBannerExist, setBannerExist] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      // Page enter
      dispatch(fetchWordpressData());
      dispatch(GamesActions.getGames());
      dispatch(TaxesActions.LoadTaxes())
      return () => {
        //Page leave
      }
    }, [wordpressCache])
  );

  useEffect(() => {
    dispatch(GamesActions.getNextSessionByGame());
  }, [])

  useEffect(() => {
    if (!wordpressData.isFetching) {
      setBannerExist(wordpressData.bannerAds?.length > 0);
    }
  }, [wordpressData])

    return (
      <ScrollView style={style.container}>
         {isBannerExist && <BannerAds t={t} data={wordpressData.bannerAds}/>}
          {/* <SearchInput t={t} placeHolder='Find a game' />
          <GameSearchFilter style={style.list} /> */}
          <LotteryGameList />
      </ScrollView>
    );
}

const style = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#F6F6F6'
  },
  list:{
    height: 60
  }
})

export default Games; 