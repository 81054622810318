import { useNavigation, useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Pressable, Modal, TextInput, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SVGClose, SVGEdit } from '../../assets/icons';
import { formatNumber } from '../../utils/FormatUtils';
import AppConfig from '../../../app.json';
import { commonStyles } from '../../common/style/common.style';
import * as BetCreatorActions from '../../store/actions/betCreator.actions';

import CustomAmountModal from './CustomAmountModal';

export default function StakePicker(props) {

  const theme = useTheme();
  const style = makeStyles(theme);
  const dispatch = useDispatch();
  const staggeredStake = AppConfig.staggered_stake || 50;
  const selectedStake = useSelector(state => state.betCreator.selectedStake);

  const {
    selectedBetType,
    t,
    currency,
  } = props;
  const { defaultPrices, listOfGridParameters } = selectedBetType;

  const [modalVisible, setModalVisible] = useState(false);
  const [customStake, setCustomStake] = useState(undefined);
  const [baseNumberCountToSelect, _] = useState(listOfGridParameters?.length > 0 ? listOfGridParameters[0].requiredNumber : 0);

  //====================================== Functions

  function computeStakeButtonClass(stake) {
    return stake === selectedStake ? style.pickNumberPressed : style.pickNumber;
  }

  function computeStakeTextClass(stake) {
    return stake === selectedStake ? style.numberPressed : style.number;
  }

  function validateCustomAmount() {
    const isValidAmount = customStake > 0 && customStake >= selectedBetType.minBetAmount && customStake <= selectedBetType.maxBetAmount;
    if (isValidAmount) {
      onStakeSelect(customStake);
      setModalVisible(false);
    }
  }

  function closeCustomAmount() {
    setModalVisible(false);
    setCustomStake(null);
  }

  function onStakeSelect(stake) {
    if (stake != customStake) {
      setCustomStake(null);
    }
    dispatch(BetCreatorActions.set_stake_selected(selectedStake === stake ? 0 : stake));
  }

  return (
    <ScrollView style={style.amountBody}>
      <Text style={style.stepTitle}>
        {t(`Ticket base amount`)} ({baseNumberCountToSelect} {t('Numbers')})
      </Text>

      <View style={style.amountContainer}>
        {defaultPrices?.map((stake, idx) => {
          return (
            <Pressable
              key={idx}
              onPress={() => onStakeSelect(stake)}
              style={({ pressed }) => {
                return [computeStakeButtonClass(stake), !pressed && commonStyles.shadowedButton];                                                                  
              }}
              disabled={!(defaultPrices?.length > 1)}
            >
              <Text style={computeStakeTextClass(stake)}>
                {formatNumber(stake)} {currency}
              </Text>
            </Pressable>
          )
        }
        )}

        {selectedBetType.minBetAmount < selectedBetType.maxBetAmount &&
          <Pressable
            style={({ pressed }) => {
              return [customStake > 0 ? style.customAmountSelected : style.customAmount, !pressed && commonStyles.shadowedButton];                                                                  
            }}
            onPress={() => setModalVisible(true)}
          >
            <Text style={customStake > 0 ? style.numberPersonalizedPressed : style.number}>
              {customStake > 0 ? `${formatNumber(customStake)} ${currency}` : t(`Custom amount`)}
            </Text>
            {customStake > 0 && (<SVGEdit fill='#ffffff' />)}
          </Pressable>
        }
      </View>

      {/* Modal for custom amount selection */}
      <CustomAmountModal
        modalVisible={modalVisible}
        currency={currency}
        onClose={() => setModalVisible(false)}
        personalizedAmount={customStake}
        closeCustomAmount={closeCustomAmount}
        computeCustomAmount={setCustomStake}
        onValidate={validateCustomAmount}
        staggeredStake={staggeredStake}
        minAmount={selectedBetType.minBetAmount}
        maxAmount={selectedBetType.maxBetAmount}
      />
    </ScrollView>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 25
  },
  amountContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  customAmount: {
    height: 50,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  customAmountSelected: {
    height: 50,
    width: '100%',
    borderRadius: 3,
    backgroundColor: theme.colors.primary,
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  pickNumber: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  pickNumberPressed: {
    height: 50,
    width: 'auto',
    borderRadius: 5,
    backgroundColor: theme.colors.primary,
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 14,
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  number: {
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    color: theme.colors.greyText
  },
  numberPressed: {
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    color: '#ffffff'
  },
  numberPersonalizedPressed: {
    fontFamily: 'Raleway-Bold',
    fontSize: 12,
    color: '#ffffff',
    width: '80%',
    textAlign: 'center'
  },
  stepTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 20,
    color: theme.colors.greyText,
  },
  nbDrawContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30
  },
  nbDraw: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nbDrawSelected: {
    fontFamily: 'Raleway-Bold',
    fontSize: 28,
    color: theme.colors.primary
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  recapTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
  recapValue: {
    width: '50%',
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
    marginTop: 15,
    color: theme.colors.greyText,
  },
  disabledValidateButton: {
    backgroundColor: theme.colors.primary,
    width: 264,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 35,
    opacity: 0.6,
    marginBottom: 10
  },
  betAmount: {
    backgroundColor: '#FFFFFF',
    width: '90%',
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 37,
    justifyContent: 'center',
    paddingLeft: 15
  }
})
