import { useTheme } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SVGLess, SVGAdd } from '../../assets/icons';
import * as BetCreatorActions from '../../store/actions/betCreator.actions';
import { commonStyles } from '../../common/style/common.style';


export default function ConsecutiveDrawPicker() {

  const dispatch = useDispatch();
  const theme = useTheme();
  const style = makeStyles(theme);
  const { t } = useTranslation();

  const selectedBetType = useSelector(state => state.betCreator.selectedBetType);
  const selectedConsecutiveDrawCount = useSelector(state => state.betCreator.selectedConsecutiveDrawCount);

  const { maxDrawByBet } = selectedBetType;

  const onConsecutiveDrawsChange = (count) => {
    dispatch(BetCreatorActions.set_consecutive_draws(count));
  }

  return (
    <View style={style.amountBody}>

      {maxDrawByBet > 1 &&
        <View>
          <Text style={style.stepTitle}>
            {t('Number of draws')}:
          </Text>
          <View style={style.nbDrawContainer}>
            <Pressable
              onPress={() => onConsecutiveDrawsChange(Math.max(selectedConsecutiveDrawCount - 1, 1))}
              style={({ pressed }) => {
                return [style.selectionNbBtnLess, !pressed && commonStyles.shadowedButton];                                                                  
              }}
            >
              <View style={style.nbDraw}>
                <SVGLess fill={theme.colors.greyText} />
              </View>
            </Pressable>
            <View>
              <Text style={style.nbDrawSelected}>
                {selectedConsecutiveDrawCount}
              </Text>
            </View>
            <Pressable
              onPress={() => onConsecutiveDrawsChange(Math.min(selectedConsecutiveDrawCount + 1, maxDrawByBet))}
              style={({ pressed }) => {
                return [style.selectionNbBtn, !pressed && commonStyles.shadowedButton];                                                                  
              }}
            >
              <View style={style.nbDraw}>
                <SVGAdd fill={theme.colors.greyText} />
              </View>
            </Pressable>
          </View>
        </View>
      }

    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  amountBody: {
    marginTop: 10,
  },
  amountContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  stepTitle: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 20,
    color: '#404040',
  },
  nbDrawContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30
  },
  selectionNbBtn: {
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    marginHorizontal: 25,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionNbBtnLess: {
    height: 35,
    width: 35,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    marginHorizontal: 25,
    paddingVertical: 0,
    alignItems: 'center'
  },
  nbDraw: {
    fontFamily: 'Raleway-Regular',
    color: theme.colors.greyText,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nbDrawSelected: {
    fontFamily: 'Raleway-Bold',
    fontSize: 28,
    color: theme.colors.primary
  },
  recapContainer: {
    width: '100%',
  },
  recapGrid: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
})
