import { StyleSheet } from 'react-native';

export const mergeStyles = (first, second) => {
  return second ?
    Object.keys(first).reduce(
      (acc, key) => ({ ...acc, [key]: { ...first[key], ...second[key] } })
      , {})
    : first;
}

export const commonStyleSettings = {
  maxWidth: 700,
}

export const commonStyles = StyleSheet.create({
  button: {
    padding: 10,
    borderWidth: 1
  },
  phoneModalContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: commonStyleSettings.maxWidth,
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webModalContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: commonStyleSettings.maxWidth,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  shadowedButton: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3,
    elevation: 4,
  },
  bumpButton: {
    transform: [{scale: 0.98}],
    opacity: 0.9
  }
});
