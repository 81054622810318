import { useTheme } from "@react-navigation/native";
import { StyleSheet, Text, View, TouchableHighlight } from "react-native";
import { useSelector } from "react-redux";
import { SVGBasket } from "../../assets/icons";

const BasketBadge = ({
  onPress
}) => {

  const theme = useTheme();
  const styles = makeStyles(theme);
  const basketItemList = useSelector(state => state.basket.itemList);

  return (
    <TouchableHighlight
      style={{borderRadius: 50, alignItems: 'center', justifyContent: 'center'}}
      activeOpacity={0.7}
      underlayColor={theme.colors.primary}
      onPress={onPress}
    >
      <View style={styles.basketButton}>
        <SVGBasket fill='white' />
        <View style={styles.basketBadge}>
          <Text style={styles.basketBadgeText}>
            {basketItemList.length}
          </Text>
        </View>
      </View>
    </TouchableHighlight>
  );
}

const makeStyles = (theme) => StyleSheet.create({
  basketBadge: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 15,
    height: 15,
    backgroundColor: '#FFA62A',
    borderRadius: 20,
  },
  basketBadgeText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 10,
    textAlign: 'center',
  },
  basketButton: {
    position: 'relative',
    marginRight: 10,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default BasketBadge;