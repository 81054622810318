export const getDefaultScreenOptions = (theme) => ({
  headerStyle: {
    backgroundColor: theme.colors.primary,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    borderBottomWidth: 0,
    elevation: 3,
  },
  headerTitleStyle: {
    fontFamily: 'Raleway-SemiBold',
  },
  headerTintColor: "#fff",
})