import * as service from "../services/games.service";
import moment from 'moment';

// Definition of the actions name

export const ACTIONS = {
  GAMES_ERROR: 'GAMES_ERROR',
  GAMES_FETCHING: 'GAMES_FETCHING',
  GAMES_FETCHING_DONE: 'GAMES_FETCHING_DONE',
  //
  GAMES_BETTYPES_BY_GAME: 'GAMES_BETTYPES_BY_GAME',
  GAMES_BETTYPE_DETAILS: 'GAMES_BETTYPE_DETAILS',
  GAMES_CURRENT_GAME: 'GAMES_CURRENT_GAME',
  GAMES_CURRENT_SESSIONS: 'GAMES_CURRENT_SESSIONS',
  GAMES_LIST: 'GAMES_LIST',
  GAMES_NEXT_SESSION_BY_GAME: 'GAMES_NEXT_SESSION_BY_GAME',
  GAMES_PAST_SESSIONS: 'GAMES_PAST_SESSIONS',
  GAMES_PAST_SESSIONS_FILTERS: 'GAMES_PAST_SESSIONS_FILTERS',
  GAMES_CURRENT_PAYLINES_LIST: 'GAMES_CURRENT_PAYLINES_LIST',
  GAMES_CACHE: 'GAMES_CACHE',
  NEXT_SESSION_CACHE: 'NEXT_SESSION_CACHE',
}

// Dispatchers

// Lifecycle
export const set_state_error = (err) => {
  return { type: ACTIONS.GAMES_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.GAMES_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.GAMES_FETCHING_DONE };
}
//
export const set_current_game = (game) => {
  return { type: ACTIONS.GAMES_CURRENT_GAME, payload: game };
}
export const set_bettype_details = (betTypeId, betType) => {
  return { type: ACTIONS.GAMES_BETTYPE_DETAILS, payload: { betTypeId, betType } };
}
export const set_current_sessions = (gameId, list) => {
  return { type: ACTIONS.GAMES_CURRENT_SESSIONS, payload: { gameId, list } };
}
export const set_game_bettype_list = (gameId, betTypes) => {
  return { type: ACTIONS.GAMES_BETTYPES_BY_GAME, payload: { gameId, betTypes } };
}
export const set_game_list = (games) => {
  return { type: ACTIONS.GAMES_LIST, payload: games };
}
export const set_next_session_by_game = (sessions) => {
  return { type: ACTIONS.GAMES_NEXT_SESSION_BY_GAME, payload: sessions };
}
export const set_past_sessions = (list, gameIdList, after, before) => {
  return { type: ACTIONS.GAMES_PAST_SESSIONS, payload: { list, gameIdList: gameIdList || [], after, before } };
}
export const reset_past_sessions = () => {
  return set_past_sessions([], null, null, null);
}
export const set_past_sessions_filters = (gameIdList, after, before) => {
  return { type: ACTIONS.GAMES_PAST_SESSIONS_FILTERS, payload: { gameIdList: gameIdList || [], after, before } };
}
export const set_current_paylines_data = (paylinesData) => {
  return { type: ACTIONS.GAMES_CURRENT_PAYLINES_LIST, payload: service.computePaytableInfosService(paylinesData) }
}
export const set_games_cache = (data) => {
  return { type: ACTIONS.GAMES_CACHE, payload: data };
}
export const set_next_session_cache = (data) => {
  return { type: ACTIONS.NEXT_SESSION_CACHE, payload: data };
}


// Actions

// GAMES

export const createGamesCacheData = () => {
  return async (dispatch, getState) => {  
    dispatch(set_games_cache(service.createCacheConfig(parseInt(moment().format("x")))));
  }
}

export const createNextSessionCacheData = () => {
  return async (dispatch, getState) => {  
    dispatch(set_next_session_cache(service.createCacheConfig(parseInt(moment().format("x")))));
  }
}

export const getGames = () => {
  return async (dispatch, getState) => {
    const cache = getState().games.gamesCacheConfig;
    if (Object.keys(cache).length === 0 || parseInt(moment().format("x")) > cache.dateDataWillExpired) {
      try {
        dispatch(set_state_fetching());
        let r = await service.getGames();
        if (r.data?.listOfGames?.length > 0) {
          dispatch(set_game_list(r.data?.listOfGames));
          dispatch(createGamesCacheData());
          dispatch(set_state_fetching_done());
        }
      } catch (err) {
        dispatch(set_state_error(err));
      }
    }
  }
}

// BET TYPES

export const getBetTypeList = (gameType, gameId) => {
  if (gameType == 'instantLottery')
    return getInstantBetTypeList(gameId);
  else
    return getMutualBetTypeList(gameId);
}

export const getInstantBetTypeList = (gameId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantBetTypeList(gameId);
      if (r.data?.betTypes?.length > 0) {
        dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getInstantBetType = (gameId, betTypeId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantBetType(gameId, betTypeId);
      if (r.data?.betType) {
        dispatch(set_bettype_details(betTypeId, r.data?.betType));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getMutualBetTypeList = (gameId) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getMutualBetTypeList(gameId);
      //for Mutual, we get all the betType info, not only the names
      dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
      r.data?.betTypes?.map(betType => {
        dispatch(set_bettype_details(betType.betTypeId, betType));
      });
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// SESSIONS

export const getSessionsFuture = (gameType, gameId, max, before, after) => {
  if (gameType == 'instantLottery')
    return getInstantSessionsFuture(gameId, max, before, after);
  else
    return getMutualSessionsFuture(gameId, max, before, after);
}

export const getInstantSessionsFuture = (gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getInstantSessionsFuture(gameId, max, before, after);
      dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const getMutualSessionsFuture = (gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getMutualSessionsFuture(gameId, max, before, after);
      dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getNextSessionByGame = () => {
  return async (dispatch, getState) => {
    const cache = getState().games.nextSessionCacheConfig;
    if (Object.keys(cache).length === 0 || parseInt(moment().format("x")) > cache.dateDataWillExpired) {
      try {
        dispatch(set_state_fetching());
        service.getNextSessionByGame().then(r => {
          dispatch(set_next_session_by_game(r.data?.listOfDraws));
        });
        dispatch(createNextSessionCacheData());
        dispatch(set_state_fetching_done());
      } catch (err) {
        dispatch(set_state_error(err));
      }
    }
  }
}

export const getPastSessions = (gameIdList, after, before, max, onlyWithResults = true) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getPastSessions(gameIdList, after, before, max, 'drawingDate');
      let list = r.data?.listOfDraws || [];
      if (onlyWithResults) {
        list = list.filter(E => E.listOfGridDrawn?.length > 0);
      }
      dispatch(set_past_sessions(list, gameIdList, after, before));
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}


// SESSIONS WITH BETTYPES (combined)

export const getSessionsFutureWithBetTypes = (gameType, gameId, max, before, after) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      const futureFunc = gameType == 'instantLottery' ? service.getInstantSessionsFuture : service.getMutualSessionsFuture;
      const betTypeFunc = gameType == 'instantLottery' ? service.getInstantBetTypeList : service.getMutualBetTypeList;
      await Promise.all([
        futureFunc(gameId, max, before, after).then(r => {
          dispatch(set_current_sessions(gameId, r.data?.listOfDraws))
        }),
        betTypeFunc(gameId).then(r => {
          dispatch(set_game_bettype_list(gameId, r.data?.betTypes))
        }),
      ])
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

/**
 * Usage: return dispatch => HANDLE_CALL(dispatch, () => PromiseFunction)
 */
export const HANDLE_CALL = async (dispatch, callback) => {
  try {
    dispatch(set_state_fetching());
    await callback();
    dispatch(set_state_fetching_done());
  } catch (err) {
    dispatch(set_state_error(err));
  }
}

export const computePaytableInfos = (data) => {
  return async dispatch => {
    if (!data) return;
    dispatch(set_current_paylines_data(data));
  }
}