import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SVGError } from '../../assets/icons';
import { formatNumber } from '../../utils/FormatUtils';
import { useTranslation } from 'react-i18next';
import AlertPopup from '../kpLibrary/AlertPopup.comp';
import StyledTextInput from '../kpLibrary/forms/StyledTextInput.comp';

const CustomAmountModal = ({
  modalVisible,
  onClose,
  computeCustomAmount,
  onValidate,
  personalizedAmount,
  currency,
  minAmount,
  maxAmount,
  staggeredStake
}) => {

  const [errorMessagecontent, setErrorMessageContent] = useState('');
  const [errorMessageAmount, setErrorMessageAmount] = useState(0);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const theme = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  useEffect(() => {
    computeErrorMessage();
  }, [personalizedAmount])

  const computeErrorMessage = () => {
    const remainder = personalizedAmount % staggeredStake;
    if (personalizedAmount < minAmount) {
      setErrorMessageContent('Min amount error')
      setIsAmountValid(false);
      setErrorMessageAmount(minAmount);
    } else if (personalizedAmount > maxAmount) {
      setErrorMessageContent('Max amount error')
      setIsAmountValid(false);
      setErrorMessageAmount(maxAmount);
    } else if (remainder !== 0 && staggeredStake) {
      setErrorMessageContent('Isnt multiple error')
      setIsAmountValid(false);
      setErrorMessageAmount(staggeredStake);
    } else {
      setErrorMessageContent('')
      setIsAmountValid(true);
    }
  }

  return (
    <AlertPopup
      type={'none'}
      visible={modalVisible}
      onClose={onClose}
      title={t(`Select amount`)}
      textAction1={t('validate')}
      onAction1={onValidate}
      disableAction1={!isAmountValid}
    >
      <View style={styles.inputContainer}>
        <StyledTextInput
          onChangeText={text => computeCustomAmount(text?.replace(/[^0-9+]+/g, ''))}
          inputProps={{ autoFocus: true }}
          value={personalizedAmount}
          placeholder=""
          keyboardType="numeric"
        />
        <Text style={styles.currency}>{currency}</Text>
      </View>
      {personalizedAmount?.length > 0 && errorMessagecontent?.length > 0 && (
        <View style={styles.errorContainer}>
          <SVGError fill={theme.colors.expiredColor} style={{ marginRight: 10 }} />
          <Text style={styles.errorText}>
            {t(errorMessagecontent)} {formatNumber(errorMessageAmount)}.
          </Text>
        </View>
      )}
    </AlertPopup>
  )
};

const makeStyles = (theme) => StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
  },
  currency: {
    paddingLeft: 10,
    color: theme.colors.greyText,
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  errorText: {
    width: 220,
    fontFamily: 'Raleway-Bold',
    color: "#FF0000"
  },
});

export default CustomAmountModal;
