import { useTheme } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { commonStyles } from '../../common/style/common.style';

export default function BetWithAdditionnal(props) {

  const theme = useTheme();
  const style = makeStyles(theme);

  const {
    selectedBetType,
    styles,
    selectedGrids,
    onNumberSelectChange,
    t,
    bet,
    currency,
  } = props;

  //====================================== Functions

  function computeNumberPickClass(grid, numb, gridIndex) {
    if (gridIndex % 2 !== 0 && selectedGrids[grid.gridName].selectedNumbers.includes(numb)) {
      return styles.oddPickNumberPressed;
    } else if (selectedGrids[grid.gridName].selectedAdditionalNumbers.includes(numb)) {
      return styles.AdditionalPickNumberPressed;
    } else if (selectedGrids[grid.gridName].selectedNumbers.includes(numb)) {
      return styles.pickNumberPressed;
    } else {
      return styles.pickNumber;
    }
  }

  function computeNumberTextClass(grid, numb, gridIndex) {
    if ((gridIndex % 2 !== 0 && selectedGrids[grid.gridName].selectedNumbers.includes(numb))
      || selectedGrids[grid.gridName].selectedAdditionalNumbers.includes(numb)
      || selectedGrids[grid.gridName].selectedNumbers.includes(numb)) {
      return styles.numberPressed;
    } else {
      return styles.number;
    }
  }

  return (
    <View>

      {/* Grid Selection Part */}

      <View style={style.betAmount}>
        <Text style={style.betTitle}>
          {t('Bet')} : {bet} {currency}
        </Text>
      </View>

      {selectedBetType?.listOfGrids.map((grid, gridIndex) => {
        const remainingRequiredNumbers = Math.max(0, selectedGrids[grid.gridName].requiredNumber - selectedGrids[grid.gridName].selectedNumbers.length);
        return (
          <View key={gridIndex}>

            {remainingRequiredNumbers == 0 && selectedGrids[grid.gridName].maxAdditional > 0 ?
              <Text style={styles.stepTitle}>
                Ajoutez jusqu'à {selectedGrids[grid.gridName].maxAdditional} numéros additionnels et multipliez vos chances de gagner
              </Text>
              : remainingRequiredNumbers > 0 ?
                <Text style={styles.stepTitle}>
                  {t('Select')} {selectedGrids[grid.gridName].requiredNumber - selectedGrids[grid.gridName].selectedNumbers.length} {t('numbers')}
                </Text>
                : <Text style={styles.stepTitle}>&nbsp;</Text>
            }

            <View style={styles.grid}>
              {selectedBetType?.listOfGrids.length > 1 &&
                <Text style={styles.gridTitle}>
                  {grid.gridName}
                </Text>
              }

              <View style={styles.gridPicker}>
                {grid.listOfLottoNumbers.map((numb, index) => {
                  const [numberPress, setNumberPress] = useState(false);
                  return (
                    <Pressable
                      key={index}
                      onPress={() => {
                        setNumberPress(!numberPress);
                        onNumberSelectChange(numb, grid.gridName);
                      }}
                      style={({ pressed }) => {
                        return [computeNumberPickClass(grid, numb, gridIndex), !pressed && commonStyles.shadowedButton];                                                  
                      }}
                    >
                      <Text style={computeNumberTextClass(grid, numb, gridIndex)}>
                        {numb}
                      </Text>
                    </Pressable>
                  )
                })}
              </View>

            </View>
          </View>
        )
      })}

    </View>
  );
}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  betTitle: {
    fontFamily: 'Raleway-Bold',
    fontWeight: 'bold',
    fontSize: 13,
    color: theme.colors.greyText,
  },
  betAmount: {
    backgroundColor: '#FFFFFF',
    width: '90%',
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 35,
    justifyContent: 'center',
    paddingLeft: 15
  }
})
