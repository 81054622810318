import { View, Text, StyleSheet, ActivityIndicator, Pressable } from "react-native";
import { useTheme, useNavigation, useFocusEffect, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../store/actions/user.actions";
import { SVGClose } from "../../assets/icons";
import { useState, useEffect } from "react";
import StyledTextInput from "../../components/kpLibrary/forms/StyledTextInput.comp";
import StyledButton from "../../components/kpLibrary/forms/StyledButton.comp";
import { useToast } from "react-native-toast-notifications";
import { checkPhoneNumberValid, formatPhoneNumber } from "../../utils/FormatUtils";
import PhoneNumberPrefixInput from "../../components/kpLibrary/forms/PhoneNumberPrefixInput.comp";
import { getBtobetErrorMessage } from "../../store/services/api.online.service";
import { Keyboard } from "react-native";
import PasswordDoubleCheckInput from "../../components/kpLibrary/forms/PasswordDoubleCheckInput";
import { useRef } from "react";


const UserPasswordReset = () => {

  const STEP_COUNT = 3;

  //====================================== Hooks

  const theme = useTheme();
  const styles = makeStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const toast = useToast();


  const user = useSelector(state => state.user);
  const isConnected = useSelector(state => state.user.isConnected);
  const isFetching = useSelector(state => state.user.isFetching);

  const searchedUser = useSelector(state => state.user.searchedUser);

  const phonePrefix = useSelector(state => state.wordpress.appSettings?.localization?.phonePrefix);
  const phoneFormatSplit = useSelector(state => state.wordpress.appSettings?.localization?.phoneFormatSplit);
  const phoneMinDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMinDigits);
  const phoneMaxDigits = useSelector(state => state.wordpress.appSettings?.localization?.phoneMaxDigits);

  const passwordDoubleCheckRef = useRef();

  const [stepTitle, setStepTitle] = useState('');
  const [stepNumber, setStepNumber] = useState(1);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState('');
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(false);
  const [isDisabledAccount, setIsDisabledAccount] = useState(false);

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);


  useFocusEffect(
    React.useCallback(() => {
      //Page enter
      dispatch(UserActions.reset_password_error());
      route?.params?.phoneNumber && onPhoneNumberInputChange(route.params.phoneNumber);

      return () => {
        //Page leave
        dispatch(UserActions.reset_error());
        dispatch(UserActions.set_searched_user(null));
      }
    }, [route])
  );

  useEffect(() => {
    const title = `${t('password.reset.title')} - ${stepNumber}/${STEP_COUNT}`;
    setStepTitle(title);
  }, [stepNumber]);

  useEffect(() => {
    setPhoneNumberValid(checkPhoneNumberValid(phoneNumber, phoneMinDigits, phoneMaxDigits));
  }, [phoneNumber]);

  useEffect(() => {
    const isDisabled = searchedUser && searchedUser.activation?.isActivated == false;
    setIsDisabledAccount(isDisabled);
    if (stepNumber == 1 && phoneNumber && searchedUser && !isDisabled) {
      setStepNumber(2);
    }
  }, [searchedUser]);

  useEffect(() => {
    if (isConnected) {
      //the password reset (with autoLogon) is a success
      toast.show(`${t('Password saved')} ${t('connecting')}`, {
        animation: true,
        duration: 2000,
        iconType: 'success',
        position: -50,
        hideOnPress: true,
        containerStyle: { width: 300 },
        onClose: () => closeModal(),
      });
    }
  }, [isConnected])


  //====================================== Functions

  const onPhoneNumberInputChange = (value) => {
    const num = value?.replace(/[^0-9+]+/g, '');
    setPhoneNumberFormatted(formatPhoneNumber(num, phoneFormatSplit || 2));
    setPhoneNumber(num);
  }

  const onPhoneNumberSubmit = () => {
    Keyboard.dismiss();
    dispatch(UserActions.searchUser({ phoneNumber }));
  }

  const onPasswordInputChange = (value, confirmedValue, isValid) => {
    setPassword(value);
    setPassword2(confirmedValue);
    setIsPasswordValid(isValid);
  }

  const onChangePasswordPress = () => {
    Keyboard.dismiss();
    passwordDoubleCheckRef.current?.displayErrors();
    if (isPasswordValid) {
      const dateOfBirth = searchedUser?.account?.dateOfBirth;
      const email = searchedUser?.account?.email;
      dispatch(UserActions.resetPasswordByCode(dateOfBirth, email, password, /*autoLogin=*/true));
    }
  }

  const closeModal = () => {
    navigation.goBack();
  }

  //====================================== Render
  return (
    <View
      style={styles.container}
    >
      <View style={styles.topContainer}>
        <Pressable onPress={() => closeModal()}>
          <SVGClose fill={theme.colors.greyText} />
        </Pressable>
      </View>

      <View style={styles.content}>

        {(isDisabledAccount || user.error?.length > 0) &&
          <View style={styles.errorGlobalContainer}>
            <Text style={styles.errorGlobalText}>
              {isDisabledAccount ? t('Account Not Activated') : getBtobetErrorMessage(user.errorCode, true)}
            </Text>
          </View>
        }

        <View style={styles.title}>
          <Text style={styles.titleText}>
            {stepTitle}
          </Text>
        </View>

        {/* ---------- STEP 1 : ENTER PHONE NUMBER ---------- */}
        {stepNumber == 1 &&
          <>
            <StyledTextInput
              onChangeText={onPhoneNumberInputChange}
              inputProps={{ autoFocus: !route?.params?.phoneNumber }}
              value={phoneNumberFormatted}
              label={t("Phone number")}
              keyboardType="numeric"
              renderPrefix={() => <PhoneNumberPrefixInput value={phonePrefix} />}
              selectTextOnFocus={true}
            />
            <View style={styles.validateContainer}>
              {!isFetching &&
                <StyledButton
                  text={t('send_verification_code')}
                  onPress={onPhoneNumberSubmit}
                  disabled={!isPhoneNumberValid}
                />
              }
              {isFetching &&
                <ActivityIndicator style={{ marginBottom: 30 }} size="small" />
              }
            </View>
          </>
        }
        {/* ---------- STEP 2 : GET VALIDATION CODE ---------- */}
        {stepNumber == 2 &&
          <>
            <Text>Code de validation par SMS : non implémenté</Text>
            <StyledButton
              text={t('Continue')}
              onPress={() => setStepNumber(stepNumber + 1)}
            />
          </>
        }
        {/* ---------- STEP 3: CHANGE PASSWORD ---------- */}
        {stepNumber == 3 &&
          <>
            <PasswordDoubleCheckInput
              ref={passwordDoubleCheckRef}
              onChangeText={onPasswordInputChange}
            />
            <View style={styles.validateContainer}>
              {!isFetching && !isConnected &&
                <StyledButton
                  disabled={!password?.length || !password2?.length}
                  onPress={onChangePasswordPress}
                  text={t('Change the password')}
                />
              }
              {isFetching &&
                <ActivityIndicator style={{ marginBottom: 30 }} size="small" />
              }
            </View>
          </>
        }
      </View>
    </View >
  );
};

export default UserPasswordReset;

//====================================== Styles

const makeStyles = () => StyleSheet.create({
  container: {
    paddingBottom: 15,
    backgroundColor: '#fff',
    flex: 1,
    alignItems: 'center',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: 20,
    paddingTop: 20,
  },
  content: {
    marginHorizontal: 45,
    paddingVertical: 5,
    width: 280,
  },
  title: {
    paddingVertical: 30,
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: 22,
    textAlign: 'center',
  },
  validateContainer: {
    marginTop: 10
  },
  errorGlobalContainer: {
    backgroundColor: '#FADFDF',
    padding: 12,
    justifyContent: 'center',
    borderRadius: 5,
    marginBottom: 30,
    marginTop: 60
  },
  errorGlobalText: {
    textAlign: 'center',
    fontFamily: 'Raleway-SemiBold',
    fontSize: 15,
    color: '#F92626'
  }
})