import { StyleSheet, Text, View } from "react-native";
import StyledTextInput from "./StyledTextInput.comp";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SVGError } from "../../../assets/icons";
import { useEffect } from "react";

const PasswordDoubleCheckInput = (
  {
    onChangeText, //callback(value, confirmedValue, isValid)
  },
  ref) => {

  //====================================== Hooks

  useImperativeHandle(ref, () => ({
    displayErrors
  }))

  const styles = makeStyles();
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [newPasswordFields, setnewPasswordFields] = useState([
    { name: 'newPassword', label: 'New password', value: '' },
    { name: 'confirmPassword', label: 'Confirm new password', value: '' },
  ]);

  useEffect(() => {
    const isValid = checkValidity(false);
    onChangeText(newPasswordFields[0].value, newPasswordFields[1].value, isValid);
  }, newPasswordFields);

  //====================================== Functions

  const onPasswordInputChange = (value, index) => {
    const newArray = [...newPasswordFields];
    newArray[index] = { ...newArray[index], value };
    setnewPasswordFields(newArray);
  }

  const checkValidity = (showErrors = false) => {
    const password = newPasswordFields[0].value;
    if (password.length < 6) {
      showErrors && setError('Password error 2');
      return false;
    }
    else if (password !== newPasswordFields[1].value) {
      showErrors && setError('password.error.mismatch');
      return false;
    }
    setError('');
    return true;
  }

  //Note: display errors on demand, and not automatically on each input change
  const displayErrors = () => {
    checkValidity(true);
  }

  //====================================== Render
  return (
    <View>
      {newPasswordFields?.map((field, index) => (
        <StyledTextInput
          key={index}
          label={t(field.label)}
          value={field.value}
          isError={error.length > 1}
          onChangeText={text => onPasswordInputChange(text, index)}
          secureTextEntry={true}
          selectTextOnFocus={true}
        />
      ))}
      {error?.length > 1 &&
        <View style={styles.errorMessageContainer}>
          <SVGError fill='#F83636' style={styles.errorMessageIcon} />
          <Text style={styles.errorMessageText}>
            {t(error)}
          </Text>
        </View>
      }
    </View>
  )
}

//====================================== Styles

const makeStyles = () => StyleSheet.create({
  errorMessageContainer: {
    width: 280,
    flexDirection: 'row',
    marginBottom: 15
  },
  errorMessageIcon: {
    width: 30,
  },
  errorMessageText: {
    flex: 1,
    fontFamily: 'Raleway-Bold',
    fontSize: 13,
    color: '#F83636',
    marginLeft: 7,
  },
});


export default forwardRef(PasswordDoubleCheckInput);