import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from 'react-native';

export default function LotoSports({t, betDetails, currency}) {

  const style = makeStyles();

  const [isBetPending, setIsBetPending] = useState(betDetails.status === 'wait');

  function getMatchInfosClass(match, index) {
    // TODO when we can cancel a game on a lotosports
    // if(true) return style.matchInfosCanceled;
    return betDetails?.listOfMatchBet[index].includes(match.result) ?
      style.matchInfosWin :
      style.matchInfosLost
  }

  function getPlayerCubesClass(index, currentCase) {
    if(betDetails?.listOfMatchBet[index].includes(currentCase)) {
      return style.cubesPlayed
    } else {
      return style.cubes
    }
  }
  
  function getPlayerCubesTextContainerClass(match, index, currentCase) {
    if (betDetails?.listOfMatchBet[index].includes(currentCase) && match.result.includes(currentCase)) {
      return style.cubesTextContainerPlayedWin;
    } else if (match.result?.includes(currentCase)) {
      return style.cubesTextContainerPlayedLost;
    } else {
      return style.cubesTextContainer;
    }
  }

  function getPlayerCubesTextClass(match, index, currentCase) {
    if (betDetails?.listOfMatchBet[index].includes(currentCase) && match.result.includes(currentCase)) {
      return style.cubesTextPlayedWin;
    } else if (match.result?.includes(currentCase)) {
      return style.cubesTextPlayedLost;
    } else {
      return style.cubesText;
    }
  }

  function isMatchCanceled(match) {
    // TODO when we can cancel a game on a lotosports
    return false;
  }

  return (
    <View style={style.container}>
      {isBetPending ? (
        <Text style={style.pendingText}>
          {t('Session')} n°{betDetails?.contestId} {betDetails?.contestName && `« ${betDetails?.contestName} »`} {t('Pending')}…
        </Text>        
      ) : 
      (
        <View>
          {betDetails.sportLotteryContest?.map((match, index) => (
            <View key={index} style={style.matchLine}>
              <View style={style.matchNumber}>
                <Text style={style.matchNumberText}>{index + 1}</Text>
              </View>

              <View style={getMatchInfosClass(match, index)}>
                <Text style={style.matchTeams}>{match.team1}</Text>

                {isMatchCanceled() ? (
                  <View style={style.matchPossibilities}>
                    <View>
                      <Text style={style.canceledMatch}>Match annulé</Text>
                    </View>  
                  </View>
                  ) : (
                  <View style={style.matchPossibilities}>
                    <View style={getPlayerCubesClass(index, '1')}>
                      <View style={getPlayerCubesTextContainerClass(match, index, '1')}>
                        <Text style={getPlayerCubesTextClass(match, index, '1')}>1</Text>
                      </View>
                    </View>
                    <View style={getPlayerCubesClass(index, 'x')}>
                      <View style={getPlayerCubesTextContainerClass(match, index, 'x')}>
                        <Text style={getPlayerCubesTextClass(match, index, 'x')}>X</Text>
                      </View>
                    </View>
                    <View style={getPlayerCubesClass(index, '2')}>
                      <View style={getPlayerCubesTextContainerClass(match, index, '2')}>
                        <Text style={getPlayerCubesTextClass(match, index, '2')}>2</Text>
                      </View>
                    </View>
                  </View>
                )}

                <Text style={style.matchTeams}>{match.team2}</Text>
              </View>

            </View>
          ))}

        </View>
      )}
    </View>
  );
}

//====================================== STYLE
const makeStyles = () => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    flex: 1
  },
  pendingText: {
    fontSize: 12,
    fontFamily: 'Raleway-LightItalic',
    color: '#404040'
  },
  matchLine: {
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  matchNumber: {
    height: 40,
    width: '10%',
    marginRight: 7,
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
  },
  matchNumberText: {
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 20
  },
  matchInfosWin: {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    width: '88%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 20,
    borderLeftWidth: 9,
    borderLeftColor: '#30C408'
  },
  matchInfosLost: {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    width: '88%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 20,
    borderLeftWidth: 9,
    borderLeftColor: '#D30000'
  },
  matchInfosCanceled: {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    width: '88%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 20,
  },
  matchTeams: {
    fontSize: 12,
    fontFamily: 'Raleway-Regular',
    color: '#404040',
  },
  matchPossibilities: {
    flexDirection: 'row',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  cubes: {
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
    padding: 2
  },
  cubesPlayed: {
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4, 
    borderWidth: 1,
    borderColor: '#30C408',
    borderRadius: 2,
    padding: 1
  },
  cubesText: {
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
  },
  cubesTextContainer: {
    borderRadius: 2,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cubesTextPlayedWin: {
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
    color: '#ffffff',
  },
  cubesTextContainerPlayedWin: {
    borderRadius: 2,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#30C408'
  },
  cubesTextPlayedLost: {
    fontFamily: 'Raleway-Bold',
    fontSize: 14,
    color: '#ffffff'
  },
  cubesTextContainerPlayedLost: {
    borderRadius: 2,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#404040'
  },
  canceledMatch: {
    fontFamily: 'Raleway-MediumItalic',
    fontSize: 12,
    color: '#D30000'
  }
});
