import React from 'react';
import { useState, useRef } from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { View, StyleSheet, Image, Dimensions, Animated, TouchableOpacity, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as Linking from 'expo-linking';
import * as GamesActions from '../../store/actions/games.actions';
import screens from '../../common/constants/screen.constants';

//====================================== Item Render
const renderItem = (source, i, onPress, width, imgHeight) => {
  return (
    <View key={i}>
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={0.8}
      >
        <Image
          style={{ maxWidth: '100%', width, height: imgHeight, borderRadius: 10 }}
          source={{ uri: source.sourceUrl }}
          resizeMode='cover'
        />
      </TouchableOpacity>
    </View>
  );
}

//====================================== BannerAds component
const BannerAds = props => {

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const bannerData = useSelector(state => state.wordpress.bannerAds);
  const gamesList = useSelector(state => state.games.lottery_games);

  const [viewWidth, setViewWidth] = useState(Dimensions.get('window').width);
  const imgHeight = viewWidth / 3.1;
  const style = makeStyles(imgHeight);

  const scrollX = new Animated.Value(0);
  const dotOpacityDivision = Animated.divide(scrollX, viewWidth);

  const scrollRef = useRef(null);
  const timerRef = useRef(null);
  const timerAutoMs = 6000;
  let currentIndex = 0;

  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      startTimer();
      return () => {
        stopTimer();
      }
    }, [viewWidth])
  );

  //====================================== Functions

  const stopTimer = () => {
    clearInterval(timerRef.current)
    timerRef.current = null;
  }
  const startTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => showNextSlide(), timerAutoMs);
  }

  const showNextSlide = () => {
    if (bannerData?.length) {
      scrollToSlide((currentIndex + 1) % bannerData.length);
    }
  }

  const manuallyShowSlideAtIndex = (index) => {
    //show the slide and restart timer
    startTimer();
    scrollToSlide(index);
  }

  const scrollToSlide = (index) => {
    currentIndex = index;
    scrollRef.current?.scrollTo({
      x: index * viewWidth,
      animated: true,
    });
  }

  const openLink = (link) => {
    if (!link) return;
    if (link[0] === '/') {
      const gameId = link.match(/(\d+)/)[0];
      const currentGame = gamesList?.find(game => game.gameId == gameId);
      if (!currentGame) return;
      dispatch(GamesActions.set_current_game(currentGame));
      navigation.navigate(screens.BetCreate);
    } else {
      if (Platform.OS == 'web') {
        window.open(link, '_blank');
      } else {
        Linking.openURL(link);
      }
    }
  }

  const onItemClick = (source) => {
    openLink(source.redirectionLink);
  }

  const onViewContainerLayout = (event) => {
    //Note: the slide positions are calculated from the container real width and the slide index
    if (event.nativeEvent.layout.width > 0)
      setViewWidth(event.nativeEvent.layout.width);
  }

  //====================================== Render
  return (
    <View style={style.banner}>
      <View
        style={style.slidesContainer}
        onLayout={onViewContainerLayout}
      >
        <Animated.ScrollView
          ref={scrollRef}
          style={{ borderRadius: 10 }}
          horizontal={true}
          pagingEnabled={true}
          showsHorizontalScrollIndicator={false}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { x: scrollX } } }],
            { useNativeDriver: false },
          )}
          onScrollEndDrag={() => startTimer() /*reset timer when user drags*/}
          scrollEventThrottle={16}
        >
          {
            bannerData.map((source, i) =>
              renderItem(source, i, () => onItemClick(source), viewWidth, imgHeight)
            )
          }
        </Animated.ScrollView>
      </View>

      <View
        style={style.dotContainer}
      >
        {bannerData.map((_, i) => {
          let opacity = dotOpacityDivision.interpolate({
            inputRange: [i - 1, i, i + 1],
            outputRange: [0.3, 1, 0.3],
            extrapolate: 'clamp'
          });
          return (
            <TouchableOpacity
              key={i}
              onPress={() => manuallyShowSlideAtIndex(i)}
            >
              <Animated.View
                style={{ ...style.dot, opacity }}
              >
              </Animated.View>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default BannerAds;

const makeStyles = (imgHeight) => StyleSheet.create({
  banner: {
    marginTop: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    height: imgHeight,
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer'
  },
  slidesContainer: {
    maxWidth: '100%',
    height: imgHeight,
    borderRadius: 10,
  },
  dotContainer: {
    flexDirection: 'row',
  },
  dot: {
    top: -25,
    height: 12,
    width: 12,
    backgroundColor: '#FFFFFF',
    margin: 8,
    borderRadius: 10
  }
});