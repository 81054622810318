import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;
const cacheTimeout = 300000;
const basePath_v2a = apiWebPlayerConfig.UrlService.replace("/v2/", "/v2-a/");

// GAMES

export const getGames = async () => {
  let params = {};
  return wp_axios.get(`${API_URL}/games`, { params });
}

// BET TYPES

export const getMutualBetTypeList = async (gameId) => {
  let params = {};
  return wp_axios.get(`${API_URL}/games/${gameId}/lottery/mutual/betTypes`, { params });
}

export const getInstantBetTypeList = async (gameId) => {
  let params = {};
  return wp_axios.get(`${basePath_v2a}/games/${gameId}/lottery/instant/betTypes`, { params });
}

export const getInstantBetType = async (gameId, betTypeId) => {
  let params = {};
  return wp_axios.get(`${API_URL}/games/${gameId}/lottery/instant/betTypes/${betTypeId}`, { params });
}

// SESSIONS

export const getNextSessionByGame = async () => {
  let params = { nbrDrawByGame: 1, max: 30 };
  return wp_axios.get(`${API_URL}/games/lottery/draws/future`, { params });
}

export const getInstantSessionsFuture = async (gameId, max, before, after) => {
  let params = { max, before, after };
  return wp_axios.get(`${API_URL}/games/${gameId}/lottery/instant/draws/future`, { params });
}

export const getMutualSessionsFuture = async (gameId, max, before, after) => {
  let params = { max, before, after };
  return wp_axios.get(`${API_URL}/games/${gameId}/lottery/mutual/draws/future`, { params });
}

export const getPastSessions = async (gameIdList, after, before, max, dateFilter) => {
  let params = { after, before, max, dateFilter };
  if (gameIdList?.length > 0) {
    params.listOfGameId = gameIdList?.join(',');
  }
  return wp_axios.get(`${API_URL}/games/lottery/draws/past`, { params });
}

//=============================================================================
// HELPERS
//=============================================================================

export const computePaytableInfosService = (data) => {
  if (!data?.listOfPayLines) return [];
  return data?.listOfPayLines
    .filter(elem => elem.gainValue !== 0 || elem.gainFormat === 'SHARED_DISTRIBUTION' || elem.gainFormat === 'PERCENT')
    .map((item) => {
      const payLinesConditions = Object.values(item?.winConditions ? item?.winConditions : item?.totalOfWinningNumbers);
      const minRange = payLinesConditions[0]?.minRange;
      const maxRange = payLinesConditions[0]?.maxRange;
      const totalFoundNumbers = payLinesConditions[0]?.totalFoundNumbers;
      const isInOrder = payLinesConditions[0]?.isInOrder;
      return {
        gain: item?.gainFormat === 'SHARED_DISTRIBUTION' || item?.gainFormat === 'PERCENT' ? 
          'Jackpot'
          : item.gainValue,
        categoryGain: payLinesConditions
          ?.map(E => E.length >= 1 ? E[0].nbrDrawed : E.nbrDrawed ? E.nbrDrawed : E.totalFoundNumbers)
          .filter(E => +E)
          .join(' + '),
        minRange: minRange > 0 ? minRange : null,
        maxRange: maxRange > 0 ? maxRange : null,
        totalFoundNumbers: totalFoundNumbers || 0,
        isInOrder: isInOrder
      }
  }).sort((a, b) => a.categoryGain < b.categoryGain ? 1 : -1)
}

export const createCacheConfig = (timestamp) => {
  return {
    dateDataWillExpired: timestamp + cacheTimeout,
    dateDataWillExpiredIso: new Date(timestamp + cacheTimeout).toISOString()
  };
}