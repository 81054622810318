import { apiWebPlayerConfig } from '../../../app.json';
import { wp_axios } from './api.webplayer.service';

//=============================================================================
// API CALLS
//=============================================================================

const API_URL = apiWebPlayerConfig.UrlService;
const API_URL_v2c = API_URL.replace("/v2/", "/v2-c/");

export const getEventsById = async (eventIdList) => {
  let params = { eventIdList };
  return wp_axios.get(`${API_URL}/oddsBetting/events`, { params });
}

export const getOddsTypeList = async () => {
  let params = {};
  return wp_axios.get(`${API_URL}/games/oddsBetting/oddsTypes`, { params });
}
