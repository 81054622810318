import { useTheme } from "@react-navigation/native";
import { StyleSheet, View, Text, Pressable } from "react-native";
import { commonStyles } from "../../../common/style/common.style";

const StyledButton = (
  {
    buttonProps,
    buttonStyle,
    buttonContainerStyle,
    fill, //outline, solid (default=solid)
    textStyle,
    disabled,
    text,
    onPress,
  }) => {

  //====================================== Hooks

  const theme = useTheme();
  const styles = makeStyles(theme);


  //====================================== Render
  return (
    <View style={[styles.buttonContainer, buttonContainerStyle]}>
      <Pressable
        style={({ pressed }) => {
          return [
            styles.button,
            buttonStyle,
            fill == 'outline' && styles.buttonOutline,
            disabled && styles.disabled,
            pressed && commonStyles.bumpButton,
          ];                                                  
        }}
        disabled={disabled}
        onPress={onPress}
        {...buttonProps}
      >
        <Text
          style={[
            styles.buttonText, 
            textStyle,
            fill == 'outline' && styles.buttonOutlineText,
          ]}
        >
          {text}
        </Text>
      </Pressable>
    </View>
  )
}

//====================================== Styles

const makeStyles = (theme) => StyleSheet.create({
  buttonContainer: {
  },
  button: {
    backgroundColor: theme.colors.primary,
    height: 45,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  buttonOutline: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.colors.primary,
  },
  buttonText: {
    color: '#ffffff',
    fontFamily: 'Raleway-Bold',
    fontSize: 15,
    padding: 10,
  },
  buttonOutlineText: {
    color: theme.colors.primary,    
  },
  disabled: {
    opacity: .6,
  }
});


export default StyledButton;