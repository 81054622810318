import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as OddsActions from '../../../store/actions/odds.actions';
import * as TicketServices from '../../../store/services/ticket.service';
import { getBetStatus, getDrawStatusColor, getBorderTicketColorMultiple } from '../../../utils/StatusUtils';
import { drawDateFormat } from "../../../utils/DateUtils";
import { getSportLogo } from "../../../utils/SportsIconsUtils";
import CustomBet from "./CustomBet";
import { useTheme } from "@react-navigation/native";

export default function BetSportsMultiple({betDetails, t}) {

  const [isSameTypeId, setIsSameTypeId] = useState(betDetails?.listOfOddsByType.length === 1);
  const [odds, setOdds] = useState(
    betDetails?.listOfOddsByType.length === 1 ?
      betDetails?.listOfOddsByType[0].listOfOdds.concat(betDetails?.listOfCustomOdds) :
      betDetails?.listOfOddsByType.concat(betDetails?.listOfCustomOdds)
  )

  return (
    <View>
      {odds?.map((odd, index) => {

        const dispatch = useDispatch();
        const games = useSelector(state => state.games.games);
        const eventInfosState = useSelector(state => state.odds.events)
      
        const [borderColor, setBorderColor] = useState('#404040');
        const [statusColor, setStatusColor] = useState({
          background: '#404040',
          text: '#FFFFFF'
        })
        const [oddName, setOddName] = useState(isSameTypeId ? betDetails?.listOfOddsByType[0].name : betDetails?.listOfOddsByType[index].name)
        const [eventId, setEventId] = useState(0)
        const [eventDate, setEventDate] = useState('')
        const [eventName, setEventName] = useState('');
        const [eventLeague, setEventLeague] = useState('');
        const [oddsPlayerInfos, setOddsPlayerInfos] = useState({
          type: '',
          name: ''
        })
        const [typeId, setTypeId] = useState(betDetails?.listOfOddsByType[0]?.typeId)
        const [isCustomBet, setIsCustomBet] = useState(odd.listOfOddsOutcome);
        const [sportsId, setSportsId] = useState(0);
        const theme = useTheme();
        const style = makeStyles(borderColor, statusColor, theme);
      
        useEffect(() => {
          fetchEventsInfos(odd)
          setStatusColor(getDrawStatusColor(isSameTypeId ? odd.status : odd.listOfOdds[0].status))
          setBorderColor(getBorderTicketColorMultiple(isSameTypeId ? odd.status : odd.listOfOdds[0].status))
          !isCustomBet && setOddsPlayerInfos({
            type: isSameTypeId ? odd.oddsOutcomeName : odd.listOfOdds[0].oddsOutcomeName,
            name: TicketServices.isMarkerSpecifier(typeId) ?
              `${t(isSameTypeId ? odd.marketSpecifier : odd.listOfOdds[0].marketSpecifier)} ${isSameTypeId ? betDetails?.listOfOddsByType[0].name : odd.name}` :
              oddName ? oddName : odd.name
          })
          getEventId(odd)
        }, [betDetails]);

        useEffect(() => {
          if(eventInfosState) {
            let currentEvent = eventInfosState.filter(item => item.eventsId === eventId);
            if(currentEvent.length > 0) {
              setEventName(currentEvent[0].events[0].list[0]?.list[0]?.list[0]?.name)
              setEventLeague(`${currentEvent[0].events[0].list[0]?.name} - ${currentEvent[0].events[0].list[0]?.list[0]?.name}`)
              setEventDate(currentEvent[0].events[0].list[0]?.list[0]?.list[0]?.begin)
              setSportsId(currentEvent[0].events[0]?.id)
            }
          }
        }, [eventInfosState, eventId])
      
        const fetchEventsInfos = (item) => {
          if(item.listOfOddsOutcome || isSameTypeId) {
            let eventId = item.eventId;
            if (eventId) dispatch(OddsActions.getEventsById(eventId));  
          } else {
            const { listOfOdds } = item;
            let eventId = listOfOdds[0].eventId;
            if (eventId) dispatch(OddsActions.getEventsById(eventId));            
          }
        }

        const getEventId = (item) => {
          let eventId;
          if (item.listOfOddsOutcome || isSameTypeId) {
            eventId = item?.eventId;
          } else {
            eventId = item?.listOfOdds[0].eventId;  
          }
          setEventId(eventId) 
        }

      return (
        <View key={index}>
          {odd?.listOfOddsOutcome ? (
            <CustomBet
              eventName={eventName}
              eventLeague={eventLeague}
              eventDate={eventDate}
              sportsId={sportsId}
              odds={odd}
              betDetails={betDetails}
              t={t}
              isSimple={false}
            />
          ) : (
            <ScrollView key={index} style={style.container}>

              <View style={style.containerDateStatus}>
                <View style={style.betDate}>
                  <Text style={style.betDateText}>{drawDateFormat(eventDate)}</Text>
                </View>   
                <View style={style.betStatus}>
                  <Text style={style.betStatusText}>{t(getBetStatus(isSameTypeId ? odd.status : odd.listOfOdds[0].status))}</Text>
                </View>
              </View>

              <View style={style.containerBetInfos}>

                <Image
                  style={style.image}
                  source={getSportLogo(sportsId)}
                />

                <View style={style.infos}>
                  <Text style={style.matchName}>
                    {eventName}
                  </Text>
                  <Text style={style.leagueName}>
                    {eventLeague}
                  </Text>
                  <View style={style.playerOddsView}>
                    <Text style={style.playerOddsType}>
                      {oddsPlayerInfos.name} :
                    </Text>
                    <Text style={style.playerOddsName}>
                      {oddsPlayerInfos.type}
                    </Text>
                  </View>
                </View>
              </View>

            </ScrollView>
          )}
        </View>
      )})}
    </View>
  );
}

//====================================== STYLE
const makeStyles = (borderColor, statusCustomBetColor, theme) => StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 10,
    paddingTop: 15,
    paddingBottom: 18,
    paddingHorizontal: 24,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 4,
    borderLeftColor: borderColor
  },
  containerDateStatus: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  betStatus: {
    borderRadius: 2,
    backgroundColor: statusCustomBetColor.background,
    paddingVertical: 2,
    paddingHorizontal: 7,
    justifyContent: "center"
  },
  betStatusText: {
    color: statusCustomBetColor.text,
    fontFamily: 'Raleway-Regular',
    fontSize: 11
  },
  betDateText: {
    fontFamily: "Raleway-Regular",
    fontSize: 12,
  },
  containerBetInfos: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  infos: {
    flexDirection: 'column',
    marginRight: 30,
    width: '85%'
  }, 
  matchName: {
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    fontSize: 14, 
    paddingBottom: 7
  }, 
  leagueName: {
    fontFamily: 'Raleway-LightItalic',
    color: '#404040',
    fontSize: 12, 
    paddingBottom: 7
  },
  playerOddsView: {
    width: '125%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  playerOddsViewCustom: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4
  },
  playerOddsType: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    marginRight: 10,
  },
  playerOddsTypeCustom: {
    fontSize: 13,
    fontFamily: 'Raleway-Bold',
    color: '#404040',
    marginRight: 10,
    marginLeft: 25
  },
  playerOddsName: {
    fontSize: 14,
    fontFamily: 'Raleway-Bold',
    color: theme.colors.primary
  },
  customBetImage: {
    width: 85,
    height: 10,
    marginVertical: 7
  },
  customBetOddsPlayer: {
    backgroundColor: '#F2F2F2',
    marginLeft: 18,
  },
  customBullet: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors.primary,
    marginLeft: -25,
    marginTop: 4
  },
  verticalLine: {
    width: 1,
    height: '100%',
    paddingVertical: 8,
    backgroundColor: theme.colors.primary,
    marginLeft: -4,
    marginBottom: -25,
    marginTop: 12
  }
});
