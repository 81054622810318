import { ACTIONS } from '../actions/wordpressApi.actions';

// initial state of this reducer

const defaultColors = {
    // Customizable via the wordpress CMS
    primary: '#2655f9',
    // Static color from the app
    secondary: '#FFA62A',
    tertiary: '',
    background: '#ededed',
    greyText: '#404040',
    clearGrey: '#9F9F9F',
    expiredBackground: '#FADFDF',
    expiredColor: '#F83D3D'
}

const initialState = {
    error: false,
    isFetched: false,
    //
    appSettings: {},
    theme: {
        colors: defaultColors,
        logoOperator: '',
    },
    bannerAds: [],
    countries: [],
    gameSettings: null,
    faq: null,
    help: null,
    wordpressCacheConfig: {},
}

// Reducer to catch the actions
// Format of action should be { type, payload }

function computeGamesLogoFormat(gamesList) {
    if (!gamesList) return null;
    return gamesList.reduce((acc, game) => {
        return {
            ...acc,
            [game.lottery_game.gameId]: {
                gameRules: game.GameRulesGroup,
                gameName: game.lottery_game.name,
                gameLogo: game.lottery_game.rectangleImage?.sourceUrl,
                gameLogoAvatar: game.lottery_game.squareImage?.sourceUrl,
            }
        }
    }, {});
}

function formatAppSettings(list) {
    if (!list) return {};
    return list.reduce((acc, item) => {
        if (item.setting.category)
            return {
                ...acc,
                [item.setting.category]: {
                    ...acc[item.setting.category],
                    [item.setting.name]: item.setting.value
                }
            }
        else
            return {
                ...acc,
                [item.setting.name]: item.setting.value
            }
    }, {});
}

function formatFaqList(list) {
    if (!list) return null;
    return list.reduce((acc, item) => {
        return [
            ...acc,
            {...item['FAQItem'] }
        ]
    }, []);
}

function formatAppTheme(list, state) {
    if (!list || list.length == 0) return {...state.theme};
    return list.reduce((acc, item) => {
        return {
            ...acc,
            colors: {
                ...state.theme.colors,
                primary: item['theme']['appTheme'].colorPrimary,
            },
            logoOperator: item['theme']['logoOperator'].sourceUrl
        }
    }, {});
}

function formatBannerList(list) {
  if (!list) return [];
  return list.map(item => ({
    sourceUrl: item?.banner?.image?.sourceUrl,
    redirectionLink: item?.banner?.redirectionLink,
  }));
}
function formatCountryList(list) {
  if (!list) return [];
  return list.map(item => ({
    label: item.country_datas?.countryDataName,
    code2: item.country_datas?.countryAlpha2code,
  }));
}

function formatHelpList(list) {
  if (!list) return null;
  return list.reduce((acc, item) => {
      return [
          ...acc,
          {...item.HelpItem }
      ]
  }, []);
}

export default function wordpressApiReducer(state = initialState, action) {
    switch (action.type) {
        //------------------
        case ACTIONS.WORDPRESS_ERROR:
            {
                return {...state, isFetched: false, error: true }
            }
        case ACTIONS.WORDPRESS_FETCHING:
            {
                return {...state, isFetched: false }
            }
        case ACTIONS.WORDPRESS_FETCHING_DONE:
            {
                return {...state, isFetched: true, error: false }
            }
            //------------------
        case ACTIONS.WORDPRESS_WORDPRESSDATA:
            {
                return {
                    ...state,
                    appSettings: formatAppSettings(action.payload.app_settings?.nodes),
                    theme: formatAppTheme(action.payload.app_themes?.nodes, state),
                    bannerAds: formatBannerList(action.payload.banner_ads?.nodes),
                    countries: formatCountryList(action.payload.countries?.nodes),
                    faq: formatFaqList(action.payload.fAQs?.nodes),
                    gameSettings: computeGamesLogoFormat(action.payload.lottery_games?.nodes),
                    help: formatHelpList(action.payload.helps?.nodes)
                }
            }
        case ACTIONS.WORDPRESS_CACHE:
            {
                return {...state, wordpressCacheConfig: action.payload }
            }
        case ACTIONS.RESET_WORDPRESS_CACHE:
            {
                return {...state, wordpressCacheConfig: action.payload }
            }
        default:
            return state;
    }
}