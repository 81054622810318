import { ACTIONS } from '../actions/games.actions';
import { convertBasketToBulletin } from '../services/ebulletin.service';

// initial state of this reducer

const initialState = {
  error: false,
  isFetching: true,
  errorMessage: '',

  // games
  games: [], //all kp-engine games
  lottery_games: [], // instant & mutual games
  currentGameId: undefined,
  currentGame: {},

  // sessions
  nextSessionByGame: {}, //{gameId1: GameSession[]}
  currentSessions: { list: [], gameId: null }, // = all the active sessions of the current game
  pastSessions: { list: [], gameIdList: [], after: null, before: null },

  // betTypes
  betTypesMap: {},
  betTypesByGame: {}, // {gameId1: BetType[]}
  betTypesActiveByGame: {}, // {gameId1: BetType[]}
  currentPaylinesList: [],

  gamesCacheConfig: {},
  nextSessionCacheConfig: {},
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function gamesReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.GAMES_ERROR: {
      return { ...state, error: true, isFetching: false, errorMessage: JSON.parse(JSON.stringify(action.payload)).message }
    }
    case ACTIONS.GAMES_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.GAMES_FETCHING_DONE: {
      return { ...state, isFetching: false, error: false }
    }
    //------------------
    case ACTIONS.GAMES_BETTYPES_BY_GAME: {
      let betTypesByGame = { ...state.betTypesByGame };
      let betTypesActiveByGame = { ...state.betTypesActiveByGame };
      betTypesByGame[action.payload.gameId] = action.payload.betTypes;
      betTypesActiveByGame[action.payload.gameId] = (action.payload.betTypes || []).filter(E => !E.status || E.status == 'ENABLED');
      return { ...state, betTypesByGame, betTypesActiveByGame }
    }
    case ACTIONS.GAMES_BETTYPE_DETAILS: {
      let betTypesMap = { ...state.betTypesMap };
      betTypesMap[action.payload.betTypeId] = action.payload.betType;
      return { ...state, betTypesMap }
    }
    case ACTIONS.GAMES_CURRENT_GAME: {
      return { ...state, currentGame: action.payload }
    }
    case ACTIONS.GAMES_CURRENT_SESSIONS: {
      return { ...state, currentSessions: action.payload }
    }
    //------------------
    // GAMES & SESSIONS
    case ACTIONS.GAMES_LIST: {
      return {
        ...state,
        games: action.payload,
        lottery_games: action.payload?.filter(E => E.gameType == 'instantLottery' || E.gameType == 'mutualLottery') || [],
      }
    }
    case ACTIONS.GAMES_NEXT_SESSION_BY_GAME: {
      //convert flat Session array to object {gameId: Session}
      const nextSessionByGame = action.payload?.reduce((acc, cur) => {
        acc[cur.gameId] = cur;
        return acc
      }, {}) || {};
      return { ...state, nextSessionByGame }
    }
    case ACTIONS.GAMES_PAST_SESSIONS: {
      return { ...state, pastSessions: action.payload }
    }
    case ACTIONS.GAMES_PAST_SESSIONS_FILTERS: {
      const pastSessions = { ...state.pastSessions };
      pastSessions.after = action.payload.after;
      pastSessions.before = action.payload.before;
      pastSessions.gameIdList = action.payload.gameIdList || [];
      return { ...state, pastSessions }
    }
    case ACTIONS.GAMES_CURRENT_PAYLINES_LIST: {
      return { ...state, currentPaylinesList: action.payload }
    }
    case ACTIONS.GAMES_CACHE:
      {
          return {...state, gamesCacheConfig: action.payload }
      }
    case ACTIONS.NEXT_SESSION_CACHE:
      {
          return {...state, nextSessionCacheConfig: action.payload }
      }
    default:
      return state
  }
}