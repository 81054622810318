import { useFocusEffect, useTheme } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import moment from "moment";

import { SVGSchedule } from '../../assets/icons';

const NextDrawCountdown = () => {

  const { t } = useTranslation();
  const styles = makeStyles(useTheme());

  const currentSessions = useSelector(state => state.games.currentSessions);

  const [nextSessionLabel, setNextSessionLabel] = useState('');
  const [refreshTimer, setRefreshTimer] = useState(null);

  const REFRESH_TIMEOUT = 60000;
  const refreshTimerRef = useRef(refreshTimer);
  refreshTimerRef.current = refreshTimer;

  //====================================== Hooks

  useFocusEffect(
    React.useCallback(() => {
      refreshData();
      initRefreshTimer();

      return () => {
        clearRefreshTimer();
      }
    }, [])
  );

  useEffect(() => {
    refreshData();
  }, [currentSessions]);

  //====================================== Functions

  const initRefreshTimer = () => {
    clearRefreshTimer();
    setRefreshTimer(setInterval(() => refreshData(), REFRESH_TIMEOUT));
  }
  const clearRefreshTimer = () => {
    refreshTimerRef.current && clearInterval(refreshTimerRef.current);
  }

  const refreshData = () => {

    const mNow = moment();
    const nextSession = currentSessions?.list?.find(S => moment(S.drawingDate).isAfter(mNow));
    const nextSessionDate = nextSession?.drawingDate;

    if (!nextSessionDate) {
      setNextSessionLabel('');
      return;
    }

    let label = '';    
    const mDrawingDate = moment(nextSessionDate);

    if (mDrawingDate.diff(mNow, 'minutes') < 120) {
      // "Prochain tirage dans moins de 10 minutes"
      const diffMinutes = mDrawingDate.diff(mNow, 'minutes') + 1;
      label = `${t('next_draw_less')} ${diffMinutes} ${t(diffMinutes > 1 ? 'minutes' : 'minute')}`;
    }
    else if (mDrawingDate.date() == mNow.date()) {
      // "Prochain tirage: aujourd'hui à 21:30"
      label = `${t('next_draw')} : ${t('today_at').toLowerCase()} ${mDrawingDate.format('HH:mm')}`;
    }
    else {
      // "Prochain tirage: mardi 18 mai 2022 - 21:30"
      label = `${t('next_draw')} : ${mDrawingDate.format('ddd LL - HH:mm')}`;
    }

    setNextSessionLabel(label);
  }

  //====================================== RENDER
  return (
    nextSessionLabel ?
      <View style={styles.container}>
        <SVGSchedule fill='#404040' />
        <Text style={styles.nextSessionText}>
          {nextSessionLabel}
        </Text>
      </View>
      : <></>
  )

}

//====================================== STYLE
const makeStyles = (theme) => StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  nextSessionText: {
    color: '#404040',
    fontSize: 13,
    fontFamily: 'Raleway-Regular',
    paddingLeft: 6,
  },
})

//======================================
export default NextDrawCountdown;