import { useTheme } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { View, StyleSheet, Pressable, Modal, Platform, useWindowDimensions, ScrollView } from 'react-native';
import { SVGClose } from '../../assets/icons';
import i18n from 'i18next';
import HTMLView from '../kpLibrary/HTMLView';

const CguModal = ({
  modalVisible,
  setModalVisible,
}) => {

  const isWeb = Platform.OS === "web";
  const [margin, setMargin] = useState('');
  const windowWidth = useWindowDimensions().width;
  const theme = useTheme();
  const styles = makeStyles(theme, margin);
  const content = useSelector(state => state.wordpress.help?.filter(E => E.language === i18n.language)[0])

  useEffect(() => {
    isWeb && getMarginHorizontal();
  }, [windowWidth])

  const getMarginHorizontal = () => {
    let maxWidth = 700;
    if (windowWidth > maxWidth) {
      let windowPercentage = (maxWidth * 100) / windowWidth;
      let marginHorizontal = (100 - windowPercentage) / 2;
      setMargin(`${marginHorizontal}%`)
    } else {
      setMargin(0);
    }
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {setModalVisible(!modalVisible)}}
    >
      <View style={isWeb ? styles.centeredView : styles.centeredViewPhone}>
        <View style={styles.modalView}>
          <View style={styles.pressableClose}>
            <Pressable onPress={() => setModalVisible(false)}>
              <SVGClose fill='#000000' style={{width: 28, height: 28}} />
            </Pressable>
          </View>

          <ScrollView style={[styles.content, isWeb ? {height: '80vh'} : {height: '80%'}]}>
            <HTMLView
              contentWidth={windowWidth}
              html={content?.cgu}
            />
          </ScrollView>
          
        </View>
      </View>
    </Modal>
  );
};

const makeStyles = (theme, margin) => StyleSheet.create({
  //Modal
  centeredView: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: 700,
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginHorizontal: margin,
  },
  centeredViewPhone: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    maxWidth: 700,
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalView: {
    width: '100%',
    paddingVertical: 27,
    paddingHorizontal: 27,
    backgroundColor: "white",
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  pressableClose: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  content: {
    fontFamily: 'Raleway-Medium',
    fontSize: 14,
    color: theme.colors.greyText
  }
});

export default CguModal;
