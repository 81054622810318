import React, { useState, useEffect, useRef } from 'react';
import { useNavigation, useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

import { BarCodeScanner } from 'expo-barcode-scanner';

import { useDispatch, useSelector } from 'react-redux';

import * as EBulletinActions from '../../store/actions/ebulletin.actions';
import * as TicketActions from '../../store/actions/ticket.actions';
import { isEbulletinFormat } from '../../store/services/ebulletin.service';

import screens from '../../common/constants/screen.constants';
import AlertPopup from '../../components/kpLibrary/AlertPopup.comp';

export default function ScanPreview() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [scanTimer, setScanTimer] = useState(null);

  const errorBulletin = useSelector(state => state.ebulletin.error);
  const errorTicket = useSelector(state => state.ticket.error);
  const isSportBet = useSelector(state => state.ebulletin.isSportBet);

  const SCAN_TIMEOUT = 30000;
  const scanTimerRef = useRef(scanTimer);
  scanTimerRef.current = scanTimer;

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      })();

    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      initScanTimer();
      return () => {
        clearScanTimer();
        resetSearchState();
        setScanned(false)
      }
    }, [])
  );

  useEffect(() => {
    const err = errorBulletin || errorTicket ? t('ticket.ref_not_found') : null;
    setErrorMessage(err);
  }, [errorBulletin, errorTicket])

  useEffect(() => {
    if (hasPermission === false) {
      setErrorMessage(t('scan.scan_not_available'));
    }
  }, [hasPermission])

  const initScanTimer = () => {
    setErrorMessage('');
    clearScanTimer();
    setScanTimer(setTimeout(() => setErrorMessage(t('scan.dialog_error_qrcode')), SCAN_TIMEOUT));
  }

  const clearScanTimer = () => {
    scanTimerRef.current && clearTimeout(scanTimerRef.current);
  }

  const handleBarCodeScanned = ({ type, data }) => {
    if (!data?.length) return; //no barcode detected

    setScanned(true);
    console.log("onBarCodeRead, entry = " + data);

    if (isEbulletinFormat(data)) {
      dispatch(EBulletinActions.searchBulletin(
        data,
        res => res?.bulletinCode && !isSportBet && goBulletinPage(res.bulletinCode),
        true
      ));
    } else {
      dispatch(TicketActions.searchTicket(data));
    }

  };

  const goManualScan = () => {
    resetSearchState();
    navigation.navigate('ScanManual');
  }

  const goBulletinPage = (bulletinCode) => {
    navigation.navigate(screens.EBulletinPage, { bulletinCode, fromScan: true });
  }

  const retryScan = () => {
    resetSearchState();
    initScanTimer();
    setScanned(false)
    dispatch(EBulletinActions.set_bulletin_is_sportbet(false))
  }

  const resetSearchState = () => {
    dispatch(EBulletinActions.reset_search_state());
    dispatch(TicketActions.reset_search_state());
  }

  return (
    <View style={styles.container}>
      {isFocused &&
        <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          style={StyleSheet.absoluteFillObject}
        />
      }
      <View style={styles.layerTop} />
      <View style={styles.layerCenter}>
        <View style={styles.layerLeft} />
        <View style={styles.focused} />
        <View style={styles.layerRight} />
      </View>
      <View style={styles.layerBottom} />

      <AlertPopup
        type={'info'}
        visible={errorMessage?.length > 0}
        message={errorMessage}
        onClose={retryScan}
        onAction1={retryScan}
        onAction2={goManualScan}
        textAction1={t('retry')}
        textAction2={t('scan.dialog_action_manual_entry')}
      />
      <AlertPopup
        type={'info'}
        visible={isSportBet}
        message={t('scan.isSportBetBulletinError')}
        onClose={retryScan}
        onAction1={retryScan}
        onAction2={retryScan}
        textAction1={t('scan.openInApp')}
        textAction2={t('scan.comebackToScan')}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%'
  },
  layerTop: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
  layerCenter: {
    flex: 1.5,
    flexDirection: 'row'
  },
  layerLeft: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
  focused: {
    flex: 3
  },
  layerRight: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
  layerBottom: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
});