import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGStats = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    style={{
      enableBackground: "new 0 0 40 40",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path d="M12 36.3H4.4c-.7 0-1.3-.6-1.3-1.3V12.5c0-.7.6-1.3 1.3-1.3H12c.7 0 1.3.6 1.3 1.3V35c0 .7-.6 1.3-1.3 1.3zM23.8 36.3h-7.6c-.7 0-1.3-.6-1.3-1.3V6.7c0-.7.6-1.3 1.3-1.3h7.6c.7 0 1.3.6 1.3 1.3V35c0 .7-.6 1.3-1.3 1.3zM35.6 36.3H28c-.7 0-1.3-.6-1.3-1.3V20.9c0-.7.6-1.3 1.3-1.3h7.6c.7 0 1.3.6 1.3 1.3V35c0 .7-.6 1.3-1.3 1.3z" />
  </Svg>
)

export default SVGStats;
