import { useNavigation, useTheme } from '@react-navigation/native';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const DrawResultDetailsPage = ({ route, navigation }) => {

  const style = makeStyles(useTheme());

  const drawId = route.params?.drawId;

  return (
    <View style={style.container}>
      <Text>BIENTOT</Text>
      <Text>TIRAGE n° { drawId }</Text>
    </View>
  )

}

const makeStyles = (theme) => StyleSheet.create({
  container: {
    padding: 20,
  },
});

export default DrawResultDetailsPage; 