export const translationPT = {
  /*eslint-disable */

  /** -------------------------------------------- GENERAL DICTIONARY  ----------------------------------------*/
  "all": "Tudo",
  "all.fem": "Todos",
  "back": "Voltar",
  "cancel": "Cancelar",
  "confirm": "Confirme",
  "clear": "Limpar",
  "code": "Código",
  "connecting": "conectando...",
  "dates": "datas",
  "delete": "Excluir",
  "game": "Jogo",
  "games": "Jogos",
  "go_back_home": "Voltar à página inicial",
  "loading": "Carregando...",
  "lotteries": "Loterias",
  "no": "Não",
  "no_result_found": "nenhum resultado encontrado.",
  "ref": "Ref.",
  "remove": "Remover",
  "results": "Resultados",
  "retry": "Tentar de novo",
  "search_in_progress": "Procurando, por favor aguarde...",
  "see": "Vejo",
  "share": "Compartilhar",
  "stake": "Estaca",
  "today": "Hoje",
  "today_at": "Hoje as",
  "validate": "Validar",
  "yes": "Sim",
  "Next draws": "Próximos sorteios",
  "number_abr": "n°",
  "Numbers": "Números",
  "Our games": "Nossos jogos",
  "Grid": "Grelha",
  "Connexion": "Conexão",
  'Others': 'Outros',
  "Deposits": "Depósitos",
  "Withdrawals": "Retiradas",
  "deposit": "Depósito",
  "Withdrawal": "Retirada",
  /** =========================================================================================================*/

  /** ------------------------------------------------ BET CREATION ----------------------------------------------*/
  "Ticket base amount": "Valor base do bilhete",
  "end_of_bets": "Fim das apostas",
  "game_rules": "Regras do jogo",
  "minute": "minuto",
  "minutes": "minutos",
  "next_draw": "Próximo sorteio",
  "next_draw_less": "Próximo sorteio em menos de 10 minutos",
  "no_draw_available": "Não há sorteio disponíveis no momento. Tente mais tarde.",
  "no_draw_available_short": "não há sorteio.",
  "select_bet_type": "Escolha um tipo de jogo",
  "select_game_sessions": "escolha uma ou mais sessões de jogo",
  "select_game_session": "escolha uma sessão de jogo",
  "Remaining": "Remanescente",
  "Number of draws": "Número de sorteios",
  "Summary": "Resumo",
  "additional numbers": "números adicionais",
  "Custom amount": "Valor personalizado",
  "Select amount": "Selecione seu valor",
  "Select between": "Selecione um valor entre",
  "And": "e",
  "Grid Complete": "Sua grade está completa",
  "Max amount error": "O valor informado deve ser menor que",
  "Min amount error": "O valor inserido deve ser inferior a",
  "Isnt multiple error": "O valor inserido deve ser um múltiplo de",

  /** ------------------------------------------------ ERRORS ----------------------------------------------*/
  "error.generic": "Ocorreu um erro.",
  "error.invalid_customer_details": "Usuário não encontrado",
  "Something went wrong": "Algo deu errado",
  "The app takes too long to start": "O aplicativo demora muito para iniciar",
  "Old password invalid": "A senha digitada está incorreta",
  "New password invalid": "A nova senha é inválida",

  /** ------------------------------------------------ E-BULLETIN ----------------------------------------------*/
  "ebulletin.fill_basket": "Começar de novo a partir desta aposta",
  "ebulletin.find_point_of_sale": "Encontre um ponto de venda",
  "ebulletin.limit_date": "Data limite",
  "ebulletin.my_ebulletin": "Meu e-boletim",
  "ebulletin.tag": "E-Boletim",
  "ebulletin.tag.s": "E-Boletims",
  "ebulletin.no_ebulletin": "Você não tem E-Boletim.",
  "ebulletin.my_ebulletins": "Meu e-Boletims",
  "Deadline": "Data limite",

  /** ------------------------------------------------ RESULTS ----------------------------------------------*/
  "results.clear_filters": "Limpar filtros",
  "results.draws_results": "Resultados do sorteio",
  "results.more_results_message": "Para ver mais sorteios, refine os filtros.",
  "results.most_popular_numbers": "Números mais populares",
  "results.show_more_results": "Mostrar mais sorteios",

  /** ------------------------------------------------ SCAN ----------------------------------------------*/
  "Manual entry": "Entrada manual",
  "scan.button_qrcode": "Digitalize um código QR",
  "scan.button_code": "Insira um código",
  "scan.dialog_action_manual_entry": "Insira seu código manualmente",
  "scan.dialog_action_qrcode": "Digitalize um código QR",
  "scan.dialog_error_qrcode": "Não foi possível ler seu código QR.",
  "scan.find_code": "Onde posso encontrar meu código ?",
  "scan.help_bulletin_1": "Abra ‟Minhas Apostas”",
  "scan.help_bulletin_2": "Selecionar ‟E-Boletins”",
  "scan.help_bulletin_3": "Abra o boletim de sua escolha clicando em ‟Visualizar”",
  "scan.help_bulletin_4": "O código está logo abaixo do código QR",
  "scan.help_bulletin_title": "Insira um código de e-boletim para carregar a mesma aposta em seu telefone ou compartilhe seu e-boletim com um amigo.",
  "scan.help_ticket_title": "Digite o código presente no seu tíquete de jogo, logo acima do código QR.",
  "scan.manual_entry_label": "Insira seu código",
  "scan.manual_entry_label_2": "(bilhete ou e-boletim)",
  "scan.manual_placeholder": "Coloque o código",
  "scan.scan_not_available": "A câmera não está disponível. Por favor, verifique as permissões no seu smartphone.",
  "scan.scan_ticket_or_bulletin": "Digitalize um tíquete ou um boletim eletrônico",
  "scan.isSportBetBulletinError": "Você leu um e-boletim de apostas esportivas.",
  "scan.openInApp": "Abrir em aplicativo dedicado",
  "scan.comebackToScan": "Volte para escanear",
  "Scan unavailable": "Disponível apenas no aplicativo Android ou IOS.",

  /** ------------------------------------------------ TABS ----------------------------------------------*/
  "my bets": "Minhas apostas",
  "my space": "Meu espaço",
  "tab scan": "Scan",

  /** ------------------------------------------------ GAMES ----------------------------------------------*/
  "Find a game": "Encontre um jogo",
  "Discover": "Descobrir",
  "See all": "Ver tudo",
  /** ------------------------------------------------ TICKET ----------------------------------------------*/
  "ticket.my_scanned_tickets": "Meus bilhetes escaneados",
  "ticket.no_ticket_scanned": "Você não digitalizou nenhum ingresso.",
  "ticket.ref_not_found": "Não encontramos seu ingresso.",
  "Ticket": "Bilhete",
  "Status": "Status",
  "Total bet": "Aposta total",
  "Draws made": "Sorteios feitos",
  "Draws pending": "Sorteios restantes",
  "Total gains": "Ganhos cumulativos",

  "Game type": "Tipo de jogo",
  "Bet": "Aposta",
  "Gains": "Ganhos",
  "Gains nets": 'Lucro líquido',
  "Gain": "Ganho",
  "Draw": "Desenhar",
  "Selection": "Seleção",

  "Expired": "Expirado",
  "Lost": "Perdido",
  "Won": "Ganho",
  "Pending": "Pendente",
  "Finished": "Finalizado",
  "Canceled": "Cancelado",
  "Price": "Preço",

  "Draw results": "Resultados do sorteio",
  "Your selection": "Sua seleção",
  "Winning combinations": "Combinações vencedoras",
  "Rank": "Posição",

  "Potential Win": "Ganhos potenciais",
  "Net Potential Win": "Ganhos potenciais líquidos",
  "Bets settled": "Apostas liquidadas",
  "Details": "Detalhes",
  "Completed matches": "Partidas concluídas",
  "Session": "Sessão",
  "Result": "Resultados",
  "Bet Blocked": "Uma ou mais apostas estão bloqueadas",
  "Blocked": "Bloqueado",
  /** ------------------------------------------------ USER ----------------------------------------------*/
  "user.personal_info": "Minha informação pessoal",

  /** ------------------------------------------------ MY SPACE ----------------------------------------------*/
  "My Parameters": "Minhas configurações",
  "My Favourites": "Meus favoritos",
  "My transactions": "Meus negócios",
  "Find a sale point": "Encontrar um ponto de retransmissão",
  'Games Rules': 'Regras dos jogos',
  'Game rule': 'Regras dos',
  'Help': 'Assistência',
  "Favorite Games": "Jogos Favoritos",
  "Favorite Grid": "Grades favoritas",
  "Parameters": "Configurações",
  "Games rules": "Regras dos jogos",
  'Legal notices': "Notícia legal",
  'No legal notices': 'Não notícia legal',
  'Application languages': 'idiomas de aplicativos',
  "fr": "Francês",
  "en": "Inglês",
  "pt": "Português",
  "Dark mode": "Modo noturno",
  'Paytable': 'Mesa de pagamentos',
  'No Game Rules': 'Sem regras do jogo',
  'Choose a game': 'Escolha um jogo',
  'Empty section': 'Esta seção está vazia',
  'Good numbers': "Bons números",
  'Jackpot': 'Jackpot',
  'x the bet': 'x a aposta',
  'Contact': 'Contato',
  'Version': 'Versão',
  'No app version': "Não versão do app especificada",
  'How to play': 'Como jogar',
  "PaytableMessage": "{{totalFoundNumbers}} número correto entre {{minRange}}° e o {{maxRange}}° bola desenhada",
  "PaytableMessage.multiple": "{{totalFoundNumbers}} números corretos entre {{minRange}}° e o {{maxRange}}° bola desenhada",
  "PaytableMessage.isInOrder": "{{totalFoundNumbers}} número correto entre {{minRange}}° e o {{maxRange}}° bola desenhada, em ordem",
  "PaytableMessage.multiple.isInOrder": "{{totalFoundNumbers}} números corretos entre {{minRange}}° e o {{maxRange}}° bola desenhada, em ordem",
  "Change password": "Alterar a senha",
  'Change the password': 'Mude a senha',
  'Actual password': 'Senha atual',
  'New password': 'Nova Senha',
  'Confirm new password': 'Confirme a nova senha',
  'Password saved': 'senha salva.',
  "Day": "Diário",
  "Week": "Semanalmente",
  "Month": "Por mês",
  "LifeTime": "Para a vida",
  "Period": "Período",
  "Amount": "Quantidade",
  "Inactive": "Inativo",
  "Delete the limit": "Remover o limite",
  "No limit": "Sem limite",
  "Bet limit": "Limite de aposta",
  "Deposit limit": "Limite de depósito",
  "Time limit": "Tempo limite",
  "Temporary exclusion": "Exclusão temporária",
  "Not defined": "Não definido",
  "Time limit passed": "Tempo limite ultrapassado",
  "My limits": "Meus limites",
  "7 days": "7 dias",
  "Limit Amount": "Quantidade limite",
  "Deposit money": "Depositar dinheiro",
  "Choose payment method": "Escolha um método de pagamento",
  /** ------------------------------------------------ BASKET ----------------------------------------------*/
  "basket.all_bets_expired": "Todas as suas apostas expiraram",
  "basket.back_to_basket": "Voltar para a cesta",
  "basket.empty": "O seu cesto está vazio",
  "basket.max_bet_exceeded": "A aposta total excede o valor autorizado",
  "basket.my_basket": "Minha cesta",
  "basket.remove_expired_items": "Excluir apostas expiradas para validar a cesta",
  "Cancel bet": "Cancel the bet ?",
  "Cancel bet content": "Observe que o conteúdo da sua cesta será excluído.",
  "Bet expired": "Aposta expirou",
  "See details": "Veja os detalhes",
  "Nb of draws": "Número de impressões",
  "Stake per bet": "Aposta por aposta",
  "Add a bet": "Adicionar aposta",
  "Max potential gain": "Ganho máximo potencial",
  'Net potential gain': 'Ganho líquido potencial',
  "Enter phone number": "Digite seu número de telefone",
  "Remember me": "Lembre de mim",
  "Bet's validated": "A sua aposta foi validada",
  "Find e-bulletin": "Encontre a e-bulletin em 'Minhas apostas'",
  /** ------------------------------------------------ CONNEXION ----------------------------------------------*/
  "Phone number": "Número de telefone",
  "Continue": "Continuar",
  "Not registered": "Não registrado ?",
  "Sign up": "Inscrever-se",
  "Password": "Senha",
  "Invalid login details": "Número de telefone ou senha incorretos",
  "Customer is already logged": "O cliente já está conectado",
  "Player disabled or blocked": "Conta desativada ou bloqueada",
  "Logout": "Sair",
  "Disable Account": "Desativar conta",
  "Account Not Activated Title": "Sua conta está desativada.",
  "Account Not Activated": "Sua conta está desativada. Entre em contato com o suporte.",
  "Confirm_deactivation.title": "Confirmar desativação?",
  "Confirm_deactivation.message": "Atenção, você não poderá mais fazer login. Para reativar sua conta, você deve entrar em contato com a equipe de suporte.",
  "deactivation_error_retry": "Há um problema com a desativação, tente novamente mais tarde.",
  "Login/Register": "Entrar/Registrar",
  "Balance": "Saldo",
  "Deposit": "Arquivar",
  "Withdraw": "Sacar",
  "Transactions": "Transações",
  "CGU text": "Certifico que sou maior de 18 anos e que li o ",
  "Already registered": "Já registrado ?",
  "Login": "Conecte-se",
  "Name": "Nome",
  "Password confirmation": "Confirmação Da Senha",
  "Password error 2": "A senha deve ter pelo menos 6 caracteres",
  "send_verification_code": "Envie o código de verificação",
  "Duplicate Phone.": "O número digitado já está em uso.",
  "Welcome": "BEM-VINDO !",
  "password.error.mismatch": "Senhas nao sao as mesmas",
  "password.forgot": "Esqueceu sua senha",
  "password.reset.title": "Redefinição de senha",
  /** ------------------------------------------------ USER INFO --------------------------------------------*/
  "address": "Endereço",
  "birthDate": "Data de nascimento",
  "city": "Cidade",
  "coords": "Detalhes do contato",
  "country": "País",
  "currency": "Moeda",
  "firstName": "Primeiro nome",
  "lastName": "Sobrenome",
  "phone": "telefone",
  "postCode": "CEP",
  /** ---------------------------------------------- FORM EDITION   ------------------------------------------ */
  /*    (build from the field translation key and the suffix '.edition')                                       */
  "address.edition": "Mudança de endereço",
  "birthDate.edition": "Mudança da data de nascimento",
  "firstName.edition": "Mudança de primeiro nome",
  "lastName.edition": "Mudança de sobrenome",
  /** ----------------------------------------- POPUPS & GENERIC MESSAGES ---------------------------------------*/
  "changes_saved": "Alterações salvas.",
  "popup.unsaved_changes.title": "As alterações não foram salvas",
  "popup.unsaved_changes.confirm_exit": "Sair sem salvar ?",
  "save_changes": "Salvar alterações",

  'setnr=1': "1°",
	'setnr=2': "2°",
	'setnr=3': "3°",
	'setnr=4': "4°",
	'setnr=5': "5°",
	'quarternr=1': "1°",
	'quarternr=2': "2°",
	'quarternr=3': "3°",
	'quarternr=4': "4°",
}