import React from 'react';
import { View, Text, useWindowDimensions, StyleSheet, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';

import HTMLView from '../../components/kpLibrary/HTMLView';

const Faq = () => {

  const { width } = useWindowDimensions();
  const wordpressFaqList = useSelector(state => state.wordpress.faq);

  return (
    <ScrollView style={style.container}>
      {wordpressFaqList.map((item, index) => (
        <View key={index} style={style.item}>
          <Text style={style.question}>{item.question}</Text>
          <HTMLView
            contentWidth={width}
            html={item.answer}
          />
        </View>
      ))}
    </ScrollView>
  );
};

export default Faq;

const style = StyleSheet.create({
  container: {
    padding: 15,
    flex: 1,
    flexDirection: 'column',
  },
  item: {
    backgroundColor: '#F6F6F6',
    borderRadius: 5,
    marginVertical: 10,
    padding: 10
  },
  question: {
    fontFamily: 'Raleway-Bold',
    fontSize: 20,
  }
})
