import { ACTIONS, STEPS } from '../actions/betCreator.actions';

// initial state of this reducer

const initialState = {
  currentStep: STEPS.NONE,
  isCustomStakeSelected: false,
  selectedStake: undefined,
  selectedSessions: {}, // {drawId: drawObj}
  selectedGrids: {}, // {gridName: [{selectedNumbers, selectedAdditionalNumbers, ...gridConfig}]}
  // with gridConfig : gridName, listOfLottoNumbers, additionalMultiplier, maxAdditional, requiredNumber
  selectedConsecutiveDrawCount: 1,
  selectedBetType: {},
  totalPrice: 0,
  isBetInBreak: false,
}

// Reducer to catch the actions

export default function betCreatorReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.BETCREATOR_BETTYPE_SELECTED: {
      return { ...state, selectedBetType: action.payload }
    }
    case ACTIONS.BETCREATOR_CONSECUTIVE_DRAWS: {
      return { ...state, selectedConsecutiveDrawCount: action.payload }
    }
    case ACTIONS.BETCREATOR_CLEAR_SELECTED_GRID_NUMBERS: {
      const selectedGrids = Object.values(state.selectedGrids).map(grid => ({
        ...grid,
        selectedNumbers: [],
        selectedAdditionalNumbers: []
      }));
      return { ...state, selectedGrids }
    }
    case ACTIONS.BETCREATOR_CURRENT_STEP: {
      return { ...state, currentStep: action.payload }
    }
    case ACTIONS.BETCREATOR_GRIDS_SELECTED: {
      return { ...state, selectedGrids: action.payload }
    }
    case ACTIONS.BETCREATOR_BREAK: {
      return { ...state, isBetInBreak: action.payload }
    }
    case ACTIONS.BETCREATOR_RESET: {
      return { ...initialState }
    }
    case ACTIONS.BETCREATOR_SESSIONS_SELECTED: {
      return { ...state, selectedSessions: action.payload }
    }
    case ACTIONS.BETCREATOR_STAKE_SELECTED: {
      return { ...state, selectedStake: action.payload }
    }
    case ACTIONS.BETCREATOR_TOTAL_PRICE: {
      return { ...state, totalPrice: action.payload }
    }
    default:
      return state
  }
}