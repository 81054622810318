import { initializeApp } from "firebase/app"; //Web
import * as AnalyticsWeb from "firebase/analytics"; //Web
import analytics from '@react-native-firebase/analytics';
import * as AppConfig from "../../../app.json";
import { Platform } from "react-native";

//=====================================
let firebaseAppWeb;
let analyticsWeb;
let isAnalyticsEnabled = true;

const defaultEventParameters = {
  appVersion: AppConfig.expo.version,
};
const isWeb = Platform.OS == 'web';

//=====================================

/**
 * Initialize Firebase services : Analytics, ...
 */
export const InitFirebase = async () => {
  try {
    if (isWeb) {
      const firebaseWebConfig = AppConfig.expo?.web?.firebase;
      isAnalyticsEnabled = await AnalyticsWeb.isSupported() && firebaseWebConfig?.apiKey.length > 0;
      if (isAnalyticsEnabled) {
        firebaseAppWeb = initializeApp(firebaseWebConfig);
        analyticsWeb = AnalyticsWeb.getAnalytics(firebaseAppWeb);
      }
    }
    else {//Native
      const appInstanceId = await analytics().getAppInstanceId();
      isAnalyticsEnabled = appInstanceId?.length > 0;
      if (isAnalyticsEnabled) {
        await analytics().setDefaultEventParameters(defaultEventParameters);
      }
    }
    console.log("Firebase Analytics enabled : " + isAnalyticsEnabled);
  }
  catch (err) {
    isAnalyticsEnabled = false;
    console.warn("Analytics is NOT supported.", err);
  }
}

/**
 * Track an event (Analytics)
 */
export const trackEvent = async (eventName, eventParams) => {
  if (isAnalyticsEnabled) {
    if (isWeb) {
      AnalyticsWeb.logEvent(analyticsWeb, eventName, { ...eventParams, ...defaultEventParameters });
    } else {
      await analytics().logEvent(eventName, eventParams);
    }
  }
}

/**
 * Track the pages browsed by the user
 */
export const trackPage = async (page_title) => {
  //Note: on the Analytics console, when the source is a mobile app, the "page view" widget displays only screens, not pages
  if (isAnalyticsEnabled) {
    if (isWeb) {
      AnalyticsWeb.logEvent(analyticsWeb, 'screen_view', { screen_class: page_title, screen_name: page_title });
    } else {
      await analytics().logScreenView({ screen_class: page_title, screen_name: page_title });
    }
  }
}

/**
 * Track an error using a custom Analytics event
 */
export const trackError = async (message, opts = {/*err,category*/ }) => {
  if (isAnalyticsEnabled) {
    if (opts.err && opts.err.message) {
      //case: err is of type 'Error': extract its message, we don't need the stacktrace.
      opts.err = opts.err.message;
    }
    if (typeof opts.err === 'object') {
      opts.err = JSON.stringify(opts.err);
    }
    //Note: all params are restricted to 100 chars
    let description = message, description2;
    if (description.length > 100) {
      description2 = description.substr(100, 100);
      description = description.substr(0, 100);
    }
    await trackEvent('exception', Object.assign({ description, description2 }, opts));
  }

  // Log the error into the console
  console.error((opts.category ? '[' + opts.category + '] ' : '') + message, opts.err);
}