import { ACTIONS } from '../actions/odds.actions';

// initial state of this reducer

const initialState = {
  error: false,
  isFetching: false,
  //
  events: [],
  oddsType: []
}

// Reducer to catch the actions
// Format of action should be { type, payload }

export default function oddsReducer(state = initialState, action) {
  switch (action.type) {
    //------------------
    case ACTIONS.FETCHING_ERROR: {
      return { ...state, error: action.payload, isFetching: false }
    }
    case ACTIONS.ODDS_FETCHING: {
      return { ...state, isFetching: true }
    }

    case ACTIONS.EVENTS_FETCHING_DONE: {
      return { ...state, events: [...state.events, action.payload], isFetching: false, error: false }
    }
    case ACTIONS.ODDSTYPE_FETCHING_DONE:
      return { ...state, oddsType: action.payload, isFetching: false, error: false }
    default:
      return state
  }
}