import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';


const KoralPlayLoader = (props) => {
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(opacity, {
            toValue: 1,
            delay: 500,
            duration : 600,
            useNativeDriver: false,
            boomerang: true,
          }),
          Animated.timing(opacity, {
            toValue: 0,
            delay: 500,
            duration : 600,
            useNativeDriver: false,
            boomerang: true,
          })
        ])
      ).start()
  }, [opacity]);

  return (
    <Animated.View
      style={[
        props.style,
        {opacity: opacity},
      ]}
    >
      {props.children}
    </Animated.View>
  );
}

export default KoralPlayLoader;