import { View, Text, StyleSheet } from "react-native";
import React from "react";

import { getBetStatus } from '../../utils/StatusUtils';
import { t } from "i18next";

export default function GridLine({title, data, status, isBetLocked, t}) {

  const style = makeStyles();

  return (
    <View style={style.gridSummary}>
      <View style={style.leftColumn}>
        <Text style={style.leftText}>
          {title}{' '}:
        </Text>
      </View>
      <View style={style.rightColumn}>
        <Text style={style.rightText}>
          {status && 
            <View style={
              isBetLocked ? style.statusBallBlocked :
                getBetStatus(status) === 'Pending' ?
                  style.statusBallPending :
                  style.statusBallEnded
              }
            ></View>
            }
          {data}
        </Text>
      </View>
    </View>
  );
}

const makeStyles = () => StyleSheet.create({
  gridSummary: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center'
  },
  leftColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 7
  },
  leftText: {
    fontFamily: 'Raleway-Regular',
    color: '#404040',
    fontSize: 13,
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    justifyContent: 'center'
  },
  rightText: {
    fontFamily: 'Raleway-SemiBold',
    color: '#404040',
    fontSize: 15
  },
  // Status Ball
  statusBallPending: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#FE1593',
    marginRight: 5
  },
  statusBallEnded: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#A9A9A9',
    marginRight: 5
  },
  statusBallBlocked: {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: '#d30000',
    marginRight: 5
  }
})