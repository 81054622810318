import AsyncStorage from "@react-native-async-storage/async-storage";
import screens from "../../common/constants/screen.constants";
import { navigate } from "../services/navigation.service";
import * as service from "../services/ticket.service";

// Definition of the actions name

export const ACTIONS = {
  TICKET_ERROR: 'TICKET_ERROR',
  TICKET_FETCHING: 'TICKET_FETCHING',
  TICKET_FETCHING_DONE: 'TICKET_FETCHING_DONE',
  TICKET_RESET_SEARCH_STATE: 'TICKET_RESET_SEARCH_STATE',
  //
  TICKET_CURRENT_TICKET: 'TICKET_CURRENT_TICKET',
  TICKET_MY_TICKET_LIST: 'TICKET_MY_TICKET_LIST',
}

export const set_state_error = (err) => {
  return { type: ACTIONS.TICKET_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.TICKET_FETCHING };
}
export const set_state_fetching_done = () => {
  return { type: ACTIONS.TICKET_FETCHING_DONE };
}
export const reset_search_state = () => {
  return { type: ACTIONS.TICKET_RESET_SEARCH_STATE };
}

export const set_current_ticket = (ticket) => {
  return { type: ACTIONS.TICKET_CURRENT_TICKET, payload: ticket };
}
export const set_my_ticket_list = (list) => {
  return { type: ACTIONS.TICKET_MY_TICKET_LIST, payload: list };
}

//

export const searchTicket = (reference) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getTicket(reference);
      if (r.data?.ticket) {
        dispatch(set_current_ticket(r.data?.ticket))
        navigate(screens.Ticket, { reference });
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}
export const searchTicketAndRedirect = (reference, navParams, navScreen = screens.Ticket) => {
  return async dispatch => {
    try {
      dispatch(set_state_fetching());
      let r = await service.getTicket(reference);
      if (r.data?.ticket) {
        dispatch(set_current_ticket(r.data?.ticket))
        const params = navParams;
        params.reference = reference;
        navigate(navScreen, Object.assign({reference}, navParams));
      }
      dispatch(set_state_fetching_done());
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const loadMyTickets = () => {
  return async dispatch => {
    const list = await service.LoadMyTicketsFromStorage();
    dispatch(set_my_ticket_list(list));
  }
}

export const addOrRefreshMyTicket = (list, ticket, game) => {
  return async dispatch => {
    const item = list.find(E => E.reference == ticket.reference);
    console.warn("addOrRefreshMyTicket", list, ticket, game);
    if (!item) {
      console.warn("new ticket, adding to list...", item, ticket);
      const new_item = service.shortenTicket(ticket, game);
      new_item && list.unshift(new_item); //push_front
      dispatch(set_my_ticket_list(list));
    }
    else if (item.gameName != game.name || item.drawingDate != service.getEarliestDrawingDate(ticket)) {
      console.warn("MyTicket update found", item, ticket, service.getEarliestDrawingDate(ticket));
      const new_item = service.shortenTicket(ticket, game);
      let new_list = list;
      new_list.splice(list.findIndex(E => E == item), 1, new_item);
      dispatch(set_my_ticket_list(new_list));
    }
  }
}